<script setup>
import { ref } from 'vue';
import { useVModel } from '@vueuse/core';

const props = defineProps({
  modelValue: {
    type: [Array, String, Object, Number],
  },
  options: {
    type: Array,
  },
  multiple: {
    type: Boolean,
  },
  mapOptions: {
    type: Boolean,
  },
  optionValue: {
    type: String,
  },
  optionLabel: {
    type: String,
  },
  offset: {
    type: Array,
  },
});

const emit = defineEmits(['update:modelValue', 'virtual-scroll']);

const model = useVModel(props, 'modelValue', emit);

const handleBlur = () => {
  pSelect.value.blur();
};

const handleFocus = () => {
  pSelect.value.focus();
};

defineExpose({
  handleBlur,
  handleFocus,
});

const pSelect = ref(null);
</script>

<template>
  <q-select
    v-bind="props"
    ref="pSelect"
    v-model="model"
    behavior="menu"
    :menu-offset="offset"
    @virtual-scroll="(details) => emit('virtual-scroll', details)"
  >
    <template
      v-if="$slots.prepend"
      #prepend
    >
      <slot name="prepend" />
    </template>
    <template
      v-if="$slots.append"
      #append
    >
      <slot name="append" />
    </template>
    <template
      v-if="$slots.hint"
      #hint
    >
      <slot name="hint" />
    </template>
    <template
      v-if="$slots.selected"
      #selected
    >
      <slot name="selected" />
    </template>
    <template
      v-if="$slots.after"
      #after
    >
      <slot name="after" />
    </template>

    <template #before-options>
      <slot name="before-options" />
    </template>
    <template
      #option="{
        index,
        opt,
        selected,
        focused,
        toggleOption,
        setOptionIndex,
        itemProps,
      }"
    >
      <slot
        name="option"
        :index="index"
        :opt="opt"
        :selected="selected"
        :focused="focused"
        :toggle-option="toggleOption"
        :set-option-index="setOptionIndex"
        :item-props="itemProps"
      />
    </template>
    <slot />
  </q-select>
</template>
