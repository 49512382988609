<script setup>
import { BaseApplicantBio, BaseBadge } from '@/components/Base';
import ApplicantActionButtons from '@/components/Elements/Applicant/ApplicantActionButtons.vue';
import { computed } from 'vue';

const props = defineProps({
  applicant: {
    type: Object,
    required: false,
    default: undefined,
  },
  rating: {
    type: Number,
    required: false,
    default: undefined,
  },
  onUnReject: {
    type: Function,
    required: true,
  },
  handleChangeStage: {
    type: Function,
    required: true,
  },
  isHired: {
    type: Boolean,
    required: true,
  },
  isStageChanging: {
    type: Boolean,
    required: true,
  },
  isRejected: {
    type: Boolean,
    required: true,
  },
  isFastTracked: {
    type: Boolean,
    required: false,
  },
  onHoverBadge: {
    type: Function,
    required: true,
  },
  adjacentApplications: {
    type: Object,
    required: false,
    default: undefined,
  },
  grade: {
    type: Object,
    required: false,
    default: undefined,
  },
  englishScore: {
    type: String,
    required: false,
    default: undefined,
  },
  codilityScore: {
    type: Number,
    required: false,
    default: undefined,
  },
  isLoading: {
    type: Boolean,
    required: false,
  },
  supplyDemandRatio: {
    type: Object,
    default() {
      return {};
    },
  },
});

const emit = defineEmits(['reject']);

const supplyDemandContext = {
  'High demand': {
    icon: 'chevron-up-double',
    color: 'Success',
  },
  Oversupply: {
    icon: 'chevron-down-double',
    color: 'Gray',
  },
};

const badges = computed(() =>
  [
    {
      color: 'Error',
      icon: '',
      isActive: props.isRejected,
      label: 'Rejected',
    },
    {
      color: 'Primary',
      icon: '',
      isActive: props.isFastTracked,
      label: 'Fast-track',
    },
    {
      color: supplyDemandContext[props.supplyDemandRatio?.label]?.color,
      icon: supplyDemandContext[props.supplyDemandRatio?.label]?.icon,
      isActive: props.supplyDemandRatio?.label,
      label: props.supplyDemandRatio?.label,
    },
    {
      color: 'Warning',
      icon: 'copy06',
      iconClass: 'text-proxify-warning-500',
      isActive: Boolean(props.applicant?.main_application_id),
      label: 'Duplicate',
    },
  ].filter((item) => item.isActive)
);
</script>
<template>
  <div class="flex justify-between px-8 py-6 bg-proxify-gray-25">
    <div class="flex gap-4">
      <BaseApplicantBio
        :avatar-url="applicant.applicant?.avatar"
        avatar-size="2xl"
        avatar-background-color="white"
        :name="applicant.applicant?.name ?? applicant.full_name"
        :supporting-text="applicant.competency"
        name-class="text-display-xs font-semibold font-poppins text-proxify-gray"
        supporting-text-class="!text-body-md !font-normal text-proxify-gray-600"
        :is-loading="isLoading"
      />
      <div class="flex gap-2 mt-1.5">
        <BaseBadge
          v-for="(item, key) in badges"
          :key="key"
          :color="item.color"
          :icon="item.icon"
          :class="{
            '!pr-2.5': !['Rejected', 'Fast-track'].includes(item.label),
            '!pl-0 !pr-3': item.label === 'Fast-track',
          }"
          class="select-none"
          icon-size="12px"
          :icon-class="item.iconClass"
          size="sm"
          dense
        >
          <template
            v-if="item.label === 'Fast-track'"
            #prepend
          >
            <div
              class="bg-proxify-primary-100 h-6 w-6 rounded-full inline-flex items-center justify-center text-body-xs font-bold"
            >
              F
            </div>
          </template>
          <div class="text-body-sm font-medium">{{ item.label }}</div>
        </BaseBadge>
      </div>
    </div>
    <ApplicantActionButtons
      :applicant="applicant"
      :on-un-reject="onUnReject"
      :is-hired="isHired"
      :is-stage-changing="isStageChanging"
      :is-rejected="isRejected"
      :handle-change-stage="handleChangeStage"
      @reject="emit('reject', $event)"
    />
  </div>
</template>
