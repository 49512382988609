<script setup>
const props = defineProps({
  maxHeight: String,
  maxWidth: {
    type: String,
    default: '20rem',
  },
  transitionShow: {
    type: String,
  },
  transitionHide: {
    type: String,
  },
  anchor: {
    type: String,
  },
  self: {
    type: String,
  },
  offset: {
    type: Array,
  },
  scrollTarget: {
    type: String,
  },
  target: {
    type: [Boolean, String, Object],
    required: false,
    default() {
      return true;
    },
  },
  noParentEvent: {
    type: Boolean,
  },
  delay: {
    type: Number,
  },
  hideDelay: {
    type: Number,
  },
});
</script>

<template>
  <q-tooltip v-bind="props">
    <slot />
  </q-tooltip>
</template>
