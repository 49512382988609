<template>
  <div :data-name="name">
    <div
      v-if="showTitle && (modelValue || !readOnly)"
      class="text-proxify-black mb-2"
    >
      <span>
        {{ name }}
      </span>
      <span
        v-if="required && !readOnly"
        class="text-disabled-normal font-normal text-xs"
      >
        *required
      </span>
    </div>
    <div
      v-if="readOnly"
      class="font-normal"
    >
      {{ modelValue }}
    </div>
    <div
      v-else
      class="activity-input-wrapper cursor-text"
      :class="{
        ...wrapperClasses
          .split(' ')
          .reduce((acc, cur) => ({ ...acc, [cur]: true }), {}),
        plain,
        'justify-end': !Boolean(attachments.length),
        'justify-between': Boolean(attachments.length),
      }"
      @click.self="handleFocus"
    >
      <div
        v-if="attachments.length"
        class="activity-input-attachments flex gap-2"
      >
        <div
          v-for="(file, i) in attachments"
          :key="`${file.name}_${i}`"
          class="attachments inline-flex"
        >
          <BaseIcon
            name="attachment02"
            size="20px"
          />
          <span class="truncate max-w-[6rem]">
            {{ file.name ?? file.original_name }}
          </span>
          <BaseIcon
            name="x-close"
            size="18px"
            class="text-proxify-gray-500 cursor-pointer"
            @click="handleRemoveFile(i)"
          />
        </div>
      </div>
      <div
        class="activity-input-container"
        :class="{ disabled }"
      >
        <slot name="input-header">
          <div
            v-if="isEditing"
            class="flex justify-between mt-4 px-4"
          >
            <div class="text-sm">Edit message</div>
            <button
              type="button"
              class="text-body-sm font-semibold text-proxify-primary-600 cursor-pointer"
              @click="onCancelEdit"
            >
              Cancel edit
            </button>
          </div>
        </slot>
        <QuillEditor
          ref="quill"
          v-model:content="deltaInput"
          :placeholder="placeholder"
          :options="quillOptions"
          :read-only="disabled"
          :enable="!disabled"
          :class="{ italic }"
          @text-change="onTextChange"
        />
      </div>
      <div class="flex justify-end gap-2 pr-2.5 pb-2.5">
        <slot name="input-footer"></slot>
        <BaseButton
          v-if="!disableActions"
          icon-prepend="attachment02"
          icon-size="20px"
          round
          class="icon-secondary-gray cursor-pointer"
          outlined
          @click="clickFileInput"
        ></BaseButton>
        <input
          ref="file"
          type="file"
          multiple
          class="hidden"
          @change="handleAddFile($event.target.files)"
        />
        <div
          class="relative"
          @mouseover="showSandClockIcon = true"
          @mouseleave="showSandClockIcon = false"
        >
          <transition name="fade">
            <BaseIcon
              v-show="activeSandClockIcon && showSandClockIcon"
              name="hourglass01"
              size="20px"
              class="ml-7 -mt-7 text-proxify-gray-300 absolute cursor-pointer"
              @click="openDialog"
            />
          </transition>
          <BaseButton
            v-if="!disableActions && !disableSendMessage"
            :button-label="submitButtonLabel"
            class="bg-primary text-white py-[10px] text-body-sm"
            rounded
            @click="handleSendMessage"
          />
        </div>
      </div>
    </div>
    <ErrorMessage
      v-if="!hideErrorMessage"
      v-slot="{ message }"
      :name="name"
      as="div"
      class="px-4 pt-2"
    >
      <span class="text-ats-red text-sm">{{ message }}</span>
    </ErrorMessage>
  </div>
</template>

<script>
import { useField } from 'vee-validate';
import { Delta } from '@vueup/vue-quill';
import { ref, toRefs, watchEffect } from 'vue';
import { BaseButton, BaseIcon } from '@/components/Base';

export default {
  name: 'AppFormTextEditor',
  components: {
    BaseButton,
    BaseIcon,
  },
  props: {
    wrapperClasses: {
      type: String,
      required: false,
      default() {
        return 'rounded-b border-t-[0.125rem] border-[#f3f3f5]';
      },
    },
    options: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
    hasInputHeader: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    activeSandClockIcon: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    modelValue: {
      type: [String, Object],
      required: false,
    },
    initialValue: {
      type: [String, Object],
      required: false,
    },
    contentToEdit: {
      type: [String, Object],
      required: false,
    },
    required: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    disabled: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    readOnly: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    showTitle: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    plain: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    maxLength: {
      type: Number,
      required: false,
    },
    onSendMessage: {
      type: Function,
      required: false,
    },
    onSaveMessage: {
      type: Function,
      required: false,
    },
    onCancelEdit: {
      type: Function,
      required: false,
    },
    disableActions: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    disableSendMessage: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    callbackFunction: {
      type: Function,
      required: false,
    },
    openDialog: {
      type: Function,
      required: false,
    },
    onUpdateHTML: {
      type: Function,
      required: false,
    },
    name: {
      type: String,
      required: false,
      default() {
        return '';
      },
    },
    placeholder: {
      type: String,
      required: false,
      default() {
        return 'Type in your response';
      },
    },
    uploadProgress: {
      type: Number,
      required: false,
      default() {
        return 100;
      },
    },
    outputType: {
      type: String,
      required: false,
      default() {
        return 'delta';
      },
    },
    outputOptions: {
      type: Object,
      required: false,
      default() {
        return {
          removeSpans: false,
          removeDatasets: true,
          removeBrs: true,
        };
      },
    },
    setEdited: {
      type: Function,
      required: false,
    },
    onAttachmentAdded: {
      type: Function,
      required: false,
    },
    onAttachmentRemoved: {
      type: Function,
      required: false,
    },
    hideErrorMessage: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    italic: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    submitButtonLabel: {
      type: String,
      default: 'Send',
    },
  },
  emits: ['input', 'update:modelValue'],
  setup(props) {
    const { required } = toRefs(props);
    const quill = ref(null);
    const { handleChange, errors, setTouched } = useField(props.name, {
      requiredDelta: required.value && props.outputType === 'delta',
      required: required.value && props.outputType === 'text',
    });
    watchEffect(() => {
      if (quill.value) {
        quill.value
          .getQuill()
          .clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
            let ops = [];
            delta.ops.forEach((op) => {
              if (op.insert && typeof op.insert === 'string') {
                ops.push(op);
              }
            });
            delta.ops = ops;
            return delta;
          });
      }
    });
    return {
      quill,
      handleChange,
      errors,
      setTouched,
    };
  },
  data() {
    return {
      deltaInput: undefined,
      lastInputBeforeEdit: {},
      textInput: '',
      mentionedUsers: [],
      lastMentionedUsersBeforeEdit: [],
      attachments: [],
      lastAttachmentsBeforeEdit: [],
      removedFiles: [],
      newAttachments: [],
      isSending: false,
      isEditing: false,
      showSandClockIcon: false,
      length: 0,
    };
  },
  computed: {
    quillOptions() {
      return {
        formats: ['mention', 'header', 'indent', 'link', 'list', 'blockquote', 'bold', 'italic', 'underline', 'strike'],
        ...this.options,
        modules: {
          ...this.options.modules,
          keyboard: {
            bindings: {
              shortkey_enter: {
                key: 13,
                shortKey: true,
                handler: () => {
                  this.handleSendMessage();
                },
              },
              enter: {
                key: 13,
                handler: (range) => {
                  const editor = this.$refs.quill.getQuill();
                  editor.insertText(range, '\n');
                },
              },
            },
          },
        },
      };
    },
  },
  watch: {
    initialValue: {
      handler(newValue) {
        this.$nextTick(() => {
          this.$refs.quill?.setContents(
            new Delta(
              typeof newValue === 'string' ? [{ insert: newValue }] : newValue
            )
          );
        });
      },
      immediate: true,
    },
    deltaInput: {
      deep: true,
      handler(newValue) {
        this.setMentionedUsers(newValue);
        this.handleChange(
          this.outputType === 'text'
            ? this.$refs.quill.getText().trim()
            : newValue
        );
        this.setTouched(
          JSON.stringify(newValue) !== JSON.stringify(this.initialValue)
        );
        this.setEdited?.(
          JSON.stringify(newValue) !== JSON.stringify(this.initialValue)
        );
        this.textInput = this.getHTML();
        this.onUpdateHTML?.(this.getHTML());

        this.$emit(
          'update:modelValue',
          this.outputType === 'text'
            ? this.$refs.quill.getText().trim()
            : newValue
        );
      },
    },
    contentToEdit: {
      deep: true,
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue) {
          if (oldValue === null) {
            this.lastInputBeforeEdit = this.deltaInput;
            this.lastAttachmentsBeforeEdit = this.attachments;
            this.lastMentionedUsersBeforeEdit = this.attachments;
          }
          this.$refs.quill?.setHTML(newValue?.body);
          this.attachments = newValue.attachments ?? [];
          this.mentionedUsers = newValue.mentionedUsers ?? [];
          this.isEditing = true;
          if (newValue.attachments.length) {
            this.onAttachmentAdded();
          }
        } else if (oldValue) {
          this.isEditing = false;
          this.$refs.quill.setContents(new Delta(this.lastInputBeforeEdit));
          this.attachments = this.lastAttachmentsBeforeEdit;
          this.removedFiles = [];
          this.newAttachments = [];
          this.mentionedUsers = this.lastMentionedUsersBeforeEdit;
          if (this.attachments.length) {
            this.onAttachmentAdded();
          } else {
            this.onAttachmentRemoved?.();
          }
        }
      },
    },
  },
  methods: {
    clickFileInput() {
      this.$nextTick(() => {
        this.$refs.file.click();
      });
    },
    onTextChange(parameters) {
      const { delta, oldContents, source } = parameters;
      if (source === 'api') return;
      if (this.maxLength) {
        const quill = this.$refs.quill?.getQuill();
        this.length = quill?.getLength() - 1;
        if (this.length > this.maxLength) {
          const retain = delta?.ops[0]?.retain ?? 0;
          const insertedTextLength = delta?.ops[1]
            ? delta?.ops[1]?.insert?.length
            : delta?.ops[0]?.insert?.length;
          const originalTextLength = oldContents?.ops[0]?.insert?.length;
          const textToInsertLength = this.maxLength - originalTextLength + 1;
          this.$nextTick(() => {
            quill?.deleteText(
              retain + textToInsertLength,
              insertedTextLength - textToInsertLength
            );
            quill?.setSelection(retain + textToInsertLength, 0);
          });
        }
      }
    },
    handleFocus() {
      this.$el.querySelector('.ql-editor').focus();
    },
    handleAddFile(fileList) {
      Array(fileList.length)
        .fill(null)
        .forEach((_, index) => {
          this.attachments = [...this.attachments, fileList.item(index)];
          if (this.isEditing) {
            this.newAttachments = [
              ...this.newAttachments,
              fileList.item(index),
            ];
          }
        });
      this.$refs.file.value = '';
      if (this.attachments.length) {
        this.onAttachmentAdded?.();
      }
    },
    getHTML() {
      const { removeSpans, removeDatasets, removeBrs } = this.outputOptions;
      const messageNode = this.$el.querySelector('.ql-editor').cloneNode(true);
      if (removeDatasets) {
        [...messageNode.querySelectorAll('.mention')].forEach((someElement) => {
          Object.keys(someElement.dataset).forEach((dataKey) => {
            delete someElement.dataset[dataKey];
          });
        });
      }
      if (removeBrs) {
        [...messageNode.querySelectorAll('p')]
          .filter(
            (p) => p.children.length === 1 && p.children[0].tagName === 'BR'
          )
          .forEach((p) => p.remove());
      }
      if (removeSpans) {
        return messageNode.innerHTML.replace(/<\/?span[^>]*>/g, '');
      }
      return messageNode.innerHTML;
    },
    setMentionedUsers(deltaValue) {
      if (
        (!this.options?.modules ||
          this.options?.modules?.mention?.mentionDenotationChars?.includes(
            '@'
          )) &&
        deltaValue.ops
      ) {
        this.mentionedUsers = Array.from(
          new Set([
            ...deltaValue.ops
              .map((item) => item.insert?.mention?.id)
              .filter(Boolean),
          ])
        );
      }
    },
    handleRemoveFile(index) {
      if (this.isEditing) {
        this.removedFiles = [...this.removedFiles, this.attachments[index]];
      }
      this.attachments = [
        ...this.attachments.slice(0, index),
        ...this.attachments.slice(index + 1),
      ];
      this.$refs.file.value = '';
      if (this.attachments.length === 0) {
        this.onAttachmentRemoved?.();
      }
    },
    async handleSendMessage() {
      if (this.isSending) return;
      if (this.isEditing) {
        try {
          this.isSending = true;
          const { id: note_id, application_id } = this.contentToEdit;
          const { textInput, newAttachments, removedFiles, mentionedUsers } =
            this;
          const data = new FormData();
          data.append('body', textInput);
          mentionedUsers.map((_, index) => {
            data.append('mentioned_users[]', mentionedUsers[index]);
          });
          Array(newAttachments.length)
            .fill(null)
            .forEach((_, index) => {
              data.append('attachments[]', newAttachments[index]);
            });
          Array(removedFiles.length)
            .fill(null)
            .forEach((_, index) => {
              data.append('removed_files[]', removedFiles[index].filename);
            });
          await this.onSaveMessage(data, { note_id, application_id });
          this.isSending = false;
          this.onCancelEdit();
        } catch (error) {
          console.log(error);
          this.isSending = false;
          this.onCancelEdit();
        }
      } else {
        try {
          this.isSending = true;
          const { id } = this.$route.params;
          const { textInput, attachments, mentionedUsers } = this;
          const data = new FormData();
          data.append('body', textInput);
          mentionedUsers.map((_, index) => {
            data.append('mentioned_users[]', mentionedUsers[index]);
          });
          Array(attachments.length)
            .fill(null)
            .forEach((_, index) => {
              data.append('attachments[]', attachments[index]);
            });
          await this.onSendMessage(data, { id });
          this.isSending = false;
          this.$refs.quill?.setContents(new Delta());
          this.attachments = [];
          this.onAttachmentRemoved?.();
          this.callbackFunction?.();
        } catch (error) {
          console.log(error);
          this.isSending = false;
        }
      }
    },
    clearContents() {
      this.$refs.quill?.setContents(new Delta());
    },
  },
};
</script>

<style scoped>
.activity-input-container {
  @apply bg-white relative w-full rounded-[inherit];
}

.activity-input-container.disabled {
  @apply cursor-not-allowed;
}

.activity-input-container.disabled :deep(.ql-editor) {
  @apply border-ats-light-grey bg-[#EEEEEE] pointer-events-none;
}

.activity-input-wrapper {
  @apply flex flex-col bg-white relative min-h-24;
}

.activity-input-wrapper.plain {
  @apply min-h-0 border-none;
}

.plain :deep(.ql-editor) {
  @apply p-0 font-normal;
}

.plain :deep(.ql-editor.ql-blank::before) {
  @apply left-0;
}

.activity-input-attachments {
  @apply bg-white
  whitespace-nowrap
  overflow-auto
  absolute
  -top-16
  right-0
  left-0
  bg-transparent;
}

.activity-attachment-chip {
  @apply relative
  bg-proxify-black
  rounded
  text-white
  whitespace-nowrap
  font-semibold
  text-center
  my-4
  ml-4
  inline-block
  w-full
  h-10
  leading-10
  max-w-36;
}

.activity-attachment-chip:last-child {
  @apply mr-4;
}

.activity-attachment-chip-icon {
  @apply absolute left-2.5 top-2.5;
}

.activity-attachment-delete-icon {
  @apply absolute top-3.5 right-3 cursor-pointer text-white;
}

.activity-attachment-chip-label {
  @apply absolute overflow-hidden text-ellipsis left-9 max-w-20;
}

.attachments {
  @apply py-2.5
  px-4
  bg-white
  text-proxify-gray-700
  rounded-full
  border
  border-proxify-gray-300;
}

.fade-enter-active {
  @apply transition duration-300;
}

.fade-leave-active {
  @apply transition duration-300 delay-[2000ms];
}

.fade-enter-from,
.fade-leave-to {
  @apply opacity-0;
}
</style>
