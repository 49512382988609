<template>
  <div class="inline-flex items-center gap-2">
    <PAvatar class="avatar">
      <span v-if="!avatar_url">
        {{ getInitials }}
      </span>
      <img
        v-else
        :src="avatar_url"
        :alt="`avatar-${alias ?? name}`"
      />
    </PAvatar>
    <div
      v-if="showName"
      :class="nameClass"
    >
      {{ getName }}
    </div>
  </div>
</template>
<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { PAvatar } from '@/components/ProxifyUI';

const props = defineProps({
  name: {
    type: String,
    required: false,
  },
  full_name: {
    type: String,
    required: false,
  },
  avatar_url: {
    type: String,
    required: false,
  },
  alias: {
    type: String,
    required: false,
  },
  showName: {
    type: Boolean,
    required: false,
    default() {
      return false;
    },
  },
  nameClass: {
    type: String,
    required: false,
    default() {
      return 'text-proxify-black';
    },
  },
});

const store = useStore();
const currentUser = computed(() => store.state.auth.user);
const getInitials = computed(() => {
  const names = props.name || props.full_name;
  if (!names) return '';
  const [firstName, ...otherNames] = names.split(' ');
  const lastName = otherNames.pop() || '';
  return (firstName[0] || '') + (lastName[0] || '');
});
const getName = computed(() => {
  return props.name === currentUser.value.name
    ? 'You'
    : props.name || props.full_name;
});
</script>
<style scoped>
.avatar {
  @apply text-body-sm
  font-normal
  rounded-full
  h-4
  w-4
  min-h-4
  min-w-4
  max-h-4
  max-w-4
  flex
  justify-center
  items-center
  overflow-hidden;
}
</style>
