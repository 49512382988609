import axios from 'axios';

export default {
  get({ id }) {
    return axios.get(`/applications/${id}/notes`);
  },
  post(body, { id, onUploadProgress }) {
    return axios.post(`/applications/${id}/notes`, body, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress,
    });
  },
  put(body, { application_id, note_id, onUploadProgress }) {
    return axios.post(
      `/applications/${application_id}/notes/${note_id}?_method=PUT`,
      body,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress,
      }
    );
  },
  delete({ application_id, note_id }) {
    return axios.delete(`/applications/${application_id}/notes/${note_id}`);
  },
};
