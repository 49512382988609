import axios from 'axios';

export default {
  getStages() {
    return axios.get('/stages');
  },
  counters({ id, ...parameters }) {
    return axios.get(`/stages/${id}/counters`, { params: parameters });
  },
};
