<template>
  <div ref="appMenuWrapper">
    <div ref="activator">
      <slot
        name="activator"
        :toggle="toggle"
      ></slot>
    </div>
    <div
      v-if="active"
      ref="appMenuContainer"
      class="app-menu-container"
      :style="{
        ...computedPositions,
      }"
    >
      <div
        v-if="isLoading && !options.length"
        class="p-4"
      >
        Loading
        <AppLoader size="0.19rem" />
      </div>
      <div v-else>
        <AppFormTextField
          v-if="hasSearchInput"
          ref="inputRef"
          v-model="query"
          name="menuSearchInput"
          hide-label
          :has-leading-icon="hasLeadingIcon"
          :has-trailing-icon="hasTrailingIcon"
          :rounded="false"
          :input-class="{
            '!border-t-0': true,
            '!border-x-0': true,
            'border-[#f3f3f5]': true,
          }"
          focus
          :placeholder="
            isOtherOptionSelected ? otherOptionPlaceholder : placeholder
          "
          :trailing-icon-action="onClearInput"
          :disable-focus-styling="true"
          :disabled="!options?.length"
          @click.stop
        />
        <ul
          v-if="options.length"
          ref="listContainer"
          class="options-list ats-scrollbar-persistent"
        >
          <li
            v-if="
              query.length &&
              computedOptions.length === 0 &&
              !isOtherOptionSelected
            "
            class="option-no-data text-ats-red"
          >
            Nothing found
          </li>
          <li
            v-if="hasCreateButton && query.length"
            class="option-item option-create relative cursor-pointer"
            @click="handleCreateNewOption(query)"
          >
            <span class="create-accent">Create</span>
            <span class="create-input">{{ query }}</span>
          </li>
          <li
            v-for="(option, index) in computedOptions"
            :key="`${option.id ?? option.value}-${index}`"
            :ref="optionRefs.set"
            class="option-item relative cursor-pointer"
            :class="{
              [highlightClass]: option[highlightValue],
              'selected-item': returnObject
                ? modelValue?.[itemValue] === option.value ||
                  modelValue?.[itemValue] === option.id
                : modelValue === option.value || modelValue === option.id,
              disabled:
                disabledOptions.includes(option.id ?? option.value) ||
                option.disabled,
            }"
            :title="hasTitle ? option.name : null"
          >
            <div
              class="flex justify-between items-center px-4 py-2"
              :data-testid="`value-name-${option.name
                .toLowerCase()
                .replaceAll(' ', '-')}`"
              @click.stop="closeOnClick ? toggle() : null"
              @click="
                !disabledOptions.includes(option.id ?? option.value) &&
                  !option.disabled &&
                  (handleChange?.(option.id ?? option.value) ??
                    option.onClick?.())
              "
            >
              <div class="truncate block text-heading-3">
                <slot name="optionPrefix"></slot>
                <span
                  v-if="option.prefix"
                  class="option-prefix"
                >
                  {{ option.prefix }}
                </span>
                {{ option.name }}
              </div>
              <span
                v-if="option.trailingText"
                class="trailing-text"
              >
                {{ option.trailingText }}
              </span>
              <IconBase
                v-if="
                  multiple &&
                  Array.isArray(modelValue) &&
                  (modelValue.includes(String(option.id)) ||
                    modelValue.includes(String(option.value)) ||
                    modelValue.includes(option.id) ||
                    modelValue.includes(option.value))
                "
                class="trailing-icon"
                border-radius="0.25rem"
              >
                <IconCheckbox />
              </IconBase>
            </div>
            <div
              v-if="option.explanationText && option.disabled"
              class="option-explanation-text"
              :class="{ 'pb-3': index === computedOptions.length - 1 }"
            >
              {{ option.explanationText }}
            </div>
          </li>
          <li
            v-if="hasOtherOption"
            class="option-item option-create relative cursor-pointer flex gap-2"
            @click="handleClickOtherOption"
          >
            <span class="create-accent">
              {{ `+ ${isOtherOptionSelected ? 'Add ' : ''}Other` }}
            </span>
            <span class="create-input truncate">{{ query }}</span>
          </li>
        </ul>
        <slot name="footer"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { useComputedPositions } from '@/composables/useComputedPositions';
import { computed, ref, toRefs, watch } from 'vue';
import {
  onClickOutside,
  useTemplateRefsList,
  useToggle,
  useVModel,
} from '@vueuse/core';
import AppFormTextField from '@/components/App/form/AppFormTextField';
import IconBase from '@/components/Icons/IconBase';
import IconCheckbox from '@/components/Icons/IconCheckbox';

export default {
  name: 'AppMenu',
  components: { AppFormTextField, IconBase, IconCheckbox },
  props: {
    modelValue: {
      type: [Array, Object, String],
      required: false,
      default() {
        return [];
      },
    },
    handleChange: {
      type: Function,
      required: false,
    },
    onClose: {
      type: Function,
      required: false,
    },
    targetElement: {
      required: false,
    },
    platformElement: {
      required: false,
    },
    relatedElements: {
      required: false,
    },
    menuPlacement: {
      type: String,
      required: false,
      default() {
        return 'bottom-end';
      },
    },
    positioningStrategy: {
      type: String,
      required: false,
    },
    menuOffsetX: {
      type: Number,
      required: false,
    },
    menuOffsetY: {
      type: Number,
      required: false,
    },
    menuPlacementSpacing: {
      type: Number,
      required: false,
    },
    options: {
      type: Array,
      required: true,
    },
    disabledOptions: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    isMenuActive: {
      type: Boolean,
      default() {
        return false;
      },
    },
    isLoading: {
      type: Boolean,
      default() {
        return false;
      },
    },
    multiple: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    closeOnClick: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    returnObject: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    itemText: {
      type: String,
      required: false,
      default() {
        return '';
      },
    },
    itemValue: {
      type: String,
      required: false,
      default() {
        return '';
      },
    },
    hasSearchInput: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    hasLeadingIcon: {
      type: [Boolean, Number],
      required: false,
      default() {
        return false;
      },
    },
    hasTrailingIcon: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    hasCreateButton: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    hasOtherOption: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    otherOptionPlaceholder: {
      type: String,
      required: false,
      default() {
        return 'Type other option...';
      },
    },
    hasTitle: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    updateTargetRectOnScroll: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    handleCreateNewOption: {
      type: Function,
      required: false,
      default(text) {
        this.handleChange(text);
        this.createdOptions = [
          ...(this.createdOptions ?? []),
          { value: text, name: text, trailingText: 0 },
        ];
      },
    },
    highlightValue: {
      type: String,
      required: false,
      default() {
        return 'risky';
      },
    },
    highlightClass: {
      type: String,
      required: false,
      default() {
        return 'text-ats-red';
      },
    },
    placeholder: {
      type: String,
      required: false,
      default() {
        return 'text-ats-red';
      },
    },
    containerWidth: {
      type: [String],
      default() {
        return '16rem';
      },
    },
  },
  emits: ['click:open', 'update:isMenuActive'],
  setup(props, { emit }) {
    const appMenuContainer = ref(null);
    const listContainer = ref(null);
    const activator = ref(null);
    const appMenuWrapper = ref(null);
    const inputReference = ref(null);
    const active = useVModel(props, 'isMenuActive', emit);
    const query = ref('');
    const isOtherOptionSelected = ref(false);
    const createdOptions = ref([]);
    const toggle = useToggle(active);
    const optionReferences = useTemplateRefsList();
    const {
      platformElement,
      targetElement,
      menuPlacement,
      menuOffsetX,
      menuOffsetY,
      menuPlacementSpacing,
      positioningStrategy,
      options,
      modelValue,
      relatedElements,
      updateTargetRectOnScroll,
    } = toRefs(props);
    const { positions } = useComputedPositions(
      computed(() =>
        targetElement.value ? targetElement.value : activator.value
      ),
      appMenuContainer,
      menuPlacement,
      {
        platformElement,
        offsetX: menuOffsetX.value,
        offsetY: menuOffsetY.value,
        spacingValue: menuPlacementSpacing.value,
        positioningStrategy: positioningStrategy.value,
        updateTargetRectOnScroll: updateTargetRectOnScroll.value,
      }
    );

    const onClearInput = () => {
      if (query.value) {
        query.value = '';
      } else {
        active.value = false;
        props.onClose?.();
      }
      isOtherOptionSelected.value = false;
    };

    const computedOptions = computed(() => {
      const syncedOptions = options.value.map((option) => {
        const { id, value } = option;
        const checks = [String(id), String(value), id, value];
        const isSelected =
          Array.isArray(modelValue.value) &&
          checks.some((check) => modelValue.value.includes?.(check));
        return {
          ...option,
          is_selected: isSelected,
        };
      });
      return !isOtherOptionSelected.value
        ? [
            ...syncedOptions.filter(({ is_selected }) => is_selected),
            ...createdOptions.value,
            ...syncedOptions.filter(({ name, value, is_selected }) => {
              return (
                !is_selected &&
                (name?.toLowerCase?.()?.includes(query.value.toLowerCase()) ||
                  value?.toLowerCase?.()?.includes(query.value.toLowerCase()))
              );
            }),
          ]
        : [];
    });

    const handleClickOtherOption = () => {
      if (query.value === '') {
        inputReference.value?.handleFocus?.();
      }
      if (isOtherOptionSelected.value) {
        props.handleChange?.(query.value);
        isOtherOptionSelected.value = false;
        active.value = false;
        query.value = '';
      } else {
        isOtherOptionSelected.value = true;
      }
    };

    watch(
      () => options.value,
      () => {
        query.value = '';
        inputReference.value?.handleFocus?.();
      },
      { deep: true, immediate: true }
    );

    onClickOutside(
      appMenuContainer,
      () => {
        query.value = '';
        active.value = false;
        isOtherOptionSelected.value = false;
      },
      {
        ignore: [targetElement, computed(() => relatedElements.value)],
      }
    );
    return {
      appMenuContainer,
      appMenuWrapper,
      listContainer,
      activator,
      inputRef: inputReference,
      computedPositions: positions,
      toggle,
      active,
      query,
      computedOptions,
      createdOptions,
      optionRefs: optionReferences,
      onClearInput,
      handleClickOtherOption,
      isOtherOptionSelected,
    };
  },
};
</script>

<style scoped>
.app-menu-container {
  @apply absolute
  bg-white
  text-proxify-black
  rounded
  box-border
  border
  border-proxify-black/5
  overflow-hidden
  z-20
  w-[v-bind(containerWidth)]
  font-inter;
}

.option-item {
  @apply font-semibold;
}

.option-item .trailing-icon,
.option-item .trailing-text {
  @apply text-proxify-primary;
}

.option-item:hover {
  @apply bg-proxify-primary text-white;
}

.option-item:hover .trailing-icon,
.option-item:hover .trailing-text {
  @apply bg-proxify-primary text-white;
}

.options-list {
  @apply max-h-[30rem];
}

.option-prefix {
  @apply text-disabled-normal;
}

.trailing-text {
  @apply ml-4
  text-xs;
}

.option-create,
.option-no-data {
  @apply px-4
  py-2
  truncate
  text-heading-3;
}

.option-create .create-accent {
  @apply text-proxify-primary;
}

.option-create .create-input {
  @apply font-normal;
}

.option-create:hover {
  @apply bg-proxify-primary
  text-white;
}

.option-create:hover .create-accent {
  @apply text-white;
}

.option-create:hover .create-input {
  @apply text-white;
}

.option-item:hover .trailing-text,
.option-item:hover .option-prefix {
  @apply text-white;
}

.option-item.disabled {
  @apply cursor-not-allowed border-ats-light-grey bg-[#EAEAEA] text-disabled-normal;
}

.option-explanation-text {
  @apply text-xs font-normal px-4 -mt-1.5;
}
</style>
