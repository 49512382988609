<template>
  <AppPopup
    :active="isPopupActive && showPopup"
    :platform-element="platformElement"
    menu-placement="bottom-start"
    :menu-offset-x="1"
  >
    <template #activator>
      <div
        class="app-chips-collapsing"
        :class="{ 'cursor-pointer': showPopup }"
        @mouseenter="activatePopup"
        @mouseleave="hidePopup"
      >
        <div
          class="chip"
          :style="`background-color: ${skillToShow?.skill?.color}`"
        >
          <div
            v-if="skillToShow?.main_skill"
            class="mr-2 text-center main-skill-circle"
            :style="`color: ${skillToShow?.skill?.color}`"
          >
            <div>M</div>
          </div>
          {{ skillToShow?.skill?.name }}
        </div>
        <button
          v-if="showPopup"
          ref="showMoreButton"
          class="mb-1 px-2 py-1.5 rounded-md text-white font-semibold bg-disabled-normal"
        >
          + {{ popupSkills?.length }}...
        </button>
      </div>
    </template>
    <template #content>
      <div class="flex flex-wrap">
        <div
          v-for="(chip, index) in popupSkills"
          :key="index"
          :ref="`chip${index}`"
          class="chip"
          :style="`background-color: ${chip.skill.color}`"
        >
          {{ chip.skill.name }}
        </div>
      </div>
    </template>
  </AppPopup>
</template>

<script>
export default {
  name: 'AppChipsCollapsing',
  props: {
    list: {
      type: Array,
      default() {
        return [];
      },
    },
    platformElement: {
      required: false,
    },
  },
  data() {
    return {
      isPopupActive: false,
      showPopupTimeout: null,
    };
  },
  computed: {
    popupSkills() {
      return this.list.filter(
        (item) => item.skill.id !== this.skillToShow?.skill?.id
      );
    },
    showPopup() {
      return this.popupSkills.length ? true : false;
    },
    skillToShow() {
      return this.list.find((item) => item.main_skill) || this.list[0];
    },
  },
  methods: {
    activatePopup() {
      this.showPopupTimeout = setTimeout(() => {
        this.isPopupActive = true;
      }, 500);
    },
    hidePopup() {
      clearTimeout(this.showPopupTimeout);
      this.isPopupActive = false;
    },
  },
};
</script>
<style scoped>
.app-chips-collapsing {
  @apply flex flex-wrap;
}

.chip {
  @apply mr-1 mb-1 px-2 py-1.5 rounded-md text-white font-semibold;
}

.main-skill-circle {
  @apply bg-white inline-block rounded-full h-4 w-4 text-2xs leading-[unset];
}
</style>
