<script setup>
import { computed, ref, watch, watchEffect } from 'vue';
import BaseBadge from '@/components/Base/BaseBadge.vue';
import BaseTooltip from '@/components/Base/BaseTooltip.vue';
import api from '@/api';
import { BaseButtonDropdown } from '@/components/Base';
import moment from 'moment';
import { PCircularProgress } from '@/components/ProxifyUI';
import { useBroadcastChannel } from '@vueuse/core';
import { useStore } from 'vuex';
import { useIntervalFn } from '@vueuse/core/index';

const props = defineProps({
  type: {
    type: String,
    required: true,
    validator: (value) => ['applicant', 'interview'].includes(value),
  },
});

const store = useStore();
const values = ref([]);
const delta = ref(null);
const verdict = ref(null);
const isLoading = ref(true);

const deltaText = computed(() => {
  const value = Math.round(delta.value);

  if (value > 0) {
    return `${value}% faster`;
  } else if (value < 0) {
    return `${-value}% slower`;
  } else {
    return '0%';
  }
});

const deltaColor = computed(() => {
  if (delta.value > 0) {
    return 'text-proxify-green-700';
  } else if (delta.value < 0) {
    return 'text-proxify-error-500';
  } else {
    return 'text-proxify-gray-400';
  }
});

const verdictText = computed(() => {
  if (props.type === 'applicant') {
    if (verdict.value === null) {
      return 'No applicants';
    }

    if (verdict.value < 1) {
      return '<1 day';
    }

    return `${verdict.value} days`;
  } else {
    const completedInterviewCount = values.value.find(
      (kpi) => kpi.label === 'Completed'
    ).value;
    return `${completedInterviewCount} interview${
      completedInterviewCount !== 1 ? 's' : ''
    }`;
  }
});

const interviewsNeedUpdate = computed(
  () => store.state.applicant.interviewsNeedUpdate
);
const isLogged = computed(() => store.getters['auth/isLogged']);

const { data: channelMessage } = useBroadcastChannel({
  name: 'data-needs-update',
});

const selectedDate = ref('This week');

const dateOptions = [{ label: 'This week' }, { label: 'Last week' }];

const getApplicantOverviewMetrics = async () => {
  const { data: { metrics } = {} } = await api.metrics.applicantOverview();

  delta.value = metrics.delta;
  verdict.value = metrics.median;

  values.value = [
    {
      value: metrics.joined_network,
      label: 'Joined network',
      color: 'blue',
    },
    {
      value: metrics.in_progress,
      label: 'In progress',
      color: 'pale',
    },
    {
      value: metrics.rejected,
      label: 'Rejected',
      color: 'red',
    },
  ];
};

const getInterviewOverviewMetrics = async () => {
  const startOfWeek =
    selectedDate.value === 'Last week'
      ? moment().subtract(1, 'week').startOf('week')
      : moment().startOf('week');

  const { data: { metrics } = {} } = await api.metrics.interviewOverview({
    start_date: startOfWeek.format('YYYY-MM-DD'),
  });

  values.value = [
    {
      value: metrics.completed,
      label: 'Completed',
      color: 'blue',
    },
    {
      value: metrics.pending_scorecard,
      label: 'Pending scorecard',
      color: 'pale',
    },
    {
      value: metrics.upcoming,
      label: 'Upcoming',
      color: 'gray',
    },
    {
      value: metrics.cancelled,
      label: 'Cancelled',
      color: 'red',
    },
  ];

  if (selectedDate.value === 'Last week') {
    values.value = values.value.filter((kpi) => kpi.label !== 'Upcoming');
  }
};

const fetchData = async () => {
  if (isLogged.value) {
    try {
      isLoading.value = true;
      if (props.type === 'applicant') {
        await getApplicantOverviewMetrics();
      } else {
        await getInterviewOverviewMetrics();
      }
    } catch (error) {
      console.error(error);
    } finally {
      isLoading.value = false;
    }
  }
};

watchEffect(() => {
  fetchData();
});

watch(
  () => [channelMessage.value, interviewsNeedUpdate.value],
  ([newChannelMessage, newTimestamp], oldValue) => {
    if (
      newChannelMessage?.data === 'interviews' ||
      (newTimestamp && newTimestamp !== oldValue?.[1])
    ) {
      fetchData();
    }
  },
  { deep: true }
);

useIntervalFn(
  () => {
    fetchData();
  },
  1000 * 60 * 5
);
</script>

<template>
  <div class="kpi-card">
    <div class="flex justify-between items-start gap-2">
      <div class="kpi-card__title">{{ type }} overview</div>
      <template v-if="!isLoading">
        <div
          v-if="type === 'applicant'"
          class="kpi-card__info"
        >
          Last 30 days
        </div>
        <BaseButtonDropdown
          v-if="type === 'interview'"
          v-model="selectedDate"
          :options="dateOptions"
          option-label="label"
          option-value="label"
          :search="false"
          menu-width="176px"
          @update:model-value="fetchData"
        >
          <template #activator>
            <BaseBadge
              icon="chevron-down"
              icon-position="right"
              icon-size="20px"
              color="transparent"
              class="!p-0 cursor-pointer h-[unset]"
            >
              {{ selectedDate }}
            </BaseBadge>
          </template>
        </BaseButtonDropdown>
      </template>
    </div>
    <div
      v-if="isLoading"
      class="h-[92px] flex items-center"
    >
      <PCircularProgress
        rounded
        indeterminate
        color="primary"
        class="w-full align-center"
      ></PCircularProgress>
    </div>
    <div
      v-else
      class="flex justify-between gap-2 flex-grow min-h-[92px]"
    >
      <div class="flex-grow flex flex-col justify-between">
        <div>
          <div class="kpi-card__description">
            {{
              type === 'applicant'
                ? 'Your median time to membership'
                : 'You have completed'
            }}
          </div>
          <div class="flex gap-2 items-center mb-4">
            <div class="kpi-card__verdict">
              {{ verdictText }}
            </div>
            <div
              v-if="delta !== null"
              class="kpi-card__delta"
              :class="deltaColor"
            >
              {{ deltaText }}
            </div>
            <BaseBadge
              v-if="type === 'interview'"
              size="sm"
              icon="info-circle"
              color="transparent"
              class="cursor-pointer !pl-0"
            >
              <BaseTooltip
                max-width="190px"
                :offset="[0, 10]"
              >
                Completed interviews are successfully happened, and scorecards
                filled.
              </BaseTooltip>
            </BaseBadge>
          </div>
        </div>
        <div>
          <div class="bars">
            <div
              v-for="(kpi, index) in values"
              :key="index"
              class="bar"
              :class="kpi.color"
              :style="{ flexGrow: kpi.value }"
            ></div>
          </div>
        </div>
      </div>
      <ul class="legends">
        <li
          v-for="(kpi, index) in values"
          :key="index"
          :class="kpi.color"
        >
          {{ kpi.label }}
          <span class="ml-2">{{ kpi.value }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.kpi-card {
  @apply rounded-xl border border-proxify-gray-200 bg-white shadow-sm p-6 flex flex-col gap-4;

  &__title {
    @apply text-body-lg font-semibold text-proxify-gray-900;

    &::first-letter {
      @apply capitalize;
    }
  }

  &__description {
    @apply block font-medium text-proxify-gray-600 text-body-md mb-1;
  }

  &__verdict {
    @apply font-poppins text-xl font-semibold text-proxify-gray-900;
  }

  &__delta {
    @apply text-sm font-medium;
  }

  &__info {
    @apply text-sm font-normal text-proxify-gray-600 text-right;
  }
}

.bars {
  @apply flex flex-grow items-stretch h-3 max-w-[320px];

  .bar {
    @apply bg-proxify-primary-100;

    &.red {
      @apply bg-proxify-error-500 order-first;
    }

    &.blue {
      @apply bg-proxify-primary-700;
    }

    &.gray {
      @apply bg-proxify-gray-400 order-last;
    }
  }
}

.legends {
  @apply text-sm text-proxify-gray-600 font-normal max-w-[320px] flex flex-col gap-1 self-end;

  li {
    @apply flex items-center justify-between gap-2 whitespace-nowrap;

    span {
      @apply grow text-right;
    }

    &::before {
      @apply block w-2 h-2 rounded-full bg-proxify-primary-100;

      content: '';
    }

    &.red::before {
      @apply bg-proxify-error-400;
    }

    &.blue::before {
      @apply bg-proxify-primary-700;
    }

    &.gray::before {
      @apply bg-proxify-gray-400;
    }
  }
}
</style>
