export default {
  /**
   * Generates a list of groups and items, including an optional karat item.
   *
   * @param {Object} options - The configuration options for the function.
   * @param {Object} options.groupsData - The groups' data.
   * @param {Array} options.itemsData - The items' data.
   * @param {boolean} [options.isKaratEnabled=false] - Indicates whether to include the karat item.
   * @param {string} [options.groupNamePrefix="Group"] - The prefix for the group name.
   * @param {Object} [options.karatItem={ id: "karat", name: "Karat", value: "karat" }] - The karat item to include if isKaratEnabled is true.
   * @returns {Array} The generated list of groups and items.
   */
  generateGroupList: ({
    groupsData,
    itemsData,
    isKaratEnabled = false,
    groupNamePrefix = 'Group',
    karatItem = { name: 'Karat', value: 'karat' },
  }) => {
    try {
      const groups = Object.entries(groupsData || {}).map(([key, name]) => {
        return {
          name: name.replace(groupNamePrefix, ''),
          prefix: groupNamePrefix,
          totalClass: 'text-disabled-dark',
          value: key,
          id: parseInt(key),
          type: 'group',
        };
      });

      return [
        ...(isKaratEnabled ? [karatItem] : []),
        ...groups,
        ...(itemsData ?? []),
      ];
    } catch (error) {
      console.error(error);
      return [];
    }
  },
};
