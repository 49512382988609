<script setup>
import { computed, ref } from 'vue';
import { BaseBadge, BaseButton, BaseIcon } from '@/components/Base';
import { useElementBounding } from '@vueuse/core';
import useNormalizedWindowSize from '@/composables/useNormalizedWindowSize';

defineProps({
  icon: {
    type: String,
    required: false,
  },
  title: {
    type: String,
    required: false,
  },
  titleBadge: {
    type: [String, Number],
    required: false,
  },
});

const emit = defineEmits(['close']);
const ghostElement = ref(null);
const { top } = useElementBounding(ghostElement);
const { height } = useNormalizedWindowSize();
const containerHeight = computed(() => {
  return height.value - top.value - 74;
});
</script>
<template>
  <div class="p-slide-menu-container h-screen">
    <div class="p-slide-menu-close">
      <BaseButton
        icon-prepend="x-close"
        icon-size="20px"
        class="p-2.5 absolute top-3 right-4 text-proxify-gray-500"
        @click="emit('close')"
      />
    </div>
    <div class="p-slide-menu-header">
      <div class="p-slide-menu-header__top">
        <BaseIcon
          v-if="icon"
          :name="icon"
          size="20px"
          class="text-proxify-gray-700"
        />
        <div class="text-body-xl font-semibold">{{ title }}</div>
        <BaseBadge v-if="titleBadge" color="error">{{ titleBadge }}</BaseBadge>
      </div>
      <div v-if="$slots.tabs" class="p-slide-menu-header__tabs">
        <slot name="tabs"></slot>
      </div>
      <div v-if="$slots.filters" class="p-slide-menu-header__filter">
        <slot name="filters"></slot>
      </div>
    </div>
    <div ref="ghostElement" class="w-0 h-0"></div>
    <div class="p-slide-menu-body" :style="{ height: containerHeight + 'px' }">
      <slot></slot>
    </div>
    <div v-if="$slots.footer" class="p-slide-menu-footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.p-slide-menu {
  &-container {
    @apply flex
    flex-col
    w-[400px]
    bg-white
    border-l
    border-proxify-gray-200
    shadow-proxify-xl
    pt-6
    relative;
  }
  &-header {
    @apply flex
    flex-col
    gap-6
    pb-4
    select-none;
    &__top {
      @apply flex
      gap-2
      px-6
      pb-2
      items-center;
    }
    &__filter {
      @apply flex
      justify-between
      px-6;
    }
  }
  &-body {
    @apply overflow-y-auto
    overflow-x-hidden
    proxify-scrollbar;
  }
  &-footer {
    @apply px-6
    py-4
    border-t
    border-proxify-gray-200;
  }
}
</style>
