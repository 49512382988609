import api from '@/api';

const state = {
  dataNeedsUpdate: { timestamp: 0, refresh: false },
  interviewsNeedUpdate: 0,
  recruiterChanged: 0,
  sourcers: [],
  recruiters: null,
  stages: [],
  users: [],
  skills: [],
  countries: [],
  industries: [],
  rejectionReasons: [],
};

const getters = {};

const actions = {
  async getRecruiters({ commit, state }) {
    if (!state.recruiters) {
      const { data: recruiters } = await api.users.recruiters();
      commit('setRecruiters', recruiters.data);
    }
  },
};

const mutations = {
  setSkills(state, skills) {
    state.skills = skills;
  },
  setCountries(state, countries) {
    state.countries = Object.values(countries);
  },
  setIndustries(state, industries) {
    state.industries = industries;
  },
  setDataNeedsUpdate(state, refresh = false) {
    state.dataNeedsUpdate = { timestamp: Date.now(), refresh };
  },
  setInterviewsNeedUpdate(state) {
    state.interviewsNeedUpdate = Date.now();
  },
  setRecruiterChanged(state) {
    state.recruiterChanged = Date.now();
  },
  setSourcers(state, values) {
    state.sourcers = values;
  },
  setRecruiters(state, values) {
    state.recruiters = values;
  },
  setStages(state, values) {
    state.stages = values;
  },
  setUsers(state, values) {
    state.users = values;
  },
  setRejectionReasons(state, values) {
    state.rejectionReasons = values;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
