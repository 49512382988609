<template>
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#a)">
      <rect x="4" y="4" width="16" height="16" rx="8" fill="currentColor" />
    </g>
    <defs>
      <filter
        id="a"
        x="-4"
        y="-2"
        width="32"
        height="32"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="4" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_9906_16407"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_9906_16407"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
</template>
