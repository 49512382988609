<template>
  <Field
    v-model="item"
    as="div"
    :name="name"
    :data-name="name"
    validate-on-model-update
    :rules="{ required }"
    :data-testid="`${name.toLowerCase().replaceAll(' ', '-')}-selector`"
    class="inline-flex flex-col gap-2 text-proxify-gray font-inter text-body-sm"
  >
    <p v-if="hasTitle" class="font-semibold">
      {{ name }}
      <span
        v-if="required || showRequiredLabel"
        class="text-disabled-normal font-normal text-xs"
        >*required</span
      >
    </p>
    <div class="inline-flex w-full">
      <button
        ref="trigger"
        type="button"
        class="flex items-center truncate leading-none"
        :class="
          ({
            'contained-button': contained,
            'button-active': active,
            'opacity-50': disabled,
            'cursor-not-allowed': disabled,
          },
          initialClass)
        "
        :disabled="disabled"
        @click="onToggle()"
      >
        <div
          class="initial-value"
          :class="{ [highlightClass]: valueObject?.[highlightValue] }"
        >
          {{
            (returnObject && valueObject?.[itemText]) || item || initialValue
          }}
        </div>
        <BaseIcon
          name="chevron-down"
          class="transition duration-300"
          :class="[active ? 'rotate-180' : '']"
          size="20px"
        />
      </button>
    </div>
    <ErrorMessage v-if="name" v-slot="{ message }" :name="name">
      <span class="text-ats-red text-sm"> {{ message }}</span>
    </ErrorMessage>
  </Field>
</template>
<script>
import BaseIcon from '@/components/Base/BaseIcon.vue';

export default {
  name: 'ValueSelectorTrigger',
  components: {
    BaseIcon,
  },
  props: {
    active: {
      type: Boolean,
      required: false,
    },
    contained: {
      type: Boolean,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    hasTitle: {
      type: Boolean,
      required: false,
    },
    required: {
      type: Boolean,
      required: false,
    },
    showRequiredLabel: {
      type: Boolean,
      required: false,
    },
    name: {
      type: String,
      required: false,
    },
    onToggle: {
      type: Function,
      required: false,
    },
    modelValue: {
      type: [String, Number, Object],
      required: false,
    },
    items: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    returnObject: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    itemText: {
      type: String,
      required: false,
      default() {
        return '';
      },
    },
    itemValue: {
      type: String,
      required: false,
      default() {
        return '';
      },
    },
    highlightValue: {
      type: String,
      required: false,
      default() {
        return '';
      },
    },
    highlightClass: {
      type: String,
      required: false,
      default() {
        return 'text-ats-red';
      },
    },
    initialValue: {
      type: String,
      required: false,
    },
    initialClass: {
      type: String,
      required: false,
      default: 'text-proxify-gray-700',
    },
  },
  data() {
    return {
      item: '',
    };
  },
  computed: {
    valueObject() {
      const { items, itemValue, modelValue } = this;
      return items?.find((item) => item[itemValue] === modelValue);
    },
  },
  watch: {
    modelValue: {
      handler(newValue) {
        this.item = newValue;
      },
      immediate: true,
    },
  },
};
</script>
<style>
.initial-value {
  @apply block truncate mr-2 font-semibold;
}

.contained-button {
  @apply rounded p-2 flex justify-between items-center;

  background-color: #f9f9f9;
}

.contained-button.button-active {
  @apply bg-proxify-primary;
}

.contained-button .initial-value {
  @apply text-proxify-gray;
}

.contained-button.button-active .initial-value {
  @apply text-white;
}
</style>
