import { QuillEditor } from '@vueup/vue-quill';

export default (app) => {
  const globalOptions = {
    debug: false,
    theme: 'snow',
  };
  QuillEditor.props.globalOptions.default = () => globalOptions;
  app.component('QuillEditor', QuillEditor);
};
