<script>
/**
 * @typedef { import('vue').Ref } Ref
 */

/**
 * @typedef {Object} ApplicationSkill
 * @property {?number} skill_id - ID of the skill.
 * @property {?number} proficiency_level - Proficiency level of the skill.
 * @property {?number} years - Years of experience of the skill.
 * @property {?boolean} main_skill - Indicates if the skill is a main skill.
 * @property {?Object} skill - Skill object.
 * @property {?number} skill.id - ID of the skill.
 * @property {?string} skill.name - Name of the skill.
 */
/**
 * @typedef {Object} ApplicationFormExperience
 * @property {?ApplicationSkill[]} applicationSkills - Skills of the applicant.
 * @property {?ApplicationSkill[]} skills - Skills of the applicant.
 * @property {?number} commercialExperienceMonths - Commercial experience of the applicant.
 * @property {?string} competency - Competency of the applicant.
 * @property {?number} englishLevel - English level of the applicant.
 * @property {?number} freelanceExperienceMonths - Freelance experience of the applicant.
 */

export default {
  name: 'ApplicationFormExperience',
};
</script>

<script setup>
import {
  BaseBadge,
  BaseIcon,
  BaseExpandableCard,
  BaseInputDropdown,
  BaseSkillSelector,
  BaseTooltip,
} from '@/components/Base';
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { answers } from '@/constants/filters';

const props = defineProps({
  commercialExperienceMonths: {
    type: Number,
    required: false,
    default: undefined,
  },
  competency: {
    type: String,
    required: false,
    default() {
      return '';
    },
  },
  englishLevel: {
    type: Number,
    required: false,
    default() {
      return null;
    },
  },
  freelanceExperienceMonths: {
    type: Number,
    required: false,
    default: undefined,
  },
  applicationSkills: {
    /** @type {{ new (): ApplicationSkill[] }} */
    type: Array,
    required: false,
    default() {
      return [];
    },
  },
  requiredFields: {
    type: Array,
    required: false,
    default() {
      return [];
    },
  },
  allowNameOnlySkills: {
    type: Boolean,
    required: false,
    default() {
      return false;
    },
  },
  source: {
    type: String,
    required: false,
    default: undefined,
  },
});
const emit = defineEmits([
  'update:application-skills',
  'update:commercial-experience-months',
  'update:competency',
  'update:english-level',
  'update:freelance-experience-months',
]);

const store = useStore();
const skills = computed(() => store.state.applicant.skills);
/** @returns {ApplicationSkill[]} */
const filteredSkills = computed(() => {
  const selectedSkillsIds = form.value.skills.map((item) => item.skill?.id);
  return skills.value.filter((item) => {
    return !selectedSkillsIds.includes(item.id);
  });
});
/** @returns {boolean} */
const hasRequiredField = computed(() => {
  return [
    'Commercial experience',
    'Freelance experience',
    'Competency',
    'English level',
    'Main skills',
    'Secondary skills',
  ].some((item) => {
    return props.requiredFields.includes(item);
  });
});
/** @returns {ApplicationSkill[]} */
const mainSkills = computed(() =>
  form.value.skills.filter((item) => item.main_skill)
);
/** @returns {ApplicationSkill[]} */
const secondarySkills = computed(() =>
  form.value.skills.filter((item) => !item.main_skill)
);

/** @type {Ref<ApplicationFormExperience>} */
const form = ref({});
/** @type {Ref<boolean>} */
const expanded = ref(false);

const selectedMainSkill = ref({
  skill: null,
  years: null,
  proficiency_level: null,
});
const selectedSecondarySkill = ref({
  skill: null,
  years: null,
  proficiency_level: null,
});

/**
 * @param skill
 * @param {number} skill.skill_id
 * @param {boolean} skill.main_skill
 */
const handleRemoveSkill = (skill) => {
  form.value = {
    ...form.value,
    skills: form.value.skills.filter(
      (item) => item.skill_id !== skill.skill_id
    ),
  };
};
const handleAddSkill = (selectedSkill) => {
  const skillId = selectedSkill.skill?.id ?? selectedSkill.skill_id;
  const years = selectedSkill.years;
  const proficiencyLevel = selectedSkill.proficiency_level?.value;
  const mainSkill = selectedSkill.main_skill;
  const skillItem = {
    skill: skills.value.find(({ id }) => id === skillId),
    years,
    skill_id: skillId,
    proficiency_level: proficiencyLevel,
    main_skill: mainSkill,
  };
  if (
    (skillId && years && proficiencyLevel) ||
    (props.allowNameOnlySkills && skillId)
  ) {
    form.value = {
      ...form.value,
      skills: [
        ...form.value.skills.filter((item) => item.skill_id !== skillId),
        skillItem,
      ],
    };
  }
};

watch(
  () => ({
    commercialExperienceMonths: props.commercialExperienceMonths,
    freelanceExperienceMonths: props.freelanceExperienceMonths,
    competency: props.competency,
    englishLevel: props.englishLevel,
    applicationSkills: props.applicationSkills,
  }),
  /** @param {ApplicationFormExperience} newProps */
  (newProps) => {
    form.value = {
      commercialExperienceMonths: newProps.commercialExperienceMonths,
      freelanceExperienceMonths: newProps.freelanceExperienceMonths,
      competency: newProps.competency,
      englishLevel: [1, 2, 3, 4].includes(newProps.englishLevel)
        ? newProps.englishLevel
        : null,
      skills: newProps.applicationSkills.map(({ skill, ...rest }) => ({
        skill: skills.value.find(({ id }) => id === skill.id),
        skill_id: skill.id,
        ...rest,
      })),
    };
    expanded.value = Boolean(
      newProps.commercialExperienceMonths ||
        newProps.freelanceExperienceMonths ||
        newProps.competency ||
        newProps.englishLevel ||
        newProps.applicationSkills.length
    );
  },
  { deep: true, immediate: true }
);

watch(
  () => form.value,
  /** @param {ApplicationFormExperience} newForm */
  (newForm) => {
    emit(
      'update:commercial-experience-months',
      newForm.commercialExperienceMonths
    );
    emit(
      'update:freelance-experience-months',
      newForm.freelanceExperienceMonths
    );
    emit('update:competency', newForm.competency);
    emit('update:english-level', newForm.englishLevel);
    emit('update:application-skills', newForm.skills);
  },
  { deep: true, immediate: true }
);

watch(
  () => props.source,
  (newSource) => {
    if (newSource !== 'sourced') {
      form.value = {
        ...form.value,
        skills: (form.value.skills ?? []).filter(
          (item) => item.years && item.proficiency_level
        ),
      };
    }
  }
);
defineExpose({
  hasRequiredField,
  form,
  expanded,
  mainSkills,
  secondarySkills,
  filteredSkills,
  handleRemoveSkill,
  handleAddSkill,
});
</script>
<template>
  <KeepAlive>
    <component
      :is="!hasRequiredField ? BaseExpandableCard : 'div'"
      v-model="expanded"
      :class="{ 'border-none !shadow-none !px-0 !py-0': !hasRequiredField }"
    >
      <template v-if="hasRequiredField">
        <div
          class="text-body-md font-semibold text-proxify-gray-900 mb-4"
          data-testid="static-header"
        >
          Skills and experience
        </div>
      </template>
      <template
        v-if="!hasRequiredField"
        #header
      >
        <div class="flex flex-col gap-2">
          <div class="flex gap-2 items-center">
            <div class="text-body-md font-semibold text-proxify-gray-900">
              Skills and experience
            </div>
            <BaseBadge class="!text-body-xs !font-medium !px-2 !py-0.5">
              Optional
            </BaseBadge>
          </div>
          <Transition>
            <div
              v-if="!expanded"
              class="text-proxify-gray-600 font-normal text-body-sm"
            >
              Add experience, competency, English level and primary and
              secondary skills.
            </div>
          </Transition>
        </div>
      </template>
      <div class="flex flex-col gap-4">
        <div class="grid gap-4 grid-cols-2">
          <div class="flex flex-col gap-[6px]">
            <div class="text-body-sm font-medium text-proxify-gray-700">
              <span>Commercial experience</span>
              <span v-if="requiredFields.includes('Commercial experience')">
                *
              </span>
            </div>
            <BaseInputDropdown
              v-model="form.commercialExperienceMonths"
              name="Commercial experience"
              :options="answers.experience"
              map-options
              option-value="value"
              emit-value
              type="search"
            />
          </div>
          <div class="flex flex-col gap-[6px]">
            <div class="text-body-sm font-medium text-proxify-gray-700">
              <span>Freelance experience</span>
              <span v-if="requiredFields.includes('Freelance experience')">
                *
              </span>
            </div>
            <BaseInputDropdown
              v-model="form.freelanceExperienceMonths"
              name="Freelance experience"
              :options="answers.experience"
              map-options
              option-value="value"
              emit-value
              type="search"
            />
          </div>
        </div>
        <div class="grid gap-4 grid-cols-2">
          <div class="flex flex-col gap-[6px]">
            <div class="text-body-sm font-medium text-proxify-gray-700">
              <span>Competency</span>
              <span v-if="requiredFields.includes('Competency')">*</span>
            </div>
            <BaseInputDropdown
              v-model="form.competency"
              name="Competency"
              :options="
                answers.competency.map((item) => ({
                  value: item,
                  name: item,
                }))
              "
              map-options
              option-value="value"
              emit-value
              type="search"
            />
          </div>
          <div class="flex flex-col gap-[6px]">
            <div class="text-body-sm font-medium text-proxify-gray-700">
              <span>English level</span>
              <span v-if="requiredFields.includes('English level')">*</span>
            </div>
            <BaseInputDropdown
              v-model="form.englishLevel"
              name="English level"
              :options="answers.english_level"
              option-value="value"
              map-options
              emit-value
            />
          </div>
        </div>
        <div class="flex flex-col gap-2">
          <BaseSkillSelector
            v-model="selectedMainSkill"
            :skills="filteredSkills"
            :disable="
              mainSkills.length > 1 &&
              mainSkills.every((item) => item.years && item.proficiency_level)
            "
            title="Primary skills"
            :required="requiredFields.includes('Main skills')"
            :allow-name-only="allowNameOnlySkills"
            reset-model-value
            @update:model-value="
              (value) => handleAddSkill({ ...value, main_skill: true })
            "
          />
          <Field
            name="Main skills"
            :model-value="mainSkills"
          >
            <div
              v-if="mainSkills.length"
              class="flex gap-2"
            >
              <BaseBadge
                v-for="skill in mainSkills"
                :key="skill.skill_id"
                outline
                class="w-fit !text-proxify-midnight-blue border-proxify-gray-300 !text-body-sm"
                color="white"
              >
                <span
                  class="cursor-pointer"
                  @click="() => (selectedMainSkill = skill)"
                >
                  {{ skill.skill?.name }}
                </span>
                <span v-if="skill.years">— {{ skill.years }}y</span>
                <BaseTooltip
                  v-if="skill.proficiency_level"
                  :offset="[0, 6]"
                >
                  {{ answers.proficiency_level[skill.proficiency_level - 1] }}
                </BaseTooltip>
                <template #append>
                  <BaseIcon
                    name="x-close"
                    size="24px"
                    class="cursor-pointer text-proxify-gray-400"
                    @click="
                      handleRemoveSkill({
                        skill_id: skill.skill.id,
                        main_skill: true,
                      })
                    "
                  />
                </template>
              </BaseBadge>
            </div>
            <ErrorMessage
              v-slot="{ message }"
              name="Main skills"
            >
              <div class="text-ats-red text-sm">
                {{ message }}
              </div>
            </ErrorMessage>
          </Field>
        </div>
        <div class="flex flex-col gap-2">
          <BaseSkillSelector
            v-model="selectedSecondarySkill"
            :skills="filteredSkills"
            title="Secondary skills"
            :required="requiredFields.includes('Secondary skills')"
            :allow-name-only="allowNameOnlySkills"
            reset-model-value
            @update:model-value="
              (value) => handleAddSkill({ ...value, main_skill: false })
            "
          />
          <Field
            name="Secondary Skills"
            :model-value="secondarySkills"
            class="flex gap-2"
          >
            <BaseBadge
              v-for="skill in secondarySkills"
              :key="skill.skill_id"
              outline
              class="w-fit !text-proxify-midnight-blue border-proxify-gray-300 !text-body-sm"
              color="white"
            >
              <span
                class="cursor-pointer"
                @click="selectedSecondarySkill = skill"
              >
                {{ skill.skill?.name }}
              </span>
              <span v-if="skill.years">— {{ skill.years }}y</span>
              <BaseTooltip
                v-if="skill.proficiency_level"
                :offset="[0, 6]"
              >
                {{ answers.proficiency_level[skill.proficiency_level - 1] }}
              </BaseTooltip>
              <template #append>
                <BaseIcon
                  name="x-close"
                  size="24px"
                  class="cursor-pointer text-proxify-gray-400"
                  @click="
                    handleRemoveSkill({
                      skill_id: skill.skill.id,
                      main_skill: false,
                    })
                  "
                />
              </template>
            </BaseBadge>
            <ErrorMessage
              v-slot="{ message }"
              name="Secondary skills"
            >
              <div class="text-ats-red text-sm">
                {{ message }}
              </div>
            </ErrorMessage>
          </Field>
        </div>
      </div>
    </component>
  </KeepAlive>
</template>

<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.4s;
}

.v-enter,
.v-leave-to {
  opacity: 0;
}
</style>
