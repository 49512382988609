<template>
  <div
    ref="containerRef"
    class="flex flex-row justify-between w-full relative cursor-default"
  >
    <div
      ref="titleRef"
      :class="titleClass"
    >
      {{ title }}
    </div>
    <div
      ref="valueRef"
      :class="valueClass"
      class="value-class"
      :data-testid="`${title.toLowerCase().replaceAll(' ', '-')}-data`"
    >
      <BaseIcon
        v-if="verified"
        name="check-verified03"
        class="verified-icon"
        size="16px"
      />
      <span v-safe-html="value"></span>
      <slot></slot>
      <slot name="append"></slot>
    </div>
    <span
      v-if="showTooltip"
      v-safe-html="value"
      class="tooltiptext"
    ></span>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import { BaseIcon } from '@/components/Base';

export default {
  name: 'TextData',
  components: { BaseIcon },
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
    titleClass: {
      type: [Object, String],
      required: false,
      default() {
        return { 'text-proxify-gray font-medium text-body-sm': true };
      },
    },
    value: {
      type: String,
      required: false,
      default: '',
    },
    valueClass: {
      type: [Object, String],
      required: false,
      default() {
        return '';
      },
    },
    verified: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    const valueReference = ref(null);

    const isEllipsisActive = (element) => {
      return element.offsetWidth < element.scrollWidth;
    };

    const showTooltip = computed(() => {
      if (!valueReference.value) return false;
      return isEllipsisActive(valueReference.value);
    });

    return {
      valueRef: valueReference,
      showTooltip,
    };
  },
};
</script>

<style lang="scss" scoped>
.value-class {
  @apply relative inline-flex text-right pl-1 text-proxify-gray font-medium text-body-sm truncate items-center gap-1;
}

.value-class + .tooltiptext {
  @apply py-2 px-3 bg-white text-proxify-black whitespace-pre rounded absolute right-0 invisible bottom-full shadow-sm;

  transition:
    visibility 0.2s linear,
    opacity 0.2s linear;
  z-index: 1;
}

.value-class:hover + .tooltiptext {
  @apply visible;
}

.verified-icon {
  @apply text-proxify-gray-500;

  :deep(svg) {
    stroke-width: 1.3px;
  }
}
</style>
