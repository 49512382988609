<template>
  <BaseButton
    class="icon-link-gray p-0"
    icon-prepend="settings03"
  >
    <PPopupProxy class="w-[212px] !max-h-[443px] pt-4">
      <div
        v-for="(obj, key) in settingsList"
        :key="key"
        class="mb-3"
      >
        <div
          class="w-full flex justify-between text-body-sm font-semibold text-proxify-gray-900 px-[14px]"
        >
          {{ obj.title }}
        </div>
        <div
          v-for="(item, name) in obj.items"
          :key="name"
        >
          <div class="flex justify-between px-4 py-2.5">
            <BaseToggle
              :model-value="item.value"
              :label="item.name"
              class="!text-body-sm !font-medium !text-proxify-gray-900"
              @update:model-value="setStatus(key, name)"
            />
          </div>
        </div>
      </div>
    </PPopupProxy>
  </BaseButton>
</template>

<script>
import { mapGetters } from 'vuex';
import { BaseButton, BaseToggle } from '@/components/Base';
import { PPopupProxy } from '@/components/ProxifyUI';

export default {
  name: 'ApplicationSettings',
  components: {
    BaseButton,
    PPopupProxy,
    BaseToggle,
  },
  computed: {
    ...mapGetters({
      applicantCardSettings: 'settings/applicantCardSettings',
      stagesSettings: 'settings/stagesSettings',
    }),
    settingsList() {
      return { ...this.stagesSettings, ...this.applicantCardSettings };
    },
  },
  methods: {
    setStatus(parentName, fieldName) {
      this.$store.commit('settings/setSettingsItemStatus', {
        parentName,
        fieldName,
      });
    },
    clickOutsideConfig(handler) {
      return {
        handler,
        middleware: this.middleware,
        capture: true,
      };
    },
    middleware(event) {
      return event.target.classList[0] !== 'icon-settings';
    },
  },
};
</script>

<style scoped>
.icon-settings {
  @apply mx-4
  text-proxify-primary
  cursor-pointer
  filter
  hover:drop-shadow-lg;
}
</style>
