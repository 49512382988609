<script setup>
import { computed, reactive, toRefs, watch, onMounted, nextTick } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { useClipboard, useTemplateRefsList } from '@vueuse/core';
import { answers } from '@/constants/filters';
import { arrayUtils } from '@/utils';
import useCodility from '@/composables/useCodility';
import {
  BaseBadge,
  BaseButton,
  BaseButtonDropdown,
  BaseIcon,
} from '@/components/Base';
import { PSeparator } from '@/components/ProxifyUI';
import ApplicationFilterBadge from '@/components/Elements/Application/ApplicationFilterBadge.vue';
import api from '@/api';
import ApplicationSettings from '@/components/Elements/Application/ApplicationSettings.vue';
import ApplicationSort from '@/components/Elements/Application/ApplicationSort.vue';

const store = useStore();
const router = useRouter();
const route = useRoute();
const { copy } = useClipboard();
const { codilityTests, getTests } = useCodility();

const state = reactive({
  rejectedValue: { value: 0, name: 'Active' },
  filterRow: [],
  sortValue: 'desc',
});

const { rejectedValue, filterRow, sortValue } = toRefs(state);

const filterBadges = useTemplateRefsList();
const passFilterQueries = () => {
  const filterQuery = filterRow.value.reduce(
    (accumulator, current) => {
      if (!current.variable || !current.relation || !current.value) {
        return accumulator;
      }
      return {
        ...accumulator,
        [`filters[0][${current.variable}:${current.relation}]`]: (
          current.value ?? []
        ).join(','),
      };
    },
    {
      ['filters[0][rejected:is]']: rejectedValue.value.value,
      sort: sortValue.value,
    }
  );
  store.commit('application/setFilterQueries', filterQuery);
};

const filterQueries = computed(() => store.state.application.filterQueries);
const currentUser = computed(() => store.state.auth.user);
const kanban = computed(() => store.state.settings.kanban);

const parseFiltersFromQuery = () => {
  const {
    ['filters[0][rejected:is]']: rejected,
    sort,
    ...rest
  } = filterQueries.value;
  sortValue.value = sort;
  rejectedValue.value = answers.rejected.find(
    ({ value }) => JSON.parse(rejected ?? null) === value
  );
  Object.entries(rest)
    .filter((entry) => entry?.[0].substring(0, 7) === 'filters')
    .forEach(([key, value]) => {
      if (!value) {
        filterRow.value = [
          ...(filterRow.value ?? []).filter(
            (item) => item.variable && item.relation && item.value?.length
          ),
        ];
      } else {
        const [variable, relation] = key
          .replace(/(^.*\[|\].*$)/g, '')
          .split(':');
        filterRow.value = [
          ...(filterRow.value ?? []).filter(
            (item) => item.variable && item.relation && item.value?.length
          ),
          {
            variable,
            relation,
            value: value.split(','),
          },
        ];
      }
    });
};
onMounted(async () => {
  parseFiltersFromQuery();
  await getTests({ withArchived: true });
});
watch(
  () => [rejectedValue.value, filterRow.value, sortValue.value],
  () => {
    if (
      filterRow.value.every(
        (item) => item.variable && item.relation && item.value
      )
    ) {
      passFilterQueries();
    }
  },
  {
    deep: true,
  }
);

const handleAddFilter = () => {
  if (
    !filterRow.value.filter(
      (item) => !item.variable || !item.relation || !item.value
    ).length > 0
  ) {
    filterRow.value = [...filterRow.value, {}];
  }
  nextTick(() => {
    if (filterBadges.value?.length) {
      filterBadges.value[filterBadges.value.length - 1].showVariableDropdown();
    }
  });
};

const handleRemoveFilter = ({ index, query }) => {
  filterRow.value = arrayUtils.remove(
    filterRow.value,
    query
      ? filterRow.value.findIndex(
          (item) =>
            item.variable === query.variable && item.relation === query.relation
        )
      : index
  );
};

const onClickExport = async () => {
  await api.applications.export({ params: filterQueries.value });
  store.commit('ui/addSnackbarMessage', {
    title: `The file was sent to<br>${
      currentUser.value?.email ?? 'your email address'
    }`,
    type: 'success',
    displayDuration: 3000,
  });
};

const onShare = async () => {
  await router.push({
    ...route,
    query: { ...filterQueries.value },
  });
  await nextTick();
  await copy(window.location.href);
  await router.push({ ...route, query: {} });

  store.commit('ui/addSnackbarMessage', {
    title: 'Link copied to clipboard',
    type: 'success',
    displayDuration: 3000,
  });
};
</script>
<template>
  <div class="flex justify-between px-8 items-start">
    <div class="flex flex-wrap gap-1.5 items-center">
      <BaseButtonDropdown
        v-model="rejectedValue"
        :options="answers.rejected"
        map-options
        option-value="value"
        :search="false"
      >
        <template #activator>
          <BaseBadge
            outline
            size="lg"
            class="cursor-pointer border-proxify-gray-300 rounded-full overflow-hidden !bg-white hover:bg-proxify-gray-50"
          >
            Show: {{ rejectedValue?.name }}
          </BaseBadge>
        </template>
      </BaseButtonDropdown>
      <ApplicationFilterBadge
        v-for="(item, index) in filterRow"
        :key="`filter_item_${index}`"
        :ref="filterBadges.set"
        :model-value="item"
        :codility-tests="codilityTests"
        @update:model-value="(value) => (filterRow[index] = value)"
        @remove="(query) => handleRemoveFilter({ index, query })"
        @update:filter-queries="parseFiltersFromQuery"
      />
      <BaseButton
        icon-prepend="plus"
        icon-size="20px"
        :round="!filterRow.length"
        :rounded="Boolean(filterRow.length)"
        class="icon-secondary-gray text-body-sm"
        :class="{ 'py-2.5': filterRow.length }"
        :button-label="filterRow.length ? 'and' : ''"
        @click="handleAddFilter"
      />
    </div>
    <div class="flex gap-4 h-[42px]">
      <BaseButton
        class="icon-link-gray p-0"
        @click="onShare"
      >
        Share
        <template #append>
          <BaseIcon
            name="share07"
            size="20px"
          />
        </template>
      </BaseButton>
      <BaseButton
        class="icon-link-gray p-0"
        @click="onClickExport"
      >
        Export
        <template #append>
          <BaseIcon
            name="share04"
            size="20px"
          />
        </template>
      </BaseButton>
      <PSeparator
        class="my-2"
        vertical
        color="#EAECF0"
      />
      <div class="flex gap-3">
        <BaseButton
          class="icon-link-gray p-0"
          :class="{ '!text-proxify-gray-900': kanban }"
          icon-prepend="columns01"
          @click="() => store.commit('settings/setKanbanStatus', true)"
        />
        <BaseButton
          class="icon-link-gray p-0"
          :class="{ '!text-proxify-gray-900': !kanban }"
          icon-prepend="rows01"
          @click="() => store.commit('settings/setKanbanStatus', false)"
        />
      </div>
      <PSeparator
        class="my-2"
        vertical
        color="#EAECF0"
      />
      <ApplicationSettings />
      <ApplicationSort v-model="sortValue" />
    </div>
  </div>
</template>
