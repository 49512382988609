<script setup>
import BaseNotification from '@/components/Base/BaseNotification.vue';
import { computed } from 'vue';
import { useStore } from 'vuex';

const store = useStore();

const snackbarMessages = computed(() => store.getters['ui/snackbarMessages']);
</script>

<template>
  <div class="app-snackbar">
    <TransitionGroup name="list" tag="div">
      <div v-for="(message, index) in snackbarMessages" :key="index">
        <BaseNotification :message="message" />
      </div>
    </TransitionGroup>
  </div>
</template>
<style scoped>
.app-snackbar {
  @apply fixed
  flex
  flex-col
  w-96
  top-0
  right-0
  mx-6
  translate-y-8
  z-[9999];
}

.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}
</style>
