<template>
  <div class="d-flex justify-center items-center">
    <div class="applicant-future-coming-soon">
      <div class="info-text">Feature coming in a future update!</div>
      <div class="info-image">
        <inline-svg :src="require('@/assets/messages-comming-soon.svg')" />
      </div>
    </div>
  </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg';

export default {
  name: 'ApplicantActivityComingSoon',
  components: {
    InlineSvg,
  },
};
</script>

<style scoped>
.applicant-future-coming-soon {
  @apply bg-white border border-proxify-black/5 rounded flex flex-col justify-center items-center text-center w-56 h-56;
}

.info-text {
  @apply font-bold font-poppins text-lg text-proxify-black mb-8;
}
</style>
