<template>
  <div ref="applicantCard" class="applicant-card" :class="customClass">
    <div class="applicant-card-title">
      <slot name="title">
        {{ title }}
      </slot>
    </div>
    <div class="applicant-card-content">
      <slot></slot>
    </div>
    <div v-show="$slots.footer" class="applicant-card-footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    customClass: {
      type: String,
      default: 'p-4',
    },
  },
  setup() {
    const applicantCard = ref(null);

    return {
      applicantCard,
    };
  },
};
</script>

<style scoped>
.applicant-card {
  @apply flex
  flex-col
  gap-3
  bg-white
  shadow-sm
  rounded-xl
  border
  border-proxify-gray-200
  break-inside-avoid
  mb-4;
}

.applicant-card-title {
  @apply text-body-md text-proxify-gray-900 font-bold truncate;
}

.applicant-card-content {
}

.applicant-card-footer {
  @apply flex justify-between;
}
</style>
