import script from "./ApplicantMonthlyRateBenchmark.vue?vue&type=script&setup=true&lang=js"
export * from "./ApplicantMonthlyRateBenchmark.vue?vue&type=script&setup=true&lang=js"

import "./ApplicantMonthlyRateBenchmark.vue?vue&type=style&index=0&id=1f4581d8&lang=scss"

const __exports__ = script;

export default __exports__
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QTooltip});
