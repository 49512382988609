<template>
  <div class="app-chip">
    <slot name="prepend"></slot>
    <slot name="content"></slot>
    <slot name="append"></slot>
  </div>
</template>

<style scoped>
.app-chip {
  @apply px-2
  py-1
  rounded-full
  text-xs
  font-semibold
  inline;
}
</style>
