<script setup>
import { PButtonToggle } from '../ProxifyUI';
const props = defineProps({
  options: {
    type: Array,
    required: true,
  },
  modelValue: {
    type: [String, Number, Boolean],
    required: true,
  },
  ripple: {
    type: [Boolean, Object],
    required: false,
  },
});
const emit = defineEmits(['update:modelValue']);
</script>

<template>
  <PButtonToggle
    v-bind="props"
    no-caps
    outline
    unelevated
    rounded
    @update:model-value="emit('update:modelValue', $event)"
  >
    <template
      v-for="option in options"
      #[option.slot]
    >
      <slot :name="option.slot" />
    </template>
  </PButtonToggle>
</template>
