import axios from 'axios';

export default {
  get() {
    return axios.get('notifications');
  },
  markAsRead(parameters) {
    return axios.post('/notifications/mark-as-read', parameters);
  },
  markAsUnread(parameters) {
    return axios.post('/notifications/mark-as-unread', parameters);
  },
};
