export default {
  isSidebarActive: true,
  isNotificationBarActive: false,
  kanban: true,
  showOnCards: {
    title: 'Show/hide on cards',
    items: {
      rating: {
        name: 'Rating',
        value: true,
      },
      skills: {
        name: 'Skills',
        value: true,
      },
      activity: {
        name: 'Activity',
        value: true,
      },
      recruiter: {
        name: 'Recruiter',
        value: true,
      },
      action: {
        name: 'Action',
        value: true,
      },
    },
  },
  showOnStages: {
    title: 'Show on stages',
    items: {
      applicantsCounter: {
        name: 'Applicants counter',
        value: true,
      },
    },
  },
  showStages: {
    title: 'Show/hide stages',
    items: {
      sourcingInbox: {
        name: 'Sourcing Inbox',
        value: null,
      },
      sourcingReview: {
        name: 'Sourcing Review',
        value: null,
      },
      inbox: {
        name: 'Inbox',
        value: null,
      },
      reviewing: {
        name: 'Reviewing',
        value: null,
      },
      screening: {
        name: 'Screening',
        value: null,
      },
      technicalAssessment: {
        name: 'Technical Assessment',
        value: null,
      },
      technicalInterview: {
        name: 'Technical Interview',
        value: null,
      },
      membershipInvite: {
        name: 'Membership Invite',
        value: null,
      },
      joinedNetwork: {
        name: 'Joined Network',
        value: null,
      },
    },
  },
};
