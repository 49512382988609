<template>
  <transition name="dropdown-content">
    <div v-if="active" class="app-dropdown-content">
      <slot />
    </div>
  </transition>
</template>

<script>
export default {
  name: 'AppDropdownContent',
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.dropdown-content-enter-active,
.dropdown-content-leave-active {
  transition: all 0.3s;
}

.dropdown-content-enter,
.dropdown-content-leave-to {
  @apply opacity-0
  -translate-y-2;
}

.app-dropdown-content {
  @apply -mt-4
  pt-2
  min-w-[16rem]
  right-12
  origin-top-right
  absolute
  text-proxify-black
  bg-white
  rounded
  shadow
  z-20;
}
</style>
