<template>
  <svg
    viewBox="0 0 14 14"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_16693_29676)">
      <g clip-path="url(#clip1_16693_29676)">
        <path
          d="M12.8682 10.5L5.2495 2.88134C5.08284 2.72221 4.86128 2.63342 4.63085 2.63342C4.40043 2.63342 4.17886 2.72221 4.01221 2.88134L2.88083 4.01272C2.717 4.17693 2.625 4.39941 2.625 4.63137C2.625 4.86332 2.717 5.08581 2.88083 5.25001L10.4991 12.8687C10.6633 13.0325 10.8858 13.1245 11.1177 13.1245C11.3497 13.1245 11.5721 13.0325 11.7364 12.8687L12.8682 11.7371C12.9494 11.6559 13.0139 11.5594 13.0579 11.4533C13.1019 11.3471 13.1245 11.2333 13.1245 11.1184C13.1245 11.0035 13.1019 10.8898 13.0579 10.7836C13.0139 10.6775 12.9494 10.5812 12.8682 10.5ZM3.4995 4.63134L4.63083 3.50001L6.81833 5.68751L5.68669 6.81928L3.49919 4.63178L3.4995 4.63134Z"
          fill="currentColor"
        />
        <path
          d="M1.74999 6.12501L0.875 7L1.74999 7.87499L2.62498 7L1.74999 6.12501Z"
          fill="currentColor"
        />
        <path
          d="M6.99999 0.875009L6.125 1.75L6.99999 2.62499L7.87498 1.75L6.99999 0.875009Z"
          fill="currentColor"
        />
        <path
          d="M1.74999 0.875008L0.875 1.75L1.74999 2.62499L2.62498 1.75L1.74999 0.875008Z"
          fill="currentColor"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_16693_29676">
        <rect width="14" height="14" fill="white" />
      </clipPath>
      <clipPath id="clip1_16693_29676">
        <rect width="14" height="14" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
