<script setup>
import { useVModel } from '@vueuse/core';

const props = defineProps({
  fullHeight: {
    type: Boolean,
    default: false,
  },
  position: {
    type: String,
    default: 'right',
  },
  maximized: {
    type: Boolean,
    default: false,
  },
  modelValue: {
    type: Boolean,
    default: false,
  },
  halfWidth: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:modelValue']);

const active = useVModel(props, 'modelValue', emit);
</script>
<template>
  <q-dialog
    v-model="active"
    :position="props.position"
    :full-height="props.fullHeight"
    :maximized="props.maximized"
    :class="{ 'half-width': halfWidth }"
  >
    <slot></slot>
  </q-dialog>
</template>
<style lang="scss">
.half-width {
  .q-dialog__inner {
    width: 50%;
    min-width: 672px;
  }
}
.q-dialog__inner {
  &--maximized {
    > div {
      @media (max-width: 1280px) {
        max-height: 111vh !important;
        height: 111vh !important;
      }

      @media (min-width: 1281px) and (max-width: 1320px) {
        max-height: 108vh !important;
        height: 108vh !important;
      }

      @media (min-width: 1321px) and (max-width: 1360px) {
        max-height: 105.3vh !important;
        height: 105.3vh !important;
      }

      @media (min-width: 1361px) and (max-width: 1400px) {
        max-height: 102.6vh !important;
        height: 102.6vh !important;
      }

      @media (min-width: 1401px) and (max-width: 1440px) {
        max-height: 101vh !important;
        height: 101vh !important;
      }

      @media (min-width: 1441px) {
        max-height: 100vh !important;
        height: 100vh !important;
      }

      @-moz-document url-prefix() {
        max-height: 100vh !important;
        height: 100vh !important;
      }
    }
  }
}
</style>
