import axios from 'axios';

export default {
  getLinkInfo({ id }) {
    return axios.get(`/application-links/${id}`);
  },
  getAllSlots(alias, body) {
    return axios.get(`/scheduler/static/${alias}`, body);
  },
  getScreeningSlots(applicationId, body) {
    return axios.get(`/scheduler/${applicationId}/screening/slots`, body);
  },
  bookScreeningSlot(applicationId, body) {
    return axios.post(`/scheduler/${applicationId}/screening/book-slot`, body);
  },
  getGeneralScreeningSlots(applicationId, body) {
    return axios.get(
      `/scheduler/${applicationId}/screening/general-slots`,
      body
    );
  },
  bookGeneralScreeningSlot(applicationId, body) {
    return axios.post(
      `/scheduler/${applicationId}/screening/book-general-slot`,
      body
    );
  },
  getTechInterviewSlots(applicationId, body) {
    return axios.get(`/scheduler/${applicationId}/tech-interview/slots`, body);
  },
  bookTechInterviewSlot(applicationId, body) {
    return axios.post(
      `/scheduler/${applicationId}/tech-interview/book-slot`,
      body
    );
  },
};
