<script setup>
import axios from 'axios';
import { computed, onBeforeUnmount, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import * as Sentry from '@sentry/vue';
import AuthenticatedLayout from '@/components/Layout/AuthenticatedLayout.vue';
import { useQuasar } from 'quasar';
import { useIntervalFn } from '@vueuse/core';
import { useRoute, useRouter } from 'vue-router';

const $q = useQuasar();
const store = useStore();
const route = useRoute();
const router = useRouter();
const user = computed(() => store.state.auth.user);
const notifications = computed(() => store.state.notifications.notifications);
const currentUser = computed(() => store.state.auth.user);
const isLogged = computed(() => store.getters['auth/isLogged']);
const token = computed(() => store.getters['auth/token']);

// Determine if topbar warning is active (based on user role and environment)
const isTopbarWarningActive = computed(() => {
  if (process.env.VUE_APP_ENVIRONMENT !== 'production') return false;
  return currentUser.value?.roles?.some((role) =>
    ['ats-administrator', 'mgmt', 'operational-manager'].includes(role.name)
  );
});

// Safari browser specific styling adjustments
if ($q.platform.is.safari) {
  const styleSheets = document.styleSheets[document.styleSheets.length - 1];
  styleSheets.insertRule('.h-screen { height: 100vh !important; }', 0);
  styleSheets.insertRule('.w-screen { width: 100vw !important; }', 0);
  styleSheets.insertRule(
    'html { height: 100vh !important; width: 100vw !important; }',
    0
  );
}
const getTodos = async () => {
  if (isLogged.value) {
    await store.dispatch('todos/fetchExpeditedTodos', user.value.id);
  }
};

// Set up an interval to periodically fetch todos
useIntervalFn(getTodos, 1000 * 60 * 5);

// Axios request interceptor to track outgoing requests
axios.interceptors.request.use(
  (config) => {
    store.commit('appTraffic/incrementRequestCount');
    return config;
  },
  (error) => {
    store.commit('appTraffic/decrementRequestCount');
    return Promise.reject(error);
  }
);

// Axios' response interceptor to handle errors and specific status codes
axios.interceptors.response.use(
  (response) => {
    store.commit('appTraffic/decrementRequestCount');
    return response;
  },
  (error) => {
    store.commit('appTraffic/decrementRequestCount');
    if (error && error.response) {
      // Handle other specific status codes
      const statuses = [400, 401, 403, 404, 405, 500];
      if (statuses.includes(error.response.status)) {
        store.commit('ui/addSnackbarMessage', {
          title: error.response.data.message ?? error.response.data.response,
          type: 'error',
          duration: 3000,
        });
        Sentry.setContext('error', error.toJSON());
      } else if (error.response.status === 422) {
        store.commit('ui/addSnackbarMessage', {
          title: Object.values(error.response.data.errors).flat().join('<br>'),
          type: 'error',
          duration: 3000,
        });
      }
      return Promise.reject(error.response);
    }
    return Promise.reject(error);
  }
);

onMounted(() => {
  if (isLogged.value) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token.value}`;
    store.dispatch('notifications/fetchNotifications');
    store.dispatch('notifications/subscribeNotifications');
    getTodos();
  }
});

onBeforeUnmount(() => {
  store.dispatch('notifications/unsubscribeNotifications');
});

watch(
  () => route.query,
  (newValue, oldValue) => {
    if (newValue && newValue !== oldValue && newValue.notificationBar) {
      try {
        store.commit(
          'settings/setNotificationBarStatus',
          Boolean(parseInt(newValue.notificationBar))
        );
      } catch (error) {
        store.commit('ui/addSnackbarMessage', {
          title: "URL query doesn't match the format",
          type: 'error',
          duration: 3000,
        });
      }
    }
  },
  { immediate: true, deep: true }
);
</script>

<template>
  <AppSnackbarList />
  <AppTopbarWarning v-if="isTopbarWarningActive" />
  <AuthenticatedLayout
    v-if="
      isLogged && route.meta?.requiresAuth && !route.meta?.requiresInitialData
    "
  >
    <router-view />
  </AuthenticatedLayout>
  <template v-else>
    <router-view />
  </template>
</template>
