/**
 * @typedef {Object} Rating
 * @property {number} min
 * @property {number} max
 * @property {number} quant_25
 * @property {number} quant_75
 */
/**
 * @typedef {Object} Application
 * @property {?number} currency_rate
 * @property {?Object} metric_distribution
 * @property {?Rating} metric_distribution.commercial_exp
 * @property {?Rating} metric_distribution.key_exp
 * @property {?Rating} metric_distribution.rates
 *
 */

const getMetricRange = (metric, factor = 12) => {
  if (!metric) return [];
  return [
    Math.round(metric.min * factor),
    Math.round(metric.quant_25 * factor),
    Math.round(metric.quant_75 * factor),
    Math.round(metric.max * factor),
  ];
};

export default {
  calculateVerdict: (value, ranges) => {
    const verdicts = [
      { limit: ranges[1], verdict: 'low' },
      { limit: ranges[2], verdict: 'typical' },
      { limit: Infinity, verdict: 'high' },
    ];

    return verdicts.find((verdict) => value < verdict.limit).verdict;
  },
  /**
   * Returns experience rates for the application
   * @param {Application} application
   * @returns {Array}
   */
  getExperienceRatesOfApplication: (application) => {
    const commercialExp = application.metric_distribution?.commercial_exp;

    return getMetricRange(commercialExp);
  },
  /**
   * Returns key skill rates for the application
   * @param {Application} application
   * @return {[number,number,number,number]}
   */
  getKeySkillRatesForApplication: (application) => {
    const keyExp = application.metric_distribution?.key_exp;

    return getMetricRange(keyExp);
  },
  getRatesOfApplication: (application, compensationPlan = 'monthly') => {
    const rates =
      application.metric_distribution?.[
        `${compensationPlan === 'monthly' ? 'monthly_' : ''}rates`
      ];
    const currencyRate = application.rate?.currency_rate;

    return getMetricRange(rates, 1 / currencyRate);
  },
  calculateRelativeHourlyRate(monthlyRateValue) {
    return Math.round(Number(monthlyRateValue) / 160);
  },
  calculateRelativeMonthlyRate(hourlyRateValue) {
    return Math.round(Number(hourlyRateValue) * 160);
  },
};
