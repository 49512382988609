<script setup>
const props = defineProps({
  onClick: {
    type: Function,
    default: () => {},
  },
});

const emit = defineEmits(['click']);

const handleClick = () => {
  if (props.disabled) {
    return;
  }
  if (props.onClick) {
    props.onClick[0]?.() ?? props.onClick();
  } else {
    emit('click');
  }
};
</script>
<template>
  <q-item @click.stop="handleClick">
    <slot />
  </q-item>
</template>
