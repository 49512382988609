import script from "./TodosMenu.vue?vue&type=script&setup=true&lang=js"
export * from "./TodosMenu.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QSkeleton from 'quasar/src/components/skeleton/QSkeleton.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QTabs,QTab,QItem,QItemSection,QSkeleton,QItemLabel});
