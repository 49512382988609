<template>
  <div
    ref="listWrapper"
    class="w-full h-full flex list-wrapper"
    :style="{ maxHeight: containerHeight - 96 + 'px' }"
  >
    <div class="listview h-full">
      <div class="border-t border-proxify-gray-200">
        <template
          v-if="isAPILoading || (isLoading && metaData.current_page === 1)"
        >
          <div
            v-for="i in 10"
            :key="i"
            class="bg-white h-[72px] border-b border-proxify-gray-200 flex justify-between items-center"
            :class="{
              'bg-proxify-gray-50': i % 2 === 0,
            }"
          >
            <div class="py-3 px-6 w-2/12 min-w-[21.5%] flex items-center gap-2">
              <q-skeleton
                height="40px"
                width="40px"
                type="QAvatar"
              />
              <div class="flex flex-col gap-2">
                <q-skeleton
                  width="150px"
                  height="24px"
                />
                <q-skeleton
                  width="100px"
                  height="12px"
                />
              </div>
            </div>
            <div class="w-2/12 min-w-[16.66%] py-2.5 px-6">
              <q-skeleton
                height="20px"
                width="100px"
              />
            </div>
            <div class="w-2/12 min-w-[21.5%] py-2.5 px-6">
              <q-skeleton
                height="20px"
                width="100px"
              />
            </div>
            <div class="w-2/12 min-w-[21.5%] py-2.5 px-6">
              <q-skeleton
                height="20px"
                width="100px"
              />
            </div>
            <div
              class="w-1/12 min-w-[11%] flex items-center justify-center gap-2"
            >
              <q-skeleton
                height="20px"
                width="100px"
              />
            </div>
          </div>
        </template>
        <template v-else>
          <ListItem
            v-for="(application, index) in applications"
            :key="application.uuid"
            :index="index"
            :application="application"
            :menu-options="menuOptions"
            class="border-b border-proxify-gray-200"
            :class="{
              'bg-proxify-gray-50': index % 2 === 0,
            }"
            @open-share-link-dialog="openShareLinkDialog"
            @change-recruiter="
              (value) =>
                handleChangeAssignees({
                  applicationId: application.id,
                  recruiter: value,
                })
            "
            @change-stage="
              ({ previousStageId, nextStageId }) =>
                handleChangeStage({
                  applicationId: application.id,
                  previousStageId,
                  nextStageId,
                  application,
                })
            "
          />
          <BaseProgressBar
            v-if="isLoading"
            indeterminate
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, reactive, ref, toRefs, watch } from 'vue';
import { useStore } from 'vuex';
import { useInfiniteScroll, useOffsetPagination } from '@vueuse/core';
import ListItem from '@/components/Elements/Scaffold/ListItem.vue';
import { BaseProgressBar } from '@/components/Base';
import api from '@/api';
import { interviewUtils } from '@/utils';

export default {
  components: {
    ListItem,
    BaseProgressBar,
  },
  props: {
    onBeforeStageChange: {
      type: Function,
      required: false,
    },
    recentlyChangedStage: {
      type: Object,
      required: false,
    },
    recentlyChangedRecruiter: {
      type: Object,
      required: false,
    },
    containerHeight: {
      type: Number,
      required: false,
    },
    isAPILoading: {
      type: Boolean,
      required: false,
    },
  },
  emits: ['open-share-link-dialog'],
  setup(props, { emit }) {
    const store = useStore();
    const listWrapper = ref(null);

    const applicantCardSettings = computed(
      () => store.state.settings.applicantCardSettings
    );
    const filterQueries = computed(() => store.state.application.filterQueries);
    const state = reactive({
      applications: [],
      metaData: {
        current_page: 0,
        per_page: 10,
      },
      isMenuOptionsLoading: false,
      isLoading: false,
      menuOptions: {
        stage: [],
      },
      selectedMenuContext: {},
      selectedMenuTrigger: null,
      activeMenuType: null,
      isMenuActive: false,
    });

    const getApplications = async (filterQueries, page, options = {}) => {
      const { concat = false } = options;
      state.isLoading = true;
      const { data: response } = await api.applications.getApplicationsList({
        params: { ...filterQueries, page },
      });
      const { data: applications, ...meta } = response.data;
      state.metaData = meta;
      state.applications =
        concat && !isFirstPage.value
          ? [...state.applications, ...applications]
          : applications;
      state.isLoading = false;
    };

    const { isFirstPage, next } = useOffsetPagination({
      total: state.metaData.total,
      page: state.metaData.current_page,
      pageSize: state.metaData.per_page,
      onPageChange: () =>
        getApplications(filterQueries.value, state.metaData.current_page + 1, {
          concat: true,
        }),
    });
    const isLastPage = computed(() => {
      if (state.metaData.total < 10) return true;
      return (
        Math.max(
          1,
          Math.ceil(state.metaData.total / state.metaData.per_page)
        ) === state.metaData.current_page
      );
    });

    useInfiniteScroll(
      listWrapper,
      () => {
        if (!isLastPage.value && !state.isLoading) {
          next();
        }
      },
      { distance: state.metaData.per_page }
    );

    const getMenuOptions = async () => {
      state.isMenuOptionsLoading = true;
      const { data: recruiters } = await api.users.recruiters();
      store.commit('applicant/setRecruiters', recruiters.data);
      const { data: stages } = await api.stages.getStages();
      store.commit('applicant/setStages', stages.data);
      state.isMenuOptionsLoading = false;
      state.menuOptions = {
        recruiter: interviewUtils.generateGroupList({
          groupsData: recruiters.data.groups,
          itemsData: recruiters.data.recruiters,
        }),
        stage: stages.data,
      };
    };

    const handleChangeStage = ({
      applicationId,
      previousStageId,
      nextStageId,
      application,
    }) => {
      props.onBeforeStageChange({
        applicationId,
        previousStageId,
        nextStageId,
        application,
      });
    };

    const updateStage = ({ applicationId, stageId }) => {
      state.applications = [
        ...state.applications.map(({ ...application }) => {
          return {
            ...application,
            stage_id:
              application.id === applicationId ? stageId : application.stage_id,
          };
        }),
      ];
    };

    watch(
      () => props.recentlyChangedStage,
      ({ applicationId, stageId }) => {
        updateStage({ applicationId, stageId });
      },
      { deep: true }
    );
    watch(
      () => props.recentlyChangedRecruiter,
      ({ applicationId, recruiterId }) => {
        state.applications = [
          ...state.applications.map(({ ...application }) => {
            return {
              ...application,
              recruiter_id:
                application.id === applicationId
                  ? recruiterId
                  : application.recruiter_id,
            };
          }),
        ];
      },
      { deep: true }
    );

    const handleChangeAssignees = async ({ applicationId, recruiter }) => {
      const { data } = await api.applications.updateAssignees(applicationId, {
        recruiter_id: recruiter?.type !== 'group' ? recruiter?.id : undefined,
        group: recruiter?.type === 'group' ? recruiter?.id : undefined,
      });
      state.applications = [
        ...state.applications.map(({ ...application }) => {
          return {
            ...application,
            recruiter_id:
              application.id === applicationId
                ? recruiter?.id
                : application.recruiter_id,
          };
        }),
      ];

      store.commit('ui/addSnackbarMessage', {
        title: 'Recruiter changed successfully',
        type: 'success',
      });
    };

    const openShareLinkDialog = (applicantId) => {
      emit('open-share-link-dialog', applicantId);
    };

    onMounted(async () => {
      await getMenuOptions();
    });

    watch(
      () => filterQueries.value,
      async (newQueries) => {
        await getApplications(newQueries, state.metaData.current_page);
      },
      { deep: true }
    );
    window.Echo.channel('stages').listen('StageUpdated', (event) => {
      if (!event.stage_from) {
        state.applications = [event.application, ...state.applications];
      } else {
        updateStage({
          application_id: event.application.id,
          stage_id: parseInt(event.stage_to, 10),
        });
      }
    });

    return {
      ...toRefs(state),
      listWrapper,
      getApplications,
      openShareLinkDialog,
      applicantCardSettings,
      filterQueries,
      handleChangeStage,
      handleChangeAssignees,
    };
  },
};
</script>

<style scoped>
.list-wrapper {
  @apply ats-scrollbar-persistent overflow-auto;
}

.listview {
  @apply table-auto
  border-separate
  w-full;

  border-spacing: 0 0.5rem;
}

.skeleton-loader {
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite linear;
}

@keyframes loading {
  0% {
    background-position: 200% 0;
  }

  100% {
    background-position: -200% 0;
  }
}
</style>
