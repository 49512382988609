<script setup>
import { BaseButton } from '@/components/Base';
import { PDialog } from '@/components/ProxifyUI';
import { useClipboard } from '@vueuse/core';
import { useStore } from 'vuex';
import RejectCandidateDialog from '@/components/Elements/Applicant/ApplicantRejectCandidateDialog.vue';
import { ref } from 'vue';

const props = defineProps({
  applicant: {
    type: Object,
    required: false,
    default: undefined,
  },
  onUnReject: {
    type: Function,
    required: true,
  },
  isHired: {
    type: Boolean,
    required: true,
  },
  isStageChanging: {
    type: Boolean,
    required: true,
  },
  isRejected: {
    type: Boolean,
    required: true,
  },
});
const emit = defineEmits(['reject']);
const store = useStore();
const isRejectCandidateDialogOpen = ref(false);

const { copy } = useClipboard();

const onShare = async () => {
  await copy(window.location.href);
  store.commit('ui/addSnackbarMessage', {
    title: 'Link copied to clipboard',
    type: 'success',
  });
};
const openRejectCandidateDialog = () => {
  if (props.isHired) return;
  isRejectCandidateDialogOpen.value = true;
};
const closeRejectCandidateDialog = () => {
  isRejectCandidateDialogOpen.value = false;
};
</script>

<template>
  <div class="flex items-center gap-3">
    <BaseButton
      color="transparent"
      rounded
      text-color="proxify-gray-700"
      class="h-[40px]"
      button-label="Share"
      icon-append="share07"
      icon-size="16px"
      data-testid="share-button"
      @click="onShare"
    />
    <BaseButton
      rounded
      :disabled="isHired"
      class="w-[150px] h-[40px] icon-primary"
      :class="{ 'cursor-pointer': !isHired, 'button-destructive': !isRejected }"
      :button-label="isRejected ? 'Unreject' : 'Reject'"
      data-testid="reject-button"
      @click="isRejected ? onUnReject() : openRejectCandidateDialog()"
    />
  </div>

  <PDialog
    v-model="isRejectCandidateDialogOpen"
    persistent
    position="standard"
  >
    <RejectCandidateDialog
      :applicant="applicant"
      :on-close="closeRejectCandidateDialog"
      @reject="emit('reject', $event)"
    />
  </PDialog>
</template>
