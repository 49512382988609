<script setup>
import { useVModel } from '@vueuse/core';

const props = defineProps({
  modelValue: Boolean,
  icon: String,
  expandIcon: String,
  expandedIcon: String,
  expandIconClass: [String, Array, Object],
  toggleAriaLabel: String,
  label: String,
  labelLines: [Number, String],
  caption: String,
  captionLines: [Number, String],
  dark: Boolean,
  dense: Boolean,
  duration: Number,
  headerInsetLevel: Number,
  contentInsetLevel: Number,
  expandSeparator: Boolean,
  defaultOpened: Boolean,
  hideExpandIcon: Boolean,
  expandIconToggle: Boolean,
  switchToggleSide: Boolean,
  denseToggle: Boolean,
  group: String,
  popup: Boolean,
  headerStyle: [String, Array, Object],
  headerClass: [String, Array, Object],
});

const emit = defineEmits(['update:modelValue']);

const model = useVModel(props, 'modelValue', emit);
</script>

<template>
  <q-expansion-item
    v-model="model"
    :icon="icon"
    :expand-icon="expandIcon"
    :expanded-icon="expandedIcon"
    :expand-icon-class="expandIconClass"
    :toggle-aria-label="toggleAriaLabel"
    :label="label"
    :label-lines="labelLines"
    :caption="caption"
    :caption-lines="captionLines"
    :dark="dark"
    :dense="dense"
    :duration="duration"
    :header-inset-level="headerInsetLevel"
    :content-inset-level="contentInsetLevel"
    :expand-separator="expandSeparator"
    :default-opened="defaultOpened"
    :hide-expand-icon="hideExpandIcon"
    :expand-icon-toggle="expandIconToggle"
    :switch-toggle-side="switchToggleSide"
    :dense-toggle="denseToggle"
    :group="group"
    :popup="popup"
    :header-style="headerStyle"
    :header-class="headerClass"
  >
    <!-- default slot -->
    <slot />

    <!-- header slot -->
    <template #header="{ expanded, detailsId, show, hide, toggle }">
      <slot
        name="header"
        :expanded="expanded"
        :details-id="detailsId"
        :show="show"
        :hide="hide"
        :toggle="toggle"
      />
    </template>
  </q-expansion-item>
</template>
