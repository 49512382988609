<script setup>
import { useStore } from 'vuex';
import { useVModel } from '@vueuse/core';
import { BaseInputDropdown, BaseIcon } from '@/components/Base';
import { PItem, PItemSection } from '@/components/ProxifyUI';
import { computed, ref, nextTick } from 'vue';
import api from '@/api';

const props = defineProps({
  modelValue: {
    type: Object,
    required: false,
  },
});
const emit = defineEmits(['update:modelValue']);
const bindedApplicant = useVModel(props, 'modelValue', emit);

const store = useStore();
const applications = ref([]);
const applicationsMetaData = ref(null);
const filteredApplications = ref([]);
const isLoadingApplications = ref(false);
const recentApplications = ref([]);
const stages = computed(() => store.state.applicant.stages);

const getApplications = async (filterQueries, page, options = {}) => {
  const { concat = false } = options;
  isLoadingApplications.value = true;
  const { data: response } = await api.applications.getApplicationsList({
    params: { ...filterQueries, page },
  });
  const { data, ...meta } = response.data;
  const applicants = data.map((item) => {
    return {
      ...item,
      full_name: `${item.first_name} ${item.last_name}`,
      stage_name: stages.value.find((stage) => stage.id === item.stage_id)
        ?.name,
    };
  });
  applicationsMetaData.value = meta;
  applications.value =
    concat && applications.value.length >= 10
      ? [...applications.value, ...applicants]
      : applicants;
  await nextTick();
  isLoadingApplications.value = false;
};

const getRecentApplications = async () => {
  const { data: { applications: data } = {} } =
    await api.applications.getRecentApplications();
  recentApplications.value = data.map((item) => ({
    ...item,
    stage_name: item.stage.name,
  }));
};

const onScroll = async ({ to, ref, index }) => {
  if (
    !isLoadingApplications.value &&
    applicationsMetaData.value?.current_page !==
      applicationsMetaData.value.last_page &&
    to === index
  ) {
    await getApplications(
      { 'filters[0][rejected:is]': 0 },
      applicationsMetaData.value?.current_page + 1,
      { concat: true }
    );
    await nextTick();
    ref.refresh();
  }
};

const filterApplicants = (value, update) => {
  if (value === '') {
    update(() => {
      filteredApplications.value = [];
    });
    return;
  }

  const needle = value.toLowerCase();
  update(async () => {
    if (needle.length < 3) {
      return;
    }
    const { data: { data } = {} } = await api.applications.search({
      q: needle,
    });
    filteredApplications.value = data.map((item) => ({
      ...item,
      stage_name: stages.value.find((stage) => stage.id === item.stage_id)
        ?.name,
    }));
  });
};

const applicationsDropdownOptions = computed(() => {
  return filteredApplications.value?.length
    ? filteredApplications.value
    : applications.value;
});

getRecentApplications();
getApplications({ 'filters[0][rejected:is]': 0 }, 1);
</script>
<template>
  <BaseInputDropdown
    v-model="bindedApplicant"
    type="search"
    placeholder="Search"
    map-options
    :menu-width="350"
    option-label="full_name"
    option-sublabel="stage_name"
    :options="applicationsDropdownOptions"
    :on-filter="filterApplicants"
    :loading="isLoadingApplications"
    @virtual-scroll="
      (details) => (!filteredApplications?.length ? onScroll(details) : null)
    "
  >
    <template
      v-if="!filteredApplications.length"
      #before-options
    >
      <PItem
        dense
        class="h-[32px] flex items-end text-proxify-gray-600 select-none py-0"
      >
        <PItemSection class="text-body-sm font-medium">Recents</PItemSection>
      </PItem>
      <PItem
        v-for="opt in recentApplications"
        :key="opt.id"
        v-close-popup
        class="font-inter !min-h-[42px] rounded-full hover:bg-proxify-gray-50 !hover:text-proxify-midnight-blue text-body-md font-medium"
        :class="{
          'bg-proxify-gray-50': bindedApplicant?.id === opt.id,
        }"
        clickable
        @click="bindedApplicant = opt"
      >
        <PItemSection
          class="flex flex-row !justify-start gap-x-2 flex-wrap items-center"
        >
          {{ opt.full_name }}
          <span class="text-proxify-gray-600 font-normal text-body-sm">
            {{ opt.stage.name }}
          </span>
        </PItemSection>

        <PItemSection
          v-if="bindedApplicant?.id === opt.id"
          side
        >
          <BaseIcon
            name="check"
            size="20px"
            color="primary"
          />
        </PItemSection>
      </PItem>

      <PItem
        dense
        class="h-[32px] flex items-end text-proxify-gray-600 select-none py-0"
      >
        <PItemSection class="text-body-sm font-medium">
          All Applicants
        </PItemSection>
      </PItem>
    </template>
  </BaseInputDropdown>
</template>
