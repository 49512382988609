<template>
  <div class="flex gap-x-2 items-center">
    <div
      class="play-button"
      @click="play"
    >
      <BaseIcon
        v-if="isPlaying"
        name="pause-circle"
        size="20px"
        custom-class="text-proxify-primary-700"
      />
      <BaseIcon
        v-else
        name="play"
        size="20px"
        custom-class="text-proxify-primary-700 ml-0.5"
      />
    </div>
    <div class="flex w-[76px] justify-center">
      {{ duration }} / {{ durationFull }}
    </div>
    <div
      :id="(props.settings ?? defaultSettings ?? {})?.container.substring(1)"
      class="grow wave-surfer"
    ></div>
  </div>
</template>

<script setup>
import { BaseIcon } from '@/components/Base';
import WaveSurfer from 'wavesurfer.js';
import { onMounted, ref, watch } from 'vue';
import { dateTimeUtils } from '@/utils';
const { formatDuration } = dateTimeUtils;
import { useRoute } from 'vue-router';
const route = useRoute();

const duration = ref('0:00');
const durationFull = ref('0:00');
const isPlaying = ref(false);
const wavesurfer = ref();

const props = defineProps({
  settings: {
    type: Object,
    required: false,
  },
  url: {
    type: String,
    required: true,
  },
});

const defaultSettings = {
  container: '#wavesurfer',
  waveColor: '#d0d5dd',
  progressColor: '#5258fb',
  barHeight: 1,
  barWidth: 3,
  barRadius: 3,
  cursorColor: 'transparent',
  hideScrollbar: true,
  height: 24,
};

onMounted(() => {
  wavesurfer.value = WaveSurfer.create({
    ...defaultSettings,
    ...props.settings,
  });

  wavesurfer.value.load(props.url);
  wavesurfer.value.on('ready', function () {
    durationFull.value = formatDuration(wavesurfer.value.getDuration());
  });

  wavesurfer.value.on('audioprocess', function () {
    if (wavesurfer.value.isPlaying()) {
      duration.value = formatDuration(wavesurfer.value.getCurrentTime());
    }
  });

  wavesurfer.value.on('finish', function () {
    isPlaying.value = false;
  });

  wavesurfer.value.on('seek', function (value) {
    duration.value = formatDuration(
      value * wavesurfer.value.getDuration() * 1.1
    );
  });
});

const play = () => {
  wavesurfer.value.playPause();
  isPlaying.value = !isPlaying.value;
};

watch(
  () => route.params.id,
  () => {
    wavesurfer.value.pause();
  }
);
</script>
<style scoped>
.play-button {
  @apply w-10
  h-10
  flex
  justify-center
  items-center
  bg-proxify-primary-50
  rounded-full
  cursor-pointer;
}
</style>
