<template>
  <div :class="valueClass">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'AppCardBody',
  props: {
    valueClass: {
      type: [Object, String],
      required: false,
    },
  },
};
</script>
