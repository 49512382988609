<template>
  <div>
    <div
      ref="historyContainerEl"
      class="ats-scrollbar-persistent scroll-smooth"
    >
      <div ref="table" />
      <div
        v-for="(obj, index) in formattedHistory"
        :key="index + obj.title"
        class="my-4 mx-3 p-2 rounded"
      >
        <AppAccordion initial-expand>
          <template #activator="{ isExpanded }">
            <AppCardTitle
              :title="obj.title"
              value-class="flex justify-between text-proxify-gray font-bold text-body-md items-center"
            >
              <div class="flex text-body-sm text-proxify-primary font-medium">
                <IconBase
                  width="0.75"
                  class="mr-1 mt-0.5 transition duration-300 ease-in-out"
                  :transform="isExpanded ? 'rotate(0)' : 'rotate(-180)'"
                >
                  <IconChevronUp />
                </IconBase>
                Collapse
              </div>
            </AppCardTitle>
          </template>
          <template #content>
            <div class="mt-3">
              <HistoryEvent
                v-for="item in obj.history"
                :key="item.id"
                :item="item"
                :max-width="historyContainerWidth"
                :stage="stage"
              />
            </div>
          </template>
        </AppAccordion>
      </div>
      <div
        v-if="meta.total && history.length !== meta.total"
        class="text-center mt-4 mb-8"
      >
        <div class="text-disabled-normal">
          Showing {{ history.length }} of {{ meta.total }} events
        </div>
        <div
          class="text-proxify-primary cursor-pointer mt-1"
          :disabled="isLoading"
          @click="getHistory()"
        >
          <AppLoader v-if="isLoading" size="0.19rem" />
          <span v-else>Load more</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HistoryEvent from '@/components/Elements/Applicant/ApplicantActivityHistoryEvent';
import IconBase from '@/components/Icons/IconBase';
import IconChevronUp from '@/components/Icons/IconChevronUp';
import api from '@/api';
import { computed, onMounted, ref, watch } from 'vue';
import { useElementBounding, useInfiniteScroll } from '@vueuse/core';
import { useRoute } from 'vue-router';
import { unitUtils } from '@/utils';

export default {
  name: 'ApplicantActivityHistory',
  components: {
    HistoryEvent,
    IconBase,
    IconChevronUp,
  },
  props: {
    stage: {
      type: Object,
      required: false,
    },
  },
  setup() {
    const history = ref([]);
    const historyContainer = ref(null);
    const isLoading = ref(true);
    const meta = ref({});
    const page = ref(1);
    const route = useRoute();
    const historyContainerElement = ref(null);
    const { convertPixelsToRem } = unitUtils;

    const { width } = useElementBounding(historyContainerElement);
    const historyContainerWidth = computed(() => {
      return `${convertPixelsToRem(width.value - 54)  }rem`;
    });

    const getHistory = async () => {
      isLoading.value = true;
      const { id } = route.params;
      const { data } = await api.applications.history.get({
        id,
        page: page.value,
      });
      page.value++;
      data?.history.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      );
      history.value = [...history.value, ...(data?.history ?? [])];
      meta.value = data.meta;
      isLoading.value = false;
    };

    const resetHistory = () => {
      history.value = [];
      isLoading.value = true;
      meta.value = {};
      page.value = 1;
    };

    onMounted(() => {
      getHistory();
    });

    watch(
      () => route.params.id,
      () => {
        resetHistory();
        getHistory();
      }
    );

    const formattedHistory = computed(() => {
      return [...new Set(history.value.map((item) => item.time_category))].map(
        (item) => ({
          title: item,
          history: history.value.filter(
            ({ time_category }) => item === time_category
          ),
        })
      );
    });

    useInfiniteScroll(
      historyContainer,
      () => {
        if (page.value <= meta?.value?.last_page) {
          return getHistory();
        }
      },
      {}
    );

    return {
      formattedHistory,
      getHistory,
      history,
      historyContainer,
      isLoading,
      meta,
      historyContainerEl: historyContainerElement,
      historyContainerWidth,
    };
  },
};
</script>
