<template>
  <svg
    :height="height + 'rem'"
    :width="(5 * height + 5 * spacing) * (heightInPixels / 16) + 'rem'"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    @mouseleave="valueModel = value"
  >
    <defs>
      <linearGradient id="half_grad">
        <stop offset="50%" stop-color="#F2B848" />
        <stop offset="50%" stop-color="#E4E5ED" stop-opacity="1" />
      </linearGradient>
      <linearGradient id="half_grad-disabled">
        <stop offset="50%" stop-color="#979797" />
        <stop offset="50%" stop-color="#E4E5ED" stop-opacity="1" />
      </linearGradient>
    </defs>
    <path
      id="stars"
      d="M7.90446 0.309181C7.9335 0.215213 8.0665 0.215213 8.09554 0.309181L9.88855 6.11146H15.7044C15.8 6.11146 15.8411 6.23285 15.7651 6.29092L11.0557 9.88854L12.8519 15.7011C12.8806 15.7941 12.773 15.8692 12.6957 15.8101L8 12.2229L3.30435 15.8101C3.22698 15.8692 3.11935 15.7941 3.1481 15.7011L4.94427 9.88854L0.234923 6.29092C0.158912 6.23285 0.199976 6.11146 0.29563 6.11146H6.11146L7.90446 0.309181Z"
      stroke="transparent"
    />

    <use
      v-for="(n, i) in 5"
      :key="`${_.uid}_${i}`"
      :x="i * heightInPixels + i * spacingInPixels"
      href="#stars"
      :fill="fillColor(n)"
      :transform="`scale(${heightInPixels / 16})`"
      :class="{ star: interactive, disabled }"
      @mouseover="onHoverStars(n)"
      @click="onSubmitRating(n)"
    ></use>
  </svg>
  <span
    class="ml-2 rating"
    :style="`font-size: ${height}rem; line-height: %90`"
    >{{ normalizedRating }}</span
  >
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: 0,
    },
    height: {
      type: Number,
      default: 1,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    interactive: {
      type: Boolean,
      default: false,
    },
    spacing: {
      type: Number,
      required: false,
      default() {
        return 0.125;
      },
    },
    id: {
      type: Number,
      required: false,
    },
  },
  emits: ['setRatingValue'],
  data() {
    return {
      finalRating: 0,
      valueModel: 0,
    };
  },
  computed: {
    normalizedRating() {
      return (this.valueModel / 20).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
    },
    heightInPixels() {
      return this.convertRemToPixels(this.height);
    },
    spacingInPixels() {
      return this.convertRemToPixels(this.spacing);
    },
  },
  watch: {
    value: {
      handler(newValue) {
        if (newValue) {
          this.valueModel = newValue;
        }
      },
      immediate: true,
    },
  },
  methods: {
    fillColor(n) {
      const { normalizedRating: rating, disabled } = this;
      if (n <= Math.floor(rating)) {
        return disabled ? '#979797' : '#F2B848';
      } else if (n - rating >= 1) {
        return '#E4E5ED';
      } else if ((n - rating) % 1 !== 0) {
        return `url('#half_grad${disabled ? '-disabled' : ''}')`;
      }
    },
    onSubmitRating(n) {
      if (this.interactive && !this.disabled) {
        this.$emit('setRatingValue', {
          id: this.id,
          value: Math.floor(this.value) / 20 === n ? 0 : n * 20,
        });
      }
    },
    onHoverStars(n) {
      if (this.interactive && !this.disabled) {
        this.valueModel = n * 20;
      }
    },
  },
};
</script>

<style>
.rating {
  @apply text-[#f2b848];
}

.star {
  @apply cursor-pointer;
}

.star.disabled {
  @apply cursor-not-allowed;
}
</style>
