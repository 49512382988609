import { createStore, createLogger } from 'vuex';
import auth from './modules/user';
import applicant from './modules/applicant';
import application from './modules/application';
import appTraffic from './modules/appTraffic';
import drafts from './modules/drafts';
import notifications from './modules/notifications';
import settings from './modules/settings';
import todos from './modules/todos';
import ui from './modules/ui';

const debug = process.env.NODE_ENV !== 'production';

export default createStore({
  modules: {
    auth,
    applicant,
    application,
    appTraffic,
    drafts,
    notifications,
    settings,
    todos,
    ui,
  },
  plugins: debug ? [createLogger()] : [],
});
