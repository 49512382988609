<script setup>
import moment from 'moment';
import { BaseBadge, BaseLabel, BaseButtonDropdown } from '@/components/Base';

const sendAction = defineModel({
  type: Object,
  default: { label: 'Now', value: null },
});

const labelOptions = [
  { label: 'Now', value: null },
  { label: '48 hours later', value: moment().add(2, 'days').toISOString() },
];
</script>
<template>
  <div class="flex flex-col gap-1">
    <BaseBadge
      icon="calendar"
      color="transparent"
      class="!px-0"
    >
      Select sending time
    </BaseBadge>
    <div class="flex gap-2">
      <BaseLabel
        v-for="(item, index) in labelOptions"
        :key="index"
        class="icon-secondary-gray py-2.5 !px-4 !text-body-sm w-fit"
        :selected="sendAction.label === item.label"
        size="xl"
        rounded
        clickable
        @click="sendAction = item"
      >
        {{ item.label }}
      </BaseLabel>
      <BaseButtonDropdown
        type="date-time"
        map-options
        option-label="label"
        option-value="value"
        :model-value="sendAction.value"
        @update:model-value="
          (value) =>
            (sendAction = {
              label: moment(value).format('MMM DD, HH:mm'),
              value: moment(value).toISOString(),
            })
        "
      >
        <template #activator>
          <BaseLabel
            class="icon-secondary-gray !px-4 py-2.5 !text-body-sm"
            prepend-icon="calendar"
            icon-size="20px"
            size="xl"
            rounded
            clickable
            :selected="
              sendAction.label &&
              !labelOptions.some((item) => item.label === sendAction.label)
            "
          >
            {{
              sendAction.label &&
              !labelOptions.some((item) => item.label === sendAction.label)
                ? sendAction.label
                : 'Select Date'
            }}
          </BaseLabel>
        </template>
      </BaseButtonDropdown>
    </div>
  </div>
</template>
