<template>
  <div>
    <a
      v-for="(attachment, index) in attachments"
      :key="index"
      v-safe-href="attachment.public_url"
      class="attachment-chip"
      target="_blank"
      download
    >
      <IconBase height="1.25" width="1.25" class="attachment-chip-icon">
        <IconAttachment />
      </IconBase>
      <div class="attachment-chip-label">
        {{ attachment.original_name }}
      </div>
    </a>
  </div>
</template>

<script>
import IconAttachment from '@/components/Icons/IconAttachment';
import IconBase from '@/components/Icons/IconBase.vue';

export default {
  name: 'AppAttachments',
  components: {
    IconAttachment,
    IconBase,
  },
  props: {
    attachments: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
  },
};
</script>

<style scoped>
.attachment-chip {
  @apply inline-flex bg-proxify-black rounded text-white hover:text-white font-semibold text-center my-1 cursor-pointer h-10 leading-10;
}

.attachment-chip:not(:first-child) {
  @apply ml-2;
}

.attachment-chip-icon {
  @apply my-2 ml-3;
}

.attachment-chip-label {
  @apply ml-2 mr-4 max-w-32 truncate;
}
</style>
