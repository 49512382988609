<template>
  <BaseConfirmationCard
    cancel-button-text="Skip"
    confirm-button-text="Fill now"
    :on-close="onCancel"
    :on-confirm="onConfirm"
  >
    <template #title>Forgot something?</template>
    <div>
      You can't move this profile forward because it's lacking:
      <div class="mt-5 mb-8 text-body-md font-medium text-proxify-gray-700">
        Interview Scorecard
      </div>
    </div>
  </BaseConfirmationCard>
</template>

<script>
import { BaseConfirmationCard } from '@/components/Base';

export default {
  components: { BaseConfirmationCard },
  props: {
    onCancel: {
      type: Function,
      required: true,
    },
    onClose: {
      type: Function,
      required: true,
    },
    onConfirm: {
      type: Function,
      required: true,
    },
  },
};
</script>
