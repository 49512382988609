<script setup>
import BaseIcon from '@/components/Base/BaseIcon.vue';
import PBadge from '@/components/ProxifyUI/PBadge.vue';
import { computed, ref, onMounted } from 'vue';
import { onClickOutside } from '@vueuse/core';

// Define component props
const props = defineProps({
  dense: {
    type: Boolean,
    default: false,
  },
  modelValue: {
    type: String,
    default: '',
  },
  label: {
    type: String,
    required: false,
  },
  outline: {
    type: Boolean,
    default: false,
  },
  selected: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String,
    default: 'sm',
  },
  state: {
    type: String,
    default: 'default',
  },
});

// Define emitted events
const emit = defineEmits(['update:inputValue']);

// Initialize state variables
const previousInputValue = ref(props.modelValue);
const inputValue = ref(props.modelValue);
const inputValueRef = ref();
const showInput = ref(false);
const badgeClass = ref('p-badge--label-default');
const badgeRef = ref();

// Handle click outside of the component
onClickOutside(badgeRef, () => (showInput.value ? saveInput() : null));

// Handle click on the label
const onClickLabel = () => {
  showInput.value = true;
  badgeClass.value = 'p-badge--label-active';
  inputValueRef.value.focus();
};

// Handle class changes dynamically
const qFieldControlWidth = computed(() => (showInput.value ? '100px' : '0px'));

const inputClass = computed(() => {
  if (badgeClass.value === 'p-badge--label-selected') {
    return 'text-white font-bold';
  } else if (
    badgeClass.value === 'p-badge--label-active' &&
    !inputValue.value
  ) {
    return 'text-proxify-gray-500';
  }
  return 'text-proxify-primary-900';
});

const crossIconClass = computed(() => {
  if (badgeClass.value === 'p-badge--label-selected' && inputValue.value) {
    return 'text-white';
  } else if (inputValue.value) {
    return 'text-proxify-gray-500';
  }
  return 'opacity-0';
});

const titleClass = computed(() => {
  if (badgeClass.value === 'p-badge--label-selected') {
    return 'text-white';
  } else if (badgeClass.value === 'p-badge--label-active') {
    return 'text-proxify-gray-800';
  }
  return 'text-proxify-gray-700';
});

// Save input value if changed, and handle badge class change
const saveInput = ({ force } = {}) => {
  if (inputValue.value !== previousInputValue.value || force) {
    emit('update:inputValue', inputValue.value);
    previousInputValue.value = !force ? inputValue.value : null;
  }
  badgeClass.value = inputValue.value
    ? 'p-badge--label-selected'
    : 'p-badge--label-default';
  showInput.value = !!inputValue.value;
};

// Clear input value and handle badge class change
const clearInput = () => {
  saveInput({ force: true });
  inputValue.value = null;
  showInput.value = false;
  badgeClass.value = 'p-badge--label-default';
};

// Handle badge class and input visibility on component mount
onMounted(() => {
  if (props.modelValue) {
    badgeClass.value = 'p-badge--label-selected';
    showInput.value = true;
  }
});
</script>

<template>
  <PBadge
    ref="badgeRef"
    :dense="dense"
    :outline="outline"
    :size="size"
    :state="state"
    :class="badgeClass"
    class="font-inter overflow-hidden"
    @click.capture="onClickLabel"
  >
    <template #prepend>
      <slot name="prepend">
        <Transition name="slide-fade">
          <BaseIcon
            v-show="!showInput"
            name="plus"
            size="18px"
            custom-class="text-proxify-gray-700"
          />
        </Transition>
      </slot>
    </template>
    <slot>
      <div
        class="font-semibold"
        :class="titleClass"
      >
        Other
      </div>
      <div
        v-show="showInput"
        class="mx-2 h-[40px] border-l"
        :class="
          badgeClass === 'p-badge--label-selected'
            ? 'border-proxify-gray-700'
            : 'border-proxify-gray-300'
        "
      ></div>
      <q-input
        ref="inputValueRef"
        v-model="inputValue"
        dense
        placeholder="Type here"
        color="transparent"
        bg-color="transparent"
        :input-class="inputClass"
        @keydown.enter="saveInput"
        @update:model-value="badgeClass = 'p-badge--label-active'"
      />
      <Transition>
        <BaseIcon
          v-show="showInput"
          name="x"
          size="20px"
          :class="crossIconClass"
          class="transition duration-300"
          @click="clearInput"
        />
      </Transition>
    </slot>
  </PBadge>
</template>
<style lang="scss" scoped>
.q-chip :deep(.q-field__control) {
  @apply transition-all
  duration-300;
  width: v-bind(qFieldControlWidth);
}
.p-badge {
  @apply transition-all
  duration-300
  cursor-pointer;

  &--label-default {
    @apply bg-[#ffffff]
    border
    border-[#d0d5dd];
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05) !important;
    &:hover {
      @apply bg-[#f9fafb];
    }
  }
  &--label-active {
    @apply bg-[#ffffff]
    border
    border-[#999afd];
    box-shadow:
      0px 1px 2px rgba(16, 24, 40, 0.05),
      0px 0px 0px 4px #f4ebff !important;
  }
  &--label-selected {
    @apply bg-[#1d2939]
    text-[#ffffff];
    input {
      @apply text-[#ffffff]
      font-bold;
    }
  }
}

.v-enter-active,
.v-leave-active {
  transition: all 0.3s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.3s ease;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  width: 0px;
  opacity: 0;
}
</style>
