<script setup>
import PRadio from '@/components/ProxifyUI/PRadio.vue';
import BaseBadge from '@/components/Base/BaseBadge.vue';
import { useVModel } from '@vueuse/core';

const props = defineProps({
  label: {
    type: String,
    required: false,
  },
  val: {
    type: String,
    required: false,
  },
  checked: {
    type: Boolean,
    required: false,
    default: () => false,
  },
  disabled: {
    type: Boolean,
    required: false,
    default: () => false,
  },
  size: {
    type: String,
    required: false,
    default: () => 'md',
  },
  modelValue: {
    type: String,
    required: false,
  },
});

const emit = defineEmits(['update:modelValue']);
const model = useVModel(props, 'modelValue', emit);

const handleClick = () => {
  if (props.disabled) {
    return;
  }
  model.value = props.val;
};
</script>

<template>
  <BaseBadge
    outline
    class="base-badge bg-white"
    clickable
    :class="{
      'cursor-pointer !text-proxify-midnight-blue': !props.disabled,
      'cursor-not-allowed !text-proxify-gray-300': props.disabled,
      'border-proxify-gray-300': !props.checked || props.disabled,
      'border-proxify-primary-300': props.checked && !props.disabled,
    }"
    :size="props.size === 'lg' ? 'xl' : 'lg'"
    color="white"
    @click.capture="handleClick"
  >
    <div
      class="absolute inset-0 z-10"
      @click="handleClick"
    ></div>
    <PRadio
      v-model="model"
      v-bind="props"
      class="p-radio"
      :class="{
        disabled: props.disabled,
        checked: props.checked,
        large: props.size === 'lg',
      }"
    ></PRadio>
  </BaseBadge>
</template>

<style lang="scss">
.p-radio {
  @apply font-inter text-proxify-gray-600;
  position: relative;
  .q-radio__inner {
    display: none;
  }

  .q-radio__label {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }

  &:before {
    content: '';
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 1px solid #d0d5dd;
    margin-right: 8px;
  }

  &:hover {
    &:before {
      border-color: #5258fb;
      background-color: #d5d5fe;
    }
  }
  &:focus {
    &:before {
      border-color: #999afd !important;
      background-color: transparent;
      box-shadow: 0 0 0 4px #f4ebff;
    }
  }

  &.checked {
    &::after {
      content: '';
      display: block;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: #5258fb;
      position: absolute;
      left: 5px;
      top: 50%;
      transform: translateY(-50%);
    }

    &::before {
      border-color: #5258fb;
      background-color: #e5e8f9;
    }

    &:hover,
    &:focus {
      &::before {
        border-color: #999afd !important;
        background-color: #e5e8f9;
      }
    }

    &:focus::before {
      box-shadow: 0 0 0 4px #f4ebff;
    }
  }

  &.disabled {
    cursor: not-allowed !important;
    &:before {
      border-color: #d0d5dd;
    }

    &:after {
      background-color: #d0d5dd;
    }

    &:hover {
      &:before {
        border-color: #d0d5dd;
        background-color: transparent;
      }
    }

    &:focus {
      &:before {
        border-color: #d0d5dd;
        box-shadow: none;
      }
    }

    .q-radio__label {
      cursor: not-allowed !important;
    }
  }

  &.large {
    &:before {
      width: 20px;
      height: 20px;
    }

    .q-radio__label {
      font-size: 16px;
    }

    &:after {
      width: 8px;
      height: 8px;
      left: 6px;
    }
  }
}

.base-badge {
  @apply rounded-full
  relative
  overflow-hidden;
  &:hover {
    @apply border-proxify-primary-300
    bg-proxify-gray-50 #{!important};
    .p-radio:before {
      @apply border-proxify-primary-300
      bg-proxify-gray-50;
    }
  }
}
</style>
