<script setup>
import { useForm } from 'vee-validate';
import { computed, ref } from 'vue';
import { BaseConfirmationCard } from '@/components/Base';
import ApplicantRejectCandidateForm from '@/components/Elements/Applicant/ApplicantRejectCandidateForm.vue';

const props = defineProps({
  applicant: {
    type: Object,
    required: false,
    default: undefined,
  },
  onClose: {
    type: Function,
    required: true,
  },
});
const emit = defineEmits(['reject']);
const sendAction = ref(undefined);
const sendEmail = ref(false);
const details = ref(null);
const reasons = ref([]);
const isLoading = ref(false);
const templateToSend = ref(undefined);
const detailsRequired = ref(false);

const isApplicantApplied = computed(() => {
  return props.applicant?.stage && !props.applicant.stage.is_sourcing;
});

const schema = computed(() => {
  return {
    'Main reason': 'required',
    'Rejection details': {
      required: detailsRequired.value,
    },
  };
});

const { handleSubmit } = useForm({
  validationSchema: schema,
});

const close = () => {
  props.onClose();
  details.value = null;
  reasons.value = [];
};
const handleReject = () => {
  emit('reject', {
    reasons: reasons.value,
    details: details.value,
    email_template_id: sendEmail.value ? templateToSend.value?.id : undefined,
    subject: templateToSend.value?.subject,
    body: templateToSend.value?.body,
    markdown: Boolean(templateToSend.value?.body),
    template_data: [''],
    send_at: sendEmail.value ? sendAction.value?.value : null,
  });
  close();
};
const handleUpdateReasons = (value) => {
  reasons.value = value;
};
</script>
<template>
  <BaseConfirmationCard
    :on-confirm="handleSubmit(handleReject)"
    :on-close="close"
    confirm-button-text="Reject"
    confirm-button-destructive
    :is-loading="isLoading"
    paddingless
    width="512px"
  >
    <template #title>Reject applicant</template>
    <template #subtitle>
      <div class="flex flex-col gap-1.5">
        <div class="text-proxify-gray-600 text-body-sm font-medium">
          You are about to reject
          <span class="font-semibold text-proxify-gray-900">
            {{ applicant.full_name }}
          </span>
          at
          <span class="font-semibold text-proxify-gray-900">
            {{ applicant.stage.name }}
          </span>
          stage.
        </div>
      </div>
    </template>
    <div class="px-6">
      <ApplicantRejectCandidateForm
        v-model:send-email="sendEmail"
        v-model:details="details"
        v-model:template-to-send="templateToSend"
        v-model:send-action="sendAction"
        v-model:details-required="detailsRequired"
        :application-id="applicant.id"
        :should-send-email="isApplicantApplied"
        @update:reasons="handleUpdateReasons"
      />
    </div>
  </BaseConfirmationCard>
</template>
