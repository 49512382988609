import { watch } from 'vue';
import { useMounted, useScroll } from '@vueuse/core';
import { unitUtils } from '@/utils';
import { useStore } from 'vuex';

export default function useScrollToItem(
  containerReference,
  itemId,
  options = {},
  onBeforeScroll = null,
  onAfterScroll = null
) {
  const { scrollToBottom = false, ...useScrollOptions } = options;
  const { y } = useScroll(containerReference, {
    behavior: 'smooth',
    ...useScrollOptions,
  });
  const isMounted = useMounted();
  const store = useStore();

  const scrollToItem = () => {
    const element = document.getElementById(itemId.value);
    if (typeof onBeforeScroll === 'function') {
      onBeforeScroll?.(itemId.value);
    }
    if (element && containerReference.value) {
      y.value =
        element.offsetTop -
        containerReference.value.offsetTop -
        unitUtils.convertRemToPixels(1);
    }
    if (typeof onAfterScroll === 'function') {
      setTimeout(onAfterScroll, 6000);
    }
  };

  const scrollToContainerBottom = () => {
    y.value = containerReference.value?.scrollHeight;
  };

  watch(
    () => [
      itemId.value,
      isMounted.value,
      store.getters['appTraffic/isLoading'],
    ],
    async ([newItemId, newIsMounted]) => {
      if (!newIsMounted) return;
      if (newItemId) {
        await scrollToItem();
      } else if (!newItemId && scrollToBottom) {
        scrollToContainerBottom();
      }
    },
    {
      immediate: true,
      deep: true,
    }
  );

  return { scrollToItem, scrollToBottom: scrollToContainerBottom };
}
