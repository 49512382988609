<script>
/**
 * @typedef { import('vue').Ref } Ref
 */

/**
 * Represents the links and attachments of an applicant.
 *
 * @typedef {Object} ApplicationLinksAndAttachments
 * @property {?(file|object)} cvFile - CV file of the applicant.
 * @property {?string} designerLink - Link to the applicant's designer profile.
 * @property {?string} githubLink - Link to the applicant's GitHub profile.
 * @property {?string} kaggleLink - Link to the applicant's Kaggle profile.
 * @property {?string} linkedinLink - Link to the applicant's LinkedIn profile.
 * @property {?(file|object)} otherFile - Other file uploaded by the applicant.
 * @property {?string} otherLink - Link to the applicant's other profile.
 * @property {?string[]} requiredFields - List of required fields.
 * @property {?number} uploadProgress - Upload progress of the applicant's attachments.
 */

export default {
  name: 'ApplicationFormLinksAndAttachments',
};
</script>
<script setup>
import { BaseButton, BaseInputField, BaseInputFile } from '@/components/Base';
import { ref, watch } from 'vue';
import { stringUtils } from '@/utils';

const props = defineProps({
  cvFile: {
    type: [File, Object],
    required: false,
    default() {
      return null;
    },
  },
  designerLink: {
    type: String,
    required: false,
    default() {
      return '';
    },
  },
  githubLink: {
    type: String,
    required: false,
    default() {
      return '';
    },
  },
  kaggleLink: {
    type: String,
    required: false,
    default() {
      return '';
    },
  },
  linkedinLink: {
    type: String,
    required: false,
    default() {
      return '';
    },
  },
  otherFile: {
    type: [File, Object],
    required: false,
    default() {
      return null;
    },
  },
  otherLink: {
    type: String,
    required: false,
    default() {
      return '';
    },
  },
  requiredFields: {
    type: Array,
    required: false,
    default() {
      return [];
    },
  },
  uploadProgress: {
    type: Number,
    required: false,
    default() {
      return 0;
    },
  },
});
const emit = defineEmits([
  'update:cv-file',
  'update:designer-link',
  'update:github-link',
  'update:kaggle-link',
  'update:linkedin-link',
  'update:other-file',
  'update:other-link',
]);
/** @type {Ref<ApplicationLinksAndAttachments>} */
const form = ref({});
/** @type {Ref<boolean>} */
const expanded = ref(false);

watch(
  () => ({
    cvFile: props.cvFile,
    designerLink: props.designerLink,
    githubLink: props.githubLink,
    kaggleLink: props.kaggleLink,
    linkedinLink: props.linkedinLink,
    otherFile: props.otherFile,
    otherLink: props.otherLink,
  }),
  /** @param {ApplicationLinksAndAttachments} newProps */
  (newProps) => {
    form.value = {
      cvFile: newProps.cvFile,
      designerLink: stringUtils.modifyUrlProtocol(newProps.designerLink, 'remove'),
      githubLink: stringUtils.modifyUrlProtocol(newProps.githubLink, 'remove'),
      kaggleLink: stringUtils.modifyUrlProtocol(newProps.kaggleLink, 'remove'),
      linkedinLink: stringUtils.modifyUrlProtocol(newProps.linkedinLink, 'remove'),
      otherFile: newProps.otherFile,
      otherLink: stringUtils.modifyUrlProtocol(newProps.otherLink, 'remove'),
    };
    expanded.value = Boolean(
      newProps.githubLink ||
        newProps.kaggleLink ||
        newProps.designerLink ||
        newProps.otherLink
    );
  },
  { deep: true, immediate: true }
);

watch(
  () => form.value,
  /** @param {ApplicationLinksAndAttachments} newForm */
  (newForm) => {
    emit('update:cv-file', newForm.cvFile);
    emit(
      'update:designer-link',
      stringUtils.modifyUrlProtocol(newForm.designerLink, 'add') || null
    );
    emit(
      'update:github-link',
      stringUtils.modifyUrlProtocol(newForm.githubLink, 'add') || null
    );
    emit(
      'update:kaggle-link',
      stringUtils.modifyUrlProtocol(newForm.kaggleLink, 'add') || null
    );
    emit(
      'update:linkedin-link',
      stringUtils.modifyUrlProtocol(newForm.linkedinLink, 'add') || null
    );
    emit('update:other-file', newForm.otherFile);
    emit(
      'update:other-link',
      stringUtils.modifyUrlProtocol(newForm.otherLink, 'add') || null
    );
  },
  { deep: true, immediate: true }
);
</script>
<template>
  <div>
    <div class="text-body-md font-semibold text-proxify-gray-900 mt-2">
      Links and attachments
    </div>
    <div class="my-4">
      <div>
        <div class="text-body-sm font-medium text-proxify-gray-700 mb-2">
          <span>LinkedIn</span>
          <span v-if="requiredFields.includes('Linkedin') && !form.cvFile">
            *
          </span>
        </div>
        <div class="flex items-center gap-4">
          <BaseInputField
            v-model="form.linkedinLink"
            leading-text="https://"
            type="url"
            hide-bottom-space
            :hint="undefined"
            name="Linkedin"
            data-testid="linkedin-link-input"
          />
          <div class="text-proxify-gray-400 text-body-sm font-medium">or</div>
          <BaseInputFile
            title="Upload CV"
            name="CV File"
            :show-required-label="
              requiredFields.includes('CV File') && !form.linkedinLink
            "
            :value="form.cvFile"
            chip
            @handle-files-changed="(file) => (form.cvFile = file)"
          />
        </div>
      </div>
    </div>
    <BaseButton
      v-if="!expanded"
      class="icon-link-color !p-0 !min-h-6 !h-6"
      icon-prepend="plus"
      icon-size="20px"
      data-testid="add-another-link-button"
      @click="() => (expanded = true)"
    >
      Add another
    </BaseButton>
    <Transition>
      <div v-show="expanded">
        <div class="my-2 grid gap-4 grid-cols-2">
          <div data-testid="github-link-input">
            <div class="text-body-sm font-medium text-proxify-gray-700 mb-2">
              Github
            </div>
            <BaseInputField
              v-model="form.githubLink"
              leading-text="https://"
              type="url"
              hide-bottom-space
              :hint="undefined"
              name="Github"
            />
          </div>
          <div>
            <div class="text-body-sm font-medium text-proxify-gray-700 mb-2">
              Kaggle
            </div>
            <BaseInputField
              v-model="form.kaggleLink"
              leading-text="https://"
              type="url"
              hide-bottom-space
              :hint="undefined"
              name="Kaggle"
            />
          </div>
          <div>
            <div class="text-body-sm font-medium text-proxify-gray-700 mb-2">
              Designer
            </div>
            <BaseInputField
              v-model="form.designerLink"
              leading-text="https://"
              type="url"
              hide-bottom-space
              :hint="undefined"
              name="Designer"
            />
          </div>
          <div>
            <div class="text-body-sm font-medium text-proxify-gray-700 mb-2">
              Other
            </div>
            <BaseInputField
              v-model="form.otherLink"
              leading-text="https://"
              type="url"
              hide-bottom-space
              :hint="undefined"
              name="Other link"
            />
          </div>
        </div>
        <BaseInputFile
          title="Upload File"
          name="Other File"
          class="mt-4"
          :value="form.otherFile"
          :upload-progress="uploadProgress"
          chip
          @handle-files-changed="(file) => (form.otherFile = file)"
        />
      </div>
    </Transition>
  </div>
</template>

<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.4s;
}

.v-enter,
.v-leave-to {
  opacity: 0;
}
</style>
