<template>
  <a
    v-safe-href="link"
    target="_blank"
  >
    <span class="flex items-center text-proxify-gray font-medium text-body-sm">
      <BaseIcon
        :name="icon"
        class="mr-2"
      />
      <span class="truncate">{{ value }}</span>
    </span>
  </a>
</template>

<script setup>
import BaseIcon from '@/components/Base/BaseIcon.vue';
import { computed } from 'vue';
import { stringUtils } from '@/utils';

const props = defineProps({
  icon: {
    type: String,
    default: 'link-external01',
  },
  url: {
    type: String,
    required: true,
  },
  value: {
    type: String,
    required: true,
  },
});

const link = computed(() => {
  const url = stringUtils.modifyUrlProtocol(props.url, 'add');
  return stringUtils.validURL(url) ? url : '/404';
});
</script>
