import axios from 'axios';
import notes from './applications/notes';
import history from './applications/history';
import emails from './applications/emails';
import interviews from './applications/interviews';
import codility from './applications/codility';
import karat from '@/api/applications/karat';

export default {
  getApplicationsList({ params, cancelToken }) {
    return axios.get('/applications/list', { params, cancelToken });
  },
  getApplications({ params, cancelToken }) {
    return axios.get('/applications', { params, cancelToken });
  },
  getRecentApplications() {
    return axios.get('/applications/recent');
  },
  postApplication(parameters) {
    return axios.post('/applications', parameters);
  },
  putApplication(id, parameters) {
    return axios.put(`/applications/${id}`, parameters);
  },
  downloadAttachment(downloadUrl) {
    return axios.get(downloadUrl);
  },
  updateAttachments(body, { id, onUploadProgress }) {
    return axios.post(`/applications/${id}/update/attachments`, body, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress,
    });
  },
  updateSkills(id, parameters) {
    return axios.post(`/applications/${id}/update/skills`, parameters);
  },
  export({ params }) {
    return axios.get('/applications/export', { params });
  },
  getApplicant(id) {
    return axios.get(`/applications/${id}`);
  },
  reject(id, parameters) {
    return axios.post(`/applications/${id}/reject`, parameters);
  },
  unReject(id) {
    return axios.post(`/applications/${id}/unreject`);
  },
  updateAssignees(id, parameters) {
    return axios.post(`/applications/${id}/assignees`, parameters);
  },
  confirmRates(id, parameters) {
    return axios.post(`/applications/${id}/confirm-rates`, parameters);
  },
  changeStage(id, parameters) {
    return axios.post(`/applications/${id}/change-stage`, parameters);
  },
  search(data, config) {
    return axios.post('applications/search', data, config);
  },
  performTriggerActions(id, body) {
    return axios({
      method: 'post',
      url: `applications/${id}/perform-trigger-actions`,
      data: body,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  getAdjacent({ id, ...parameters }) {
    return axios.get(`/applications/${id}/adjacent`, { params: parameters });
  },
  notes,
  history,
  karat,
  emails,
  interviews,
  codility,
};
