<template>
  <div class="notification-container h-screen">
    <div class="notification-header">
      <div class="text-body-xl font-semibold flex items-center">
        <BaseIcon
          name="bell01"
          size="20px"
          class="mr-2"
        />
        Notifications
      </div>
      <BaseButton
        icon-prepend="x-close"
        icon-size="20px"
        class="text-proxify-gray-500 p-0"
        @click="$store.commit('settings/setNotificationBarStatus')"
      />
    </div>
    <div
      ref="notificationTabsRef"
      class="notification-tabs"
    >
      <button
        v-for="(tab, index) in notificationTabs"
        :key="index"
        class="notification-tab"
        :class="{ active: currentTab === tab }"
        @click="currentTab = tab"
      >
        {{ tab }}
        <span
          v-if="getNotificationCount(tab)"
          class="w-4 h-4 min-h-4 min-w-4 rounded-full flex items-center justify-center"
          :class="{
            'bg-proxify-error-500 text-white': currentTab === tab,
            'bg-proxify-error-50 text-proxify-error-700': currentTab !== tab,
          }"
        >
          <span class="text-body-xs font-medium">
            {{ getNotificationCount(tab) }}
          </span>
        </span>
      </button>
    </div>
    <div
      ref="ghostElement"
      class="w-0 h-0 -mt-6"
    ></div>
    <div
      class="notification-items"
      :style="{ height: containerHeight + 'px' }"
    >
      <span
        v-if="unreadNotifications.length"
        class="text-proxify-gray-600 text-right cursor-pointer"
        @click="markAsRead('all')"
      >
        Mark all as read
      </span>
      <div
        v-else
        class="no-data-container"
      >
        <div class="no-data">
          Phew!
          <br />
          Nothing else to see here :)
        </div>
      </div>
      <transition-group
        :css="false"
        @before-enter="onBeforeEnter"
        @enter="onEnter"
        @leave="onLeave"
      >
        <NotificationCard
          v-for="notification in unreadNotifications"
          :key="notification.id"
          :mark-as-read="() => markAsRead([notification.id])"
          :notification="notification"
        />
      </transition-group>
      <div class="flex justify-center">
        <BaseButton
          color="transparent"
          class="!text-proxify-gray-600 !p-0 cursor-pointer font-body-sm h-[unset]"
          @click="showReadNotifications = !showReadNotifications"
        >
          {{ showReadNotifications ? 'Hide' : 'Show' }} previous
          <template #append>
            <BaseIcon
              name="chevron-down"
              color="gray-600"
              size="20px"
            />
          </template>
        </BaseButton>
      </div>
      <template v-if="showReadNotifications">
        <transition-group
          :css="false"
          @before-enter="onBeforeEnter"
          @enter="onEnter"
          @leave="onLeave"
        >
          <NotificationCard
            v-for="notification in readNotifications"
            :key="notification.id"
            :mark-as-read="() => markAsUnread([notification.id])"
            :notification="notification"
          />
        </transition-group>
      </template>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { computed, ref } from 'vue';
import { gsap } from 'gsap';
import NotificationCard from '@/components/Layout/Scaffold/NotificationCard.vue';
import api from '@/api';
import { BaseIcon, BaseButton } from '@/components/Base';
import { useElementBounding } from '@vueuse/core';
import useNormalizedWindowSize from '@/composables/useNormalizedWindowSize';

export default {
  name: 'NotificationPanel',
  components: {
    NotificationCard,

    BaseIcon,
    BaseButton,
  },
  setup() {
    const notificationTabsReference = ref(null);
    const ghostElement = ref(null);

    const currentTab = ref('All');
    const showReadNotifications = ref(false);
    const store = useStore();
    const notifications = computed(
      () => store.state.notifications.notifications
    );
    const notificationTabs = computed(() =>
      notifications.value.length
        ? new Set([
            'All',
            ...notifications.value
              .map((notification) => notification.data.category)
              .filter(Boolean),
          ])
        : []
    );

    const { top } = useElementBounding(ghostElement);
    const { height } = useNormalizedWindowSize();
    const containerHeight = computed(() => {
      return height.value - top.value - 24;
    });

    const filteredNotifications = computed(() =>
      notifications.value.filter(
        (notification) =>
          currentTab.value === 'All' ||
          notification.data.category === currentTab.value
      )
    );
    const unreadNotifications = computed(() =>
      filteredNotifications.value.filter((item) => !item.read_at)
    );
    showReadNotifications.value = Boolean(!unreadNotifications.value.length);
    const readNotifications = computed(() =>
      filteredNotifications.value.filter((item) => Boolean(item.read_at))
    );
    const getNotificationCount = (tab) => {
      return notifications.value.filter(
        (notification) =>
          (notification.data.category === tab || tab === 'All') &&
          !notification.read_at
      ).length;
    };
    const markAsRead = async (notificationIds) => {
      try {
        await api.notifications.markAsRead({
          notifications:
            notificationIds === 'all'
              ? filteredNotifications.value
                  .filter((item) => !item.read_at)
                  .map(({ id }) => id)
              : notificationIds,
        });
      } catch (error) {
        console.log(error);
      } finally {
        await store.dispatch('notifications/fetchNotifications');
      }
    };
    const markAsUnread = async (notificationIds) => {
      try {
        await api.notifications.markAsUnread({
          notifications: notificationIds,
        });
      } catch (error) {
        console.log(error);
      } finally {
        await store.dispatch('notifications/fetchNotifications');
      }
    };

    const onBeforeEnter = (element) => {
      element.style.opacity = 0;
      element.style.height = 0;
    };

    const onEnter = (element, done) => {
      gsap.to(element, {
        opacity: 1,
        height: 'unset',
        delay: element.dataset.index * 0.15,
        onComplete: done,
      });
    };

    const onLeave = (element, done) => {
      gsap.to(element, {
        opacity: 0,
        height: 0,
        delay: element.dataset.index * 0.15,
        onComplete: done,
      });
    };

    return {
      currentTab,
      notificationTabsRef: notificationTabsReference,
      notifications: filteredNotifications,
      notificationTabs,
      readNotifications,
      showReadNotifications,
      unreadNotifications,
      getNotificationCount,
      markAsRead,
      markAsUnread,
      onBeforeEnter,
      onEnter,
      onLeave,
      ghostElement,
      containerHeight,
    };
  },
};
</script>
<style scoped>
.notification-container {
  @apply w-[400px] max-w-[400px] min-w-[400px] bg-white py-6 border-l border-proxify-gray-200 shadow-xl;
}

.notification-header {
  @apply flex justify-between items-center px-4;
}

.notification-tabs {
  @apply flex flex-wrap gap-2 mt-6 mb-8 px-4;
}

.notification-tab {
  @apply border border-proxify-gray-300 px-4 py-2 rounded-[100px] flex gap-1 text-body-sm font-semibold items-center;
}

.notification-tab.active {
  @apply bg-proxify-primary-700 text-white;
}

.notification-tab:not(.active) {
  @apply border-proxify-gray-300 text-proxify-gray-700;
}

.notification-items {
  @apply w-full flex flex-col gap-4 relative proxify-scrollbar overflow-y-auto px-4;
}

.no-data-container {
  @apply grid place-items-center py-14;
}

.no-data {
  @apply text-center font-medium text-disabled-normal;
}
</style>
