<template>
  <BaseConfirmationCard
    :width="width"
    :cancel-button-text="null"
    confirm-button-text="Generate"
    :on-close="onClose"
    :on-confirm="handleSubmit(handleGenerate)"
    paddingless
  >
    <template #title>New Test</template>
    <BaseInputDropdown
      v-model="selectedTest"
      name="Test"
      :options="codilityTests"
      map-options
      type="search"
      class="px-6 pb-1"
    />
  </BaseConfirmationCard>
</template>
<script>
import { ref, inject } from 'vue';
import api from '@/api';
import { useForm } from 'vee-validate';
import { BaseInputDropdown, BaseConfirmationCard } from '@/components/Base';
import { useStore } from 'vuex';

export default {
  components: {
    BaseInputDropdown,
    BaseConfirmationCard,
  },
  props: {
    width: {
      type: String,
      required: false,
    },
    applicationId: {
      type: [String, Number],
      required: true,
    },
    positioningStrategy: {
      type: String,
      required: false,
    },
    menuPlacement: {
      type: String,
      required: false,
      default() {
        return 'right-start';
      },
    },
    platformElement: {
      required: false,
    },
    onGenerateTest: {
      type: Function,
      required: false,
    },
    onClose: {
      type: Function,
      required: false,
    },
  },
  setup(props) {
    const codilityTests = inject('codilityTests');
    const store = useStore();
    const { handleSubmit } = useForm();
    const selectedTest = ref(null);
    const generateTestCard = ref(null);
    const handleGenerate = async () => {
      const { data } = await api.applications.codility.generateLink({
        test_id: selectedTest.value.id,
        application_id: props.applicationId,
      });
      store.commit('ui/addSnackbarMessage', {
        title: `"${selectedTest.value.name}" test successfully generated`,
        type: 'success',
      });
      props.onGenerateTest(data.link);
      props.onClose();
    };
    const handleChange = (value) => {
      selectedTest.value = value;
    };

    return {
      generateTestCard,
      handleChange,
      handleSubmit,
      codilityTests,
      selectedTest,
      handleGenerate,
    };
  },
};
</script>
