<script setup>
import { computed, toRefs, watch } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { BaseButton } from '@/components/Base';

const store = useStore();
const route = useRoute();

const props = defineProps({
  adjacentApplications: {
    type: Object,
    required: true,
  },
  isLoading: {
    type: Boolean,
    required: true,
  },
});

const { adjacentApplications } = toRefs(props);

const isNotificationBarActive = computed(
  () => store.state.settings.isNotificationBarActive
);
const isTodosMenuActive = computed(() => store.state.todos.isTodosMenuActive);
const filterQueries = computed(() => store.state.application.filterQueries);
const shouldSwapValues = computed(() => filterQueries.value?.sort === 'asc');

const handleSwapIds = (direction) => {
  const adjacentApplicationIds = {
    next: adjacentApplications.value?.next_id,
    prev: adjacentApplications.value?.prev_id,
  };
  const swappedDirection = {
    next: 'prev',
    prev: 'next',
  };
  return shouldSwapValues.value
    ? adjacentApplicationIds[swappedDirection[direction]]
    : adjacentApplicationIds[direction];
};

const isNextPageAvailable = computed(() => Boolean(handleSwapIds('next')));
const isPreviousPageAvailable = computed(() => Boolean(handleSwapIds('prev')));

const getLink = (id) => {
  if (route.params.tab) {
    return `/applications/${id}/${route.params.tab}`;
  }
  return `/applications/${id}`;
};

const previousLink = computed(() => getLink(handleSwapIds('prev')));
const nextLink = computed(() => getLink(handleSwapIds('next')));

watch(
  () => route.params.id,
  () => {
    store.commit('applicant/setDataNeedsUpdate', true);
  }
);
</script>

<template>
  <div class="pagination-wrapper">
    <BaseButton
      class="pagination-button"
      dense
      :disable="!isPreviousPageAvailable"
      icon-prepend="arrow-left"
      icon-size="14px"
      :to="previousLink"
    >
      <span
        v-if="!isNotificationBarActive && !isTodosMenuActive"
        class="tw-hidden md:inline"
      >
        <span>Previous (</span>
        <AppLoader v-if="isLoading" />
        <span v-else>
          {{
            shouldSwapValues
              ? adjacentApplications?.next_count
              : adjacentApplications?.prev_count
          }}
        </span>
        <span>)</span>
      </span>
    </BaseButton>
    <BaseButton
      class="pagination-button"
      dense
      :disable="!isNextPageAvailable"
      icon-append="arrow-right"
      icon-size="14px"
      :to="nextLink"
    >
      <span
        v-if="!isNotificationBarActive && !isTodosMenuActive"
        class="tw-hidden md:inline"
      >
        <span>Next (</span>
        <AppLoader v-if="isLoading" />
        <span v-else>
          {{
            shouldSwapValues
              ? adjacentApplications?.prev_count
              : adjacentApplications?.next_count
          }}
        </span>
        <span>)</span>
      </span>
    </BaseButton>
  </div>
</template>

<style scoped>
.pagination-wrapper {
  @apply flex
  flex-row
  items-center
  justify-evenly
  gap-2;
}

.pagination-button {
  @apply px-2
  text-body-sm
  text-proxify-gray-700
  bg-proxify-gray-200
  rounded-[6px];
}
</style>
