<template>
  <div
    class="interview-card"
    :class="{
      'cursor-pointer': isCompleted,
      'cursor-default': !isCompleted,
      highlighted: isCardHighlighted(interview.id),
    }"
    @click="
      () =>
        interviewStatus !== 'cancelled' &&
        (hasEditAccess || interviewStatus === 'completed') &&
        openScorecardDialog(interview)
    "
  >
    <div class="interview-card-header">
      <div>
        {{ interview.name }}
      </div>
      <div class="grow"></div>
      <div
        v-if="menuOptions.length"
        @click.stop
      >
        <BaseButton
          icon-prepend="dots-vertical"
          round
          class="icon-tertiary-gray h-[40px] w-[40px] -mt-4 -mr-4"
          size="13px"
        ></BaseButton>
        <BaseMenu :items="menuOptions" />
      </div>
    </div>
    <div class="interview-card-content">
      <div
        class="interview-status"
        :class="interviewStatusClass"
      >
        {{ interviewStatus }}
      </div>
      <div class="interview-date">
        <span v-if="interviewStatus === 'invited'">Waiting for response</span>
        <span
          v-else
          v-safe-html="formattedInterviewDate"
        ></span>
        <span v-if="interview.type === 'KARAT'">Karat</span>
        <BaseButtonDropdown
          v-else-if="shouldShowInterviewerValueSelector"
          name="Interviewer"
          map-options
          :model-value="interviewer"
          :options="
            interviewers.map((item) => ({
              ...item,
              leftSection: !isCompleted
                ? {
                    icon: {
                      name: 'dot',
                      size: '6px',
                      color: 'proxify-success-500',
                    },
                  }
                : {},
            }))
          "
          :close-on-click="true"
          :is-loading="isAvailableInterviewersLoading"
          :placeholder="
            !isCompleted ? `${interviewers?.length} available` : 'Search'
          "
          @update:model-value="handleChangeInterviewer"
          @click.stop="getAvailableInterviewers"
        >
          <template #activator>
            <BaseBadge
              color="transparent"
              class="!text-proxify-primary-600 !p-0 cursor-pointer font-body-sm h-[unset]"
            >
              {{ interviewer?.name }}
              <template #append>
                <BaseIcon
                  name="chevron-down"
                  color="primary"
                  size="20px"
                />
              </template>
            </BaseBadge>
          </template>
          <template
            v-if="!isCompleted"
            #footer
          >
            <div
              class="value-selector-footer"
              :class="{ 'border-t border-black/10': interviewers?.length }"
            >
              <span v-if="!interviewers?.length">
                Looks like everyone’s calendars are full! Try contacting them
                via Slack to check their availability.
              </span>
              <span v-else>
                Looking for someone else? Contact them via Slack to check their
                availability.
              </span>
            </div>
          </template>
        </BaseButtonDropdown>
        <span
          v-else
          class="text-disabled-normal"
        >
          {{ interviewer?.name || interview?.group }}
        </span>
      </div>
      <div class="interview-card-footer">
        <div
          v-if="recommendationInfo"
          class="recommendation-bar"
          :class="recommendationInfo.color"
        >
          {{ recommendationInfo.text }}
        </div>
        <div
          v-if="interview.scheduler_link && interviewStatus === 'invited'"
          class="scheduler-link-bar"
        >
          <div class="truncate text-disabled-dark font-light italic">
            {{ interview.scheduler_link }}
          </div>
          <div
            class="text-proxify-primary cursor-pointer flex items-center gap-1"
            @click.stop="copy(interview.scheduler_link)"
          >
            <IconBase
              width="1"
              height="1"
              border-radius="none"
            >
              <IconCopy />
            </IconBase>
            <span>Copy</span>
          </div>
        </div>
        <div v-if="isCompleted && interview.published_at && interview.rating">
          <AppCardRatingData
            title="Score: "
            :value="parseInt(interview.rating, 10)"
            class="items-end gap-2"
          />
        </div>
        <div
          v-if="
            interview.type === 'PROXIFY' &&
            !isCompleted &&
            !interview.is_interview_filled &&
            formattedTimeRemaining <= 10 &&
            timeRemaining?.status === 'upcoming' &&
            interviewStatus !== 'cancelled'
          "
          class="text-ats-red mb-2"
        >
          Starts in
          <span v-if="formattedTimeRemaining > 1">
            {{ formattedTimeRemaining }} mins!
          </span>
          <span v-else>less than 1 min!</span>
        </div>
        <div
          v-else
          class="grow"
        ></div>
        <BaseButton
          v-if="hasEditAccess && isCompleted && !interview.published_at"
          rounded
          color="primary"
          icon-prepend="file05"
          icon-size="20px"
        >
          Fill Card
        </BaseButton>
        <BaseButton
          v-else-if="
            hasEditAccess &&
            !isCompleted &&
            !interview.published_at &&
            formattedTimeRemaining <= 10 &&
            timeRemaining?.status === 'upcoming' &&
            interviewStatus !== 'cancelled'
          "
          rounded
          color="primary"
          icon-prepend="link-external01"
          icon-size="20px"
          @click="
            () =>
              interviewStatus !== 'cancelled' && openScorecardDialog(interview)
          "
        >
          Open Interview Mode
        </BaseButton>
        <BaseButton
          v-else-if="isCompleted"
          icon-prepend="eye"
          icon-size="20px"
          class="icon-secondary-gray"
          rounded
          @click.stop="
            () =>
              interview.type === 'KARAT'
                ? reveal()
                : openScorecardDialog(interview)
          "
        >
          <span>{{ hasEditAccess ? 'Preview & Edit' : 'Preview' }}</span>
        </BaseButton>
      </div>
    </div>
    <PDialog
      ref="confirmationPopupDialog"
      v-model="isConfirmationDialogRevealed"
      position="standard"
      persistent
    >
      <BaseConfirmationCard
        confirm-button-text="Close"
        cancel-button-text=""
        :on-confirm="confirmConfirmationDialog"
        width="27rem"
        class="ats-scrollbar-persistent overflow-hidden"
      >
        <template #title>Summary</template>
        <div
          v-safe-html="
            Autolinker.link(interview.karatInterview.result_summary, {
              className: 'text-proxify-primary hover:underline',
            })
          "
          class="whitespace-pre-wrap"
        ></div>
      </BaseConfirmationCard>
    </PDialog>
  </div>
</template>
<script>
import { computed, reactive, ref, toRefs, watch } from 'vue';
import { computedEager, useConfirmDialog, useClipboard } from '@vueuse/core';
import moment from 'moment';
import { dateTimeUtils } from '@/utils';
import api from '@/api';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import IconBase from '@/components/Icons/IconBase.vue';
import IconCopy from '@/components/Icons/IconCopy03.vue';
import AppCardRatingData from '@/components/App/card/AppCardRatingData.vue';
import Autolinker from 'autolinker';
import useTimeRemaining from '@/composables/useTimeRemaining';
import { PDialog } from '@/components/ProxifyUI';
import {
  BaseConfirmationCard,
  BaseButton,
  BaseMenu,
  BaseButtonDropdown,
  BaseIcon,
  BaseBadge,
} from '@/components/Base';

export default {
  name: 'ApplicantActivityInterviewCard',
  components: {
    AppCardRatingData,
    IconBase,
    IconCopy,
    PDialog,
    BaseButton,
    BaseMenu,
    BaseButtonDropdown,
    BaseConfirmationCard,
    BaseIcon,
    BaseBadge,
  },
  props: {
    /**
     * @typedef {Object} KaratInterviewResource
     * @property {number} id - The Karat interview ID.
     * @property {string} state - The state of the Karat interview.
     * @property {string} start_time - The start time of the Karat interview.
     * @property {string} end_time - The end time of the Karat interview.
     * @property {('DO_NOT_PURSUE'|'REQUIRES_FURTHER_REVIEW'|'INVITE_TO_NEXT_ROUND'|'FAST_TRACK')} recommendation - The recommendation for the Karat interview.
     * @property {string} overall_thoughts - The overall thoughts for the Karat interview.
     * @property {string} result_summary - The result summary of the Karat interview.
     * @property {string} link - The link to the Karat interview.
     * @property {boolean} is_redo - Indicates if the Karat interview is a redo or not.
     */

    /**
     * @typedef {Object} ApplicationInterviewQuestionResource
     * @property {number} id - The question ID.
     * @property {string} name - The name of the question.
     * @property {string} group - The group the question belongs to.
     * @property {string} label - The label of the question.
     * @property {string} type - The type of the question.
     * @property {string} answer - The answer to the question.
     * @property {Array} answers - The answers when loaded.
     */

    /**
     * @typedef {Object} EventResource
     * @property {string} organizerEmail - The organizer's email.
     * @property {string} date - The event date.
     * @property {string} start_at - The event start time.
     * @property {string} end_at - The event end time.
     * @property {string} timezone - The event timezone.
     * @property {string} summary - The event summary.
     * @property {string} status - The event status.
     * @property {string} meeting_link - The event meeting link.
     * @property {Array<EventAttendeeResource>} attendees - The event attendees.
     * @property {UserResource} organizer - The event organizer.
     * @property {boolean} isUpcoming - Whether the event is upcoming.
     * @property {string} scheduledFor - The scheduled date for the event.
     * @property {number} duration - The event duration.
     * @property {string} group - The event group.
     * @property {string} formattedDate - The formatted event date.
     * @property {string} reminder_sent_at - The reminder sent timestamp.
     */

    /**
     * @typedef {Object} EventAttendeeResource
     * @property {string} email
     * @property {string} email - The attendee's email.
     * @property {string} name - The attendee's name.
     * @property {string} response - The attendee's response.
     * */
    /**
     *
     * @typedef {Object} UserResource
     * @property {string} name - The user's name.
     * @property {string} email - The user's email.
     * @property {string} avatar_url - The user's avatar URL.
     * */
    /**
     *
     * @typedef {Object} StageResource
     * @property {number} id - The stage ID.
     * @property {string} name - The stage name.
     * @property {number} days_due - The stage's due days.
     * @property {boolean} recruiter_required - Whether a recruiter is required.
     * @property {boolean} interviewer_required - Whether an interviewer is required.
     * @property {boolean} interview_required - Whether an interview is required.
     * @property {boolean} skip_on_fast_track - Whether to skip on the fast track.
     * */

    /**
     * @typedef {Object} Interview
     * @property {number} id - The interview ID.
     * @property {string} name - The interview name.
     * @property {StageResource|null} stage - The interview stage (if available).
     * @property {string[]} skills - The interview skills.
     * @property {number} rating - The average rating of the interview.
     * @property {UserResource|null} interviewer - The interviewer (if available).
     * @property {ApplicationInterviewQuestionResource[]} questions - The interview questions.
     * @property {boolean} is_interview_filled - Indicates if the interview is filled or not.
     * @property {EventResource} calendarEvent - The calendar event associated with the interview.
     * @property {KaratInterviewResource} karatInterview - The Karat interview details.
     * @property {string} type - The type of interview (PROXIFY or KARAT).
     */
    interview: {
      type: Object,
      required: true,
    },
    recruiterId: {
      type: Number,
      required: false,
    },
    openRescheduleDialog: {
      type: Function,
      required: true,
    },
    openScorecardDialog: {
      type: Function,
      required: true,
    },
    platformElement: {
      required: false,
    },
  },
  setup(props) {
    const interviewers = ref([]);
    const isAvailableInterviewersLoading = ref(false);
    const menuTrigger = ref(null);
    const { interview } = toRefs(props);
    const state = reactive({
      interviewer: undefined,
      isMenuActive: false,
    });
    const { formatDateTimeString } = dateTimeUtils;
    const route = useRoute();
    const store = useStore();

    const {
      reveal,
      isRevealed: isConfirmationDialogRevealed,
      confirm: confirmConfirmationDialog,
    } = useConfirmDialog();

    const { copy, copied } = useClipboard();

    watch(
      () => copied.value,
      (copied) => {
        if (copied) {
          store.commit('ui/addSnackbarMessage', {
            title: 'Copied to clipboard',
            type: 'success',
            displayDuration: 5000,
          });
        }
      }
    );

    const currentUser = computedEager(() => store.state.auth.user);
    const hasEditAccess = computedEager(() => {
      const { id } = currentUser.value || {};
      const { interviewer, type } = interview.value || {};

      return type === 'PROXIFY' && interviewer?.id === id;
    });

    const isCardHighlighted = (interviewId) => {
      return route.params.itemId === String(interviewId);
    };

    // Interview Date

    const interviewDate = computedEager(() => {
      const { calendarEvent, karatInterview } = interview.value;
      if (!calendarEvent && !karatInterview) return null;
      if (interview.value.type === 'PROXIFY') {
        const { date, start_at, end_at, timezone } = calendarEvent;
        return {
          start: formatDateTimeString(date, start_at, timezone),
          end: formatDateTimeString(date, end_at, timezone),
        };
      } else {
        const { start_time, end_time } = karatInterview;
        return {
          start: start_time,
          end: end_time,
        };
      }
    });

    const formattedInterviewDate = computedEager(() => {
      return dateTimeUtils.formatDateWithCalendar(interviewDate.value?.start);
    });

    const isCompleted = computed(() => interviewStatus.value === 'completed');

    const isOccurring = (interviewDate) =>
      moment().isBetween(interviewDate?.start, interviewDate?.end, '[]');

    const { calendarEvent } = interview.value;
    const { timeRemaining } = useTimeRemaining(calendarEvent);
    const formattedTimeRemaining = computed(() => {
      return Math.ceil(timeRemaining?.value?.duration?.asMinutes?.() ?? 0);
    });

    // Interview Status

    const getCalendarEventStatus = (
      calendarEvent,
      interviewDate,
      published_at,
      rescheduling_reason
    ) => {
      const statusMap = [
        { condition: rescheduling_reason, status: 'rescheduled' },
        {
          condition:
            interview.value?.cancelling_reason ||
            calendarEvent?.status === 'cancelled',
          status: 'cancelled',
        },
        { condition: published_at, status: 'completed' },
        { condition: !calendarEvent, status: 'invited' },
        {
          condition: moment().isAfter(interviewDate?.end),
          status: 'completed',
        },
        { condition: isOccurring(interviewDate), status: 'occurring' },
        { condition: true, status: 'scheduled' },
      ];

      const matchingStatus = statusMap.find((item) => item.condition);

      return matchingStatus?.status;
    };

    const interviewStatus = computedEager(() => {
      const {
        calendarEvent,
        published_at,
        karatInterview,
        rescheduling_reason,
      } = interview.value;

      return (
        karatInterview?.state ??
        getCalendarEventStatus(
          calendarEvent,
          interviewDate.value,
          published_at,
          rescheduling_reason
        )
      );
    });

    const interviewStatusClass = computedEager(() => {
      const statusClasses = {
        invited: 'text-proxify-warning-500',
        scheduled: 'text-proxify-warning-500',
        occurring: 'text-proxify-success',
        completed: 'text-proxify-success',
        cancelled: 'text-ats-red',
        rescheduled: 'text-ats-red',
      };
      return statusClasses[interviewStatus.value] || '';
    });

    // Action Menu

    const shouldShowReschedule = computedEager(() => {
      return (
        interview.value.type === 'PROXIFY' &&
        [
          'occurring',
          'scheduled',
          'cancelled',
          'completed',
          'invited',
        ].includes(interviewStatus.value)
      );
    });

    const shouldShowInterviewerValueSelector = computedEager(() => {
      return (
        interview.value.calendarEvent &&
        ['occurring', 'scheduled', 'completed'].includes(interviewStatus.value)
      );
    });

    const handleOpenRescheduleDialog = () => {
      props.openRescheduleDialog({
        interview: interview.value,
        reason: null,
      });
    };
    const handleOpenRescheduleDialogNoShow = () => {
      props.openRescheduleDialog({
        interview: interview.value,
        reason: 'no-show',
      });
    };
    const handleOpenScorecardDialog = () => {
      interviewStatus.value !== 'cancelled' &&
        props.openScorecardDialog(interview.value);
    };

    const menuOptions = computedEager(() => {
      const rescheduleOption = {
        label: 'Reschedule',
        type: 'item',
        props: {
          clickable: true,
          onClick: handleOpenRescheduleDialog,
          closeOnClick: true,
          disable:
            interviewStatus.value === 'invited' ||
            !interview.value.calendarEvent,
        },
        subLabel: 'Possible after interview confirmed.',
        condition:
          interview.value.type === 'PROXIFY' &&
          !interview.value.rescheduling_reason,
      };
      const sendReminderOption = {
        label: 'Send Reminder',
        type: 'item',
      };
      const cancelInterviewOption = {
        label: 'Cancel Interview',
        type: 'item',
      };
      const markAsNoShowOption = {
        label: 'Mark as no show',
        type: 'item',
        props: {
          clickable: true,
          onClick: handleOpenRescheduleDialogNoShow,
          disable: ['invited', 'scheduled'].includes(interviewStatus.value),
          closeOnClick: true,
        },
        subLabel: 'Possible after interview starts.',
        condition:
          interview.value.type === 'PROXIFY' &&
          !interview.value.rescheduling_reason,
      };
      const interviewMode = {
        label: 'Interview Mode',
        type: 'item',
        subLabel: 'Possible for the assigned interviewer.',
        props: {
          clickable: true,
          onClick: handleOpenScorecardDialog,
          disable: !hasEditAccess.value,
          closeOnClick: true,
        },
        condition: true,
      };

      return shouldShowReschedule.value
        ? [
            rescheduleOption,
            sendReminderOption,
            cancelInterviewOption,
            markAsNoShowOption,
            interviewMode,
          ]
            .filter(({ condition }) => Boolean(condition))
            .sort((a, b) => Boolean(a.disabled) - Boolean(b.disabled))
        : [];
    });

    // Recommandation Bar

    const karatRecommendationMap = {
      DO_NOT_PURSUE: {
        color: 'text-ats-red bg-ats-red/10',
        text: 'Recommendation: Do not pursue',
      },
      INVITE_TO_NEXT_ROUND: {
        color: 'text-proxify-success bg-proxify-success/10',
        text: 'Recommendation: Move further',
      },
      REQUIRES_FURTHER_REVIEW: {
        color: 'text-proxify-warning-500 bg-proxify-warning-500/10',
        text: 'Recommendation: Requires further review',
      },
      FAST_TRACK: {
        color: 'text-proxify-success bg-proxify-success/10',
        text: 'Recommendation: Prioritize',
      },
    };

    const proxifyRecommendationMap = computed(() => {
      return [
        {
          condition: interviewStatus.value === 'cancelled',
          content: {
            color: 'text-disabled-dark bg-disabled-dark/10',
            text: `Reason: ${interview.value.cancelling_reason ?? 'n/a'}`,
          },
        },
        {
          condition: interviewStatus.value === 'rescheduled',
          content: {
            color: 'text-disabled-dark bg-disabled-dark/10',
            text: `Reason: ${interview.value.rescheduling_reason ?? 'n/a'}`,
          },
        },
        {
          condition: interviewStatus.value === 'completed',
          content: {
            color: 'text-disabled-dark bg-disabled-dark/10',
            text: 'Reminder: Scorecard is not filled',
          },
        },
      ];
    });

    const recommendationInfo = computedEager(() => {
      const { karatInterview, published_at } = interview.value;
      if (
        (published_at && !karatInterview) ||
        !['cancelled', 'completed', 'rescheduled'].includes(
          interviewStatus.value
        )
      )
        return null;
      if (!karatInterview) {
        return proxifyRecommendationMap.value.find(({ condition }) =>
          Boolean(condition)
        )?.content;
      }
      return !['cancelled', 'rescheduled'].includes(interviewStatus.value)
        ? karatRecommendationMap[karatInterview?.recommendation] || {
            color: 'text-disabled-dark bg-disabled-dark/10',
            text: 'Recommendation: Processing...',
          }
        : null;
    });

    const getAvailableInterviewers = async () => {
      isAvailableInterviewersLoading.value = true;
      const response =
        await api.applications.interviews.getAvailableInterviewers(
          interview.value?.id
        );
      interviewers.value = response.data.interviewers
        .filter((item) => item.id !== state.interviewer?.id)
        .sort((a, b) => (a.name > b.name ? 1 : -1));
      isAvailableInterviewersLoading.value = false;
    };

    const handleChangeInterviewer = async (interviewer) => {
      const response = await api.applications.interviews.changeInterviewer(
        interview.value?.id,
        {
          interviewer_id: interviewer.id,
        }
      );

      state.interviewer = interviewer;
      store.commit('applicant/setDataNeedsUpdate');

      const showSnackbarMessage = (
        title,
        body,
        type,
        displayDuration = 5000
      ) => {
        store.commit('ui/addSnackbarMessage', {
          title,
          body,
          type,
          displayDuration,
        });
      };

      const snackbarMessages = [
        {
          condition: 'response.status === 207',
          showSnackbarMessage: () => {
            showSnackbarMessage(
              `<div><span class='uppercase'>${interviewer.name}</span><span class='ml-1'>assigned to interview</span></div>`,
              '',
              'success'
            );
            showSnackbarMessage(
              'Unable to update Google Calendar invite',
              'Please update changes manually',
              'error'
            );
          },
        },
        {
          condition: "interviewStatus.value === 'completed'",
          showSnackbarMessage: () => {
            showSnackbarMessage(
              `<div><span class='uppercase'>${interviewer.name}</span><span class='ml-1'>assigned to scorecard</span></div>`,
              "<div class='mt-2'>Please note interview will not be updated in Google Calendar</div>",
              'success',
              6000
            );
          },
        },
        {
          condition: 'default',
          showSnackbarMessage: () => {
            showSnackbarMessage(
              `<div><span class='uppercase'>${interviewer.name}</span><span class='ml-1'>assigned to interview</span></div>`,
              "<div class='mt-2'>The Google Calendar invite has been updated</div>",
              'success'
            );
          },
        },
      ];
      if (response.status === 207) {
        snackbarMessages
          .find(({ condition }) => condition === 'response.status === 207')
          ?.showSnackbarMessage();
      } else if (isCompleted.value) {
        snackbarMessages
          .find(
            ({ condition }) =>
              condition === "interviewStatus.value === 'completed'"
          )
          ?.showSnackbarMessage();
      } else {
        snackbarMessages
          .find(({ condition }) => condition === 'default')
          ?.showSnackbarMessage();
      }

      interviewers.value = [];
    };

    watch(
      () => interview.value,
      (newValue) => (state.interviewer = newValue.interviewer),
      { immediate: true, deep: true }
    );

    return {
      ...toRefs(state),
      moment,
      interviewDate,
      interviewStatus,
      recommendationInfo,
      handleChangeInterviewer,
      hasEditAccess,
      menuOptions,
      menuTrigger,
      reveal,
      copy,
      isConfirmationDialogRevealed,
      confirmConfirmationDialog,
      formattedInterviewDate,
      interviewStatusClass,
      isCardHighlighted,
      interviewers,
      getAvailableInterviewers,
      isAvailableInterviewersLoading,
      shouldShowInterviewerValueSelector,
      isCompleted,
      timeRemaining,
      formattedTimeRemaining,
    };
  },
  computed: {
    Autolinker() {
      return Autolinker;
    },
  },
};
</script>
<style scoped>
.interview-card {
  @apply rounded-[12px]
  bg-white
  w-full
  border
  border-proxify-gray-200
  shadow-sm
  overflow-hidden;
}

.interview-card-header {
  @apply bg-white
  h-14
  border-b
  border-proxify-black/5
  p-4
  pb-[1.125rem]
  font-poppins
  text-lg
  flex;
}

.interview-card-content {
  @apply p-4
  flex
  flex-col
  gap-2;
}

.interview-status {
  @apply capitalize;
}

.recommendation-bar {
  @apply py-2 px-4 mr-2 rounded-full inline-flex items-center truncate h-8;
}

.scheduler-link-bar {
  @apply border border-dashed border-[#C4C4C4] w-full h-10 flex items-center justify-between px-4 rounded gap-4;
}

.interview-date {
  @apply flex
  items-center
  gap-2;
}

.interview-card-footer {
  @apply flex
  justify-between
  items-end;
}

.interview-action-button {
  @apply h-10
  rounded-lg
  inline-flex
  items-center
  px-[1.125rem]
  text-base
  cursor-pointer;
}

.interview-action-button.filled {
  @apply bg-proxify-primary
  text-white
  hover:bg-[#4BA8FD]
  active:bg-[#1C81D4]
  cursor-pointer;
}

.interview-action-button.outlined {
  @apply text-proxify-primary
  border
  border-proxify-primary
  hover:bg-[#f0f0f0]
  active:bg-[#dddddd];
}

.interview-action-button-rounded {
  @apply h-10
  rounded-full
  inline-flex
  items-center
  px-[1.125rem]
  text-base
  cursor-pointer;
}

@keyframes highlight {
  0% {
    background-color: rgb(88 194 162 / 40%);
  }

  99% {
    background-color: transparent;
  }
}

.highlighted {
  animation: highlight 3s ease-out forwards;
}

.value-selector-footer {
  @apply whitespace-normal
  mx-4
  pt-2
  pb-3
  text-proxify-black/30;
}
</style>
