<template>
  <div class="page-container flex flex-col gap-4">
    <div class="page-title">Reset password</div>
    <div class="flex flex-row">
      <div class="flex flex-col">
        <div class="flex flex-col">
          <Form
            class="flex flex-col"
            @submit="submit"
          >
            <BaseConfirmationCard
              paddingless
              :cancel-button-text="null"
              confirm-button-text="Reset"
              confirm-button-type="submit"
              :is-loading="isLoading"
            >
              <template #title>Your new password</template>
              <div class="px-6 flex flex-col gap-4">
                <div class="flex flex-col gap-[6px]">
                  <div class="text-body-sm font-medium text-proxify-gray-700">
                    Password
                  </div>
                  <BaseInputField
                    v-model="password"
                    name="Password"
                    required
                    type="password"
                    placeholder="Type in a password"
                    data-testid="password"
                  />
                </div>
                <div class="flex flex-col gap-[6px]">
                  <div class="text-body-sm font-medium text-proxify-gray-700">
                    And once more...
                  </div>
                  <BaseInputField
                    v-model="password_confirmation"
                    name="password_confirmation"
                    required
                    type="password"
                    placeholder="Type in your password"
                    data-testid="password"
                  />
                </div>
              </div>
            </BaseConfirmationCard>
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import snackbarMessagesMixin from '@/mixins/snackbarMessagesMixin';
import { BaseConfirmationCard, BaseInputField } from '@/components/Base';

export default {
  components: {
    BaseConfirmationCard,
    BaseInputField,
  },
  mixins: [snackbarMessagesMixin],
  data: () => ({
    password: '',
    password_confirmation: '',
  }),
  methods: {
    submit: function () {
      const token = this.$route.query.token;
      const email = this.$route.query.email;

      axios
        .post(`${process.env.VUE_APP_API}/reset/${token}`, {
          email,
          token,
          password: this.password,
          password_confirmation: this.password_confirmation,
        })
        .then((response) => {
          this.showSuccessMessage(response);
          setTimeout(() => {
            this.$router.push('/');
          }, 3000);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.page-container {
  @apply ml-64;
}

.page-title {
  @apply text-proxify-black ml-4 font-poppins font-bold text-heading-2;
}

.container {
  @apply mt-8 mr-8 min-h-[32rem] w-72 rounded-lg mb-[4.5rem] bg-[#f0f0f0];
}

.reset-container {
  @apply mb-0;
}

.container-title {
  @apply text-proxify-black/80 pt-6 pl-4 font-bold text-lg;
}

.card {
  @apply p-4 w-64 left-4 top-16 bg-white border-t-[4rem] border-transparent rounded shadow;
}

.border-blue {
  @apply border-t-4 border-proxify-primary;
}

.border-yellow {
  @apply border-t-4 border-proxify-warning-500;
}

.card-title {
  @apply text-proxify-black font-poppins font-bold text-lg;
}

.input-label {
  @apply text-proxify-black mt-6 relative;
}

input {
  @apply text-proxify-black mt-4 p-4 border border-ats-light-grey rounded-lg;
}

input:focus {
  @apply border border-proxify-primary;
}

input.input-error {
  @apply border border-ats-red;
}

.message {
  @apply mt-2;
}

.error {
  @apply text-ats-red;
}

::placeholder {
  @apply text-proxify-black/30;
}
</style>
