<script setup>
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { gsap } from 'gsap';
import {
  BaseConfirmationCard,
  BaseSlideOutMenu,
  BaseBadge,
  BaseButton,
  BaseButtonDropdown,
  BaseButtonToggle,
  BaseEmptyState,
} from '@/components/Base';
import { PDialog } from '@/components/ProxifyUI';
import CreateTodoForms from '@/components/Layout/Scaffold/CreateTodoForm.vue';
import TodosMenuItem from '@/components/Layout/Scaffold/TodosMenuItem.vue';

import useApplicantInfo from '@/composables/useApplicantInfo';
import { useRouteParams } from '@vueuse/router';

const store = useStore();

const todos = computed(() => store.state.todos.todos);

const { applicant, getApplicantInfo } = useApplicantInfo();
const applicationId = useRouteParams('id');

const todosCount = computed(() => store.getters['todos/expeditedTodosCount']);
const currentUser = computed(() => store.state.auth.user);
const isCreateTodoActive = computed(() => store.state.todos.isCreateTodoActive);
const selectedTodo = computed(() => store.state.todos.selectedTodo);

const isDeleteDialogActive = ref(false);
const isCompleted = ref(0);
const filterByUser = ref('assigned_to');
const sortBy = ref('due_date');
const isLoading = ref(false);
const isSaving = ref(false);
const createTodoFormsRef = ref(null);

const filterOptions = [
  { label: 'Open', value: 0 },
  { label: 'Completed', value: 1 },
];
const sortOptions = computed(() => [
  ...(isCompleted.value
    ? [{ name: 'Completion date', value: 'completed_at' }]
    : []),
  { name: 'Due date', value: 'due_date' },
  { name: 'Creation date', value: 'created_at' },
]);
const filterParameters = computed(() => ({
  is_completed: isCompleted.value ? 1 : 0,
  sort_by: sortBy.value,
  sort_direction: sortBy.value === 'due_date' ? 'asc' : 'desc',
}));
const todosWithDueDate = computed(() => {
  return todos.value.filter(
    (todo) =>
      todo[filterByUser.value]?.id === currentUser.value.id &&
      (filterByUser.value === 'assigned_to' ||
        todo.assigned_to?.id !== currentUser.value.id) &&
      (sortBy.value === 'created_at' || Boolean(todo.due_date))
  );
});
const todosWithoutDueDate = computed(() => {
  return sortBy.value === 'due_date'
    ? todos.value.filter(
        (todo) =>
          todo[filterByUser.value]?.id === currentUser.value.id &&
          (filterByUser.value === 'assigned_to' ||
            todo.assigned_to?.id !== currentUser.value.id) &&
          !todo.due_date
      )
    : [];
});

const fetchTodos = async (options = {}) => {
  const { showLoading = true, params } = options;
  try {
    isLoading.value = showLoading;
    await store.dispatch('todos/fetchTodos', params);
  } catch (error) {
    console.error('Failed to fetch todos', error);
  } finally {
    isLoading.value = false;
  }
};
const updateTodoCompletion = async ({ is_completed, todo_id }) => {
  try {
    isSaving.value = true;
    const todo = todos.value.find((todo) => todo.id === todo_id);
    todo.is_completed = is_completed;
    await store.dispatch('todos/updateTodo', {
      todo_id,
      body: { is_completed },
    });
    store.commit(
      'todos/setGlobalTodos',
      todos.value.filter((todo) => todo.id !== todo_id)
    );
    store.dispatch('todos/fetchExpeditedTodos', currentUser.value.id);
  } catch (error) {
    console.error('Failed to update todo completion', error);
  } finally {
    isSaving.value = false;
  }
};
const confirmDeleteTodos = async () => {
  await store.dispatch('todos/deleteTodo', {
    todo_id: selectedTodo.value.id,
  });
  store.commit('ui/addSnackbarMessage', {
    title: 'To-do deleted successfully',
    type: 'success',
    displayDuration: 5000,
  });
  store.dispatch('todos/fetchExpeditedTodos', currentUser.value.id);
  closeDeleteDialog();
};
const openTodoForm = (todo) => {
  store.commit('todos/setCreateTodoStatus', true);
  store.commit('todos/setSelectedTodo', todo);
};
const closeTodoForm = () => {
  store.commit('todos/setCreateTodoStatus', false);
  store.commit('todos/setSelectedTodo', null);
};
const openDeleteDialog = (todo) => {
  isDeleteDialogActive.value = true;
  store.commit('todos/setSelectedTodo', todo);
};
const closeDeleteDialog = () => {
  isDeleteDialogActive.value = false;
  store.commit('todos/setSelectedTodo', null);
};
watch(
  () => filterParameters.value,

  (newValue, oldValue) => {
    const wasCompleted = oldValue?.is_completed;
    const isNowCompleted = newValue?.is_completed;
    const newSort = newValue.sort_by;

    const shouldChangeSort =
      (isNowCompleted && !wasCompleted && newSort !== 'completed_at') ||
      (!isNowCompleted && wasCompleted && newSort === 'completed_at');

    if (shouldChangeSort) {
      sortBy.value = isNowCompleted ? 'completed_at' : 'due_date';
      return;
    }

    fetchTodos({ showLoading: true, params: newValue });
  },
  {
    deep: true,
    immediate: true,
  }
);

if (applicationId.value) {
  getApplicantInfo(applicationId.value);
}

const onBeforeEnter = (element) => {
  element.style.opacity = 0;
};

const onEnter = (element, done) => {
  gsap.to(element, {
    opacity: 1,
    delay: element.dataset.index * 0.15,
    onComplete: done,
  });
};

const onLeave = (element, done) => {
  gsap.to(element, {
    opacity: 0,
    delay: element.dataset.index * 0.15,
    onComplete: done,
  });
};
</script>
<template>
  <BaseSlideOutMenu
    icon="check-done01"
    title="To-Dos"
    :title-badge="todosCount"
    @close="store.commit('todos/setTodosMenuStatus', { active: false })"
  >
    <template #tabs>
      <q-tabs
        v-model="filterByUser"
        no-caps
        active-color="proxify-primary-700"
        indicator-color="proxify-primary-700"
        class="min-h-8 border-b border-proxify-gray-200"
        :disable="isLoading || isSaving"
      >
        <q-tab
          class="p-0 pb-2.5 min-h-8 text-body-sm font-semibold text-proxify-gray-500"
          content-class="p-0"
          :ripple="false"
          name="assigned_to"
        >
          Assigned to you
        </q-tab>
        <q-tab
          class="p-0 pb-2.5 min-h-8 text-body-sm font-semibold text-proxify-gray-500"
          content-class="p-0"
          :ripple="false"
          name="assigned_by"
        >
          Assigned by you
        </q-tab>
      </q-tabs>
    </template>
    <template #filters>
      <BaseButtonToggle
        v-model="isCompleted"
        :options="filterOptions"
        class="text-proxify-gray-700"
        :disable="isLoading || isSaving"
      />
      <BaseButtonDropdown
        v-model="sortBy"
        :options="sortOptions"
        option-value="value"
        :search="false"
        :disable="isLoading || isSaving"
        :header="{
          type: 'item',
          label: 'Sort by',
          leftSection: {
            icon: {
              name: 'switch-vertical01',
              size: '16px',
            },
          },
          props: {
            class: 'pointer-events-none',
          },
        }"
      >
        <template #activator>
          <BaseBadge
            outline
            icon="switch-vertical01"
            size="lg"
            class="cursor-pointer border-none inner-border inner-border-proxify-gray-300 rounded-full overflow-hidden !bg-white hover:bg-proxify-gray-50"
            icon-size="20px"
          >
            {{ sortOptions.find((option) => option.value === sortBy).name }}
          </BaseBadge>
        </template>
      </BaseButtonDropdown>
    </template>
    <template #footer>
      <BaseButton
        color="primary"
        class="w-full py-2.5 text-body-sm"
        icon-prepend="plus"
        icon-size="20px"
        rounded
        @click="openTodoForm()"
      >
        Create a To-Do
      </BaseButton>
    </template>
    <PDialog
      seamless
      maximized
      :model-value="isCreateTodoActive"
    >
      <BaseSlideOutMenu
        :title="selectedTodo?.id ? 'Edit To-Do' : 'Create a To-Do'"
        @close="closeTodoForm()"
      >
        <CreateTodoForms
          ref="createTodoFormsRef"
          :todo="selectedTodo"
          :applicant="applicant"
          @close-create-dialog="closeTodoForm"
        />
        <template #footer>
          <BaseButton
            color="primary"
            class="w-full py-2.5 text-body-sm"
            rounded
            @click="createTodoFormsRef.save()"
          >
            {{ selectedTodo?.id ? 'Save' : 'Create' }}
          </BaseButton>
        </template>
      </BaseSlideOutMenu>
    </PDialog>
    <PDialog
      v-model="isDeleteDialogActive"
      position="standard"
      persistent
    >
      <BaseConfirmationCard
        cancel-button-text="Cancel"
        confirm-button-text="Delete"
        :on-close="closeDeleteDialog"
        :on-confirm="confirmDeleteTodos"
        class="select-none"
      >
        <template #title>Delete to-do item</template>
        <div class="text-body-sm font-medium text-proxify-gray-600">
          Are you sure you want to delete this to-do item? This action can not
          be undone.
        </div>
      </BaseConfirmationCard>
    </PDialog>
    <div
      v-if="isLoading"
      class="flex flex-col gap-2"
    >
      <q-item
        v-for="n in 10"
        :key="n"
        style="max-width: 300px"
      >
        <q-item-section side>
          <q-skeleton type="QCheckbox" />
        </q-item-section>

        <q-item-section>
          <q-item-label>
            <q-skeleton type="text" />
          </q-item-label>
          <q-item-label caption>
            <q-skeleton
              type="text"
              width="65%"
            />
          </q-item-label>
        </q-item-section>
      </q-item>
    </div>
    <div
      v-else-if="!todosWithDueDate.length && !todosWithoutDueDate.length"
      class="flex items-center justify-center py-7"
    >
      <BaseEmptyState
        title="No to-dos"
        description="When you have a to-do, it will appear here"
        icon="check-done01"
        body-class="max-w-40 flex justify-center text-center"
      />
    </div>
    <TransitionGroup
      :css="false"
      @before-enter="onBeforeEnter"
      @enter="onEnter"
      @leave="onLeave"
    >
      <TodosMenuItem
        v-for="(todo, index) in todosWithDueDate"
        v-show="!isLoading"
        :key="todo.id"
        :todo="todo"
        :show-assignee="
          filterByUser === 'assigned_by' &&
          todo.assigned_to?.id !== currentUser.id
        "
        :show-assigner="
          filterByUser === 'assigned_to' &&
          todo.assigned_by &&
          todo.assigned_by?.id !== currentUser.id
        "
        :show-border="index !== todosWithDueDate.length - 1"
        @toggle-todo="updateTodoCompletion"
        @open-edit-dialog="openTodoForm"
        @open-delete-dialog="openDeleteDialog"
      ></TodosMenuItem>
    </TransitionGroup>
    <div
      v-if="todosWithoutDueDate.length > 0 && !isLoading"
      class="text-body-md font-medium text-proxify-gray-700 px-6 pt-4 border-t border-proxify-gray-200 select-none"
    >
      No due date
    </div>
    <TransitionGroup
      :css="false"
      @before-enter="onBeforeEnter"
      @enter="onEnter"
      @leave="onLeave"
    >
      <TodosMenuItem
        v-for="(todo, index) in todosWithoutDueDate"
        v-show="!isLoading"
        :key="todo.id"
        :todo="todo"
        :show-assignee="
          filterByUser === 'assigned_by' &&
          todo.assigned_to?.id !== currentUser.id
        "
        :show-assigner="
          filterByUser === 'assigned_to' &&
          todo.assigned_by &&
          todo.assigned_by?.id !== currentUser.id
        "
        :show-border="index !== todosWithDueDate.length - 1"
        @toggle-todo="updateTodoCompletion"
        @open-edit-dialog="openTodoForm"
        @open-delete-dialog="openDeleteDialog"
      ></TodosMenuItem>
    </TransitionGroup>
  </BaseSlideOutMenu>
</template>
