const state = {
  requestCount: 0,
  currentRoute: [],
  pagination: null,
};

const getters = {
  isLoading: (state) => state.requestCount > 0,
};

const actions = {};

const mutations = {
  incrementRequestCount(state) {
    state.requestCount++;
  },
  decrementRequestCount(state) {
    state.requestCount--;
  },
  setCurrentRoute(state, route) {
    state.currentRoute = route;
  },
  setPagination(state, pagination) {
    state.pagination = pagination;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
