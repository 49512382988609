<script setup>
const props = defineProps({
  avatar: { type: Boolean, default: false },
  thumbnail: { type: Boolean, default: false },
  side: { type: Boolean, default: false },
  top: { type: Boolean, default: false },
  noWrap: { type: Boolean, default: false },
});
</script>
<template>
  <q-item-section v-bind="props">
    <slot />
  </q-item-section>
</template>

<style lang="scss" scoped>
.q-item__section--side {
  padding-right: 8px;
  color: currentColor;
}
.q-item__section--main {
  ~ .q-item__section--side {
    padding: 0px;
  }
}
</style>
