export default {
  getCurrencySymbol: (code) => {
    const currency = {
      ALL: '4c, 65, 6b',
      AFN: '60b',
      ARS: '24',
      AWG: '192',
      AUD: '24',
      AZN: '20bc',
      BSD: '24',
      BBD: '24',
      BYN: '42, 72',
      BZD: '42, 5a, 24',
      BMD: '24',
      BOB: '24, 62',
      BAM: '4b, 4d',
      BWP: '50',
      BGN: '43b, 432',
      BRL: '52, 24',
      BND: '24',
      KHR: '17db',
      CAD: '24',
      KYD: '24',
      CLP: '24',
      CNY: 'a5',
      COP: '24',
      CRC: '20a1',
      HRK: '6b, 6e',
      CUP: '20b1',
      CZK: '4b, 10d',
      DKK: '6b, 72',
      DOP: '52, 44, 24',
      XCD: '24',
      EGP: 'a3',
      SVC: '24',
      EUR: '20ac',
      FKP: 'a3',
      FJD: '24',
      GHS: 'a2',
      GIP: 'a3',
      GTQ: '51',
      GGP: 'a3',
      GYD: '24',
      HNL: '4c',
      HKD: '24',
      HUF: '46, 74',
      ISK: '6b, 72',
      INR: '',
      IDR: '52, 70',
      IRR: 'fdfc',
      IMP: 'a3',
      ILS: '20aa',
      JMD: '4a, 24',
      JPY: 'a5',
      JEP: 'a3',
      KZT: '43b, 432',
      KPW: '20a9',
      KRW: '20a9',
      KGS: '43b, 432',
      LAK: '20ad',
      LBP: 'a3',
      LRD: '24',
      MKD: '434, 435, 43d',
      MYR: '52, 4d',
      MUR: '20a8',
      MXN: '24',
      MNT: '20ae',
      MZN: '4d, 54',
      NAD: '24',
      NPR: '20a8',
      ANG: '192',
      NZD: '24',
      NIO: '43, 24',
      NGN: '20a6',
      NOK: '6b, 72',
      OMR: 'fdfc',
      PKR: '20a8',
      PAB: '42, 2f, 2e',
      PYG: '47, 73',
      PEN: '53, 2f, 2e',
      PHP: '20b1',
      PLN: '7a, 142',
      QAR: 'fdfc',
      RON: '6c, 65, 69',
      RUB: '20bd',
      SHP: 'a3',
      SAR: 'fdfc',
      RSD: '414, 438, 43d, 2e',
      SCR: '20a8',
      SGD: '24',
      SBD: '24',
      SOS: '53',
      ZAR: '52',
      LKR: '20a8',
      SEK: '6b, 72',
      CHF: '43, 48, 46',
      SRD: '24',
      SYP: 'a3',
      TWD: '4e, 54, 24',
      THB: 'e3f',
      TTD: '54, 54, 24',
      TRY: '',
      TVD: '24',
      UAH: '20b4',
      GBP: 'a3',
      USD: '24',
      UYU: '24, 55',
      UZS: '43b, 432',
      VEF: '42, 73',
      VND: '20ab',
      YER: 'fdfc',
      ZWD: '5a, 24',
    };
    const symbols = currency[code]?.split(', ');
    return (
      symbols
        ?.map((char) => String.fromCharCode(parseInt(char, 16)))
        .join('') ?? ''
    );
  },
};
