<script setup>
import { BaseIcon, BaseBadge, BaseApplicantBio } from '@/components/Base';
import { useStore } from 'vuex';
import { computed, toRefs } from 'vue';
import { useCurrentElement } from '@vueuse/core';

const props = defineProps({
  item: {
    type: Object,
    required: true,
    default() {
      return {};
    },
  },
  searchTerm: {
    type: String,
    required: false,
    default() {
      return '';
    },
  },
});

const store = useStore();
const stages = computed(() => store.state.applicant.stages);

const { searchTerm } = toRefs(props);
const element = useCurrentElement();

const isOverlapping = computed(() => {
  return element.value?.offsetWidth < element.value?.scrollWidth;
});

/**
 * Escapes a string to be used in a regular expression
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#escaping
 * @param {string} string - The string to escape
 * @returns {string}
 */
const escapeRegExp = (string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
};

const highlightText = (text) => {
  if (!searchTerm.value) return text;
  const escapedSearchTerm = escapeRegExp(searchTerm.value);
  const regex = new RegExp(escapedSearchTerm, 'ig');
  return text?.replace?.(
    regex,
    (match) => `<span class='highlight'>${match}</span>`
  );
};

const getStageName = (stageId) => {
  const stage = stages.value.find(({ id }) => id === stageId);
  return (stage?.name ?? '').replace('Technical', 'Tech');
};

const getSkillsString = (mainSkills) => {
  return mainSkills.map(({ skill }) => skill.name).join(', ');
};
</script>

<template>
  <router-link
    :key="item.id"
    class="result-container group"
    :to="`/applications/${item.id}`"
    target="_blank"
  >
    <BaseApplicantBio
      :avatar-url="item.avatar"
      avatar-size="sm"
      avatar-icon-size="20px"
      :name="item.full_name"
      :fast-tracked="item.fast_tracked"
      name-class="text-body-sm font-semibold text-proxify-gray-700 truncate w-full"
      supporting-text-class="text-body-sm font-normal text-proxify-gray-600 truncate w-full"
      text-wrapper-class="flex flex-col items-start overflow-hidden"
      fast-tracked-badge-class="!w-4 !h-4 !left-5 !top-[22px]"
      class="pl-2.5"
      dense
    >
      <template #supporting-text>
        <div class="flex flex-col gap-1">
          <div class="flex gap-1 text-proxify-gray-600 font-normal">
            <span
              v-if="item.main_skills?.length > 0"
              class="max-w-[250px] truncate"
            >
              {{ getSkillsString(item.main_skills) }}
            </span>
            <span
              v-if="item.main_skills?.length > 0"
              class="px-px"
            >
              ·
            </span>
            <span
              v-safe-html="highlightText(item.email)"
              class="max-w-[250px] truncate"
            ></span>
          </div>
          <BaseBadge
            v-if="item.duplicates_count > 0"
            color="transparent"
            icon="copy06"
            class="!p-0 !text-body-sm !font-normal !text-proxify-gray-500 !h-[21px]"
            dense
          >
            This main application has
            {{ item.duplicates_count }} duplicate{{
              item.duplicates_count > 1 ? 's' : ''
            }}
          </BaseBadge>
        </div>
      </template>
      <template #name>
        <div class="flex gap-2">
          <span
            v-safe-html="highlightText(item.full_name)"
            class="truncate"
            :class="{
              'max-w-[250px]': !isOverlapping,
              'max-w-[200px]': isOverlapping,
            }"
          ></span>
          <BaseBadge
            v-if="item.is_assigned"
            color="primary"
            size="xs"
            class="!px-2"
          >
            Assigned to you
          </BaseBadge>
        </div>
      </template>
    </BaseApplicantBio>
    <BaseBadge
      class="!rounded text-body-sm !bg-proxify-gray-200 border border-proxify-gray-100 !h-[21px] !px-2 !py-0"
    >
      <BaseIcon
        name="dot"
        size="6px"
        :color="item?.is_rejected ? 'proxify-error-500' : 'proxify-success-500'"
      />
      <span class="text-proxify-gray-500 font-light">
        <span v-if="item?.is_rejected">Rejected at</span>
        {{ getStageName(item.stage_id) }}
      </span>
    </BaseBadge>
  </router-link>
</template>

<style scoped>
.result-container {
  @apply text-proxify-black
  hover:bg-proxify-gray-50
  cursor-pointer
  flex
  justify-between
  rounded-lg
  py-2
  pr-2;
}

.result-container:last-of-type {
  @apply rounded-b-[12px] pb-2;
}

:deep(.highlight) {
  @apply bg-[#F9ECBF];

  box-shadow: -2px 0 2px 0 #f9ecbf;
}
</style>
