<script setup>
import { BaseIcon } from '@/components/Base';
import { computed } from 'vue';

const props = defineProps({
  message: {
    type: Object,
    required: true,
  },
});

const colorVariants = {
  error: {
    'bg-25': 'bg-proxify-error-25',
    'bg-50': 'bg-proxify-error-50',
    'bg-100': 'bg-proxify-error-100',
    'text-600': 'text-proxify-error-600',
  },
  success: {
    'bg-25': 'bg-proxify-success-25',
    'bg-50': 'bg-proxify-success-50',
    'bg-100': 'bg-proxify-success-100',
    'text-600': 'text-proxify-success-600',
  },
};

const icon = computed(() => {
  if (props.message.icon) {
    return props.message.icon;
  } else if (props.message.type === 'success') {
    return 'check-verified01';
  } else if (props.message.type === 'error') {
    return 'alert-triangle';
  }
  return '';
});
</script>
<template>
  <div
    class="app-snackbar-wrapper"
    :class="colorVariants[message.type]['bg-25']"
    data-testid="snackbar"
  >
    <div
      class="app-snackbar-content"
      data-testid="snackbar-content"
    >
      <div class="flex gap-x-4">
        <div
          class="flex justify-center items-center rounded-full w-10 h-10 p-[5px]"
          :class="colorVariants[message.type]['bg-50']"
        >
          <div
            class="flex justify-center items-center rounded-full p-[5px]"
            :class="colorVariants[message.type]['bg-100']"
          >
            <BaseIcon
              :name="icon"
              size="20px"
              rounded
              :class="colorVariants[message.type]['text-600']"
            />
          </div>
        </div>
        <div class="text-body-sm grid grid-col gap-y-1 content-center">
          <div
            v-safe-html="message?.title"
            class="flex font-semibold"
            :class="{ 'pt-1.5': message?.body }"
          ></div>
          <div
            v-safe-html="message?.body"
            class="font-normal text-proxify-gray-600"
          ></div>
        </div>
      </div>
      <div :class="{ 'flex items-center': !message?.body }">
        <BaseIcon
          name="x-close"
          size="20px"
          rounded
          class="cursor-pointer text-proxify-gray-500"
          @click="$store.commit('ui/removeSnackbarMessage', message?.id)"
        />
      </div>
    </div>
  </div>
</template>
<style scoped>
.app-snackbar-wrapper {
  @apply p-4
  mb-2
  flex
  items-center
  border
  border-proxify-gray-100
  rounded-xl
  shadow-lg
  pointer-events-auto;
}
.app-snackbar-content {
  @apply flex
  justify-between
  w-full
  overflow-hidden
  text-proxify-gray;
}
</style>
