import axios from 'axios';

export default {
    getAll() {
        return axios.get('/users');
    },
    recruiters() {
        return axios.get('/users/recruiters?active=1');
    },
    sourcers() {
        return axios.get('/users/sourcers?active=1');
    },
    techInterviewers() {
        return axios.get('/users/tech-interviewers?active=1');
    },
};
