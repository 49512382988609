import { datadogRum } from '@datadog/browser-rum';

export default () => {
  if (process.env.VUE_APP_ENVIRONMENT !== 'production') return;
  datadogRum.init({
    applicationId: process.env.VUE_APP_DD_APPLICATION_ID,
    clientToken: process.env.VUE_APP_DD_CLIENT_TOKEN,
    site: 'datadoghq.eu',
    service: 'proxify-ats-frontend',
    env: process.env.VUE_APP_ENVIRONMENT,
    version: process.env.VUE_APP_VERSION,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'allow',
  });

  const user = localStorage.getItem('user');
  if (user) {
    datadogRum.setUser(JSON.parse(user));
  }

  datadogRum.startSessionReplayRecording();
};
