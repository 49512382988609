import Echo from 'laravel-echo';

export default () => {
  window.Pusher = require('pusher-js');

  const token = localStorage.getItem('token');

  window.Echo = new Echo({
    broadcaster: 'pusher',
    key: `${process.env.VUE_APP_MIX_PUSHER_KEY}`,
    wsHost: `${process.env.VUE_APP_SOCKET_DOMAIN}`,
    wsPort: `${process.env.VUE_APP_SOCKET_PORT}`,
    wssPort: `${process.env.VUE_APP_SOCKET_PORT}`,
    forceTLS: process.env.VUE_APP_MIX_PUSHER_APP_FORCE_TLS,
    encrypted: process.env.VUE_APP_MIX_PUSHER_ENCRYPTED,
    enabledTransports: ['ws', 'wss'],
    disableStats: true,
    auth: {
      headers: {
        authorization: `Bearer ${  token}`,
        accept: 'application/json',
      },
    },
    authEndpoint: `${process.env.VUE_APP_API}/broadcasting/auth`,
  });
};
