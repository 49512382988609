import DOMPurify from 'dompurify';

export default (app) => {
  app.directive('safe-html', {
    beforeMount(element, binding) {
      element.innerHTML = DOMPurify.sanitize(binding.value);
    },
    updated(element, binding) {
      element.innerHTML = DOMPurify.sanitize(binding.value);
    },
  });

  app.directive('safe-href', {
    beforeMount(element, binding) {
      element.setAttribute('href', DOMPurify.sanitize(binding.value));
      element.setAttribute('rel', 'noopener');
    },
    updated(element, binding) {
      element.setAttribute('href', DOMPurify.sanitize(binding.value));
      element.setAttribute('rel', 'noopener');
    },
  });
};
