<template>
  <BaseBadge
    :label="title"
    size="sm"
    :icon="icon"
    icon-class="-mr-1 text-proxify-gray-500"
    badge-class="bg-proxify-gray-100 text-proxify-gray-700"
  ></BaseBadge>
</template>

<script setup>
import BaseBadge from '@/components/Base/BaseBadge.vue';
import { computed, toRefs } from 'vue';
import { useStore } from 'vuex';

const props = defineProps({
  referrerEmail: {
    type: String,
    required: false,
  },
  source: {
    type: String,
    required: true,
  },
  trafficSource: {
    type: Object,
    default() {
      return {};
    },
  },
});
const { source } = toRefs(props);

const store = useStore();
const sources = computed(() => store.state.application.sources);
const icon = computed(() => {
  return source.value === 'careers'
    ? 'globe011'
    : source.value === 'sourced'
    ? 'search-lg'
    : 'users01';
});
const title = computed(
  () => sources.value.find((item) => item.value === source.value)?.name
);
</script>
