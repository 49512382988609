import api from '@/api';

const state = {
  notifications: [],
};

const getters = {
  unreadCount: (state) => {
    return state.notifications.filter((notification) => {
      return !notification.read_at;
    }).length;
  },
};

const actions = {
  fetchNotifications({ commit }) {
    return new Promise((resolve, reject) => {
      api.notifications
        .get()
        .then(({ data }) => {
          commit('setNotifications', data.notifications);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  subscribeNotifications({ rootState, commit }) {
    const { id } = rootState.auth.user;
    window.Echo.options.auth.headers.authorization = `Bearer ${rootState.auth.token}`;
    window.Echo.private(`notifications.${id}`).notification((notification) => {
      const { type, id, ...data } = notification;
      commit('addNotification', {
        created_at: new Date().toISOString(),
        type,
        id,
        data,
      });
    });
  },
  unsubscribeNotifications({ rootState }) {
    const { id } = rootState.auth.user;
    window.Echo.leave(`notifications.${id}`);
    window.Echo.options.auth.headers.authorization = null;
  },
};

const mutations = {
  setNotifications(state, notifications) {
    state.notifications = notifications;
  },
  addNotification(state, notification) {
    state.notifications = [notification, ...state.notifications];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
