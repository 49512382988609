<template>
  <div class="flex flex-col gap-y-3 font-inter">
    <div class="text-body-sm font-medium text-proxify-gray-600">
      Final grade
    </div>
    <div class="text-body-lg font-semibold text-proxify-gray-900">
      <span v-if="grade?.grade">{{ grade.grade * 100 }}%</span>
      <AppLoader v-else-if="isLoading" />
      <span v-else>-</span>
    </div>
    <BaseTooltip
      v-if="
        Boolean(grade?.calculation_impossibility_reasons?.length || list.length)
      "
      tooltip-class="border border-proxify-gray-200 gap-[12px]"
      max-width="216px"
    >
      <div
        v-if="!grade?.calculation_impossibility_reasons?.length && list.length"
      >
        <div class="score-popup-title">Final grade details</div>
        <TextData
          v-for="item in list"
          :key="item.name"
          :value="item.value"
          :title="item.name"
        />
      </div>
      <div
        v-else
        class="w-full"
      >
        <div class="score-popup-title">
          {{ impossibilityContent.title }}
        </div>
        <div class="score-popup-text">
          {{ impossibilityContent.content }}
        </div>
      </div>
    </BaseTooltip>
  </div>
</template>

<script setup>
import BaseTooltip from '@/components/Base/BaseTooltip.vue';
import TextData from '@/components/Elements/Scaffold/TextData.vue';
import { computed, toRefs } from 'vue';

const props = defineProps({
  grade: {
    type: Object,
    required: false,
  },
  isLoading: {
    type: Boolean,
    required: false,
  },
});

const { grade } = toRefs(props);
const impossibilityContent = computed(() => {
  const formatReasons = {
    codility: 'Codility test',
    tech_interview: 'Technical interview scorecard',
    voice_sense: 'Voice Sense recording',
    speech_ace: 'Speech Ace recording',
  };
  if (grade.value) {
    if (grade.value.calculation_impossibility_reasons?.length > 1) {
      return {
        title: 'Missing information',
        content: `We can’t calculate the final grade because "${grade.value.calculation_impossibility_reasons
          .map((item) => formatReasons[item])
          .join(', ')}" are missing.`,
      };
    }
    return {
      title: `${
        formatReasons[grade.value.calculation_impossibility_reasons[0]]
      } is missing`,
      content: `We can’t calculate the final grade because ${
        formatReasons[grade.value.calculation_impossibility_reasons[0]]
      } is missing.`,
    };
  }
  return {};
});
const list = computed(() =>
  (grade.value
    ? [
        {
          value:
            grade.value.english && `${(grade.value.english * 100).toFixed()}%`,
          name: 'English',
        },
        {
          value:
            grade.value.professionalism &&
            `${(grade.value.professionalism * 100).toFixed()}%`,
          name: 'Professionalism',
        },
        {
          value: grade.value.total && `${(grade.value.total * 100).toFixed()}%`,
          name: 'Total experience',
        },
        {
          value: grade.value.key && `${(grade.value.key * 100).toFixed()}%`,
          name: 'Key experience',
        },
        {
          value:
            grade.value.codility_score &&
            `top ${((1 - grade.value.codility_score) * 100).toFixed()}%`,
          name: 'Codility (perc)',
        },
        {
          value:
            grade.value.avg_case &&
            `${(grade.value.avg_case * 100).toFixed()}%`,
          name: 'Technical interview',
        },
      ]
    : []
  ).filter(({ value }) => Boolean(value))
);
</script>

<style scoped>
.score-popup-title {
  @apply text-proxify-gray-700
  text-body-sm
  font-semibold;
}

.score-popup-text {
  @apply pt-4
  text-proxify-gray-700
  text-body-xs
  font-normal;
}
</style>
