<script setup>
import { computed, ref } from 'vue';
import { PAvatar } from '@/components/ProxifyUI';
import { BaseBadge, BaseIcon } from '@/components/Base';
import { vElementHover } from '@vueuse/components';

const props = defineProps({
  dense: {
    type: Boolean,
    required: false,
    default: false,
  },
  avatarUrl: {
    type: String,
    required: false,
    default() {
      return '';
    },
  },
  avatarSize: {
    type: String,
    required: false,
    default() {
      return 'md';
    },
  },
  avatarIconSize: {
    type: String,
    required: false,
    default: undefined,
  },
  avatarBackgroundColor: {
    type: String,
    default() {
      return '#f9fafb';
    },
  },
  name: {
    type: String,
    required: false,
    default() {
      return '';
    },
  },
  nameClass: {
    type: String,
    required: false,
    default() {
      return '';
    },
  },
  showSupportingText: {
    type: Boolean,
    required: false,
    default() {
      return true;
    },
  },
  supportingText: {
    type: String,
    required: false,
    default() {
      return '';
    },
  },
  supportingTextClass: {
    type: String,
    required: false,
    default() {
      return '';
    },
  },
  isLoading: {
    type: Boolean,
    required: false,
    default() {
      return false;
    },
  },
  textWrapperClass: {
    type: String,
    required: false,
    default() {
      return '';
    },
  },
  enableAvatarLetters: {
    type: Boolean,
    required: false,
    default() {
      return false;
    },
  },
  underlineWhenHovered: {
    type: Boolean,
    required: false,
    default() {
      return false;
    },
  },
  fastTracked: {
    type: Boolean,
    required: false,
    default() {
      return false;
    },
  },
  fastTrackedBadgeClass: {
    type: String,
    required: false,
    default() {
      return '';
    },
  },
});

const forcePlaceholder = ref(false);
const isApplicationHovered = ref(false);

const avatarLetters = computed(() => {
  if (!props.enableAvatarLetters || !props.name) return '';
  return props.name
    .match(/(^\S\S?|\s\S)?/g)
    .map((v) => v.trim())
    .join('')
    .match(/(^\S|\S$)?/g)
    .join('')
    .toLocaleUpperCase();
});
</script>

<template>
  <div
    v-element-hover="(state) => (isApplicationHovered = state)"
    :class="{
      'flex gap-3 items-center': dense,
      'flex gap-5': !dense,
    }"
  >
    <PAvatar
      :size="avatarSize"
      class="avatar"
    >
      <img
        v-if="avatarUrl && !forcePlaceholder"
        :src="avatarUrl"
        :alt="name"
        data-testid="avatar-img"
        @error="forcePlaceholder = true"
      />
      <span
        v-else-if="enableAvatarLetters"
        class="text-body-md font-normal"
        data-testid="avatar-letters"
      >
        {{ avatarLetters }}
      </span>
      <BaseIcon
        v-else
        name="user01"
        :size="avatarIconSize"
        data-testid="avatar-baseicon"
      />
      <div
        v-if="fastTracked"
        class="fast-tracked-badge"
        :class="fastTrackedBadgeClass"
        data-testid="fast-tracked-badge"
      >
        F
      </div>
    </PAvatar>
    <AppLoader
      v-if="isLoading"
      size="0.5rem"
    />
    <div
      v-else
      class="flex flex-col justify-center items-start gap-[4px]"
      :class="textWrapperClass"
    >
      <div
        class="text-body-md font-inter font-semibold text-proxify-gray-900"
        :class="{
          [nameClass]: true,
          underline: underlineWhenHovered && isApplicationHovered,
        }"
        data-testid="name"
      >
        <slot name="name">
          {{ name }}
        </slot>
      </div>
      <Transition>
        <slot
          v-if="showSupportingText"
          name="supporting-text"
        >
          <BaseBadge
            :label="supportingText"
            class="!m-0 !p-0 !h-[21px] !rounded-none font-normal text-proxify-gray-600 text-body-md"
            color="transparent"
            :class="supportingTextClass"
            data-testid="supporting-text"
          >
            {{ supportingText }}
          </BaseBadge>
        </slot>
      </Transition>
    </div>
  </div>
</template>

<style scoped>
.avatar {
  background-color: v-bind(avatarBackgroundColor);
  transition: all 0.3s ease;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.2s;
}

.v-enter,
.v-leave-to {
  opacity: 0;
}

.fast-tracked-badge {
  @apply absolute
  w-[22px]
  h-[22px]
  top-[24px]
  left-[24px]
  flex
  justify-center
  items-center
  rounded-full
  border-[1.5px]
  border-white
  text-[10px]
  leading-[18px]
  font-bold
  text-proxify-primary-600
  bg-proxify-primary-100;
}
</style>
