<script setup>
import { ref } from 'vue';
import { PDate } from '@/components/ProxifyUI';
import { BaseButton } from '@/components/Base';
import moment from 'moment';
import { useVModel } from '@vueuse/core';
const element = ref(null);
const currentMonth = ref();

const props = defineProps({
  modelValue: {
    type: [Array, String, Object],
    required: false,
  },
  range: {
    type: Boolean,
    required: false,
  },
  disablePastDates: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:modelValue', 'range-end']);

const model = useVModel(props, 'modelValue', emit);

const initializeCurrentMonth = () => {
  if (props.range) {
    return moment(model.value?.from).format('MMMM YYYY');
  }
  return moment(model.value).format('MMMM YYYY');
};
currentMonth.value = initializeCurrentMonth();
</script>
<template>
  <PDate
    ref="element"
    v-model="model"
    minimal
    :locale="{ daysShort: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sat'] }"
    :range="range"
    first-day-of-week="1"
    :disable-past-dates="disablePastDates"
    @navigation="
      (val) =>
        (currentMonth = moment()
          .month(val.month - 1)
          .year(val.year)
          .format('MMMM YYYY'))
    "
    @range-end="emit('range-end')"
  >
    <div class="flex items-center justify-between w-full">
      <BaseButton
        icon-prepend="chevron-left"
        icon-size="20px"
        class="p-2"
        @click.stop="element.offsetCalendar('month', true)"
      />
      <div class="text-body-md font-semibold">{{ currentMonth }}</div>
      <BaseButton
        icon-prepend="chevron-right"
        icon-size="20px"
        class="p-2"
        @click.stop="element.offsetCalendar('month')"
      />
    </div>
  </PDate>
</template>
