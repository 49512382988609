import script from "./BaseInputDropdown.vue?vue&type=script&setup=true&lang=js"
export * from "./BaseInputDropdown.vue?vue&type=script&setup=true&lang=js"

import "./BaseInputDropdown.vue?vue&type=style&index=0&id=64148552&lang=scss"

const __exports__ = script;

export default __exports__
import QField from 'quasar/src/components/field/QField.js';
import QVirtualScroll from 'quasar/src/components/virtual-scroll/QVirtualScroll.js';
import QItem from 'quasar/src/components/item/QItem.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QField,QVirtualScroll,QItem});
