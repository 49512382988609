<template>
  <div :data-name="name">
    <Field
      v-slot="{ field, errors }"
      v-model="data"
      :name="fieldName"
      :label="fieldName"
      :rules="{
        required,
        email: type === 'email',
        url: type === 'url',
        numeric: type === 'number',
        ...rules,
      }"
      validate-on-model-update
      :validate-on-mount="validateOnMount"
    >
      <p
        v-if="!hideLabel && (modelValue || !readOnly)"
        class="font-semibold mb-2"
      >
        <span>
          {{ fieldName }}
        </span>
        <span
          v-if="(required || showRequiredLabel) && !readOnly"
          class="text-disabled-normal font-normal text-xs"
        >
          *required
        </span>
      </p>
      <div
        v-if="readOnly"
        class="font-normal"
      >
        {{ modelValue }}
      </div>
      <div
        v-else
        class="text-field"
        :class="{
          '!border-none': borderless,
          '!border-ats-red': errors?.length,
          'rounded-lg': rounded,
          'overflow-hidden': rounded,
          'focus-within:border-proxify-primary': !disableFocusStyling,
          dense,
          ...inputClass,
        }"
      >
        <div class="font-semibold">
          {{ prefix }}
        </div>
        <IconBase
          v-if="hasLeadingIcon"
          class="opacity-50 ml-2"
          height="1.375"
          width="1.375"
        >
          <IconSearch />
        </IconBase>
        <input
          v-bind="field"
          ref="inputRef"
          class="w-full h-full"
          :class="{
            '!pl-2': prefix,
            paddingless,
          }"
          :data-testid="fieldName.toLowerCase().replaceAll(' ', '-') + '-input'"
          :placeholder="placeholder"
          :type="type"
          :disabled="disabled"
          :min="type === 'number' ? 0 : null"
          autocomplete="off"
          :maxlength="maxLength"
        />
        <span
          v-if="maxLength"
          class="mr-2 text-[#c4c4c4] text-2xs font-semibold"
        >
          {{ modelValue.length }}/{{ maxLength }}
        </span>
        <IconBase
          v-if="hasTrailingIcon && !disabled"
          class="text-proxify-black cursor-pointer mr-2 p-1"
          height="1.4"
          width="1.4"
          @click="trailingIconAction"
        >
          <IconCross />
        </IconBase>
      </div>
      <ErrorMessage
        v-if="!hideErrorMessage"
        v-slot="{ message }"
        :name="fieldName"
      >
        <div class="text-ats-red text-sm mt-1 px-4">
          {{ message }}
        </div>
      </ErrorMessage>
    </Field>
  </div>
</template>

<script>
import { useFocus, useVModel } from '@vueuse/core';
import IconBase from '@/components/Icons/IconBase';
import IconCross from '@/components/Icons/IconCross';
import IconSearch from '@/components/Icons/IconSearch';
import { nextTick, onMounted, ref } from 'vue';

export default {
  name: 'AppFormTextField',
  components: {
    IconBase,
    IconCross,
    IconSearch,
  },
  props: {
    focus: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    disableFocusStyling: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    maxLength: {
      type: [String, Number],
      required: false,
      default() {
        return '';
      },
    },
    validateOnMount: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    dense: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    type: {
      type: String,
      required: false,
      default() {
        return 'text';
      },
    },
    label: {
      type: String,
      required: false,
      default() {
        return '';
      },
    },
    name: {
      type: String,
      required: false,
      default() {
        return '';
      },
    },
    modelValue: {
      type: [String, Number],
      required: false,
      default() {
        return '';
      },
    },
    placeholder: {
      type: String,
      required: false,
      default() {
        return '';
      },
    },
    refName: {
      type: String,
      required: false,
      default() {
        return '';
      },
    },
    rules: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
    valueClass: {
      type: [Object, String],
      required: false,
    },
    hideLabel: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    hideErrorMessage: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    rounded: {
      type: Boolean,
      required: false,
      default() {
        return true;
      },
    },
    borderless: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    paddingless: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    prefix: {
      type: String,
      required: false,
      default() {
        return '';
      },
    },
    italic: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    hasTrailingIcon: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    hasLeadingIcon: {
      type: [Boolean, Number],
      required: false,
      default() {
        return false;
      },
    },
    trailingIconAction: {
      type: Function,
      required: false,
      default() {
        this.data = '';
      },
    },
    inputClass: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
    required: {
      type: Boolean,
      required: false,
    },
    showRequiredLabel: {
      type: Boolean,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    readOnly: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const data = useVModel(props, 'modelValue', emit, {
      eventName: 'update:modelValue',
    });
    const inputReference = ref(null);
    const { focused } = useFocus(inputReference);

    const handleFocus = async () => {
      await nextTick();
      focused.value = true;
    };
    onMounted(async () => {
      if (props.focus) {
        await handleFocus();
      }
    });
    return { data, inputRef: inputReference, handleFocus };
  },
  computed: {
    fieldName() {
      return this.name || this.label;
    },
  },
};
</script>

<style>
.text-field {
  @apply w-full h-12 border border-ats-light-grey flex items-center;
}

.text-field.dense {
  @apply h-8;
}

.text-field input {
  @apply w-full font-normal;
}

.text-field input:not(.paddingless) {
  @apply px-4;
}

input:active,
input:focus,
input:focus-visible {
  @apply border-0 outline-0;
}

.text-field:has(input:active),
.text-field:has(input:focus) {
  outline: none;
}

.text-field input::placeholder {
  @apply text-proxify-black/50 font-normal;
}

.text-field:has(input:disabled) {
  @apply cursor-not-allowed;
}

.text-field:has(input:disabled:active) {
  @apply border-disabled-light;
}
</style>
