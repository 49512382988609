<template>
  <BaseConfirmationCard
    :on-confirm="onSubmit"
    :on-close="closeRescheduleDialog"
    :cancel-button-text="null"
    :is-loading="isLoading"
    paddingless
    width="576px"
  >
    <template #title>
      {{ isNoShowFlow ? 'Mark as no show' : 'Reschedule interview' }}
    </template>
    <div
      v-if="isNoShowFlow"
      class="flex flex-col gap-4 px-6"
    >
      <div
        class="text-proxify-gray-600 text-body-sm font-medium"
        data-testid="reschedule-card-description"
      >
        This interview will be cancelled and marked as no show.
      </div>

      <BaseToggle
        v-model="isReschedulingEnabled"
        class="pb-6"
      >
        <div class="text-body-sm">
          <div class="font-medium text-proxify-gray-700">
            Reschedule interview
          </div>
        </div>
      </BaseToggle>
    </div>
    <div
      v-if="isReschedulingEnabled"
      class="flex flex-col gap-4 px-6"
    >
      <div
        v-if="!isNoShowFlow"
        class="font-medium text-body-sm font-proxify-gray-700 flex flex-col gap-1.5"
      >
        <span>Rescheduling Reason</span>
        <BaseInputDropdown
          v-model="rescheduleReason"
          name="Rescheduling Reason"
          :options="rescheduleReasons"
          map-options
          option-value="value"
          emit-value
          type="search"
          required
          data-testid="rescheduling-reason-selector"
        />
      </div>
      <div
        class="font-medium text-body-sm font-proxify-gray-700 flex flex-col gap-1.5"
      >
        <span>Interviewer</span>
        <BaseInputDropdown
          v-model="selectedInterviewer"
          name="Interviewer"
          :options="
            interviewName === 'Screening interview'
              ? recruitersList
              : interviewersList
          "
          option-label="prefix"
          option-sublabel="name"
          map-options
          type="search"
          required
        />
      </div>

      <BaseToggle v-model="sendEmail">
        <div class="text-body-sm">
          <div class="font-medium text-proxify-gray-700">
            Send e-mail to the applicant
          </div>
          <div class="font-normal text-proxify-gray-600">
            You will see this e-mail under e-mails tab.
          </div>
        </div>
      </BaseToggle>
      <template v-if="sendEmail">
        <BaseLabelScheduler v-model="sendAction" />
        <div v-if="templateToSend">
          <div class="field-title">
            Edit text before sending
            <BaseButton
              class="icon-tertiary-gray border-none p-2.5 trailing-button"
              icon-prepend="eye"
              @click="showPreview = true"
            />
          </div>
          <TemplateEditor
            ref="templateEditor"
            v-model="templateToSend"
            :on-update-h-t-m-l="onUpdateHTMLOutput"
            :set-edited="setTemplateEdited"
          />
        </div>
      </template>
    </div>
  </BaseConfirmationCard>

  <!--Template-->
  <PDialog
    v-model="showPreview"
    position="standard"
  >
    <EmailPreviewCard
      :template="templateToSend?.template"
      :on-close="() => (showPreview = false)"
    />
  </PDialog>
</template>

<script>
import { ref, computed } from 'vue';
import EmailPreviewCard from '@/components/Elements/Scaffold/EmailPreviewCard';
import { interviewUtils, stringUtils } from '@/utils';
import TemplateEditor from '@/components/Elements/Scaffold/TemplateEditor';
import api from '@/api';
import { useStore } from 'vuex';
import { useForm } from 'vee-validate';
import { PDialog } from '@/components/ProxifyUI';
import {
  BaseConfirmationCard,
  BaseInputDropdown,
  BaseToggle,
  BaseButton,
  BaseLabelScheduler,
} from '@/components/Base';
import moment from 'moment';

export default {
  name: 'ApplicantActivityInterviewRescheduleCard',
  components: {
    BaseLabelScheduler,
    BaseConfirmationCard,
    EmailPreviewCard,
    TemplateEditor,
    BaseInputDropdown,
    BaseToggle,
    BaseButton,
    PDialog,
  },
  props: {
    closeRescheduleDialog: {
      type: Function,
      required: true,
    },
    interviewers: {
      type: Object,
      required: true,
    },
    recruiters: {
      type: Object,
      required: true,
      default() {
        return {};
      },
    },
    interviewId: {
      type: [Number, String],
      required: true,
    },
    interviewName: {
      type: String,
      required: true,
    },
    initialInterviewer: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
    onReschedule: {
      type: Function,
      required: true,
    },
    isNoShowFlow: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
  },
  setup(props) {
    const rescheduleCard = ref(null);
    const emailPreviewCard = ref(null);
    const store = useStore();

    const isReschedulingEnabled = ref(!props.isNoShowFlow);
    const showPreview = ref(false);
    const sendEmail = ref(true);
    const sendNow = ref(true);
    const isLoading = ref(false);
    const isTemplateEdited = ref(false);
    const htmlBody = ref('');
    const templateToSend = ref(null);
    const rescheduleReason = ref('');
    const selectedInterviewer = ref(props.initialInterviewer);
    const sendAction = ref(undefined);

    const schema = computed(() => {
      return {
        'Rescheduling Reason': {
          required: isReschedulingEnabled.value && !props.isNoShowFlow,
        },
        Interviewer: {
          required: isReschedulingEnabled.value,
        },
      };
    });

    const { handleSubmit } = useForm({
      validationSchema: schema,
    });

    const rescheduleReasons = [
      'Rare Profile - No Show',
      'Applicant requested',
      'Interviewer not available',
    ].map((item) => ({
      value: item,
      name: item,
    }));

    const rescheduleData = computed(() => {
      const conditionalData = {
        noShow: {
          reschedule: isReschedulingEnabled.value,
        },
        default: {
          reason: rescheduleReason.value,
        },
      };

      return {
        ...conditionalData[props.isNoShowFlow ? 'noShow' : 'default'],
        interviewer_id:
          selectedInterviewer.value.type !== 'group'
            ? selectedInterviewer.value.id
            : undefined,
        interview_group_id:
          selectedInterviewer.value.type === 'group'
            ? selectedInterviewer.value.id
            : undefined,
        send_email: sendEmail.value,
        subject: templateToSend.value.subject,
        body: isTemplateEdited.value ? htmlBody.value : undefined,
        send_at: sendAction.value?.value,
      };
    });

    const interviewersList = computed(() => {
      return interviewUtils.generateGroupList({
        groupsData: props.interviewers?.groups,
        itemsData: props.interviewers?.interviewers,
      });
    });

    const getEmailTemplate = async () => {
      const { data } =
        await api.applications.interviews.getRescheduleEmailTemplate(
          props.interviewId
        );
      const { subject, body, markdown, template } = data.email;
      templateToSend.value = {
        fields: [],
        name: 'Reschedule the meeting',
        markdown: stringUtils.convertToDelta(markdown),
        subject: subject ?? null,
        body: body ?? null,
        template: template ?? null,
      };
    };
    getEmailTemplate();

    const onUpdateHTMLOutput = (value) => {
      htmlBody.value = value;
    };

    const setTemplateEdited = (value) => {
      isTemplateEdited.value = value;
    };

    const recruitersList = computed(() => {
      return interviewUtils.generateGroupList({
        groupsData: props.recruiters?.groups,
        itemsData: props.recruiters?.recruiters,
      });
    });

    const currentInterviewerLabel = computed(() => {
      return selectedInterviewer.value?.type === 'group'
        ? selectedInterviewer.value.value
        : selectedInterviewer.value?.name;
    });

    const handleChangeInterviewer = (value) => {
      selectedInterviewer.value = value;
    };

    const handleChangeRescheduleReason = (reason) => {
      rescheduleReason.value = reason;
    };

    const rescheduleInterview = async () => {
      try {
        isLoading.value = true;
        const conditionalRoute = {
          noShow: api.applications.interviews.markAsNoShow,
          default: api.applications.interviews.rescheduleInterview,
        };
        await conditionalRoute[props.isNoShowFlow ? 'noShow' : 'default'](
          props.interviewId,
          rescheduleData.value
        );
        props.onReschedule();
        const conditionalSnackbarMessage = [
          {
            condition: !props.isNoShowFlow && !rescheduleData.value.send_email,
            message: 'Rescheduling link created successfully.',
          },
          {
            condition: !props.isNoShowFlow && rescheduleData.value.send_email,
            message: 'Reschedule e-mail sent successfully.',
          },
          {
            condition: props.isNoShowFlow && !rescheduleData.value.reschedule,
            message: 'Interview cancelled and marked as no show.',
          },
          {
            condition:
              props.isNoShowFlow &&
              rescheduleData.value.reschedule &&
              !rescheduleData.value.send_email,
            message: 'Interview marked as no-show and rescheduled succesfully.',
          },
          {
            condition:
              props.isNoShowFlow &&
              rescheduleData.value.reschedule &&
              rescheduleData.value.send_email,
            message: 'Interview marked as no-show and reschedule email sent.',
          },
        ];
        store.commit('ui/addSnackbarMessage', {
          title: conditionalSnackbarMessage.find(({ condition }) =>
            Boolean(condition)
          ).message,
          type: 'success',
          displayDuration: 5000,
        });
        if (!rescheduleData.value.send_email) {
          store.commit('ui/addSnackbarMessage', {
            title:
              'You can create an email with a rescheduling e-mail template under the emails tab.',
            type: 'success',
            displayDuration: 5000,
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
        props.closeRescheduleDialog();
      }
    };
    const handleInvalidSubmit = ({ errors }) => {
      const invalidQuestions = Object.keys(errors);
      const firstInvalidQuestionElement = document.querySelector(
        `[data-name='${invalidQuestions[0]}']`
      );
      if (firstInvalidQuestionElement) {
        firstInvalidQuestionElement.scrollIntoView();
      }
    };

    const onSubmit = handleSubmit(rescheduleInterview, handleInvalidSubmit);

    return {
      currentInterviewerLabel,
      emailPreviewCard,
      handleChangeInterviewer,
      handleChangeRescheduleReason,
      htmlBody,
      interviewersList,
      isLoading,
      isTemplateEdited,
      onUpdateHTMLOutput,
      recruitersList,
      isReschedulingEnabled,
      rescheduleCard,
      rescheduleInterview,
      rescheduleReason,
      rescheduleReasons,
      selectedInterviewer,
      sendEmail,
      sendNow,
      setTemplateEdited,
      showPreview,
      templateToSend,
      onSubmit,
      sendAction,
      moment,
    };
  },
};
</script>
