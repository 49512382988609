<script setup>
import { computed } from 'vue';
import { dateTimeUtils, ratingUtils } from '@/utils';

const props = defineProps({
  value: {
    type: Number,
    required: true,
  },
  rates: {
    type: Array,
    required: true,
  },
  unit: {
    type: String,
    default: '€',
  },
  description: {
    type: String,
    default: '',
  },
});

const bars = computed(() => {
  const { rates, unit } = props;

  const colorMap = {
    months: ['red', 'gray', 'green'],
    default: ['gray', 'green', 'red'],
  };

  return rates.slice(0, -1).map((start, index) => {
    const end = rates[index + 1];
    const width = (end - start) / (rates[rates.length - 1] - rates[0]);
    const color = colorMap[unit]
      ? colorMap[unit][index]
      : colorMap.default[index];

    return {
      start: unit === 'months' ? `${Math.round(start / 12)}y` : unit + start,
      end: unit === 'months' ? `${Math.round(end / 12)}y` : unit + end,
      width,
      color,
    };
  });
});

const left = computed(() => {
  const min = Math.min(...props.rates);
  const max = Math.max(...props.rates);
  const { value } = props;

  if (value <= min) {
    return 0;
  }

  if (value >= max) {
    return 100;
  }

  return ((value - min) / (max - min)) * 100;
});

const verdict = computed(() => {
  return ratingUtils.calculateVerdict(props.value, props.rates);
});

const indicatorText = computed(() => {
  if (props.unit === 'months') {
    return dateTimeUtils.convertMonthsToYearsAndMonths(props.value);
  }

  return props.unit + props.value;
});

const indicatorColor = computed(() => {
  const { unit } = props;
  const colorMap = {
    months: {
      low: 'red',
      typical: 'gray',
      high: 'green',
    },
    default: {
      low: 'gray',
      typical: 'green',
      high: 'red',
    },
  };

  return colorMap[unit]
    ? colorMap[unit][verdict.value]
    : colorMap.default[verdict.value];
});
</script>

<template>
  <p
    v-if="description"
    class="chart-description"
  >
    {{ description.replace('{verdict}', verdict) }}
  </p>
  <div class="bar-chart">
    <div
      class="indicator"
      :class="indicatorColor"
      :style="{ left: left + '%' }"
    >
      {{ indicatorText }}
    </div>
    <div class="bars">
      <div
        v-for="(bar, index) in bars"
        :key="index"
        class="bar"
        :class="bar.color"
        :style="{ flexGrow: bar.width }"
      >
        <span
          v-if="index === 0"
          :class="{ min: index === 0 }"
        >
          {{ bar.start }}
        </span>
        <span :class="{ max: index === bars.length - 1 }">{{ bar.end }}</span>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.chart-description {
  @apply text-body-xs font-semibold mb-3;
  &:first-letter {
    @apply capitalize;
  }
}

.bar-chart {
  @apply relative mx-[24px] pt-[40px];
}

.indicator {
  @apply absolute font-semibold text-body-xs -translate-x-1/2 top-0 py-[8px] px-[4px] rounded-[8px] whitespace-nowrap;

  &:after {
    @apply block absolute top-full left-1/2 -ml-[6px] border-[6px] border-solid border-transparent;
    content: '';
  }

  &.gray {
    @apply bg-proxify-stone-gray text-disabled-dark;
    &:after {
      @apply border-t-proxify-stone-gray;
    }
  }

  &.red {
    @apply bg-proxify-error-100 text-ats-red;
    &:after {
      @apply border-t-proxify-error-100;
    }
  }

  &.green {
    @apply bg-proxify-green-100 text-proxify-success-500;
    &:after {
      @apply border-t-proxify-green-100;
    }
  }
}

.bars {
  @apply flex gap-[1px] h-[30px];
}

.bar {
  @apply relative font-normal text-body-sm text-proxify-gray-700;

  span {
    @apply block absolute right-0 bottom-0 transform translate-x-1/2 text-body-xs;

    &.min,
    &.max {
      @apply text-proxify-gray-400 w-[24px] -top-[6px] bottom-auto transform-none;
    }

    &.min {
      @apply text-right -left-[26px];
    }

    &.max {
      @apply text-left -right-[26px];
    }
  }

  &:before {
    @apply block absolute left-0 top-0 w-full h-[8px] rounded-full;
    content: '';
  }

  &.red:before {
    @apply bg-ats-red;
  }

  &.gray:before {
    @apply bg-proxify-gray-300;
  }

  &.green:before {
    @apply bg-proxify-success-500;
  }
}
</style>
