<template>
  <span
    class="toggle-wrapper"
    role="checkbox"
    tabindex="0"
    @click="$emit('update:modelValue', !modelValue)"
  >
    <span class="toggle-background" />
    <span
      class="toggle-indicator"
      :class="modelValue ? 'bg-proxify-primary' : 'bg-white'"
      :style="indicatorStyles"
    />
  </span>
</template>

<script>
export default {
  name: 'AppSwitch',
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    indicatorStyles() {
      return {
        transform: this.modelValue ? 'translateX(0.65rem)' : 'translateX(0)',
      };
    },
  },
};
</script>

<style scoped>
.toggle-wrapper {
  @apply relative
  inline-block
  cursor-pointer
  h-[1.5rem]
  w-[2.25rem]
  rounded-full
  focus:outline-none;
}

.toggle-background {
  @apply inline-block
  rounded-full
  h-full
  w-full
  shadow-inner
  bg-[#f0f0f0];

  transition: background-color 0.4s ease;
}

.toggle-indicator {
  @apply absolute
  h-[1rem]
  w-[1rem]
  left-[0.25rem]
  bottom-[0.25rem]
  rounded-full
  shadow-md;

  transition: transform 0.4s ease;
}
</style>
