import { computed, h, reactive, ref, toRefs } from 'vue';
import { useComputedPositions } from '../useComputedPositions';

export const UseComputedPositions = {
  name: 'UseComputedPositions',
  props: {
    as: {
      type: String,
      required: false,
      default() {
        return 'div';
      },
    },
    menuPlacement: {
      type: String,
      required: false,
      default() {
        return 'right-start';
      },
    },
    floatingElement: {
      required: true,
    },
    targetElement: {
      required: false,
    },
    platformElement: {
      required: false,
    },
    positioningStrategy: {
      type: String,
      required: false,
    },
    menuOffsetX: {
      type: Number,
      required: false,
    },
    menuOffsetY: {
      type: Number,
      required: false,
    },
    menuPlacementSpacing: {
      type: Number,
      required: false,
    },
    updateTargetRectOnScroll: {
      type: Boolean,
      required: false,
    },
  },
  setup(properties, { slots }) {
    const {
      as,
      menuPlacement,
      floatingElement,
      targetElement,
      platformElement,
      positioningStrategy,
      menuOffsetX,
      menuOffsetY,
      menuPlacementSpacing,
      updateTargetRectOnScroll,
    } = toRefs(properties);
    const root = ref(null);
    const data = reactive(
      useComputedPositions(
        computed(() =>
          targetElement.value ? targetElement.value : root.value
        ),
        floatingElement,
        menuPlacement,
        {
          platformElement,
          offsetX: menuOffsetX.value,
          offsetY: menuOffsetY.value,
          spacingValue: menuPlacementSpacing.value,
          positioningStrategy: positioningStrategy.value,
          updateTargetRectOnScroll: updateTargetRectOnScroll.value,
        }
      )
    );

    return () => {
      if (slots.default) return h(as.value, { ref: root }, slots.default(data));
    };
  },
};
