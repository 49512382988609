<template>
  <input
    class="app-card-number-input"
    type="number"
    min="0"
    :value="value"
    :tabindex="tabIndex"
    placeholder="0"
    @input="$emit('update:value', $event.target.valueAsNumber)"
  />
</template>

<script>
export default {
  name: 'AppCardNumberInput',
  props: {
    tabIndex: {
      type: Number,
      default: 1,
    },
    value: {
      type: Number,
      required: false,
    },
  },
};
</script>

<style scoped>
.app-card-number-input {
  @apply h-12 w-1/4 px-4 border border-ats-light-grey rounded;
}
</style>
