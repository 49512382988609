<template>
  <div
    ref="containerRef"
    class="flex flex-row justify-between w-full relative text-proxify-gray font-inter text-body-sm font-medium"
    :class="justifyContent"
  >
    <div
      v-show="title"
      ref="titleRef"
      class=""
    >
      {{ title }}
    </div>
    <div class="flex flex-nowrap overflow-hidden">
      <div
        ref="valueRef"
        class="value-class"
        :class="valueClass"
        @click.prevent="copy(value)"
        @mouseenter="setTooltip"
        @mouseleave="isTooltipActive = false"
      >
        <span :data-testid="`${title.toLowerCase().replaceAll(' ', '-')}-data`">
          {{ value }}
        </span>
      </div>
      <BaseIcon
        class="ml-2 mt-0.5 cursor-pointer"
        name="copy03"
        @click.prevent="copy(value)"
      />
    </div>
    <span
      v-if="isTooltipActive"
      class="tooltiptext"
    >
      {{ value }}
    </span>
    <span
      v-if="isCopyTooltipActive"
      class="copy-email-tooltiptext"
    >
      Email copied to clipboard!
    </span>
  </div>
</template>

<script>
import { BaseIcon } from '@/components/Base';
import { computed, ref, watch } from 'vue';
import { useClipboard } from '@vueuse/core';

export default {
  name: 'EmailData',
  components: {
    BaseIcon,
  },
  props: {
    title: {
      type: String,
      required: false,
    },
    value: {
      type: String,
      required: true,
    },
    valueClass: {
      type: [Object, String],
      required: false,
      default(props) {
        return { 'text-right': !!props.title };
      },
    },
    justifyContent: {
      type: String,
      required: false,
      default() {
        return 'justify-between';
      },
    },
  },
  setup() {
    const valueReference = ref(null);

    const isTooltipActive = ref(false);
    const isCopyTooltipActive = ref(false);

    const { copy, copied } = useClipboard();

    const isEllipsisActive = (element) => {
      return element.offsetWidth < element.scrollWidth;
    };

    const showTooltip = computed(() => {
      if (!valueReference.value) return false;
      return isEllipsisActive(valueReference.value);
    });

    watch(
      () => copied.value,
      (isCopied) => {
        if (isCopied) {
          isTooltipActive.value = false;
          isCopyTooltipActive.value = true;
          setTimeout(() => {
            isCopyTooltipActive.value = false;
          }, 3000);
        }
      }
    );

    const setTooltip = () => {
      if (isCopyTooltipActive.value) return;
      isTooltipActive.value = showTooltip.value;
    };

    return {
      valueRef: valueReference,
      showTooltip,
      setTooltip,
      isCopyTooltipActive,
      isTooltipActive,
      copy,
    };
  },
};
</script>

<style scoped>
.value-class {
  @apply text-right truncate pl-1 cursor-pointer;
}

.value-class::after {
  @apply block content-none;
}

.tooltiptext {
  @apply py-2 px-3 -mt-10 right-0 text-proxify-gray whitespace-nowrap bg-white rounded absolute shadow-sm z-20;

  transition:
    visibility 0.2s linear,
    opacity 0.2s linear;
}

.copy-email-tooltiptext {
  @apply py-3 px-4 -mt-14 right-0 text-proxify-gray text-body-sm whitespace-nowrap bg-white rounded absolute visible shadow-sm;

  transition:
    visibility 0.2s linear,
    opacity 0.2s linear;
}
</style>
