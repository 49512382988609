<template>
  <div
    v-element-hover="onHoverCard"
    class="notification-item"
    @click="onClickCard"
  >
    <NotificationCardHeader
      :title="title"
      :is-card-hovered="isCardHovered"
      :is-read="Boolean(notification.read_at)"
      :mark-as-read="markAsRead"
    />

    <div class="text-sm font-medium">
      <template
        v-for="(word, index) in body"
        :key="index"
      >
        <component
          :is="getComponent(word)"
          v-if="isVariable(word)"
          v-bind="getAvatarProps(word)"
          show-name
          class="mx-1 align-middle"
        ></component>
        <template v-else>
          <span
            class="mx-1 align-middle text-body-sm text-proxify-gray-400 font-normal"
            :class="isStyledText(word) ? bodyStyle : ''"
          >
            {{ word.replace(/^\[|\]$/g, '') }}
          </span>
        </template>
      </template>
    </div>
    <div
      v-if="highlightedContent"
      class="notification-highlighted-body"
    >
      {{ highlightedContent }}
    </div>
    <div class="notification-timestamp">
      {{ formattedDate }}
    </div>
  </div>
</template>

<script>
import { computed, ref, toRefs } from 'vue';
import AppAvatar from '@/components/App/AppAvatar.vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { vElementHover } from '@vueuse/components';
import moment from 'moment/moment';
import NotificationCardHeader from '@/components/Layout/Scaffold/NotificationCardHeader.vue';
const recommendationMap = {
  DO_NOT_PURSUE: {
    color: 'text-ats-red bg-ats-red/10',
    text: 'Recommendation: Do not pursue',
  },
  INVITE_TO_NEXT_ROUND: {
    color: 'text-proxify-success bg-proxify-success/10',
    text: 'Recommendation: Move further',
  },
  REQUIRES_FURTHER_REVIEW: {
    color: 'text-proxify-warning-500 bg-proxify-warning-500/10',
    text: 'Recommendation: Requires further review',
  },
  FAST_TRACK: {
    color: 'text-proxify-success bg-proxify-success/10',
    text: 'Recommendation: Prioritize',
  },
};
export default {
  name: 'NotificationCard',
  components: {
    NotificationCardHeader,
  },
  directives: {
    elementHover: vElementHover,
  },
  props: {
    markAsRead: {
      type: Function,
      required: true,
    },
    notification: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const currentUser = computed(() => store.state.auth.user);
    const { notification } = toRefs(props);
    const isCardHovered = ref(false);
    const formattedDate = computed(() =>
      moment(notification.value.created_at).format('dddd, HH:mm')
    );
    const redirectObject = computed(() => {
      const { data } = notification.value;
      const routesByCategory = {
        Applications: {
          name: 'Applicant',
          params: {
            id: data?.application_id,
            tab: 'history',
            itemId: null,
          },
        },
        Tests: {
          name: 'Applicant',
          params: {
            id: data?.application_id,
            tab: 'tests',
            itemId: null,
          },
        },
        Notes: {
          name: 'Applicant',
          params: {
            id: data?.application_id,
            tab: 'notes',
            itemId: data?.note?.id,
          },
        },
        Todos: {
          name: 'Applicant',
          params: {
            id: data?.application_id,
            tab: 'todos',
            itemId: data?.todo?.id,
          },
        },
        Interviews: {
          name: 'Applicant',
          params: {
            id: data?.application_id,
            tab: 'interviews',
            itemId: data?.interview?.id,
          },
        },
      };
      return routesByCategory[notification.value?.data?.category];
    });
    const contentsByCategory = computed(() => {
      const categoryMap = {
        Notes: {
          highlightedContent: notification.value?.data?.note?.body?.replace(
            /<[^>]*>/g,
            ''
          ),
        },
        Todos: {
          highlightedContent: null,
          bodyStyle: '!text-proxify-black',
        },
        Interviews: {
          highlightedContent:
            recommendationMap[
              notification.value?.data?.interview?.karatInterview
                ?.recommendation
            ]?.text,
        },
        Tests: {
          highlightedContent: null,
          bodyStyle: '!text-proxify-black',
        },
      };
      return categoryMap[notification.value?.data?.category];
    });

    const contentsByType = computed(() => {
      const notificationMap = {
        MentionedInANote: {
          title: 'You were tagged',
          body: [
            '{user}',
            'mentioned',
            '{you}',
            'in',
            '{candidate}',
            "'s profile",
          ],
        },
        TodoIsPastDueDate: {
          title: `Oops! ${
            notification.value?.data?.todo?.assigned_to?.id ===
            currentUser.value?.id
              ? 'Your'
              : "Your teammate's"
          } to-do is overdue`,
          body: [
            ...(notification.value?.data?.todo?.assigned_to?.id ===
            currentUser.value?.id
              ? ['Todo']
              : ['{user}', "'s todo"]),
            `[${notification.value?.data?.todo?.body}]`,
            ...(notification.value?.data?.application_id !== null
              ? ['in', '{candidate}', "'s profile"]
              : []),
          ],
        },
        TodoIsAssigned: {
          title: 'To-do assigned to you',
          body: [
            '{assignedBy}',
            'assigned',
            '{user}',
            `[${notification.value?.data?.todo?.body}]`,
            ...(notification.value?.data?.application_id !== null
              ? ['in', '{candidate}', "'s profile"]
              : []),
          ],
        },
        TodoIsCompleted: {
          title: 'To-do completed',
          body: [
            '{completedBy}',
            'completed the todo',
            `[${notification.value?.data?.todo?.body}]`,
            ...(notification.value?.data?.application_id !== null
              ? ['in', '{candidate}', "'s profile"]
              : []),
          ],
        },
        TodoIsExpiringToday: {
          title: `${
            notification.value?.data?.todo?.assigned_to?.id ===
            currentUser.value?.id
              ? 'Your'
              : "Your teammate's"
          } to-do expires today`,
          body: [
            ...(notification.value?.data?.todo?.assigned_to?.id ===
            currentUser.value?.id
              ? ['Todo']
              : ['{user}', "'s todo"]),
            `[${notification.value?.data?.todo?.body}]`,
            ...(notification.value?.data?.application_id !== null
              ? ['in', '{candidate}', "'s profile"]
              : []),
          ],
        },
        TodoIsUnassigned: {
          title: 'To-do reassigned to teammate',
          body: [
            'Todo',
            `[${notification.value?.data?.todo?.body}]`,
            ...(notification.value?.data?.application_id !== null
              ? ['in', '{candidate}', "'s profile"]
              : []),
            'was reassigned to',
            '{user}',
            'by',
            '{assignedBy}',
          ],
        },
        KaratInterviewProcessed: {
          title: 'Karat: Interview Processed',
          body: [
            'Technical Interview results are processed for',
            '{candidate}',
          ],
        },
        UnresponsiveApplicationRejected: {
          title: 'Applicant got rejected',
          body: [
            '{candidate}',
            'didn’t book a an interview after 2 reminders and got rejected.',
          ],
        },
        StageChanged: {
          title: 'Stage changed',
          body: [
            '{candidate}',
            'has been moved from',
            `${notification.value?.data?.stage_from}`,
            'to',
            `${notification.value?.data?.stage_to}`,
          ],
        },
        CodilityTestSent: {
          title: 'Codility: Candidate Invited',
          body: [
            '{candidate}',
            'has been invited to Codility test',
            `[${notification.value?.data?.test_name}]`,
            'by',
            `[${notification.value?.data?.invited_by}]`,
          ],
        },
        ApplicationRejected: {
          title: 'Application rejected',
          body: ['{candidate}', 'has been rejected.'],
        },
      };
      return notificationMap[notification.value?.data?.event_type] ?? {};
    });
    const isVariable = (word) => {
      return word.startsWith('{') && word.endsWith('}');
    };
    const isStyledText = (word) => {
      return word.startsWith('[') && word.endsWith(']');
    };
    const getComponent = (word) => {
      if (isVariable(word)) {
        return AppAvatar;
      }
      return 'span';
    };
    const getAvatarprops = (word) => {
      const propsByCategory = {
        Notes: {
          user: notification.value?.data?.note?.user,
          candidate: { name: notification.value?.data?.full_name },
        },
        Todos: {
          user: notification.value?.data?.todo?.assigned_to,
          assignedBy: notification.value?.data?.todo?.assigned_by,
          candidate: {
            name: notification?.value?.data?.full_name,
          },
          completedBy: notification.value?.data?.todo?.completed_by,
        },
        Interviews: {
          candidate: { name: notification.value?.data?.full_name },
        },
        Applications: {
          candidate: { name: notification.value?.data?.full_name },
        },
        Tests: {
          candidate: { name: notification.value?.data?.full_name },
        },
      };
      const currentCategory = notification.value?.data?.category;
      const propsMap = {
        '{you}': currentUser.value,
        '{user}': propsByCategory[currentCategory]?.user,
        '{candidate}': propsByCategory[currentCategory]?.candidate,
        '{assignedBy}': propsByCategory[currentCategory]?.assignedBy,
        '{completedBy}': propsByCategory[currentCategory]?.completedBy,
      };
      return propsMap[word] || {};
    };
    const onClickCard = async () => {
      await router.push(redirectObject.value);
      if (!notification.value.read_at) {
        await props.markAsRead();
      }
    };

    const onHoverCard = (state) => {
      isCardHovered.value = state;
    };

    return {
      ...contentsByType.value,
      ...contentsByCategory.value,
      onClickCard,
      onHoverCard,
      isVariable,
      isStyledText,
      isCardHovered,
      formattedDate,
      getComponent,
      getAvatarProps: getAvatarprops,
    };
  },
};
</script>

<style scoped>
.notification-item {
  @apply border border-proxify-gray-200 rounded-lg p-4 flex flex-col gap-4 cursor-pointer hover:bg-[#f0f0f0] transition-colors duration-300;
}

.notification-highlighted-body {
  @apply px-2 py-1 text-xs font-medium bg-proxify-gray-200 text-disabled-dark rounded truncate w-fit max-w-full;
}

.notification-timestamp {
  @apply text-disabled-normal text-xs font-medium;
}
</style>
