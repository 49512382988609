import settings from '@/constants/settings';
import { useStorage } from '@vueuse/core';
import * as changeCase from 'change-case';

const state = useStorage('settings', settings);

const getters = {
  applicantCardSettings: (state) =>
    (({ showOnCards, showOnStages }) => ({
      showOnCards,
      showOnStages,
    }))(state),
  stagesSettings: (state) =>
    (({ showStages }) => ({
      showStages,
    }))(state),
};

// Helper function to check if a user role should be shown for a stage
const shouldUserRoleBeShown = (userRole, stageRole) => {
  return userRole.name === stageRole.name && !stageRole.stage_hidden;
};

// Helper function to determine if a stage should be shown
const isStageShown = (authUserRoles, stageRoles) => {
  return authUserRoles?.some(userRole =>
    stageRoles?.some(stageRole => shouldUserRoleBeShown(userRole, stageRole))
  );
}

const actions = {
  setStagesDefaultShowHideStatus({ rootState, commit }, stages) {
    for (const stage of stages) {
      const fieldName = changeCase.camelCase(stage.name);
      const isShown = isStageShown(rootState.auth.user?.roles, stage?.roles);
      commit('setStageStatus', {
        fieldName,
        isShown,
      });
    }
  },
};

const mutations = {
  setKanbanStatus(state, value) {
    state.kanban = value ?? !state.kanban;
  },
  setSidebarStatus(state) {
    state.isSidebarActive = !state.isSidebarActive;
  },
  setNotificationBarStatus(state, value) {
    state.isTodosMenuActive = false;
    state.isNotificationBarActive = value ?? !state.isNotificationBarActive;
  },
  setSettingsItemStatus(state, { parentName, fieldName }) {
    state[parentName].items[fieldName].value =
      !state[parentName].items[fieldName].value;
  },
  setStageStatus(state, { fieldName, isShown }) {
    const fieldValue = state['showStages']?.items?.[fieldName]?.value;
    if (fieldValue !== null && fieldValue !== undefined) return;
    state.showStages = {
      ...state.showStages,
      items: {
        ...(state.showStages?.items ?? {}),
        [fieldName]: {
          name: changeCase.capitalCase(fieldName),
          value: isShown,
        },
      },
      title: 'Show/hide stages',
    };
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
