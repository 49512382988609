<script setup>
import { ref } from 'vue';
import { PDialog } from '@/components/ProxifyUI';
import { BaseConfirmationCard } from '@/components/Base';

defineProps({
  onClose: {
    type: Function,
    required: true,
  },
  onConfirm: {
    type: Function,
    required: true,
  },
  disabled: {
    type: Boolean,
    required: false,
    default() {
      return false;
    },
  },
  cancelButtonText: {
    type: String,
    required: false,
    default() {
      return 'Cancel';
    },
  },
  confirmButtonText: {
    type: String,
    required: false,
    default() {
      return 'Confirm';
    },
  },
  cancelButtonState: {
    type: String,
    required: false,
    default() {
      return 'danger';
    },
  },
  confirmButtonState: {
    type: String,
    required: false,
    default() {
      return 'primary';
    },
  },
});

const isDialogOpen = ref(false);
</script>

<template>
  <PDialog
    v-model="isDialogOpen"
    position="standard"
    persistent
  >
    <BaseConfirmationCard
      width="100%"
      :cancel-button-text="cancelButtonText"
      :confirm-button-text="confirmButtonText"
      :confirm-button-state="confirmButtonState"
      :cancel-button-state="cancelButtonState"
      :on-close="onClose"
      :on-confirm="onConfirm"
      :disabled="disabled"
    >
      <template #title>
        <slot name="title"></slot>
      </template>
      <slot></slot>
    </BaseConfirmationCard>
  </PDialog>
</template>
