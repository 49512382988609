<script setup>
import { BaseButton, BaseIcon } from '@/components/Base/index';
import { useField } from 'vee-validate';
import { ref, toRefs, watch } from 'vue';

const props = defineProps({
  accept: {
    type: String,
    default: 'application/pdf',
  },
  title: {
    type: String,
    default: 'Attach File',
  },
  chip: {
    type: Boolean,
    required: false,
    default() {
      return false;
    },
  },
  uploadProgress: {
    type: Number,
    required: false,
    default() {
      return 100;
    },
  },
  value: {
    type: [File, Object],
    required: false,
    default() {
      return null;
    },
  },
  name: {
    type: String,
    required: true,
  },
  required: {
    type: Boolean,
    required: false,
    default() {
      return false;
    },
  },
  showRequiredLabel: {
    type: Boolean,
    required: false,
  },
});

const emit = defineEmits(['handle-files-changed']);

const { required, name } = toRefs(props);
const fileInput = ref(null);
const {
  value: file,
  handleChange,
  handleBlur,
  errorMessage,
} = useField(
  name.value,
  { required: required.value },
  { initialValue: props.value }
);

watch(
  () => file.value,
  (file) => {
    emit('handle-files-changed', file);
  },
  {
    immediate: true,
    deep: true,
  }
);
</script>

<template>
  <div class="app-card-text-data">
    <div
      class="font-medium text-body-sm font-proxify-gray-700 flex flex-col gap-1.5"
    >
      <BaseButton
        v-if="!file"
        class="w-fit !text-body-sm icon-secondary-gray !py-2.5"
        icon-prepend="upload-cloud02"
        icon-size="20px"
        rounded
        @click="fileInput.click()"
      >
        {{ title }}
      </BaseButton>
      <BaseButton
        v-else
        outline
        class="w-fit !text-proxify-midnight-blue border-proxify-gray-300 !text-body-sm icon-secondary-gray"
        color="white"
      >
        {{ file.name ?? file.original_name }}
        <template #append>
          <BaseIcon
            name="x-close"
            size="24px"
            rounded
            class="cursor-pointer text-proxify-gray-400 !py-2.5"
            @click="handleChange(null)"
          />
        </template>
      </BaseButton>
    </div>
    <input
      ref="fileInput"
      :data-testid="`${name.toLowerCase().replaceAll(' ', '-')}-file-input`"
      type="file"
      :accept="accept"
      :name="name"
      @change="handleChange"
      @blur="handleBlur"
    />
    <div
      v-if="required && errorMessage"
      class="text-ats-red text-sm mt-1"
    >
      {{ errorMessage }}
    </div>
  </div>
</template>

<style scoped>
.title {
  @apply text-proxify-primary cursor-pointer;
}

input[type='file'] {
  @apply opacity-0 w-px h-px hidden;
}
</style>
