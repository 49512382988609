<script setup>
import AuthenticatedLayoutSidebar from '@/components/Layout/AuthenticatedLayoutSidebar.vue';
import AuthenticatedLayoutHeader from '@/components/Layout/AuthenticatedLayoutHeader.vue';
import NotificationPanel from '@/components/Layout/Scaffold/NotificationPanel';
import TodosMenu from '@/components/Layout/Scaffold/TodosMenu';
import { computed } from 'vue';
import { useStore } from 'vuex';

const store = useStore();
const isNotificationBarActive = computed(
  () => store.state.settings.isNotificationBarActive
);
const isTodosMenuActive = computed(() => store.state.todos.isTodosMenuActive);
</script>
<template>
  <div class="layout">
    <!-- Side Drawer -->
    <aside class="bg-white drawer"><AuthenticatedLayoutSidebar /></aside>
    <!-- Header -->

    <!-- Page Content -->
    <main class="overflow-auto main h-screen">
      <header class="pt-8">
        <AuthenticatedLayoutHeader />
      </header>
      <slot></slot>
      <!-- Your page content goes here -->
    </main>
    <div class="notifications">
      <TransitionGroup name="notifications">
        <NotificationPanel v-if="isNotificationBarActive" />
        <TodosMenu v-else-if="isTodosMenuActive" />
      </TransitionGroup>
    </div>
  </div>
</template>
<style>
.layout {
  display: grid;
  grid-template-areas: "drawer main notifications";
  grid-template-columns: 81px 1fr auto;
  grid-template-rows: auto 1fr;
  row-gap: 32px;
}

.drawer {
  grid-area: drawer;
  width: 81px;
}

.notifications {
  grid-area: notifications;
}

.main {
  @apply bg-proxify-gray-25 proxify-scrollbar;

  grid-area: main;
}
</style>
