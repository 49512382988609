<template>
  <svg
    viewBox="0 0 20 20"
    fill="none"
    stroke="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.11113 15.2233C12.0385 15.2233 15.2223 12.0393 15.2223 8.11163C15.2223 4.18398 12.0385 1 8.11113 1C4.18376 1 1 4.18398 1 8.11163C1 12.0393 4.18376 15.2233 8.11113 15.2233Z"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M17.0014 17L13.1348 13.1331"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
