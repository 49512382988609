import { Delta } from '@vueup/vue-quill';

export default {
  validURL: function (string_) {
    const pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', // fragment locator
      'i'
    );
    return !!pattern.test(string_);
  },
  /**
   * Converts a string to a Delta object.
   *
   * This function first splits the input string at each newline character.
   * Then it trims each line, removing any leading and trailing whitespace.
   * Next, it filters out any empty lines.
   * Finally, it joins all the remaining lines back together, separated by newline characters, and creates a new Delta object from the result.
   *
   * @param {string} string - The string to be converted into a Delta object.
   * @returns {Delta} The Delta object that has been created from the input string.
   */
  convertToDelta: (string) => {
    return new Delta([
      {
        insert: `${string
          .split('\n')
          .map((item) => item.trim())
          .filter(Boolean)
          .join('\n')}\n`,
      },
    ]);
  },
  capitalizeFirstLetter: ([first = '', ...rest]) => {
    return [first.toUpperCase(), ...rest].join('');
  },
  /**
   * Convert Markdown links to HTML anchors
   * @param {string} text - The Markdown text to convert
   * @param {Object} options - Options object
   * @return {string} - The converted HTML text
   */
  markdownLinkToHTML: (text, options = {}) => {
    const { className } = options;
    const regex = /\[([^[]+)\]\(([^)]+)\)/g;
    return text.replace(
      regex,
      `<a href="$2" target="_blank" rel="noopener noreferrer"${
        className ? ` class="${className}"` : ''
      }>$1</a>`
    );
  },
  /**
   * Represents a function that removes or adds a protocol to a URL.
   * @typedef {Function} ModifyUrlProtocol
   * @param {string} url - URL to remove the protocol from.
   * @param {string} action - Action to perform on the URL.
   * @param {string} protocol - Protocol to add to the URL.
   * @returns {string} URL without the protocol.
   */
  modifyUrlProtocol: (url, action, protocol = 'https://') => {
    if (!url) return '';
    if (action === 'remove') return url.replace(/(^\w+:|^)\/\//, '');
    if (action === 'add') {
      const urlWithoutProtocol = url.replace(/(^\w+:|^)\/\//, '');
      return `${protocol}${urlWithoutProtocol}`;
    }
    return url;
  },
};
