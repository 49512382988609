export default {
  install: () => {
    return (
      process.env.VUE_APP_ENVIRONMENT === 'production' &&
      (function (h, o, t, index) {
        h.hj =
          h.hj ||
          function () {
            (h.hj.q = h.hj.q || []).push(arguments);
          };
        h._hjSettings = { hjid: 3406868, hjsv: 6 };
        const a = o.getElementsByTagName('head')[0];
        const r = o.createElement('script');
        r.async = 1;
        r.src = t + h._hjSettings.hjid + index + h._hjSettings.hjsv;
        a.appendChild(r);
      })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=')
    );
  },
};
