<template>
  <div class="hint-box" :style="{ left: convertPixelsToRem(x) + 'rem', top }">
    <div class="hint-box-title">{{ title }}</div>
    <div class="hint-box-text">
      {{ text }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    x: {
      type: Number,
      required: true,
    },
    y: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
  computed: {
    top() {
      const { y: yInPx } = this;
      const y = this.convertPixelsToRem(yInPx);
      const spacer = 0.5;
      if (y < spacer * 2 + 8.75) {
        return `${spacer  }rem`;
      } else {
        return `${y - spacer - 8.75  }rem`;
      }
    },
  },
};
</script>

<style scoped>
.hint-box {
  @apply p-4
  mb-2
  w-64
  fixed
  bg-white
  rounded
  shadow-md
  box-border
  z-10;
}

.hint-box-title {
  @apply font-poppins
  text-heading-3;
}

.hint-box-text {
  @apply mt-4
  text-base;
}
</style>
