import {
  configure,
  defineRule,
  ErrorMessage,
  Field,
  FieldArray,
  Form,
} from 'vee-validate';
import * as AllRules from '@vee-validate/rules';
import { localize } from '@vee-validate/i18n';
import en from '@vee-validate/i18n/dist/locale/en.json';
import { Delta } from '@vueup/vue-quill';

export default (app) => {
  app
    .component('ErrorMessage', ErrorMessage)
    .component('Field', Field)
    .component('FieldArray', FieldArray)
    .component('Form', Form);
  Object.keys(AllRules).forEach((rule) => {
    if (typeof AllRules[rule] === 'function') {
      defineRule(rule, AllRules[rule]);
    }
  });
  configure({
    generateMessage: localize({
      en: {
        ...en,
        messages: {
          ...en.messages,
          required: 'This field is required',
          requiredDelta: 'This field is required',
          between: 'Between 0:{min}-1:{max}',
        },
      },
    }),
  });

  function isNullOrUndefined(value) {
    return value === null || value === undefined;
  }

  function isEmptyArray(array) {
    return Array.isArray(array) && array.length === 0;
  }

  function isEmptyString(string_) {
    return String(string_).trim().length === 0;
  }

  function isValidValue(value) {
    return !(
      isNullOrUndefined(value) ||
      isEmptyArray(value) ||
      isEmptyString(value) ||
      value === false
    );
  }

  defineRule('validSkill', (value) => {
    if (value && !Array.isArray(value)) {
      return false;
    }
    return (
      value.filter((item) => item.main_skill).length < 3 &&
      value.every((item) =>
        Boolean(
          item.skill && item.skill.id && item.years && item.proficiency_level
        )
      )
    );
  });

  defineRule('requiredDelta', (value) => {
    if (!isValidValue(value)) {
      return false;
    }
    return Boolean(
      new Delta(value)
        .filter(
          (op) => typeof op.insert === 'string' || op.insert?.mention?.value
        )
        .map((op) => op.insert)
        .join('')
        .trim().length
    );
  });
};
