<script setup>
import BaseChartBar from '@/components/Base/BaseChartBar.vue';
import { dateTimeUtils, ratingUtils } from '@/utils';
import BaseBadge from '@/components/Base/BaseBadge.vue';
import { computed } from 'vue';
import BaseTooltip from '@/components/Base/BaseTooltip.vue';

const props = defineProps({
  experience: {
    type: Number,
    required: true,
  },
  rates: {
    type: Array,
    required: true,
  },
});

const verdict = computed(() => {
  return ratingUtils.calculateVerdict(props.experience, props.rates);
});

const color = computed(() => {
  const colors = {
    low: 'error',
    typical: 'gray',
    high: 'success',
  };

  return colors[verdict.value];
});

const experienceText = computed(() => {
  return dateTimeUtils.convertMonthsToYearsAndMonths(props.experience);
});
</script>

<template>
  <div
    :key="experience"
    class="flex items-center flex-wrap justify-between"
  >
    <span class="text-proxify-gray-900 truncate">Commercial exp</span>
    <span class="text-right flex-grow py-[2px]">
      <BaseBadge
        size="sm"
        :color="color"
        class="bg-transparent !text-body-sm !font-medium"
        clickable
      >
        {{ experienceText }}
      </BaseBadge>
      <BaseBadge
        size="sm"
        :color="color"
        icon="info-circle"
        icon-position="right"
        class="capitalize !text-body-sm !font-medium"
        clickable
        :outline="false"
      >
        {{ verdict }}
      </BaseBadge>
      <BaseTooltip
        max-width="216px"
        :offset="[0, 10]"
      >
        <BaseChartBar
          :value="experience"
          :rates="rates"
          unit="months"
          description="{verdict} years of experience compared to similar profiles from the network"
        ></BaseChartBar>
      </BaseTooltip>
    </span>
  </div>
</template>
