<template>
  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.66675 6.66663V4.33329C6.66675 3.39987 6.66675 2.93316 6.8484 2.57664C7.00819 2.26304 7.26316 2.00807 7.57676 1.84828C7.93328 1.66663 8.39999 1.66663 9.33341 1.66663H15.6667C16.6002 1.66663 17.0669 1.66663 17.4234 1.84828C17.737 2.00807 17.992 2.26304 18.1518 2.57664C18.3334 2.93316 18.3334 3.39987 18.3334 4.33329V10.6666C18.3334 11.6 18.3334 12.0668 18.1518 12.4233C17.992 12.7369 17.737 12.9918 17.4234 13.1516C17.0669 13.3333 16.6002 13.3333 15.6667 13.3333H13.3334M4.33341 18.3333H10.6667C11.6002 18.3333 12.0669 18.3333 12.4234 18.1516C12.737 17.9918 12.992 17.7369 13.1518 17.4233C13.3334 17.0668 13.3334 16.6 13.3334 15.6666V9.33329C13.3334 8.39987 13.3334 7.93316 13.1518 7.57664C12.992 7.26304 12.737 7.00807 12.4234 6.84828C12.0669 6.66663 11.6002 6.66663 10.6667 6.66663H4.33341C3.39999 6.66663 2.93328 6.66663 2.57676 6.84828C2.26316 7.00807 2.00819 7.26304 1.8484 7.57664C1.66675 7.93316 1.66675 8.39987 1.66675 9.33329V15.6666C1.66675 16.6 1.66675 17.0668 1.8484 17.4233C2.00819 17.7369 2.26316 17.9918 2.57676 18.1516C2.93328 18.3333 3.39999 18.3333 4.33341 18.3333Z"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
