<script setup>
import { computed, defineAsyncComponent, toRefs } from 'vue';
import { useVModel } from '@vueuse/core';
const BaseLabelGroup = defineAsyncComponent(() =>
  import('@/components/Base/BaseLabelGroup.vue')
);
const BaseRadioGroup = defineAsyncComponent(() =>
  import('@/components/Base/BaseRadioGroup.vue')
);
const BaseInputField = defineAsyncComponent(() =>
  import('@/components/Base/BaseInputField.vue')
);
const BaseCheckbox = defineAsyncComponent(() =>
  import('@/components/Base/BaseCheckbox.vue')
);
const BaseInputDropdown = defineAsyncComponent(() =>
  import('@/components/Base/BaseInputDropdown.vue')
);
const BaseIcon = defineAsyncComponent(() =>
  import('@/components/Base/BaseIcon.vue')
);
const BaseTooltip = defineAsyncComponent(() =>
  import('@/components/Base/BaseTooltip.vue')
);
const BaseRating = defineAsyncComponent(() =>
  import('@/components/Base/BaseRating.vue')
);
const PSeparator = defineAsyncComponent(() =>
  import('@/components/ProxifyUI/PSeparator.vue')
);

const props = defineProps({
  question: {
    type: Object,
    required: true,
  },
  index: {
    type: Number,
    required: true,
  },
  answers: {
    type: Object,
    required: true,
  },
  hasSectionLabel: {
    type: Boolean,
    default: false,
  },
  hasSubsectionLabel: {
    type: Boolean,
    default: false,
  },
  readOnly: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:answers', 'update:field']);

const model = useVModel(props, 'answers', emit);

const { question } = toRefs(props);

const formComponents = {
  radio: {
    component: BaseRadioGroup,
    props: () => ({
      optionValue: 'label',
      optionLabel: 'label',
    }),
  },
  label_group: {
    component: BaseLabelGroup,
    props: () => ({
      optionValue: 'label',
      optionLabel: 'label',
      outline: true,
      size: 'xl',
    }),
  },
  textarea: {
    component: BaseInputField,
    props: () => ({
      type: 'textarea',
      class: 'w-[408px]',
      hideBottomSpace: true,
      debounce: 15000,
    }),
  },
  number: {
    component: BaseInputField,
    props: () => ({
      type: 'number',
      class: 'w-[408px]',
      hideBottomSpace: true,
    }),
  },
  checkbox: {
    component: BaseCheckbox,
    props: (item) => ({
      label: item.name,
      class: '-mt-[16px]',
    }),
  },
  multi_select: {
    component: BaseInputDropdown,
    props: () => ({
      type: 'tags',
      optionValue: 'label',
      optionLabel: 'label',
      class: 'w-[400px]',
      emitValue: true,
      hideBottomSpace: true,
    }),
  },
  select: {
    component: BaseInputDropdown,
    props: () => ({
      optionValue: 'label',
      optionLabel: 'label',
      class: 'w-[400px]',
      emitValue: true,
      hideBottomSpace: true,
    }),
  },
  rating: {
    component: BaseRating,
    props: () => ({}),
  },
};
const CEFRLevels = [
  {
    label: 'Beginner',
    value: 'A1',
    code: 'text-proxify-error-500',
  },
  {
    label: 'Pre-Intermediate',
    value: 'A2',
    code: 'text-proxify-error-500',
  },
  {
    label: 'Intermediate',
    value: 'B1',
    code: 'text-proxify-warning-500',
  },
  {
    label: 'Upper-Intermediate',
    value: 'B2',
    code: 'text-proxify-warning-500',
  },
  {
    label: 'Advanced',
    value: 'C1',
    code: 'text-proxify-success-500',
  },
  {
    label: 'Proficiency',
    value: 'C2',
    code: 'text-proxify-success-500',
  },
];

const parseAnswer = (string_) => {
  try {
    return JSON.parse(string_).join(', ');
  } catch {
    return string_;
  }
};

const questionModel = computed({
  get: () => {
    return model.value[question.value.id];
  },
  set: (value) => {
    if (value === undefined) return;
    model.value[question.value.id] = value;
    props.question.followUps.forEach(({ id }) => {
      model.value[id] = null;
      emit('update:field', { [id]: null });
    });
    emit('update:field', {
      [props.question.id]: Array.isArray(value) ? JSON.stringify(value) : value,
    });
  },
});
</script>
<template>
  <div
    v-if="
      (!readOnly || question.type !== 'checkbox') &&
      question.group?.toLowerCase?.() !== 'notes'
    "
    :class="{
      'py-[8px]': !readOnly || (!question.label && question.type !== 'rating'),
    }"
  >
    <div
      v-if="hasSectionLabel"
      class="text-body-md font-semibold mb-[16px] text-proxify-gray-900"
    >
      <PSeparator
        v-if="index !== 0"
        class="-mt-[8px] mb-[16px]"
        :class="{
          'mt-[16px]': readOnly,
        }"
        color="#EAECF0"
      />
      {{ question.group }}
    </div>
    <div
      v-if="
        hasSubsectionLabel && (!readOnly || question.type !== 'cross_check')
      "
      class="text-body-md font-normal text-proxify-gray-700"
      :class="{
        'mb-[12px]': !readOnly,
        'mb-[8px]': readOnly,
        'mt-[24px]': readOnly && !hasSectionLabel,
      }"
    >
      {{ question.label }}
      <div
        v-if="question.name === 'CEFR Level'"
        class="mt-[8px] text-body-sm"
      >
        For more information, check
        <a
          href="https://www.coe.int/en/web/common-european-framework-reference-languages/table-1-cefr-3.3-common-reference-levels-global-scale"
          target="_blank"
          rel="noopener noreferrer"
          class="text-proxify-primary-700 hover:underline cursor-pointer"
        >
          CEFR standards
        </a>
      </div>
    </div>
    <div
      v-if="!readOnly"
      class="flex flex-col gap-[8px]"
    >
      <div
        v-if="
          (question.name &&
            question.type !== 'checkbox' &&
            question.group !== 'Final decision') ||
          question.type === 'rating'
        "
        class="text-proxify-gray-700"
        :class="{
          'text-body-sm font-medium': question.label,
          'text-body-md font-normal': !question.label,
        }"
      >
        {{ question.name }}{{ !question.required ? ' (Optional)' : '' }}
        <span v-if="question.name === 'CEFR Level'">
          <BaseIcon
            name="info-circle"
            class="text-proxify-gray-400"
            size="12px"
          />
          <BaseTooltip
            :offset="[0, 6]"
            class="font-inter"
          >
            <div class="w-[200px] flex flex-col gap-[4px]">
              <div class="text-gray-700 text-body-xs font-semibold">
                CEFR Levels
              </div>
              <div
                v-for="{ code, value, label } in CEFRLevels"
                :key="value"
                class="flex gap-[8px]"
              >
                <div>
                  <BaseIcon
                    name="dot"
                    :class="code"
                  />
                </div>
                <div class="text-body-sm font-semibold text-proxify-gray-600">
                  {{ value }}
                </div>
                <div class="text-body-sm font-normal text-proxify-gray-500">
                  {{ label }}
                </div>
              </div>
            </div>
          </BaseTooltip>
        </span>
      </div>
      <div
        class="flex gap-[16px] flex-wrap"
        :data-name="`${question.id}_${question.name?.replace?.(
          /[^a-zA-Z0-9]+/g,
          '-'
        )}`"
      >
        <component
          :is="formComponents[question.type]?.component"
          v-if="
            (question.type !== 'cross_check' &&
              question.group !== 'Final decision') ||
            question.type === 'rating'
          "
          v-bind="formComponents[question.type]?.props(question)"
          v-model="questionModel"
          :name="`${question.id}_${question.name?.replace?.(
            /[^a-zA-Z0-9]+/g,
            '-'
          )}`"
          :field-label="question.name"
          :options="question.answers"
        />
        <template v-else-if="question.type === 'cross_check'">
          <slot name="cross-check" />
        </template>
        <template v-else-if="question.group === 'Final decision'">
          <slot
            name="final-decision"
            :question="question"
          />
        </template>
        <div
          v-for="(followUp, fIndex) in question.followUps"
          v-show="
            followUp.condition ===
            question.answers.find(({ label }) => label === model[question.id])
              ?.id
          "
          :key="`question_${index}-followup_${fIndex}`"
          class="flex gap-[16px]"
          :class="{
            'basis-full': followUp.type === 'textarea',
          }"
          :data-name="`${followUp.id}_${followUp.name?.replace?.(
            /[^a-zA-Z0-9]+/g,
            '-'
          )}`"
        >
          <PSeparator
            v-show="
              followUp.condition ===
                question.answers.find(
                  ({ label }) => label === model[question.id]
                )?.id && followUp.type !== 'textarea'
            "
            vertical
            color="#D0D5DD"
            class="h-[24px] my-auto lg:hidden xl:block"
          />
          <component
            :is="formComponents[followUp.type].component"
            v-show="
              followUp.condition ===
              question.answers.find(({ label }) => label === model[question.id])
                ?.id
            "
            v-bind="formComponents[followUp.type]?.props(followUp)"
            v-model="model[followUp.id]"
            :name="`${followUp.id}_${followUp.name.replace(
              /[^a-zA-Z0-9]+/g,
              '-'
            )}`"
            :field-label="followUp.name"
            :options="followUp.answers"
            :placeholder="followUp.name"
            class="scroll-mt-[16px]"
            @update:model-value="
              (value) =>
                emit('update:field', {
                  [followUp.id]: Array.isArray(value)
                    ? JSON.stringify(value)
                    : value,
                })
            "
          />
        </div>
      </div>
    </div>
    <div
      v-else
      class="flex gap-[8px]"
      :class="{
        'flex-col': !question.label,
        'mt-[16px]':
          question.group === 'Final decision' && question.type !== 'rating',
      }"
    >
      <span
        v-if="
          (question.name && question.group !== 'Final decision') ||
          question.type === 'rating'
        "
        class="text-proxify-gray-700"
        :class="{
          'text-body-sm font-medium': question.label,
          'text-body-md font-normal': !question.label,
        }"
      >
        <span>
          {{ question.name }}{{ !question.required ? ' (Optional)' : '' }}
        </span>
        <span v-if="question.label">:</span>
      </span>
      <div
        v-if="question.answer.label && question.type === 'rating'"
        class="flex-row inline-flex"
      >
        <BaseIcon
          v-for="n in 5"
          :key="n"
          name="star"
          size="20px"
          :class="{
            'text-proxify-warning-400':
              n <= Number(questionModel / 20).toFixed(),
            'text-proxify-gray-100':
              n > Number(questionModel / 20).toFixed() || !questionModel,
          }"
        />
      </div>
      <span
        v-else-if="question.answer.label"
        class="text-body-sm font-medium text-proxify-gray-700 max-w-[400px]"
        :class="{
          '!text-proxify-success-500': question.answer.highlight === 'positive',
          '!text-proxify-warning-500': question.answer.highlight === 'neutral',
          '!text-proxify-error-500': question.answer.highlight === 'negative',
        }"
      >
        {{ parseAnswer(question.answer.label) }}
      </span>
      <template v-if="question.type === 'cross_check'">
        <slot name="cross-check" />
      </template>
      <template
        v-if="question.group === 'Final decision' && question.type !== 'rating'"
      >
        <slot
          name="final-decision"
          :question="question"
        />
      </template>
    </div>
  </div>
</template>
