<script setup>
import { ref } from 'vue';
const props = defineProps({
  dark: {
    type: Boolean,
    required: false,
  },
  fit: {
    type: Boolean,
    required: false,
  },
  cover: {
    type: Boolean,
    required: false,
  },
  anchor: {
    type: String,
    required: false,
  },
  self: {
    type: String,
    required: false,
  },
  offset: {
    type: Array,
    required: false,
  },
  scrollTarget: {
    type: [String, Element],
    required: false,
  },
  touchPosition: {
    type: Boolean,
    required: false,
  },
  persistent: {
    type: Boolean,
    required: false,
  },
  noRouteDismiss: {
    type: Boolean,
    required: false,
  },
  autoClose: {
    type: Boolean,
    required: false,
  },
  separateClosePopup: {
    type: Boolean,
    required: false,
  },
  square: {
    type: Boolean,
    required: false,
  },
  noRefocus: {
    type: Boolean,
    required: false,
  },
  noFocus: {
    type: Boolean,
    required: false,
  },
  maxHeight: {
    type: String,
    required: false,
  },
  maxWidth: {
    type: String,
    required: false,
  },
});

const emits = defineEmits([
  'escape-key',
  'show',
  'hide',
  'before-show',
  'before-hide',
]);

const menu = ref(null);

const show = () => {
  menu.value.show();
};

const hide = () => {
  menu.value.hide();
};

defineExpose({
  show,
  hide,
});
</script>
<template>
  <q-menu
    ref="menu"
    v-bind="props"
    @escape-key="emits('escape-key')"
    @show="emits('show')"
    @hide="emits('hide')"
    @before-show="emits('before-show')"
    @before-hide="emits('before-hide')"
  >
    <slot />
  </q-menu>
</template>
