<script setup>
const props = defineProps({
  modelValue: {
    type: Boolean,
  },
  label: {
    type: String,
    required: false,
  },
  hint: {
    type: String,
    required: false,
  },
  size: {
    type: String,
    required: false,
    default: () => 'md',
  },
  disabled: {
    type: Boolean,
    required: false,
    default: () => false,
  },
});
</script>

<template>
  <q-checkbox v-bind="props">
    <slot />
  </q-checkbox>
</template>
