<script setup>
import { computed } from 'vue';
import { useVModel } from '@vueuse/core';
import { PExpansionItem } from '@/components/ProxifyUI';
import BaseIcon from '@/components/Base/BaseIcon.vue';
import { styleUtils } from '@/utils';
const props = defineProps({
  modelValue: Boolean,
  icon: String,
  expandIcon: {
    type: String,
    default: 'chevron-right',
  },
  expandedIcon: {
    type: String,
    default: 'chevron-up',
  },
  collapsedIcon: {
    type: String,
    default: 'chevron-down',
  },
  expandIconClass: [String, Array, Object],
  collapsedIconClass: {
    type: [String, Array, Object],
    default: 'mt-2',
  },
  toggleAriaLabel: String,
  label: String,
  labelLines: [Number, String],
  caption: String,
  captionLines: [Number, String],
  dark: Boolean,
  dense: Boolean,
  duration: Number,
  headerInsetLevel: Number,
  contentInsetLevel: Number,
  expandSeparator: Boolean,
  defaultOpened: Boolean,
  hideExpandIcon: Boolean,
  expandIconToggle: Boolean,
  switchToggleSide: Boolean,
  denseToggle: Boolean,
  group: String,
  popup: Boolean,
  headerStyle: [String, Array, Object],
  headerClass: [String, Array, Object],
});

const headerClasses = computed(() => {
  const { convertClassesToObject } = styleUtils;
  return {
    '!p-0': true,
    'text-body-lg': true,
    '!min-h-[28px]': true,
    ...convertClassesToObject(props.headerClass),
  };
});

const emit = defineEmits(['update:modelValue']);

const model = useVModel(props, 'modelValue', emit);
</script>

<template>
  <PExpansionItem
    v-model="model"
    :icon="null"
    :expand-icon="null"
    :hide-expand-icon="true"
    :expand-icon-class="expandIconClass"
    :toggle-aria-label="toggleAriaLabel"
    :label="label"
    :label-lines="labelLines"
    :caption="caption"
    :caption-lines="captionLines"
    :dark="dark"
    :dense="dense"
    :duration="duration"
    :header-inset-level="headerInsetLevel"
    :content-inset-level="contentInsetLevel"
    :expand-separator="expandSeparator"
    :default-opened="defaultOpened"
    :expand-icon-toggle="expandIconToggle"
    :switch-toggle-side="switchToggleSide"
    :dense-toggle="denseToggle"
    :group="group"
    :popup="popup"
    :header-style="headerStyle"
    :header-class="headerClasses"
    class="p-expansion-item shadow-sm rounded-[12px] bg-white border border-proxify-gray-200"
  >
    <div class="content-container">
      <slot />
    </div>

    <template #header="{ expanded, detailsId, show, hide, toggle }">
      <div class="header-container">
        <slot
          name="header"
          :expanded="expanded"
          :details-id="detailsId"
          :show="show"
          :hide="hide"
          :toggle="toggle"
        >
          {{ label }}
        </slot>
        <div class="grow"></div>

        <BaseIcon
          :name="collapsedIcon"
          class="transition duration-300"
          :class="[expanded ? '-rotate-180' : '', collapsedIconClass]"
        />
      </div>
    </template>
  </PExpansionItem>
</template>
<style lang="scss" scoped>
.p-expansion-item {
  padding: 12px 24px;
}

.content-container {
  margin-top: 12px;
}

.header-container {
  width: 100%;
  display: flex;
}
</style>
