<template>
  <div class="page-container flex flex-col gap-4">
    <div class="page-title">Log In to Proxify ATS</div>
    <div class="flex flex-row">
      <div class="flex flex-col">
        <div class="flex flex-col">
          <Form
            class="flex flex-col"
            @submit="forgot ? reset() : submit()"
          >
            <BaseConfirmationCard
              v-if="!forgot"
              paddingless
              :cancel-button-text="null"
              confirm-button-text="Login"
              confirm-button-type="submit"
              :is-loading="isLoading"
            >
              <template #title>Type in your credentials</template>
              <div class="px-6 flex flex-col gap-4">
                <div class="flex flex-col gap-[6px]">
                  <div class="text-body-sm font-medium text-proxify-gray-700">
                    E-mail
                  </div>
                  <BaseInputField
                    v-model="email"
                    name="Email"
                    required
                    type="email"
                    placeholder="Type in your email"
                    data-testid="email"
                  />
                </div>
                <div class="flex flex-col gap-[6px]">
                  <div class="text-body-sm font-medium text-proxify-gray-700">
                    Password
                  </div>
                  <BaseInputField
                    v-model="password"
                    name="Password"
                    required
                    type="password"
                    placeholder="Type in your password"
                    data-testid="password"
                  />
                  <div class="flex justify-end -mt-1">
                    <BaseButton
                      class="icon-link-gray p-0"
                      @click="() => (forgot = true)"
                    >
                      Forgot password
                    </BaseButton>
                  </div>
                </div>
              </div>
            </BaseConfirmationCard>
            <BaseConfirmationCard
              v-else
              paddingless
              :cancel-button-text="null"
              confirm-button-text="Reset Password"
              confirm-button-type="submit"
              :is-loading="isLoading"
            >
              <template #title>Forgot password?</template>
              <div class="px-6 flex flex-col gap-4">
                <div class="flex flex-col gap-[6px]">
                  <div class="text-body-sm font-medium text-proxify-gray-700">
                    E-mail
                  </div>
                  <BaseInputField
                    v-model="resetEmail"
                    name="Email"
                    required
                    type="email"
                    placeholder="Type in your email"
                    data-testid="email"
                  />
                  <div class="flex justify-end -mt-1">
                    <BaseButton
                      class="icon-link-gray p-0"
                      @click="() => (forgot = false)"
                    >
                      Go back
                    </BaseButton>
                  </div>
                </div>
              </div>
            </BaseConfirmationCard>
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api';
import snackbarMessagesMixin from '@/mixins/snackbarMessagesMixin';
import {
  BaseConfirmationCard,
  BaseInputField,
  BaseButton,
} from '@/components/Base';

export default {
  components: {
    BaseConfirmationCard,
    BaseInputField,
    BaseButton,
  },
  mixins: [snackbarMessagesMixin],
  data: () => ({
    email: '',
    password: '',
    isLoading: false,
    forgot: false,
    resetEmail: '',
  }),
  mounted() {
    if (this.$store.getters['auth/isLogged']) {
      this.$router.push('/');
    }
  },
  methods: {
    async submit() {
      const { email, password } = this;
      this.isLoading = true;
      try {
        await this.$store.dispatch('auth/login', {
          email,
          password,
        });

        this.$router.push('/');
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    async reset() {
      const { resetEmail } = this;
      this.isLoading = true;
      try {
        const { data } = await api.auth.reset({
          email: resetEmail,
        });
        this.showSuccessMessage(data?.response);
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
.page-container {
  @apply ml-64;
}

.page-title {
  @apply text-proxify-black ml-4 text-heading-2 font-poppins font-bold;
}

.container {
  @apply mt-8 mr-8 min-h-[32rem] w-72 rounded-lg mb-[4.5rem] bg-[#f0f0f0];
}

.reset-container {
  @apply mb-0 rounded-lg;
}

.container-title {
  @apply text-proxify-black/80 text-lg font-bold pt-6 pl-4;
}

.card {
  @apply p-4 w-64 left-4 top-16 bg-white border-t-[4rem] border-transparent rounded shadow;
}

.border-blue {
  @apply border-t-4 border-proxify-primary;
}

.border-yellow {
  @apply border-t-4 border-proxify-warning-500;
}

.card-title {
  @apply text-proxify-black font-poppins font-bold text-lg;
}

::placeholder {
  @apply text-proxify-black/30;
}
</style>
