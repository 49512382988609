export default {
  methods: {
    showErrorMessage(message, displayDuration = 3000) {
      this.$store.commit('ui/addSnackbarMessage', {
        title: message || 'Oops! Something went wrong',
        type: 'error',
        displayDuration,
      });
    },
    showSuccessMessage(message, displayDuration = 3000) {
      this.$store.commit('ui/addSnackbarMessage', {
        title: message || 'Good job!',
        type: 'success',
        displayDuration,
      });
    },
    showValidationErrorMessage(errors, displayDuration = 3000) {
      this.$store.commit('ui/addSnackbarMessage', {
        title: Object.values(errors).flat().join('<br>'),
        type: 'error',
        displayDuration,
      });
    },
  },
};
