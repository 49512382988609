<script setup>
import ApplicantCard from '@/components/Elements/Applicant/ApplicantCard.vue';
import { BaseBadge, BaseButton, BaseIcon } from '@/components/Base';
import moment from 'moment';
import { computed, nextTick, ref, watch } from 'vue';
import { useStore } from 'vuex';

const store = useStore();
const stages = computed(() => store?.state?.applicant?.stages);
const getStageName = (id) => {
  const stage = stages.value?.find((stage) => stage.id === id);
  return stage?.name ?? '';
};

const props = defineProps({
  duplications: {
    type: Array,
    default: () => [],
  },
  isDuplicate: {
    type: Boolean,
    default: false,
  },
});

const expanded = ref(false);
const expandedRef = ref(null);

const duplicatedApplications = computed(() => {
  const duplications = props?.duplications?.filter(
    (duplication) => !duplication?.is_main
  );
  return duplications.sort(
    (a, b) => moment(b.applied_at).valueOf() - moment(a.applied_at).valueOf()
  );
});
const mainApplication = computed(() =>
  props?.duplications?.find((duplication) => duplication?.is_main)
);

const getAlertMessage = (isDuplicate) => {
  const alertMessages = [
    {
      label: 'You are viewing a duplicate application',
      prependIcon: 'copy06',
      iconClass: 'text-proxify-warning-500',
      customColor: 'Warning',
      active: isDuplicate,
    },
    {
      label: 'You are viewing the main application',
      prependIcon: 'check-square-broken',
      iconClass: 'text-proxify-success-500',
      customColor: 'Success',
      active: !isDuplicate,
    },
  ];
  return alertMessages.find((alert) => alert.active);
};

const scrollToShowAllDuplicates = async () => {
  await nextTick();
  expandedRef.value.scrollIntoViewIfNeeded(true);
};

watch(
  () => props.duplications,
  () => {
    expanded.value = false;
  }
);

watch(
  () => expanded.value,
  () => {
    if (expandedRef.value) {
      scrollToShowAllDuplicates();
    }
  }
);
</script>
<template>
  <ApplicantCard
    :title="`Duplicates (${duplications?.length})`"
    custom-class="px-4 pt-4"
  >
    <div
      ref="expandedRef"
      class="flex flex-col gap-y-3 pb-4"
    >
      <BaseBadge
        :color="getAlertMessage(isDuplicate).customColor"
        badge-class="h-full"
        :icon="getAlertMessage(isDuplicate).prependIcon"
        icon-size="12px"
        :icon-class="getAlertMessage(isDuplicate).iconClass"
      >
        <div
          class="whitespace-break-spaces"
          data-testid="alert-message"
        >
          {{ getAlertMessage(isDuplicate).label }}
        </div>
      </BaseBadge>
      <router-link
        v-if="isDuplicate && mainApplication"
        :to="{
          name: 'Applicant',
          params: {
            id: mainApplication?.id,
          },
        }"
        target="_blank"
        class="main-applicant-card"
        data-testid="main-applicant-card"
      >
        <div class="flex flex-col gap-y-1">
          <div
            class="font-medium text-proxify-gray-900 truncate"
            data-testid="main-applicant-name"
          >
            {{ mainApplication?.name }}
          </div>
          <BaseBadge
            color="transparent"
            :label="getStageName(mainApplication?.stage_id)"
            badge-class="!font-normal !px-0"
            icon="dot"
            icon-size="6px"
            :icon-class="
              mainApplication.is_rejected
                ? 'proxify-error-500'
                : 'proxify-success-500'
            "
            data-testid="main-application-stage-name"
          />
        </div>
        <div class="action-section">
          <BaseButton
            label="Go to main application"
            icon-append="chevron-right"
            icon-size="20px"
            flat
            class="border-0 px-0 text-proxify-primary-700 font-semibold"
          />
        </div>
      </router-link>
      <div
        v-if="duplicatedApplications?.length"
        class="flex flex-col gap-y-3"
      >
        <div class="text-right">
          <div
            class="inline-block cursor-pointer !font-semibold text-proxify-primary-700"
            data-testid="show-all-button"
            @click="expanded = !expanded"
          >
            {{ expanded ? 'Hide' : 'Show' }} all
            <BaseIcon
              name="chevron-down"
              class="transition duration-300"
              :class="[expanded ? 'rotate-180' : '', 'ml-2']"
              size="20px"
            />
          </div>
        </div>
        <Transition>
          <div
            v-show="expanded"
            class="overflow-auto proxify-scrollbar flex flex-col gap-y-2 max-h-[210px]"
            data-testid="expanded-content"
          >
            <router-link
              v-for="item in duplicatedApplications"
              :key="item.id"
              :to="{
                name: 'Applicant',
                params: {
                  id: item.id,
                },
              }"
              target="_blank"
              class="flex flex-col p-2 rounded-lg hover:bg-proxify-gray-50 transition"
            >
              <div class="font-medium text-proxify-gray-900 truncate">
                {{ item.name }}
              </div>
              <BaseBadge
                color="Transparent"
                badge-class="!px-0"
                icon="copy06"
                icon-size="12px"
              >
                <div class="font-normal text-proxify-gray-700 truncate">
                  {{ item.duplicated_by }}
                </div>
              </BaseBadge>
              <div class="flex gap-x-2">
                <div>
                  <BaseIcon
                    name="dot"
                    size="6px"
                    :class="
                      item.is_rejected
                        ? 'proxify-error-500'
                        : 'proxify-success-500'
                    "
                    data-testid="status-dot"
                  />
                </div>
                <div
                  v-if="item.is_rejected"
                  class="font-normal text-proxify-gray-700"
                >
                  <span data-testid="rejection-type">
                    {{ item.rejection?.type }}
                  </span>
                  at
                  <span
                    data-testid="rejected-duplicated-application-stage-name"
                  >
                    {{ getStageName(item?.stage_id) }}
                  </span>
                  on
                  <span data-testid="rejection-date">
                    {{
                      moment(item.rejection?.created_at).format('MMM D, YYYY')
                    }}
                  </span>
                  <span
                    v-for="(reason, index) in item.rejection?.reasons"
                    :key="index"
                  >
                    <span class="mx-1">·</span>
                    {{ reason?.name }}
                  </span>
                </div>
                <div
                  v-else
                  class="font-normal text-proxify-gray-700"
                >
                  <span data-testid="duplicated-application-stage-name">
                    {{ getStageName(item?.stage_id) }}
                  </span>
                  <span
                    v-if="item?.stage_id === 1"
                    data-testid="applied-at-section"
                    class="inline-block"
                  >
                    , applied at
                    <span data-testid="applied-at-date">
                      {{ moment(item.applied_at).format('MMM D, YYYY') }}
                    </span>
                  </span>
                </div>
              </div>
            </router-link>
          </div>
        </Transition>
      </div>
    </div>
  </ApplicantCard>
</template>
<style scoped>
.main-applicant-card {
  @apply p-3
  rounded-lg
  bg-proxify-primary-25
  flex
  flex-col
  gap-y-2
  transition
  duration-300
  hover:bg-proxify-primary-50
  cursor-pointer;
}

.action-section {
  @apply border-t
  border-proxify-gray-200;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.3s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
