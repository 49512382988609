import { createApp } from 'vue/dist/vue.esm-bundler';
import App from './App.vue';
import router from './router';
import store from './store';

import '@fontsource-variable/inter';
import '@fontsource/poppins/300.css';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';

import Quasar from 'quasar/src/vue-plugin.js';;
import quasarUserOptions from './quasar-user-options';
import QuillEditor from '@/plugins/quill';
import Socket from '@/plugins/socket';
import Sentry from '@/plugins/sentry';
import VeeValidate from '@/plugins/vee-validate';
import globalMixin from '@/mixins/globalMixin';
import hotjar from '@/plugins/hotjar';
import sanitizer from '@/plugins/sanitizer';
import datadog from '@/plugins/datadog';

import '@/styles/index.css';

// Register global components
const app = createApp(App);
const files = require.context('./components/App', true, /\.vue$/i);
files.keys().map((key) => {
  app.component(
    files(key).default.name ?? key.split('/').pop().split('.')[0],
    files(key).default
  );
});

app.mixin(globalMixin);

app.config.errorHandler = (error) => {
  if (error.name === 'ChunkLoadError') {
    window.location.reload();
  }
};

app.use(Quasar, quasarUserOptions);
app.use(store);
app.use(router);
app.use(QuillEditor);
app.use(Socket);
app.use(Sentry);
app.use(VeeValidate);
app.use(hotjar);
app.use(sanitizer);
app.use(datadog);
app.mount('#app');
