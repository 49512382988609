function getTitle(vm) {
  const { title } = vm.$options;
  if (title) {
    return typeof title === 'function' ? title.call(vm) : title;
  }
}
export default {
  created() {
    this.setPageTitle();
  },
  methods: {
    setPageTitle(pageTitle) {
      const title = pageTitle || getTitle(this);
      if (title) {
        document.title = `${title} | Proxify ATS`;
      }
    },
  },
};
