<script setup>
import { toRef } from 'vue';
import { useField } from 'vee-validate';
import { useVModel } from '@vueuse/core';
import BaseRadio from '@/components/Base/BaseRadio.vue';

const props = defineProps({
  name: {
    type: String,
    required: false,
  },
  modelValue: {
    type: String,
    required: false,
  },
  options: {
    type: Array,
    required: true,
  },
  orientation: {
    type: String,
    required: false,
    default: () => 'horizontal',
  },
  size: {
    type: String,
    required: false,
    default: () => 'md',
  },
  optionLabel: {
    type: String,
    required: false,
    default: () => 'label',
  },
  optionValue: {
    type: String,
    required: false,
    default: () => 'id',
  },
  required: {
    type: Boolean,
    required: false,
    default: () => false,
  },
  fieldLabel: {
    type: String,
    required: false,
  },
});

const emit = defineEmits(['update:modelValue']);

const model = useVModel(props, 'modelValue', emit);

const { errorMessage, value, meta } = useField(
  toRef(props, 'name'),
  { required: props.required },
  {
    initialValue: model,
    label: toRef(props, 'fieldLabel'),
    validateOnValueUpdate: false,
    syncVModel: true,
  }
);

defineExpose({ meta });
</script>

<template>
  <div
    class="flex flex-col gap-[6px]"
    :data-name="name"
  >
    <div
      class="flex gap-[8px]"
      :class="{ 'flex-col': orientation === 'vertical' }"
    >
      <BaseRadio
        v-for="option in props.options"
        :key="option[optionValue]"
        v-model="value"
        :label="option[optionLabel]"
        :val="option[optionValue]"
        :disabled="option.disabled"
        :size="props.size"
        :checked="option[optionValue] === value"
      ></BaseRadio>
    </div>
    <span
      v-if="errorMessage"
      class="text-body-sm font-normal text-proxify-error-500"
    >
      {{ errorMessage }}
    </span>
  </div>
</template>
