<template>
  <div class="app-list">
    <div
      v-for="(item, index) in items"
      :key="index"
      class="py-1 hover:bg-sidebar-gray cursor-pointer transition hover:border-sidebar-gray rounded border-transparent border-2"
    >
      <slot name="item" v-bind="item"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppList',
  props: {
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    maxHeight: {
      type: String,
      default() {
        return 'auto';
      },
    },
  },
  data() {
    return {
      isHovered: false,
    };
  },
};
</script>

<style scoped>
.app-list {
  @apply max-h-[v-bind(maxHeight)];
}
</style>
