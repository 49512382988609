<template>
  <svg
    viewBox="0 0 20 20"
    fill="none"
    stroke="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.99267 9.76458C5.65795 10.0993 5.11527 10.0993 4.78055 9.76458C4.44583 9.42986 4.44583 8.88718 4.78055 8.55246L9.99967 3.33333C11.8406 1.49238 14.8254 1.49238 16.6663 3.33333C18.5073 5.17428 18.5073 8.15905 16.6663 10L9.14195 17.5244C7.77471 18.8916 5.55797 18.8916 4.19073 17.5244C2.8235 16.1572 2.8235 13.9404 4.19074 12.5732L10.7911 5.97285C11.7107 5.05322 13.2017 5.05322 14.1213 5.97285C15.0413 6.89324 15.0411 8.38441 14.1213 9.30416L9.8268 13.5987C9.49208 13.9334 8.94939 13.9334 8.61468 13.5987C8.27996 13.264 8.27996 12.7213 8.61468 12.3866L12.9092 8.09204C13.1597 7.84161 13.1597 7.43558 12.9093 7.18509C12.659 6.93478 12.2534 6.93478 12.0032 7.18498L5.40286 13.7853C4.70505 14.4831 4.70505 15.6145 5.40286 16.3123C6.10066 17.0101 7.23202 17.0101 7.92983 16.3123L15.4542 8.78788C16.6257 7.61637 16.6257 5.71697 15.4542 4.54545C14.2827 3.37394 12.3833 3.37394 11.2118 4.54545L5.99267 9.76458Z"
      stroke-width="0.1"
      fill="currentColor"
    />
  </svg>
</template>
