<template>
  <div>
    <div
      v-if="!isLoading"
      ref="assessmentsContainer"
      class="assessments-container"
      :class="{
        'justify-start': !isEmpty || desiredTestDate,
        'justify-center items-center': isEmpty && !desiredTestDate,
      }"
    >
      <div
        class="px-4 pt-5 -mb-10"
        :class="
          isEmpty && desiredTestDate
            ? 'flex justify-between w-full'
            : 'inline-flex justify-end'
        "
      >
        <div
          v-if="isEmpty && desiredTestDate"
          class="text-lg mt-2"
        >
          Pending tests
        </div>
        <BaseButton
          v-if="!isEmpty"
          class="icon-secondary-gray py-2.5 text-body-sm"
          outlined
          rounded
          icon-prepend="plus"
          icon-size="20px"
          @click="handleOpenGenerateDialog"
        >
          New test
        </BaseButton>
      </div>

      <div
        v-if="isEmpty && desiredTestDate"
        class="flex w-full"
      >
        <AppCard
          class="mx-4 relative"
          width="19rem"
          height="12rem"
        >
          <AppCardTitle title="Test pending" />
          <div class="flex justify-between mt-5">
            <div class="font-semibold text-base">Desired date</div>
            <div
              class="pl-1 text-base"
              :class="desiredDateClass"
            >
              {{ desiredDate }}
            </div>
          </div>
          <div
            class="absolute text-proxify-primary cursor-pointer inline-block bottom-4 left-4"
            @click="handleOpenGenerateDialog"
          >
            Generate test
          </div>
        </AppCard>
      </div>
      <div
        v-else-if="isEmpty"
        class="text-center w-[352px]"
      >
        <div class="font-semibold text-body-md">No tests generated yet</div>
        <div class="font-normal text-body-sm text-proxify-gray-600">
          When someone generate a test, it will appear here
        </div>
        <BaseButton
          class="icon-white text-white !text-body-md bg-proxify-primary-600 py-2 mt-4"
          rounded
          icon-prepend="plus"
          icon-size="20px"
          @click="handleOpenGenerateDialog"
        >
          Generate a test
        </BaseButton>
      </div>
      <div v-else>
        <div
          v-if="completedTests.length"
          class="assessments-title"
        >
          Completed
        </div>

        <div class="grid xl:grid-cols-2 gap-4 mx-4">
          <div
            v-for="assessment in [...completedTests]"
            :key="assessment.id"
            :ref="completedTestRefs.set"
          >
            <ApplicantCard
              :title="assessment.test.name"
              class="!pt-6 !px-6"
            >
              <div class="flex flex-col gap-3">
                <PercentageData
                  title="Passing Threshold"
                  :value="`${
                    assessment.result?.passing_threshold ??
                    assessment.passing_threshold
                  }%`"
                  class="text-body-md"
                />
                <PercentageData
                  title="Total"
                  :value="Math.round(assessment.result.result_value)"
                ></PercentageData>
                <div
                  v-for="task in assessment.result.tasks"
                  :key="`${assessment.id}-task-${task.task_name}`"
                  v-element-hover="
                    (state) => onHover(assessment.id, task.name, state)
                  "
                  class="flex flex-col gap-y-2"
                >
                  <PercentageData
                    :title="task.task_name"
                    :value="
                      getPercentageDataValue(task.result, task.max_result) ??
                      'Not submitted'
                    "
                    :underline="hoveredTask === task.name"
                  ></PercentageData>
                  <BaseTooltip
                    tooltip-class="border border-proxify-gray-200 gap-[12px]"
                  >
                    <div class="task-card">
                      <div class="flex flex-col gap-2">
                        <div class="text-body-sm font-semibold">
                          {{ task.task_name }}
                        </div>
                        <TextData
                          v-if="task.prg_lang"
                          title="Language"
                          :value="task.prg_lang"
                        />
                        <PercentageData
                          title="Score"
                          :value="
                            getPercentageDataValue(
                              task.result,
                              task.max_result
                            ) ?? 'Not submitted'
                          "
                        ></PercentageData>
                      </div>
                    </div>
                  </BaseTooltip>
                </div>
              </div>
              <div class="border-t border-proxify-gray-200 mt-4 -mb-3"></div>
              <template #footer>
                <div class="flex justify-between w-full pt-3 pb-1">
                  <BaseButton
                    class="text-proxify-primary font-semibold"
                    icon-prepend="download02"
                    icon-size="20px"
                    padding="0"
                    @click="handleDownloadResult(assessment)"
                  >
                    Download PDF
                  </BaseButton>
                  <BaseButton
                    class="text-proxify-primary font-semibold p-0"
                    icon-prepend="copy03"
                    icon-size="20px"
                    padding="0"
                    @click="copy(assessment.link)"
                  >
                    Copy link
                  </BaseButton>
                </div>
              </template>
            </ApplicantCard>
          </div>
        </div>

        <div
          v-if="unfinishedTests.length"
          class="assessments-title"
        >
          In Progress
        </div>

        <div class="grid xl:grid-cols-2 gap-4 mx-4">
          <div
            v-for="assessment in [...unfinishedTests]"
            :key="assessment.id"
            :ref="unfinishedTestRefs.set"
          >
            <ApplicantCard
              :title="assessment.test.name"
              class="!px-6 !pt-6"
            >
              <div class="flex flex-col gap-3">
                <PercentageData
                  title="Passing Threshold"
                  :value="`${
                    assessment.result?.passing_threshold ??
                    assessment.passing_threshold
                  }%`"
                  class="text-body-md"
                />
                <PercentageData
                  title="Total"
                  :value="
                    getPercentageDataValue(
                      assessment.result?.result,
                      assessment.result?.max_result
                    ) ?? 'Not submitted'
                  "
                ></PercentageData>
                <div
                  v-if="assessment.result"
                  class="flex flex-col gap-2"
                >
                  <div
                    v-for="task in assessment.result.tasks"
                    :key="`${assessment.id}-task-${task.task_name}`"
                    v-element-hover="
                      (state) => onHover(assessment.id, task.name, state)
                    "
                    class="flex flex-col gap-y-4"
                  >
                    <PercentageData
                      :title="task.task_name"
                      :value="
                        getPercentageDataValue(task.result, task.max_result) ??
                        'Not submitted'
                      "
                      :underline="hoveredTask === task.name"
                    ></PercentageData>
                    <BaseTooltip
                      tooltip-class="border border-proxify-gray-200 gap-[12px]"
                    >
                      <div class="task-card">
                        <div class="text-body-sm font-semibold">
                          {{ task.task_name }}
                        </div>
                        <TextData
                          v-if="task.prg_lang"
                          class="mt-2"
                          title="Language"
                          :value="task.prg_lang"
                        />
                        <PercentageData
                          title="Score"
                          :value="
                            getPercentageDataValue(
                              task.result,
                              task.max_result
                            ) ?? 'Not submitted'
                          "
                          class="mt-2"
                        ></PercentageData>
                      </div>
                    </BaseTooltip>
                  </div>
                </div>
              </div>
              <div class="border-t border-proxify-gray-200 mt-4 -mb-3"></div>
              <template #footer>
                <div class="flex justify-between w-full pt-3 pb-1">
                  <BaseButton
                    class="font-semibold text-proxify-gray-400"
                    icon-prepend="download02"
                    icon-size="20px"
                    disabled
                    padding="0"
                  >
                    Download PDF
                  </BaseButton>
                  <BaseButton
                    class="text-proxify-primary font-semibold"
                    icon-prepend="copy03"
                    icon-size="20px"
                    padding="0"
                    @click="copy(assessment.link)"
                  >
                    Copy link
                  </BaseButton>
                </div>
              </template>
            </ApplicantCard>
          </div>
        </div>
      </div>
    </div>
    <PDialog
      v-model="codilityActionsPopup"
      persistent
      position="standard"
    >
      <ApplicantCodilityDialog
        :on-generate-test="fetchTests"
        :application-id="$route.params.id"
        :on-close="() => (codilityActionsPopup = false)"
      />
    </PDialog>
  </div>
</template>

<script>
import { ref, toRefs, watch } from 'vue';
import { computedEager, useClipboard, useTemplateRefsList } from '@vueuse/core';
import { vElementHover } from '@vueuse/components';
import ApplicantCard from '@/components/Elements/Applicant/ApplicantCard';
import PercentageData from '@/components/Elements/Scaffold/PercentageData';
import TextData from '@/components/Elements/Scaffold/TextData';
import { useStore } from 'vuex';
import ApplicantCodilityDialog from '@/components/Elements/Applicant/ApplicantCodilityDialog';
import api from '@/api';
import moment from 'moment';
import { PDialog } from '@/components/ProxifyUI';
import { BaseButton, BaseTooltip } from '@/components/Base';

export default {
  name: 'ApplicantActivityTests',
  components: {
    ApplicantCodilityDialog,
    PercentageData,
    TextData,
    ApplicantCard,
    PDialog,
    BaseButton,
    BaseTooltip,
  },
  directives: {
    elementHover: vElementHover,
  },
  props: {
    tests: {
      type: Array,
      required: true,
    },
    applicantName: {
      type: String,
      required: false,
      default() {
        return '';
      },
    },
    desiredTestDate: {
      type: String,
      default() {
        return '';
      },
    },
    fetchTests: {
      type: Function,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const store = useStore();
    const { tests, applicantName } = toRefs(props);
    const newTestDialog = ref(null);
    const completedTestReferences = useTemplateRefsList();
    const assessmentsContainer = ref(null);
    const { copy, copied } = useClipboard();
    const unfinishedTestReferences = useTemplateRefsList();
    const codilityActionsPopup = ref(false);

    const hoveredTask = ref(null);
    const onHover = (id, task_name, state) => {
      hoveredTask.value = state ? id + task_name : null;
    };

    watch(
      () => copied.value,
      (isCopied) => {
        if (isCopied) {
          store.commit('ui/addSnackbarMessage', {
            title: 'Link copied to clipboard!',
            type: 'success',
          });
        }
      }
    );
    const isEmpty = computedEager(() => !tests.value?.length);

    const completedTests = computedEager(
      () =>
        tests.value?.filter((test) => Boolean(test.result?.close_date)) ?? []
    );
    const unfinishedTests = computedEager(
      () =>
        tests.value?.filter((test) => Boolean(!test.result?.close_date)) ?? []
    );

    const handleOpenGenerateDialog = () => {
      codilityActionsPopup.value = true;
    };

    const handleDownloadResult = async (assessment) => {
      const { id, test } = assessment;
      const { data } = await api.applications.codility.downloadResult({
        codility_test_link_id: id,
      });
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `Codility Test Result - ${test.name} (${applicantName.value}).pdf`
      );
      document.body.appendChild(link);
      link.click();
    };

    const getPercentageDataValue = (result, maxResult) => {
      return result != null ? (result / maxResult) * 100 : null;
    };

    return {
      unfinishedTestRefs: unfinishedTestReferences,
      newTestDialog,
      completedTestRefs: completedTestReferences,
      assessmentsContainer,
      isEmpty,
      completedTests,
      unfinishedTests,
      copy,
      onHover,
      hoveredTask,
      handleOpenGenerateDialog,
      handleDownloadResult,
      getPercentageDataValue,
      codilityActionsPopup,
    };
  },
  computed: {
    desiredDate() {
      return this.desiredTestDate
        ? moment(this.desiredTestDate).format('DD MMM YYYY')
        : '';
    },
    desiredDateClass() {
      return moment().isAfter(this.desiredTestDate, 'day')
        ? 'text-proxify-warning-500'
        : 'text-proxify-black';
    },
  },
};
</script>

<style scoped>
.assessments-container {
  @apply grow overflow-y-auto overflow-x-hidden flex flex-col h-full;
}

.assessments-title {
  @apply text-proxify-gray text-body-md mt-5 mb-4 ml-4 w-full;
}

.task-card {
  @apply grid w-56 max-h-96 p-2;

  grid-template-rows: 1fr auto;
  break-inside: avoid;
}
</style>
