import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { useTitle } from '@vueuse/core';
import { useRouteParams } from '@vueuse/router';
import api from '@/api';

export default function useApplicantInfo() {
  const store = useStore();
  const filterQueries = computed(() => store.state.application.filterQueries);
  const isLoading = ref(false);
  const applicant = ref({});
  const adjacentApplications = ref(null);

  const title = useTitle();
  const applicationId = useRouteParams('id');

  const getApplicantInfo = async (refresh = true) => {
    const queries =
      Object.keys(filterQueries.value).length === 0
        ? JSON.parse(localStorage.getItem('filterQueries') ?? '{}')
        : filterQueries.value;
    if (applicationId.value) {
      isLoading.value = refresh;
      try {
        const response = await api.applications.getApplicant(
          applicationId.value
        );
        applicant.value = response.data.response;
        if (!adjacentApplications.value) {
          const { data: { data } = {} } = await api.applications.getAdjacent({
            id: applicationId.value,
            ...queries,
          });
          adjacentApplications.value = data;
        }
        title.value = applicant.value.full_name;
      } catch (error) {
        console.error(error);
      } finally {
        isLoading.value = false;
      }
    }
  };

  watch(
    [() => filterQueries.value, () => applicationId.value],
    getApplicantInfo
  );

  return {
    isLoading,
    applicant,
    adjacentApplications,
    getApplicantInfo,
  };
}
