<template>
  <div :style="preloaderWrapperStyle">
    <div class="preloader-container">
      <div class="line"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppSkeletonLoader',
  props: {
    wrapperHeight: {
      type: String,
      required: false,
      default: '100%',
    },
    fullHeight: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
  },
  computed: {
    preloaderWrapperStyle() {
      const { fullHeight } = this;
      return {
        height: fullHeight ? '100%' : `calc(100vh - ${this.wrapperHeight})`,
      };
    },
  },
};
</script>

<style scoped>
.preloader-container {
  @apply h-full bg-proxify-gray-50 w-[100%] min-w-[100%] relative overflow-hidden rounded-lg;
}

.line {
  @apply bg-[#e1e1e5] filter blur-[3rem] inline-block h-[62.5rem] w-10 absolute left-[3.75rem] bottom-[3.75rem] opacity-0;

  animation-name: skeleton-loading;
  animation-duration: 2s;
  animation-delay: 0s;
  animation-iteration-count: infinite;
}

@keyframes skeleton-loading {
  0% {
    animation-timing-function: linear;
    opacity: 1;
    transform: translateX(-31.25rem) translateY(-31.25rem) rotate(45deg);
  }

  100% {
    animation-timing-function: linear;
    opacity: 1;
    transform: translateX(62.5rem) translateY(62.5rem) rotate(45deg);
  }
}
</style>
