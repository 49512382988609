<template>
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-icon="open"
    stroke="currentColor"
  >
    <circle
      cx="12"
      cy="12"
      r="10"
      transform="rotate(-180 12 12)"
      stroke="transparent"
      fill="none"
    />
    <path
      d="M7 12L17 12"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12 7L17 12L12 17"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
