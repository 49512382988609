import { useElementBounding, useScroll } from '@vueuse/core';
import useNormalizedWindowSize from '@/composables/useNormalizedWindowSize';
import { unitUtils } from '@/utils';
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';

export const useComputedPositions = (
  targetElement,
  floatingElement,
  placement,
  {
    offsetX,
    offsetY = 0,
    spacingValue = 0.5,
    platformElement = null,
    positioningStrategy = 'absolute',
    updateTargetRectOnScroll = false,
    debug = false,
  } = {}
) => {
  const store = useStore();
  const isSidebarActive = computed(() => store.state.settings.isSidebarActive);
  const sidebarOffset = ref(15);
  const computedOffsetX = computed(() => offsetX ?? sidebarOffset.value);
  const {
    top,
    right,
    bottom,
    left,
    width: targetElementWidth,
    height: targetElementHeight,
    update: updateTargetElementRect,
  } = useElementBounding(targetElement);
  const {
    width: floatingElementWidth,
    height: floatingElementHeight,
    update: updateFloatingElementRect,
  } = useElementBounding(floatingElement);
  const {
    left: platformElementLeft,
    top: platformElementTop,
    update: updatePlatformElementRect,
  } = useElementBounding(platformElement);
  const { width: viewportWidth, height: viewportHeight } =
    useNormalizedWindowSize();
  const { convertPixelsToRem } = unitUtils;
  const { x: scrollOffsetX, y: scrollOffsetY } = useScroll(platformElement);
  const side = ref(placement.value.split('-')[0]);
  const alignment = ref(placement.value.split('-')[1]);
  watch(
    () => placement.value,
    (newValue) => {
      side.value = newValue.split('-')[0];
      alignment.value = newValue.split('-')[1];
    }
  );
  watch(
    () => [scrollOffsetX, scrollOffsetY],
    () => {
      if (updateTargetRectOnScroll) {
        updateTargetElementRect();
      }
    },
    { deep: true, immediate: true }
  );
  watch(
    () => isSidebarActive.value,
    (newValue) => {
      sidebarOffset.value = newValue ? 15 : 0;

      // We have to use setTimeout for 400ms because of a Sidebar transition
      setTimeout(() => {
        updateTargetElementRect();
        updateFloatingElementRect();
        updatePlatformElementRect();
      }, 400);
    },
    { deep: true, immediate: true }
  );
  const mainAxis = computed(() =>
    ['top', 'bottom'].includes(side.value) ? 'x' : 'y'
  );
  const midPointX = computed(
    () =>
      left.value -
      (positioningStrategy === 'relative' ? platformElementLeft.value : 0) +
      targetElementWidth.value / 2 -
      floatingElementWidth.value / 2 -
      scrollOffsetX.value
  );
  const midPointY = computed(
    () =>
      top.value -
      (positioningStrategy === 'relative' ? platformElementTop.value : 0) +
      targetElementHeight.value / 2 -
      floatingElementHeight.value / 2 -
      scrollOffsetY.value
  );
  const commonAlign = computed(() =>
    mainAxis.value === 'y'
      ? targetElementHeight.value / 2 - floatingElementHeight.value / 2
      : targetElementWidth.value / 2 - floatingElementWidth.value / 2
  );
  if (debug && process.env.VUE_APP_ENVIRONMENT === 'localhost') {
    watch(
      () => [
        { targetElement },
        { floatingElement },
        { platformElement },
        { platformElement },
        { top, right, bottom, left, targetElementWidth, targetElementHeight },
        { floatingElementWidth, floatingElementHeight },
        { platformElementLeft, platformElementTop },
        { viewportWidth, viewportHeight },
        { scrollOffsetX, scrollOffsetY },
        { midPointX, midPointY },
        { commonAlign },
      ],
      (value) => {
        console.log(value);
      },
      {
        immediate: true,
        deep: true,
      }
    );
  }
  const positions = computed(() => {
    const positions = {
      top:
        mainAxis.value === 'x'
          ? convertPixelsToRem(
              side.value === 'top'
                ? top.value -
                    floatingElementHeight.value -
                    (positioningStrategy === 'relative'
                      ? platformElementTop.value
                      : 0)
                : bottom.value -
                    (positioningStrategy === 'relative'
                      ? platformElementTop.value
                      : 0)
            ) -
            offsetY +
            spacingValue * (side.value === 'top' ? -1 : 1)
          : convertPixelsToRem(
              midPointY.value +
                (alignment.value === 'start' || alignment.value === 'end'
                  ? commonAlign.value * (alignment.value === 'start' ? -1 : 1)
                  : 0)
            ) - offsetY,
      left:
        mainAxis.value === 'x'
          ? convertPixelsToRem(
              midPointX.value +
                (alignment.value === 'start' || alignment.value === 'end'
                  ? commonAlign.value * (alignment.value === 'start' ? -1 : 1)
                  : 0)
            ) - computedOffsetX.value
          : convertPixelsToRem(
              side.value === 'left'
                ? left.value - floatingElementWidth.value
                : right.value -
                    (positioningStrategy === 'relative'
                      ? platformElementLeft.value
                      : 0)
            ) -
            computedOffsetX.value +
            spacingValue * (side.value === 'left' ? -1 : 1),
    };
    const correctedPositions = {
      top:
        convertPixelsToRem(viewportHeight.value - floatingElementHeight.value) -
          positions.top -
          spacingValue <
        0
          ? `${convertPixelsToRem(
              viewportHeight.value - floatingElementHeight.value
            ) -
            spacingValue 
            }rem`
          : `${positions.top  }rem`,
      left:
        convertPixelsToRem(viewportWidth.value - floatingElementWidth.value) -
          positions.left -
          spacingValue <
        0
          ? `${convertPixelsToRem(
              viewportWidth.value - floatingElementWidth.value
            ) -
            spacingValue 
            }rem`
          : `${positions.left  }rem`,
    };
    return correctedPositions;
  });
  return {
    positions,
  };
};
