<template>
  <div
    class="p-6 bg-white"
    :class="valueClass"
    :style="`height: ${height}; width: ${width}`"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'AppCard',
  props: {
    valueClass: {
      type: [Object, String],
      default: 'rounded-[12px] bg-white shadow-xl',
    },
    width: {
      type: String,
      default: '14rem',
    },
    height: {
      type: String,
      default: 'auto',
    },
  },
};
</script>
