<template>
  <div class="todo-form">
    <div class="todo-form-editor">
      <AppFormTextEditor
        ref="todosEditor"
        v-model="selectedTodo.body"
        :initial-value="initialValue.body"
        class="w-full h-full"
        wrapper-classes="h-full !justify-start"
        name="todoBody"
        output-type="text"
        required
        disable-actions
        plain
        :max-length="90"
        hide-error-message
        placeholder="Add your to-do"
      />
      <div class="todo-form-editor-counter">
        {{ selectedTodo?.body?.length ?? 0 }}/90
      </div>
    </div>
    <div class="flex justify-between">
      <div class="flex gap-4">
        <BaseButtonDropdown
          type="date"
          map-options
          option-label="label"
          option-value="value"
          :model-value="
            selectedTodo?.due_date
              ? moment(selectedTodo.due_date).format('YYYY/MM/DD')
              : null
          "
          @update:model-value="handleChangeDueDate"
        >
          <template #activator>
            <div
              class="text-proxify-primary cursor-pointer flex gap-1 items-center"
            >
              <IconBase
                border-radius="0%"
                width="0.9"
                height="0.9"
              >
                <IconCalendar />
              </IconBase>
              <span ref="dueDateButton">
                {{ dueDateLabel }}
              </span>
            </div>
          </template>
        </BaseButtonDropdown>
        <BaseButtonDropdown
          map-options
          :model-value="selectedTodo.assigned_to"
          :options="[
            {
              name: 'Unassign',
              value: selectedTodo?.created_by?.id ?? currentUser.id,
            },
            ...users.filter(({ active }) => active),
          ]"
          @update:model-value="
            (val) =>
              selectedTodo?.id &&
              selectedTodo?.created_by?.id !== currentUser.id &&
              selectedTodo?.assigned_to?.id === currentUser.id
                ? reveal(val)
                : handleAssignTodo(val)
          "
        >
          <template #activator>
            <div
              ref="assignButton"
              class="text-proxify-primary cursor-pointer"
            >
              <IconBase
                border-radius="0%"
                width="0.9"
                height="0.9"
              >
                <IconUserPlus />
              </IconBase>
              {{ assignLabel }}
            </div>
          </template>
        </BaseButtonDropdown>
      </div>
      <div class="flex gap-4">
        <div
          class="text-ats-red cursor-pointer"
          @click="onClearInput"
        >
          Cancel
        </div>
        <div
          class="text-proxify-primary cursor-pointer"
          :class="{ 'text-disabled-normal': !selectedTodo?.body?.length }"
          @click="selectedTodo?.body?.length && onSubmit()"
        >
          Save
        </div>
      </div>
    </div>

    <ValueSelector
      v-model:isMenuActive="isAssignMenuActive"
      class="w-auto"
      hide-trigger
      :target-element="assignButton"
      menu-placement="bottom-start"
      :on-close="() => (isAssignMenuActive = false)"
      :on-change="
        (val) =>
          selectedTodo?.id &&
          selectedTodo?.created_by?.id !== currentUser.id &&
          selectedTodo?.assigned_to?.id === currentUser.id
            ? reveal(val)
            : handleAssignTodo(val)
      "
      :options="[
        {
          name: 'Unassign',
          value: selectedTodo?.created_by?.id ?? currentUser.id,
        },
        ...users.filter(({ active }) => active),
      ]"
      return-object
      item-text="name"
      item-value="id"
    />

    <DatePicker
      v-if="isDatePickerActive"
      v-click-outside="() => (isDatePickerActive = false)"
      class="absolute"
      :target-element="dueDateButton"
      menu-placement="bottom-start"
      lite
      :initial-date="selectedTodo?.due_date ? [selectedTodo.due_date] : null"
      @pick="handleChangeDueDate"
    ></DatePicker>
    <AppConfirmationDialog
      v-if="isRevealed"
      :on-confirm="confirm"
      :on-close="cancel"
      :confirm-button-text="
        selectedTodo?.assigned_to.id === currentUser.id
          ? 'Unassign task'
          : 'Assign task'
      "
      cancel-button-text="Go back"
      cancel-button-state="primary"
    >
      <template #title>
        {{
          selectedTodo?.assigned_to?.id === selectedTodo?.created_by?.id
            ? 'Really unassign?'
            : 'Assign to another user?'
        }}
      </template>
      <span
        v-if="selectedTodo?.assigned_to?.id === selectedTodo?.created_by?.id"
      >
        This task will be unassigned. Only the creator will be able to edit or
        delete it
      </span>
      <span v-else>
        This task will be assigned to another user. Only they will be able to
        edit or delete it
      </span>
    </AppConfirmationDialog>
  </div>
</template>

<script>
import IconBase from '@/components/Icons/IconBase.vue';
import IconCalendar from '@/components/Icons/IconCalendar.vue';
import IconUserPlus from '@/components/Icons/IconUserPlus.vue';
import {
  onKeyStroke,
  useConfirmDialog,
  useMagicKeys,
  useVModel,
} from '@vueuse/core';
import { computed, onMounted, ref, watchEffect } from 'vue';
import { vOnClickOutside } from '@vueuse/components';
import { useStore } from 'vuex';
import ValueSelector from '@/components/Elements/Scaffold/ValueSelector.vue';
import DatePicker from '@/components/Elements/Scaffold/DatePicker.vue';
import moment from 'moment';
import { useForm } from 'vee-validate';
import { BaseButtonDropdown } from '@/components/Base';

export default {
  name: 'ApplicantActivityTodosInput',
  components: {
    DatePicker,
    ValueSelector,
    IconUserPlus,
    IconBase,
    IconCalendar,
    BaseButtonDropdown,
  },
  directives: {
    clickOutside: vOnClickOutside,
  },
  props: {
    onClearInput: {
      type: Function,
      required: true,
    },
    handleCreateOrUpdateTodo: {
      type: Function,
      required: true,
    },
    modelValue: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const users = computed(() => store.state.applicant.users);
    const currentUser = computed(() => store.state.auth.user);
    const selectedTodo = useVModel(props, 'modelValue', emit);
    const initialValue = ref(structuredClone(selectedTodo.value));
    // Template refs
    const todosEditor = ref(null);
    const assignButton = ref(null);
    const dueDateButton = ref(null);
    // Flags for menus
    const isAssignMenuActive = ref(false);
    const isDatePickerActive = ref(false);
    const { handleSubmit } = useForm();

    const onSubmit = handleSubmit(props.handleCreateOrUpdateTodo);

    const {
      reveal,
      isRevealed,
      cancel,
      confirm,
      onConfirm,
      onCancel,
      onReveal,
    } = useConfirmDialog();

    onConfirm(() => {
      handleAssignTodo(selectedTodo.value.assigned_to);
    });
    onReveal((assignee) => {
      selectedTodo.value = {
        ...selectedTodo.value,
        assigned_to: assignee ?? currentUser.value,
      };
    });

    onCancel(() => {
      selectedTodo.value.assigned_to = initialValue.value.assigned_to;
    });

    const dueDateLabel = computed(() => {
      const { due_date: dueDate } = selectedTodo.value;
      if (dueDate) {
        return moment(dueDate).calendar(null, {
          sameDay: '[Today]',
          nextDay: '[Tomorrow]',
          lastDay: '[Yesterday]',
          sameElse: 'DD MMMM YYYY',
        });
      }
      return 'Add due date';
    });

    const assignLabel = computed(() => {
      const { assigned_to: assignedTo, createdBy } = selectedTodo.value;
      const assignee = users.value.find(
        ({ id }) => assignedTo === id || assignedTo?.id === id
      );
      if (assignee?.name && assignee?.id !== currentUser.value.id) {
        return `Assigned to ${assignee?.name}`;
      } else if (
        assignee?.id === currentUser.value.id &&
        createdBy?.id !== currentUser.value.id
      ) {
        return 'Assigned to you';
      }
      return 'Assign';
    });

    onMounted(() => {
      todosEditor.value?.handleFocus?.();
    });

    // Submit on shift+enter
    const { shift, enter } = useMagicKeys();
    watchEffect(() => {
      if (shift.value && enter.value && selectedTodo.value.body.trim().length) {
        onSubmit();
      }
    });
    // Cancel on esc
    onKeyStroke('Escape', (event) => {
      event.preventDefault();
      props.onClearInput();
    });

    const handleAssignTodo = (assignee) => {
      if (selectedTodo.value.id) {
        props.handleCreateOrUpdateTodo({
          todo_id: selectedTodo.value.id,
          assigned_to: assignee ?? null,
        });
      } else {
        selectedTodo.value = {
          ...selectedTodo.value,
          assigned_to: assignee ?? currentUser.value,
        };
        isAssignMenuActive.value = false;
      }
    };
    const handleChangeDueDate = (selectedDate) => {
      const currentTime = moment();
      const date = selectedDate
        ? moment(selectedDate)
            .hour(currentTime.hour())
            .minute(currentTime.minute())
            .toISOString()
        : null;
      selectedTodo.value = {
        ...selectedTodo.value,
        due_date: date,
      };
      if (selectedTodo.value.id) {
        props.handleCreateOrUpdateTodo({
          todo_id: selectedTodo.value.id,
          due_date: date,
        });
      }
    };

    return {
      todosEditor,
      assignButton,
      dueDateButton,
      selectedTodo,
      initialValue,
      isAssignMenuActive,
      isDatePickerActive,
      users,
      currentUser,
      handleAssignTodo,
      handleChangeDueDate,
      onSubmit,
      moment,
      dueDateLabel,
      assignLabel,
      reveal,
      isRevealed,
      cancel,
      confirm,
    };
  },
};
</script>

<style scoped>
.todo-form {
  @apply border border-proxify-primary w-full min-h-[6.5rem] rounded p-2 ml-2.5 mb-1.5 flex flex-col justify-between overflow-auto;
}

.todo-form-editor {
  @apply inline-flex justify-between min-h-16 h-full;
}

.todo-form-editor-counter {
  @apply mr-2 ml-4 text-[#c4c4c4] text-2xs font-semibold;
}
</style>
