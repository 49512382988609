<template>
  <div class="app-warning-topbar">Production environment</div>
</template>

<style scoped>
.app-warning-topbar {
  @apply absolute
  top-0
  w-full
  text-center
  bg-proxify-error-600/80
  backdrop-blur-sm
  text-white
  text-center
  text-body-md
  z-20;
}
</style>
