import axios from 'axios';

export default {
  getTests(parameters) {
    return axios.get('codility/tests', { params: parameters });
  },
  getTestLinks(applicationId) {
    return axios.get(`applications/${applicationId}/tests`);
  },
  generateLink(parameters) {
    return axios.post('codility/tests/generate-link', parameters);
  },
  getResult(parameters) {
    return axios.get('codility/tests/result', { params: parameters });
  },
  downloadResult(parameters) {
    return axios.get('codility/tests/result/pdf', {
      params: parameters,
      responseType: 'blob',
    });
  },
};
