import axios from 'axios';

export default {
  get({ id }) {
    return axios.get(`/applications/${id}/interviews`);
  },
  getInterview(interviewId) {
    return axios.get(`/application-interviews/${interviewId}`);
  },
  saveAnswers(interviewId, body) {
    return axios.post(
      `/application-interviews/${interviewId}/save-answers`,
      body
    );
  },
  changeInterviewer(interviewId, body) {
    return axios.post(
      `/application-interviews/${interviewId}/change-interviewer`,
      body
    );
  },
  rescheduleInterview(interviewId, body) {
    return axios.post(
      `/application-interviews/${interviewId}/reschedule`,
      body
    );
  },
  markAsNoShow(interviewId, body) {
    return axios.post(
      `/application-interviews/${interviewId}/mark-no-show`,
      body
    );
  },
  getRescheduleEmailTemplate(interviewId) {
    return axios.get(`/application-interviews/${interviewId}/reschedule/email`);
  },
  getAvailableInterviewers(interviewId) {
    return axios.get(`/application-interviews/${interviewId}/interviewers`);
  },
  getInterviewQuestions(interviewId) {
    return axios.get(`/application-interviews/${interviewId}/questions`);
  },
  publishInterview(interviewId, body) {
    return axios.post(`/application-interviews/${interviewId}/publish`, body);
  },
  saveInterviewNotes(interviewId) {
    return axios.post(`/application-interviews/${interviewId}/save-notes`);
  },
  getUserInterviews({ params }) {
    return axios.get('/application-interviews/user-interviews', { params });
  },
};
