<template>
  <div>
    <div
      class="cursor-pointer"
      @click.prevent="toggleExpand()"
    >
      <slot
        name="activator"
        :is-expanded="isExpanded"
      >
        <div>Collapse</div>
      </slot>
    </div>
    <div
      ref="content"
      class="overflow-hidden"
      :class="{ 'transition-all duration-300 ease-in-out': !isLoading }"
      :style="isExpanded ? { height: height + 'px' } : { height: 0 }"
    >
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
import { useElementBounding, useToggle } from '@vueuse/core';
import { computed, ref } from 'vue';

export default {
  name: 'AppAccordion',
  props: {
    initialExpand: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const content = ref(null);
    const isExpanded = ref(props.initialExpand);
    const toggleExpand = useToggle(isExpanded);
    const { height } = useElementBounding(
      computed(() => content.value?.firstElementChild)
    );
    return {
      content,
      height,
      isExpanded,
      toggleExpand,
    };
  },
};
</script>
