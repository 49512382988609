<script setup>
const props = defineProps({
  options: {
    type: Array,
    required: true,
  },
  modelValue: {
    type: [String, Number, Boolean],
    required: true,
  },
});

const emit = defineEmits(['update:modelValue']);
</script>

<template>
  <q-btn-toggle
    v-bind="props"
    class="p-button-toggle"
    @update:model-value="emit('update:modelValue', $event)"
  >
    <template
      v-for="option in options"
      #[option.slot]
    >
      <slot :name="option.slot" />
    </template>
  </q-btn-toggle>
</template>
<style lang="scss" scoped>
.p-button-toggle {
  :deep(.q-btn) {
    @apply bg-white
    text-body-sm
    inner-border
    inner-border-[#D0D5DD]
    text-proxify-gray-700
    transition
    duration-300
    max-h-[40px]
    py-2.5
    px-4;
    &[aria-pressed='true'] {
      @apply bg-proxify-gray-100 text-proxify-gray-800 #{!important};
    }
    &:hover {
      @apply bg-[#f9fafb]
    inner-border
    inner-border-[#D0D5DD] #{!important};
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    }
    &:active {
      @apply text-[#344054] #{!important};
      box-shadow:
        0px 1px 2px rgba(16, 24, 40, 0.05),
        0px 0px 0px 4px #f2f4f7;
    }
    &:disabled {
      @apply text-[#d0d5dd]
    inner-border
    inner-border-[#eaecf0] #{!important};
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    }
  }

  :deep(.q-btn__content) {
    @apply flex-row-reverse max-h-[20px];
  }
}
</style>
