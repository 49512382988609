import script from "./BaseMenu.vue?vue&type=script&setup=true&lang=js"
export * from "./BaseMenu.vue?vue&type=script&setup=true&lang=js"

import "./BaseMenu.vue?vue&type=style&index=0&id=0bef357c&lang=scss"

const __exports__ = script;

export default __exports__
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QField from 'quasar/src/components/field/QField.js';import ClosePopup from 'quasar/src/directives/close-popup/ClosePopup.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QItemLabel,QField});qInstall(script, 'directives', {ClosePopup});
