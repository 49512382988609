import axios from 'axios';

export default {
  get({ id, ...parameters }) {
    return axios.get(`/applications/${id}/messages`, { params: parameters });
  },
  post(body, { id, onUploadProgress }) {
    return axios.post(`/applications/${id}/messages`, body, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress,
    });
  },
  getSubjects({ id }) {
    return axios.get(`/applications/${id}/messages/subjects`);
  },
  getTemplates({ id }) {
    return axios.get(`/applications/${id}/templates`);
  },
  delete({ id, draft_id }) {
    return axios.delete(`/applications/${id}/messages/${draft_id}`);
  },
};
