<script>
export default {
  name: 'BaseMessage',
  /**
   *
   * @typedef {Object} Message
   * @property {number} id - The unique identifier of the message.
   * @property {string} body - The HTML content of the message body.
   * @property {string} trigger_reason - The reason why the message was triggered, may be null.
   * @property {boolean} auto_sent - Indicates if the message was sent automatically by the system.
   * @property {string} read_at - The timestamp when the message was read, may be null if not read.
   * @property {string} scheduled_for - The timestamp when the message is scheduled to be sent, may be null if not scheduled.
   *
   * @typedef {Object} Attachment
   * @property {string} filename - The filename of the attachment.
   * @property {string} original_name - The original name of the attachment file.
   * @property {string} download_url - The download URL where the attachment can be downloaded.
   * @property {string} type - The type of the attachment, may be null.
   *
   *
   * @typedef {Object} User
   * @property {string} name - The full name of the user.
   * @property {string} email - The email address of the user.
   * @property {string} avatar_url - The URL to the user's avatar image, if available.
   * @property {number} id - The unique identifier of the user.
   * @property {boolean} active - Indicates whether the user is active or not.
   * @property {string} alias - The user's alias or username.
   * @property {boolean} is_gig_recruiter - Indicates if the user is a gig recruiter.
   * @property {Role[]} roles - An array of roles associated with the user.
   *
   *
   * @typedef {Object} Role
   * @property {number} id - The unique identifier of the role.
   * @property {string} name - The name of the role, which is used in the system.
   * @property {string} display_name - The display name of the role for presentation purposes.
   */
};
</script>
<script setup>
import moment from 'moment';
import { computed } from 'vue';
import {
  BaseApplicantBio,
  BaseBadge,
  BaseButton,
  BaseIcon,
  BaseMenu,
  BaseTooltip,
} from '@/components/Base';
import { useStore } from 'vuex';

const props = defineProps({
  id: {
    type: Number,
    required: false,
    default() {
      return null;
    },
  },
  body: {
    type: String,
    required: true,
  },
  user: {
    /** @type {{ new (): User }} */
    type: Object,
    required: false,
    default() {
      return null;
    },
  },
  attachments: {
    type: Array,
    required: false,
    default() {
      return [];
    },
  },
  isAutomated: {
    type: Boolean,
    required: false,
    default() {
      return false;
    },
  },
  triggerReason: {
    type: String,
    required: false,
    default() {
      return null;
    },
  },
  isScheduled: {
    type: Boolean,
    required: false,
    default() {
      return false;
    },
  },
  date: {
    type: String,
    required: false,
    default() {
      return null;
    },
  },
  direction: {
    type: String,
    required: false,
    default() {
      return 'outgoing';
    },
  },
  type: {
    type: String,
    required: false,
    default() {
      return 'message';
    },
  },
  showSentIcon: {
    type: Boolean,
    required: false,
    default() {
      return false;
    },
  },
  showReadIcon: {
    type: Boolean,
    required: false,
    default() {
      return false;
    },
  },
});

const emit = defineEmits(['delete', 'edit', 'cancel']);

const store = useStore();

const formattedDate = computed(() => {
  const date = moment(props.date);
  const showYear = moment().diff(date, 'year') > 0;
  const showTime = !props.isScheduled;
  const format = `ddd, D MMM${showYear ? ', YYYY' : ''}${
    showTime ? ', HH:mm' : ''
  }`;
  return date.format(format);
});

/**
 *
 * @returns {Object[]} An array of menu option objects. Each object in the array has the following structure:
 *  - label {string} - The display text for the menu option.
 *  - type {string} - The type of the menu option, typically 'item'.
 *  - props {Object} - An object containing properties for the menu option. It includes:
 *    - clickable {boolean} - Indicates if the menu option is clickable.
 *    - onClick {Function} - The function to call when the menu option is clicked.
 *    - closeOnClick {boolean} - Specifies whether the menu should close upon clicking this option.
 *    - disable {boolean} - Indicates if the menu option should be disabled based on certain conditions.
 *  - subLabel {string} - A secondary label providing additional information about the menu option.
 *  - condition {boolean} - A boolean value indicating whether the menu option should be included in the returned array, based on certain conditions.
 *
 */
const menuOptions = computed(() => {
  return [
    {
      label: 'Edit',
      type: 'item',
      props: {
        clickable: true,
        onClick: () => emit('edit', props.id),
        closeOnClick: true,
        'data-testid': 'edit-message',
      },
      condition: props.id && props.type === 'note',
    },
    {
      label: 'Delete',
      type: 'item',
      props: {
        clickable: true,
        onClick: () => emit('delete', props.id),
        closeOnClick: true,
        'data-testid': 'delete-message',
      },
      condition: props.id,
    },
  ].filter(({ condition }) => Boolean(condition));
});

const downloadFile = (downloadUrl) => {
  store.dispatch('application/downloadAttachment', downloadUrl);
};
</script>

<template>
  <div
    class="base-message-container"
    :class="isScheduled ? 'draft' : direction"
  >
    <div>
      <div
        class="flex gap-4"
        :class="{
          'justify-between': user,
          'justify-end': !user,
        }"
      >
        <BaseApplicantBio
          v-if="user"
          :name="user.name"
          :avatar-url="user.avatar_url"
          dense
          text-wrapper-class="w-full !gap-0"
          :show-supporting-text="Boolean(user.roles?.length)"
          :name-class="`text-body-sm${
            direction === 'outgoing' && !isScheduled ? ' !text-white' : ''
          }`"
        >
          <template
            v-if="user.roles && user.roles.length"
            #supporting-text
          >
            <div data-testid="message-role">
              <BaseBadge
                color="transparent"
                class="!m-0 !p-0 !rounded-none select-none !font-normal"
                :class="{
                  'text-white': direction === 'outgoing' && !isScheduled,
                }"
              >
                <div class="flex items-center">
                  <span>
                    {{ user.roles[0].display_name }}
                  </span>
                  <span v-if="user.roles.length > 1">
                    , +{{ user.roles.length - 1 }}
                  </span>
                </div>
                <template
                  v-if="user.roles.length > 1"
                  #append
                >
                  <div>
                    <BaseIcon
                      name="info-circle"
                      :class="
                        direction === 'outgoing' && !isScheduled
                          ? 'text-white'
                          : 'text-proxify-gray-600'
                      "
                      size="12px"
                    />
                    <BaseTooltip :offset="[0, 6]">
                      {{
                        user.roles
                          .slice(1)
                          .map((role) => role.display_name)
                          .join(', ')
                      }}
                    </BaseTooltip>
                  </div>
                </template>
              </BaseBadge>
            </div>
          </template>
        </BaseApplicantBio>
        <div class="flex items-center gap-2 h-6">
          <BaseBadge
            class="!m-0 !p-0 !rounded-none select-none !text-proxify-gray-600 !text-body-xs !font-normal self-center"
            color="transparent"
            data-testid="message-date"
            :icon="isScheduled ? 'hourglass01' : null"
            icon-size="12px"
            dense
          >
            <div
              class="flex gap-1"
              :class="{
                'text-white': direction === 'outgoing' && !isScheduled,
              }"
            >
              <span v-if="isScheduled">Planned for</span>
              <span
                v-else-if="showReadIcon"
                class="flex items-center justify-center"
              >
                <BaseIcon
                  name="double-check"
                  size="12px"
                  data-testid="read-icon"
                />
              </span>
              <span
                v-else-if="showSentIcon"
                class="flex items-center justify-center"
              >
                <BaseIcon
                  name="check"
                  size="12px"
                  data-testid="sent-icon"
                />
              </span>
              <span>
                {{ formattedDate }}
              </span>
            </div>
          </BaseBadge>
          <div
            v-if="menuOptions.length"
            @click.stop
          >
            <BaseButton
              icon-prepend="dots-vertical"
              round
              class="icon-tertiary-gray !text-proxify-gray-400 hover:!bg-transparent"
              :class="{
                '!text-white': direction === 'outgoing' && !isScheduled,
              }"
              icon-size="24px"
              size="8px"
              data-testid="message-menu"
            ></BaseButton>
            <BaseMenu :items="menuOptions" />
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="isAutomated"
      class="w-32"
    >
      <BaseBadge
        class="!m-0 !p-0 !rounded-none select-none"
        :class="{ 'text-white': direction === 'outgoing' && !isScheduled }"
        color="transparent"
        data-testid="automated-badge"
        icon="magic-wand01"
      >
        Automatic Email
      </BaseBadge>

      <BaseTooltip max-width="216px">
        <div class="text-body-sm">
          <div class="my-2 font-normal text-proxify-gray whitespace-normal">
            <span v-safe-html="triggerReason"></span>
          </div>
          <div class="text-proxify-gray-600 font-normal">
            {{ formattedDate }}
          </div>
        </div>
      </BaseTooltip>
    </div>
    <div
      v-safe-html="body"
      class="message-body"
      data-testid="message-body"
    ></div>
    <div
      v-if="attachments?.length"
      class="flex flex-col gap-2"
      data-testid="message-attachments"
    >
      <div class="font-semibold ml-2 mt-8">Attachments</div>
      <div class="flex gap-2">
        <a
          v-for="attachment in attachments"
          :key="attachment.id"
          class="cursor-pointer"
          data-testid="message-attachment"
          @click.prevent="downloadFile(attachment.download_url)"
        >
          <span class="attachments inline-flex">
            <BaseIcon
              name="attachment02"
              size="20px"
              class="attachment-icon mr-1"
            />
            <BaseIcon
              name="download02"
              size="20px"
              class="download-icon mr-1"
            />
            <span class="truncate max-w-[6rem]">
              {{ attachment.original_name }}
            </span>
          </span>
        </a>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.base-message-container {
  @apply p-4
  w-[85%];
  display: flex;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  gap: 12px;

  box-shadow:
    0 2px 4px -2px rgba(16, 24, 40, 0.06),
    0 4px 8px -2px rgba(16, 24, 40, 0.1);
  &.incoming {
    margin-right: 76px;
    align-self: flex-start;
    border-radius: 12px 12px 12px 0;
  }
  &.outgoing,
  &.draft {
    margin-left: 76px;
    align-self: flex-end;
    border-radius: 12px 12px 0 12px;
  }
}

.message-body {
  @apply text-body-sm font-normal text-proxify-gray-700 break-words;

  // Style h1-h6 tags, links, blockquotes, ordered and unordered lists
  :deep(h1) {
    @apply text-body-xl font-bold;
  }
  :deep(h2) {
    @apply text-body-lg font-semibold;
  }
  :deep(h3) {
    @apply text-body-md font-semibold;
  }
  :deep(h4),
  :deep(h5),
  :deep(h6) {
    @apply font-semibold;
  }

  :deep(a) {
    @apply underline;
  }

  :deep(blockquote) {
    @apply border-l-4 border-proxify-gray-300 pl-4;
  }

  :deep(ul) {
    @apply list-disc list-inside;
  }

  :deep(ol) {
    @apply list-decimal list-inside;
  }
}

.outgoing {
  @apply text-white bg-proxify-primary-400;
  .message-body {
    @apply text-white;
  }
}

.incoming {
  @apply bg-white;
}

.draft {
  @apply bg-proxify-gray-200;
}
</style>
