import axios from 'axios';

export default {
  applicantOverview() {
    return axios.get('/kpis/overviews/metrics/applicant');
  },

  interviewOverview(parameters) {
    return axios.get('/kpis/overviews/metrics/interview', { params: parameters });
  },
};
