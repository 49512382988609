<template>
  <div class="app-card-title" :class="valueClass">
    {{ title }}
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'AppCardTitle',
  props: {
    title: {
      type: String,
      required: false,
    },
    valueClass: {
      type: [Object, String],
      required: false,
    },
  },
};
</script>

<style scoped>
.app-card-title {
  @apply text-body-lg font-semibold text-proxify-gray-900;
}
</style>
