<script setup>
import { computed, ref, toRefs } from 'vue';
import { useVModel } from '@vueuse/core';

import { BaseMenu } from '@/components/Base';
const props = defineProps({
  options: {
    type: Array,
    required: false,
    default: () => [],
  },
  optionLabel: {
    type: String,
    required: false,
    default: () => 'name',
  },
  optionValue: {
    type: String,
    required: false,
    default: () => 'id',
  },
  mapOptions: {
    type: Boolean,
    required: false,
    default: () => false,
  },
  modelValue: {
    type: [String, Number, Object],
    required: false,
  },
  multiple: {
    type: Boolean,
    required: false,
    default: () => false,
  },
  disable: {
    type: Boolean,
    required: false,
    default: () => false,
  },
  type: {
    type: String,
    required: false,
  },
  search: {
    type: Boolean,
    required: false,
    default: () => true,
  },
  header: {
    type: Object,
    required: false,
  },
  disablePastDates: {
    type: Boolean,
    default: false,
  },
  menuWidth: {
    type: String,
    required: false,
    default: '240px',
  },
  range: {
    type: Array,
    default() {
      return [];
    },
  },
});

const selectedValue = computed(() => {
  if (props.multiple) {
    if (props.mapOptions) {
      return props.modelValue;
    } else {
      return props.modelValue.map((value) =>
        props.options.find((option) => option[props.optionValue] === value)
      );
    }
  } else {
    if (props.mapOptions) {
      return props.modelValue;
    } else {
      return props.options.find(
        (option) => option[props.optionValue] === props.modelValue
      );
    }
  }
});

const emit = defineEmits(['update:modelValue', 'change']);

const menu = ref(null);

const model = useVModel(props, 'modelValue', emit);
const { options, header } = toRefs(props);

const updateModelValue = (option) => {
  const optionValue = props.mapOptions ? option : option[props.optionValue];
  emit('change', optionValue);

  if (props.multiple) {
    const exists = model.value?.some(
      (item) => item.value === optionValue.value
    );

    if (exists) {
      model.value = model.value.filter(
        (item) => item.value !== optionValue.value
      );
    } else {
      model.value = model.value ? [...model.value, optionValue] : [optionValue];
    }
  } else {
    model.value = optionValue;
  }
};

const isSelected = (option) => {
  const optionValue = option[props.optionValue];

  return props.multiple
    ? selectedValue.value?.findIndex(
        (item) => item[props.optionValue] === optionValue
      ) > -1
    : selectedValue.value?.[props.optionValue] === optionValue &&
        selectedValue.value?.single_value === option.single_value;
};

const list = computed(() => {
  return [
    ...(header.value ? [header.value] : []),
    ...(options.value ?? [])
      .map((option) => ({
        type: 'item',
        label: option[props.optionLabel],
        props: {
          clickable: true,
          onClick: () => updateModelValue(option),
          closeOnClick: !props.multiple,
          selected: isSelected(option),
        },
        leftSection: {
          ...option.leftSection,
        },
      }))
      .sort((a, b) => {
        if (a.props.selected && !b.props.selected) {
          return -1;
        }
        return 1;
      }),
  ];
});

const show = () => {
  menu.value.show();
};

const hide = () => {
  menu.value.hide();
};

defineExpose({
  show,
  hide,
});
</script>
<template>
  <div>
    <slot name="activator"></slot>
    <BaseMenu
      ref="menu"
      v-model="model"
      :items="list"
      :auto-close="!multiple"
      :multiple="multiple"
      :target="!disable"
      :type="type"
      :search="search"
      :disable-past-dates="disablePastDates"
      :menu-width="menuWidth"
      :range="range"
    >
      <template
        v-if="$slots.footer"
        #footer
      >
        <slot name="footer"></slot>
      </template>
    </BaseMenu>
  </div>
</template>
