<script setup>
import { ref } from 'vue';

const props = defineProps({
  name: {
    type: String,
    required: false,
  },
  label: {
    type: String,
    required: false,
  },
  type: {
    type: String,
    required: false,
    default: () => 'text',
  },
  placeholder: {
    type: String,
    required: false,
  },
  maxLength: {
    type: [Number, String],
    required: false,
  },
  hint: {
    type: String,
    required: false,
  },
  hideHint: {
    type: Boolean,
    required: false,
  },
  hideBottomSpace: {
    type: Boolean,
    required: false,
  },
  disable: {
    type: Boolean,
    required: false,
    default: () => false,
  },
  readOnly: {
    type: Boolean,
    required: false,
    default: () => false,
  },
  error: {
    type: Boolean,
    required: false,
    default: () => false,
  },
  errorMessage: {
    type: String,
    required: false,
  },
  emitValue: {
    type: Boolean,
    required: false,
    default: () => false,
  },
  rows: {
    type: String,
    default: '3',
  },
  borderless: {
    type: Boolean,
    default: false,
  },
});
const input = ref(null);
defineExpose({
  focus: () => {
    input.value.focus();
  },
  blur: () => {
    input.value.blur();
  },
});
</script>

<template>
  <q-input
    ref="input"
    v-bind="props"
    stack-label
    no-error-icon
    :maxlength="maxLength"
  >
    <template
      v-if="$slots.prepend"
      #prepend
    >
      <slot name="prepend"></slot>
    </template>
    <template
      v-if="$slots.before"
      #before
    >
      <slot name="before"></slot>
    </template>
    <template
      v-if="$slots.append"
      #append
    >
      <slot name="append"></slot>
    </template>
    <template
      v-if="$slots.after"
      #after
    >
      <slot name="after"></slot>
    </template>
  </q-input>
</template>
