import script from "./BaseBadge.vue?vue&type=script&setup=true&lang=js"
export * from "./BaseBadge.vue?vue&type=script&setup=true&lang=js"

import "./BaseBadge.vue?vue&type=style&index=0&id=267323cf&lang=scss"

const __exports__ = script;

export default __exports__
import QChip from 'quasar/src/components/chip/QChip.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QChip});
