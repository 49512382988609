export default {
  /**
   * Converts string or array of class names into a corresponding object with keys as class names and values as boolean true.
   *
   * @param {string | string[] | null | undefined} classes - The class or classes to convert.
   * @returns {object} An object with keys as class names and values as boolean true.
   * @throws {TypeError} If the `classes` parameter is neither a string, nor an array of strings, nor null, nor undefined.
   */
  convertClassesToObject: (classes) => {
    if (classes === null || classes === undefined) {
      return {};
    }

    if (typeof classes !== 'string' && !Array.isArray(classes)) {
      throw new TypeError(
        'The classes parameter must be either a string, an array of strings, null, or undefined.'
      );
    }

    const classesArray = Array.isArray(classes) ? classes : classes.split(' ');

    return classesArray.reduce((accumulator, className) => {
      return { ...accumulator, [className]: true };
    }, {});
  },
};
