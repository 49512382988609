import axios from 'axios';

export default {
  isKaratEnabled(applicationId) {
    return axios.get(`/applications/${applicationId}/karat/is-handled`);
  },
  inviteApplicant(applicationId) {
    return axios.post(`/applications/${applicationId}/karat/invite`);
  },
};
