<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width + 'rem'"
    :height="height + 'rem'"
    viewBox="0 0 100 100"
    role="presentation"
  >
    <g :fill="iconColor">
      <slot />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 1.125,
    },
    height: {
      type: [Number, String],
      default: 1.125,
    },
    backgroundColor: {
      type: String,
      default: 'transparent',
    },
    borderRadius: {
      type: String,
      default: '50%',
    },
    iconColor: {
      type: String,
      default: 'currentColor',
    },
    marginBottom: {
      type: String,
      default: '-0.125rem',
    },
  },
};
</script>

<style scoped>
svg {
  display: inline-block;
  vertical-align: baseline;
  margin-bottom: v-bind(marginBottom);
  border-radius: v-bind(borderRadius);
  background-color: v-bind(backgroundColor);
}
</style>
