export default {
  filterChanges: function (initialValue, currentValue) {
    const changes = Object.entries(currentValue).reduce(
      (accumulator, [key, value]) => {
        if (initialValue[key] !== value) {
          accumulator[key] = value;
        }
        return accumulator;
      },
      {}
    );
    return changes;
  },
  /**
   * Removes a specified key from an object and returns a new object without the key.
   *
   * @param {Object} object - The original object.
   * @param {string} keyToRemove - The key to remove from the object.
   * @returns {Object} A new object without the specified key.
   */
  removeKeyFromObject: (object, keyToRemove) => {
    // eslint-disable-next-line no-unused-vars
    const { [keyToRemove]: _, ...newObject } = object;
    return newObject;
  },
  /**
   * Creates a new object by omitting a specified key from the given object.
   *
   * @param {Object} object - The original object from which a key will be omitted.
   * @param {string} keyToOmit - The key to omit from the new object.
   * @returns {Object} A new object with the same properties as the original except for the omitted key.
   *
   * @example
   * const originalObj = { a: 1, b: 2, c: 3 };
   * const newObj = omit(originalObj, 'b');
   * console.log(newObj); // Output: { a: 1, c: 3 }
   */
  omit: (object, keyToOmit) => {
    return Object.keys(object).reduce((result, key) => {
      if (key !== keyToOmit) {
        result[key] = object[key];
      }
      return result;
    }, {});
  },
  isObject: function (value) {
    return Object.prototype.toString.call(value).slice(8, -1) === 'Object';
  },
};
