<script setup>
const props = defineProps({
  name: {
    type: String,
    required: false,
  },
  label: {
    type: String,
    required: false,
  },
  modelValue: {
    type: String,
    required: false,
    default: () => '',
  },
  checked: {
    type: Boolean,
    required: false,
    default: () => false,
  },
  disable: {
    type: Boolean,
    required: false,
    default: () => false,
  },
});
</script>

<template>
  <q-radio v-bind="props"></q-radio>
</template>
