<script setup>
import PCircularProgress from '@/components/ProxifyUI/PCircularProgress.vue';

const props = defineProps({
  indeterminate: {
    type: Boolean,
    default: false,
  },
  rounded: {
    type: Boolean,
    default: false,
  },
  color: {
    type: String,
    default: 'primary',
  },
  size: {
    type: String,
    default: 'md',
  },
  trackColor: {
    type: String,
    default: 'proxify-gray-100',
  },
  thickness: {
    type: Number,
    default: 0.25,
  },
  animationSpeed: {
    type: [Number, String],
    default: 300,
  },
});
</script>
<template>
  <PCircularProgress v-bind="props" />
</template>
