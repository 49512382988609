export default {
  /**
   * Generates an object with the main and secondary skills sorted by years and proficiency level.
   * @param {Array} skills - The skills to sort.
   * @returns {Object} An object with the main and secondary skills sorted by years and proficiency level.
   */
  separateSkills(skills) {
    const sortSkills = (a, b) => {
      return b.years === a.years
        ? b.proficiency_level - a.proficiency_level
        : b.years - a.years;
    };

    const { mainSkills, secondarySkills } = (skills ?? []).reduce(
      (accumulator, item) => {
        if (item.main_skill) {
          accumulator.mainSkills.push(item);
        } else {
          accumulator.secondarySkills.push(item);
        }
        return accumulator;
      },
      { mainSkills: [], secondarySkills: [] }
    );

    mainSkills.sort(sortSkills);
    secondarySkills.sort(sortSkills);

    return { mainSkills, secondarySkills };
  },
};
