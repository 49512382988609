<script setup>
import { computed, ref, toRef, toRefs, watch } from 'vue';
import { PAvatar, PSelect, PItem, PItemSection } from '@/components/ProxifyUI';
import { BaseBadge, BaseIcon } from '@/components/Base';
import { useVModel } from '@vueuse/core';
import { useField } from 'vee-validate';

const props = defineProps({
  modelValue: {
    type: [Array, String, Object, Number],
    required: false,
    default: undefined,
  },
  title: {
    type: String,
    required: false,
    default: undefined,
  },
  placeholder: {
    type: String,
    default: 'Select',
  },
  type: {
    type: String,
    default: 'default',
    validator: (value) =>
      [
        'default',
        'icon-leading',
        'avatar-leading',
        'dot-leading',
        'search',
        'tags',
        'country',
      ].includes(value),
  },
  optionAvatar: {
    type: String,
    default: 'avatar_url',
  },
  optionValue: {
    type: String,
    default: 'id',
  },
  optionLabel: {
    type: String,
    default: 'name',
  },
  optionSublabel: {
    type: String,
  },
  options: {
    type: Array,
  },
  hint: {
    type: String,
  },
  prependIcon: {
    type: String,
    required: false,
  },
  prependIconClass: {
    type: [String, Object],
    required: false,
  },
  trailingMenu: {
    type: Boolean,
    required: false,
    default: false,
  },
  onFilter: {
    type: Function,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  emitValue: {
    type: Boolean,
    default: false,
  },
  borderless: {
    type: Boolean,
    default: false,
  },
  sharpBorder: {
    type: [String, Boolean],
    default: false,
    validator: (value) => ['left', 'right', false].includes(value),
  },
  paddingless: {
    type: Boolean,
    default: false,
  },
  inputWidth: {
    type: String,
  },
  inputMinWidth: {
    type: String,
    default: '0px',
  },
  offset: {
    type: Array,
  },
  menuWidth: {
    type: Number,
  },
  limitMenuHeight: {
    type: Boolean,
    required: false,
    default: true,
  },
  name: {
    type: String,
  },
  required: {
    type: Boolean,
    default: false,
  },
  fieldLabel: {
    type: String,
  },
  hideBottomSpace: {
    type: Boolean,
    default: true,
  },
  badgeSize: {
    type: String,
  },
  disable: {
    type: Boolean,
    default: false,
  },
  menuSelf: {
    type: String,
    required: false,
  },
  menuAnchor: {
    type: String,
    required: false,
  },
});
const emit = defineEmits([
  'update:modelValue',
  'virtual-scroll',
  'focus',
  'blur',
]);

const model = useVModel(props, 'modelValue', emit);

const { options, disable } = toRefs(props);

const handleBlur = () => {
  pSelect.value.handleBlur();
  validate();
};
const handleFocus = () => {
  pSelect.value.handleFocus();
};
const pSelect = ref(null);
const filteredOptions = ref(options.value);

const hasValue = computed(() => Boolean(model.value));
const optionList = computed(() =>
  props.onFilter ? options.value : filteredOptions.value
);
const isTags = computed(() => props.type === 'tags');
const isCountry = computed(() => props.type === 'country');
const isInputUsed = computed(() =>
  ['search', 'tags', 'country', 'avatar-leading'].includes(props.type)
);
const isAppendIconVisible = computed(() =>
  ['default', 'icon-leading', 'avatar-leading', 'dot-leading'].includes(
    props.type
  )
);
const isAvatarLeading = computed(() => props.type === 'avatar-leading');
const isSearchIconVisible = computed(() =>
  ['country', 'tags', 'search'].includes(props.type)
);
const isIconLeading = computed(() => props.type === 'icon-leading');
const isDotLeading = computed(() => props.type === 'dot-leading');
const shouldShowPlaceholder = computed(() => {
  const typesToShowPlaceholder = ['default', 'icon-leading', 'dot-leading'];
  return (
    !model.value?.length &&
    !model.value?.[props.optionValue] &&
    !hasValue.value &&
    typesToShowPlaceholder.includes(props.type)
  );
});

const handleFilter = (value, update) => {
  if (props.onFilter) {
    return props.onFilter(value, update);
  }
  const needle = value.toLowerCase();
  update(() => {
    if (isInputUsed.value) {
      filteredOptions.value = options.value.filter((option) =>
        (option[props.optionSublabel ?? props.optionLabel] ?? '')
          .toLowerCase()
          .includes(needle)
      );
    } else {
      update(() => {
        filteredOptions.value = options.value;
      });
    }
  });
};

const removeTag = (index) => {
  model.value = model.value.filter((_, index_) => {
    return index_ !== index;
  });
};

const removeCountry = () => {
  model.value = null;
};

const getCountryCode = () => {
  return (
    model.value?.code?.split?.('').join?.('-') ??
    model.value?.code?.country?.code?.split?.('').join?.('-') ??
    'earth'
  );
};

const { errorMessage, validate, meta } = useField(
  toRef(props, 'name'),
  { required: props.required },
  {
    initialValue: model,
    label: toRef(props, 'fieldLabel'),
    validateOnValueUpdate: false,
    syncVModel: true,
  }
);
watch(
  () => disable.value,
  (newValue, oldValue) => {
    if (oldValue && !newValue) {
      pSelect.value.handleBlur();
    }
  }
);

defineExpose({ handleBlur, handleFocus, meta });
</script>
<template>
  <div
    class="base-input-dropdown"
    :data-name="name"
  >
    <div
      v-if="title"
      class="text-body-sm font-medium"
    >
      {{ title }}
    </div>
    <PSelect
      ref="pSelect"
      v-model="model"
      outlined
      class="p-select"
      :class="{
        'p-select__borderless': borderless,
        'p-select__paddingless': paddingless,
      }"
      :options="optionList"
      map-options
      :option-value="optionValue"
      :option-label="optionSublabel ?? optionLabel"
      :emit-value="emitValue"
      :popup-content-class="`p-select__menu${
        limitMenuHeight ? ` !max-h-[300px]` : ''
      }`"
      :popup-content-style="menuWidth ? { width: `${menuWidth}px` } : undefined"
      input-class="p-select__input"
      hide-dropdown-icon
      :hide-bottom-space="hideBottomSpace"
      :multiple="isTags"
      :use-input="isInputUsed"
      :placeholder="
        (Array.isArray(model)
          ? !model?.length
          : model === undefined || model === null) &&
        !model?.[optionSublabel ?? optionLabel] &&
        !model?.[optionValue]
          ? placeholder
          : null
      "
      :hint="hint"
      :label="shouldShowPlaceholder ? placeholder : undefined"
      :loading="loading"
      :offset="offset"
      :error="Boolean(errorMessage)"
      :error-message="errorMessage"
      no-error-icon
      :disable="disable"
      :menu-self="menuSelf"
      :menu-anchor="menuAnchor"
      @filter="handleFilter"
      @update:model-value="
        (val) =>
          !Array.isArray(val) &&
          val !== null &&
          val !== undefined &&
          handleBlur()
      "
      @virtual-scroll="(details) => emit('virtual-scroll', details)"
      @focus="emit('focus')"
      @blur="emit('blur')"
    >
      <template
        v-if="$slots['before-options']"
        #before-options
      >
        <slot name="before-options" />
      </template>
      <template
        v-if="isAppendIconVisible"
        #append
      >
        <BaseIcon
          name="chevron-down"
          size="20px"
        />
      </template>
      <template
        v-if="type !== 'default'"
        #prepend
      >
        <PAvatar
          v-if="isAvatarLeading"
          size="xs"
          class="bg-transparent"
        >
          <img
            v-if="model?.[optionAvatar]"
            :src="model?.[optionAvatar]"
            :alt="model?.[optionSublabel ?? optionLabel]"
          />
          <BaseIcon
            v-else
            name="user01"
            size="20px"
          />
        </PAvatar>
        <BaseIcon
          v-if="isSearchIconVisible"
          :key="prependIcon"
          :name="prependIcon ?? 'search-lg'"
          :class="prependIconClass"
        />
        <BaseIcon
          v-if="isIconLeading"
          :name="prependIcon"
        />
        <BaseIcon
          v-if="isDotLeading"
          name="dot"
          size="8px"
          class="proxify-success-500 ml-[3px] -mr-[3px]"
        />
      </template>
      <template #option="{ opt, selected, focused, itemProps }">
        <PItem
          class="font-inter !min-h-[42px] rounded-full hover:bg-proxify-gray-50 !hover:text-proxify-midnight-blue text-body-md font-medium"
          :class="{ 'bg-proxify-gray-50': selected || focused }"
          clickable
          v-bind="itemProps"
        >
          <PItemSection
            v-if="isAvatarLeading || isIconLeading || isDotLeading"
            side
          >
            <PAvatar
              v-if="isAvatarLeading"
              size="xs"
            >
              <img
                v-if="opt?.[optionAvatar]"
                :src="opt?.[optionAvatar]"
                :alt="opt?.[optionSublabel ?? optionLabel]"
              />
              <BaseIcon
                v-else
                name="user01"
              />
            </PAvatar>
            <BaseIcon
              v-if="isIconLeading"
              :name="prependIcon"
            />
            <BaseIcon
              v-if="isDotLeading"
              name="dot"
              size="8px"
              class="proxify-success-500"
            />
          </PItemSection>

          <PItemSection
            class="flex flex-row !justify-start gap-x-2 flex-wrap !items-center"
          >
            <span class="whitespace-nowrap max-w-[85%] truncate">
              {{ opt[optionLabel] ?? opt[optionSublabel] ?? opt }}
            </span>
            <span
              v-if="opt?.[optionSublabel] && opt?.[optionLabel]"
              class="text-proxify-gray-600 font-normal text-body-sm"
            >
              {{ opt[optionSublabel] }}
            </span>
          </PItemSection>

          <PItemSection
            v-if="selected"
            side
          >
            <BaseIcon
              name="check"
              size="20px"
              color="primary"
            />
          </PItemSection>
        </PItem>
      </template>
      <template #selected>
        <div
          v-if="isTags"
          class="flex flex-wrap gap-2"
        >
          <BaseBadge
            v-for="(item, index) in model"
            :key="item[optionValue]"
            outline
            class="h-[32px] !text-proxify-midnight-blue border-proxify-gray-300"
            color="white"
            :size="badgeSize"
          >
            {{ item?.[optionLabel] ?? item }}
            <template #append>
              <BaseIcon
                name="x-close"
                size="24px"
                class="cursor-pointer text-proxify-gray-400"
                @click="removeTag(index)"
              />
            </template>
          </BaseBadge>
        </div>
        <BaseBadge
          v-else-if="isCountry && model?.[optionLabel]"
          outline
          class="h-[32px] !text-proxify-midnight-blue border-proxify-gray-300"
          color="white"
        >
          {{ model?.[optionLabel] }}
          <template #prepend>
            <BaseIcon
              :name="`flag-${getCountryCode()}`"
              size="16px"
            />
          </template>
          <template #append>
            <BaseIcon
              name="x-close"
              size="24px"
              class="cursor-pointer text-proxify-gray-400"
              @click="removeCountry"
            />
          </template>
        </BaseBadge>
        <div
          v-else
          class="flex gap-x-2 items-center w-full"
        >
          <span
            class="whitespace-nowrap"
            :class="{ 'max-w-[85%] truncate': !trailingMenu }"
          >
            {{
              model?.[optionLabel] ??
              model?.[optionSublabel] ??
              options.find((item) => item[optionValue] === model)?.[
                optionLabel
              ] ??
              model
            }}
          </span>
          <span
            v-if="model?.[optionSublabel] && model?.[optionLabel]"
            class="text-proxify-gray-600 font-normal text-body-sm"
          >
            {{ model[optionSublabel] }}
          </span>
        </div>
      </template>
      <template
        v-if="$slots.after"
        #after
      >
        <slot name="after" />
      </template>
    </PSelect>
  </div>
</template>

<style lang="scss">
.base-input-dropdown {
  @apply flex
  flex-col
  gap-[8px]
  min-w-[v-bind(inputMinWidth)]
  w-[v-bind(inputWidth)];
}

.q-field--auto-height {
  height: unset !important;
  min-height: 40px !important;
  .q-field {
    &__control {
      height: unset !important;
      min-height: 40px !important;
      &-container {
        padding-top: 0 !important;
      }
    }
    &__native {
      height: unset !important;
      min-height: 40px !important;
    }
  }
}

.q-field--error {
  .q-field__native {
    border-color: #fda29b;

    &:focus {
      box-shadow:
        0 1px 2px rgba(16, 24, 40, 0.05),
        0 0 0 4px #fee4e2 !important;
    }
  }

  .q-field__bottom {
    color: #f04438 !important;
  }
}
.p-select {
  @apply font-inter text-body-md font-medium transition-[height] #{!important};

  input:not([placeholder]) {
    display: none;
  }

  &.p-select__borderless {
    .q-field__inner,
    .q-field__control {
      border: none;
      box-shadow: none;
      border-radius: 0;
    }
    &.q-field--highlighted {
      .q-field__inner {
        box-shadow: none !important;
        border-radius: 0 !important;
      }
    }
  }
  &.p-select__paddingless {
    .q-field {
      &__control {
        padding: 0;
      }
      &__native {
        padding: 0 !important;
      }
      &__append {
        padding-left: 4px;
      }
    }
  }
  &.q-field--highlighted {
    .q-field__control {
      box-shadow:
        0px 1px 2px rgba(16, 24, 40, 0.05),
        0px 0px 0px 4px #f4ebff;
      border-radius: 8px;
    }
    input {
      display: unset;
    }
  }

  &.q-field--error {
    .q-field__control {
      border-color: #fda29b !important;
      box-shadow:
        0px 1px 2px rgba(16, 24, 40, 0.05),
        0px 0px 0px 4px #fee4e2 !important;
    }
    .q-field__bottom {
      color: #f04438;
    }
  }
  &__menu {
    border: 1px solid #eaecf0;
    border-radius: 8px !important;
    .q-virtual-scroll__content {
      box-shadow:
        0px 12px 16px -4px rgba(16, 24, 40, 0.08),
        0px 4px 6px -2px rgba(16, 24, 40, 0.03);
      display: flex;
      flex-direction: column;
      gap: 4px;
      .q-item {
        display: flex;
        align-items: center;
      }
    }
  }
  .q-field {
    &__native {
      @apply text-proxify-gray-900 text-body-md #{!important};
      font-size: 16px !important;
      font-weight: 500 !important;
      min-height: 40px !important;
    }
    &__control {
      @apply text-proxify-primary-300;
      height: unset !important;
      border-radius: 8px !important;
      border: 1px solid #d0d5dd;
      &:before,
      &:after {
        content: none;
      }
    }
    &__bottom {
      @apply font-inter text-body-sm font-normal text-proxify-gray-600 px-0;
    }
    &__marginal {
      height: unset !important;
    }
    &__label {
      top: 10px;
    }
  }
}

.q-field--float .q-field__label {
  transform: none !important;
  transition: none !important;
}
</style>
