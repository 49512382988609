const state = {
  snackbarMessages: [],
  recentMessageTime: null,
};

const getters = {
  snackbarMessages: (state) => state.snackbarMessages,
};

const actions = {};

const mutations = {
  addSnackbarMessage(
    state,
    { title, body = '', icon = '', type, displayDuration = 3000 }
  ) {
    const id = Date.now();
    const delay = calculateDelay(state, id);

    state.recentMessageTime = id + delay;

    scheduleMessageAddition(
      state,
      { id, title, body, icon, type },
      delay,
      displayDuration
    );
  },
  removeSnackbarMessage(state, id) {
    removeSnackbarMessage(state, id);
  },
};

const calculateDelay = (state, id) => {
  let delay = 0;
  if (state.recentMessageTime && id - state.recentMessageTime < 1000) {
    delay = 1000 - (id - state.recentMessageTime);
  }
  return delay;
};

const scheduleMessageAddition = (state, message, delay, displayDuration) => {
  setTimeout(() => {
    state.snackbarMessages = [...state.snackbarMessages, message];
    scheduleMessageRemoval(state, message.id, displayDuration);
  }, delay);
};

const scheduleMessageRemoval = (state, id, displayDuration) => {
  setTimeout(() => {
    removeSnackbarMessage(state, id);
  }, displayDuration);
};

const removeSnackbarMessage = (state, id) => {
  state.snackbarMessages = state.snackbarMessages.filter(
    (item) => item.id !== id
  );
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
