<template>
  <div>
    <AppCard
      width="auto"
      class="inline-block my-2"
      value-class="min-w-[28.5rem] max-w-[100%] shadow-sm rounded-lg"
    >
      <div class="flex justify-between mb-3">
        <div class="flex">
          <IconBase
            v-if="isAutomaticEmailSent"
            height="1.2"
            width="1.2"
            border-radius="0%"
            class="mr-2 mt-0.5 text-proxify-warning-500"
          >
            <IconSend />
          </IconBase>
          <IconBase
            v-else-if="isAutomaticStageChange || isAutomaticRejection"
            height="1.2"
            width="1.2"
            class="mr-2"
            :class="[
              isAutomaticRejection
                ? 'text-ats-red'
                : 'text-proxify-warning-500',
            ]"
          >
            <IconMagic />
          </IconBase>
          <img
            v-else-if="item?.karat_interview"
            src="@/assets/karat_logo.svg"
            width="16"
            class="mr-2 rounded-full"
            alt="Karat Logo"
          />
          <AppCardTitle :title="cardTitle" />
        </div>
        <AppTooltip content-class="right-0 mb-1">
          <template #title>
            <span class="text-disabled-normal text-xs">
              {{ moment(item?.created_at).fromNow() }}
            </span>
          </template>
          <template #content>
            <IconBase
              class="mx-1 mt-0.5"
              border-radius="0%"
              width="0.9"
              height="0.9"
            >
              <IconCalendar />
            </IconBase>
            {{ moment(item?.created_at).format('DD MMMM, HH:mm') }}
          </template>
        </AppTooltip>
      </div>

      <!-- Updated stage  -->
      <div
        v-if="
          (item?.activity === 'Updated stage' || item?.activity === 'Hired') &&
          (item?.stage_from?.name || item?.stage_to?.name)
        "
      >
        <div class="flex">
          <div class="text-disabled-normal">
            {{ item?.stage_from?.name }}
          </div>
          <div class="mx-1 -mt-0.5">&#10230;</div>
          <div class="text-proxify-black">{{ item?.stage_to?.name }}</div>
        </div>
      </div>

      <!-- Automatic Actions -->
      <div v-if="isAutomaticStageChange">
        <div class="flex">
          <div class="text-disabled-normal">
            {{ item?.stage_from?.name }}
          </div>
          <div class="mx-1 -mt-0.5">&#10230;</div>
          <div class="text-proxify-black">{{ item?.stage_to?.name }}</div>
        </div>
        <div class="whitespace-normal mt-4">
          <span v-safe-html="item?.description"></span>
        </div>
      </div>

      <div v-if="isAutomaticRejection">
        <div class="flex">
          <div class="text-disabled-normal">
            {{ stage?.name }}
          </div>
          <div class="mx-1 -mt-0.5">&#10230;</div>
          <div class="text-ats-red">Rejected</div>
        </div>
        <div class="whitespace-normal mt-4">
          Rejection Reason:
          <span v-safe-html="item?.rejection_reason"></span>
        </div>
      </div>

      <!-- Email -->
      <div v-if="item?.email">
        <div
          v-safe-html="item?.email?.subject + '. ' + item?.email?.body"
          class="truncate"
          :style="`max-width: ${maxWidth}`"
        ></div>
        <div class="flex justify-between mt-3 items-center">
          <div class="text-xs">
            <span class="text-proxify-black">By</span>
            <span class="ml-1">
              {{ item?.user?.name || 'ATS' }}
            </span>
          </div>
          <router-link
            :to="{
              name: 'ApplicantEmails',
              params: {
                subject: encodeURI(item.email?.subject),
                itemId: item.email?.id,
              },
            }"
            class="text-proxify-primary"
          >
            Read Full in Messages
          </router-link>
        </div>
      </div>

      <!-- Notes -->
      <div
        v-if="
          [
            'Note created',
            'Note updated',
            'Note deleted',
            'User tagged in the note',
          ].includes(item?.activity)
        "
      >
        <div
          v-if="item?.note?.body"
          v-safe-html="formattedNotes(item?.note?.body)"
          class="truncate"
          :style="`max-width: ${maxWidth}`"
        ></div>

        <div class="flex justify-between mt-3 items-center">
          <div class="text-xs">
            <span class="text-proxify-black">By</span>
            <span class="ml-1">
              {{ item?.user?.name || 'ATS' }}
            </span>
          </div>
          <router-link
            v-if="Boolean(item?.note)"
            class="text-proxify-primary"
            :to="{
              name: 'Applicant',
              params: { tab: 'notes', itemId: item?.note?.id },
            }"
          >
            View in Notes
          </router-link>
        </div>
      </div>

      <!-- To Dos -->
      <div
        v-if="
          [
            'Todo created',
            'Todo updated',
            'Todo marked as completed',
            'Todo assigned',
          ].includes(item?.activity)
        "
      >
        <div class="flex justify-between mt-3 items-center">
          <div class="text-xs">
            <span class="text-proxify-black">By</span>
            <span class="ml-1">
              {{ item?.user?.name || 'ATS' }}
            </span>
          </div>
          <router-link
            :to="{
              name: 'Applicant',
              params: { tab: 'todos', itemId: item?.todo?.id },
            }"
            class="text-proxify-primary"
          >
            View in Todos
          </router-link>
        </div>
      </div>

      <!-- Interview -->
      <div v-if="item?.interview">
        <div
          v-if="
            item?.activity === 'Interviewer Changed' &&
            Boolean(item?.properties?.old?.interviewer) &&
            Boolean(item?.properties?.attributes?.interviewer)
          "
          class="flex my-1.5"
        >
          <span class="text-disabled-normal">
            {{ item?.properties?.old?.interviewer?.name || item?.properties?.old?.interviewer }}
          </span>
          <div class="mx-1 -mt-0.5">&#10230;</div>
          <span>
            {{ item?.properties?.attributes?.interviewer?.name || item?.properties?.attributes?.interviewer }}
          </span>
        </div>
        <div v-else>
          <span class="text-proxify-black">
            {{ item?.interview?.name }} with
          </span>
          <span class="text-proxify-primary ml-1">
            {{
              item?.properties?.group_name ?? item?.interview?.interviewer?.name
            }}
          </span>
        </div>
        <div
          v-if="
            item?.activity !== 'Interview Filled' &&
            item?.activity !== 'Interview Updated'
          "
          class="text-proxify-black text-xs mt-3"
        >
          {{ formattedDate(item?.interview?.calendarEvent) }}
        </div>
        <div
          v-if="
            [
              'Interview Filled',
              'Interview Updated',
              'Interview Rescheduled',
              'Interviewer Changed',
              'Interview Created',
            ].includes(item?.activity)
          "
          class="flex justify-between mt-3 items-center"
        >
          <div class="text-xs">
            <span class="text-proxify-black">By</span>
            <span class="ml-1">
              {{ item?.user?.name || 'ATS' }}
            </span>
          </div>
          <router-link
            :to="{
              name: 'Applicant',
              params: { tab: 'interviews' },
            }"
            class="text-proxify-primary"
          >
            View in Interviews
          </router-link>
        </div>
      </div>

      <!-- Karat Interview -->
      <div v-if="item?.karat_interview">
        <div
          v-if="item?.activity === 'Scheduler link sent'"
          class="whitespace-normal"
        >
          Applicant invited to Technical Interview with Karat
          <div class="flex justify-between mt-3 items-center">
            <div class="text-xs">
              <span class="text-proxify-black">By</span>
              <span class="ml-1">
                {{ item?.user?.name || 'ATS' }}
              </span>
            </div>
            <router-link
              class="text-proxify-primary"
              :to="{
                name: 'Applicant',
                params: {
                  tab: 'interviews',
                  itemId: item?.karat_interview?.id,
                },
              }"
            >
              View in Interviews
            </router-link>
          </div>
        </div>
        <div
          v-else
          class="whitespace-normal"
        >
          Technical Interview with Karat
          <div class="flex justify-between mt-3 items-center">
            <div class="text-xs">
              {{ moment(item?.created_at).format('DD MMMM YYYY, hh:mm') }}
            </div>
            <router-link
              class="text-proxify-primary"
              :to="{
                name: 'Applicant',
                params: {
                  tab: 'interviews',
                  itemId: item?.karat_interview?.id,
                },
              }"
            >
              View in Interviews
            </router-link>
          </div>
        </div>
      </div>

      <div
        v-else-if="item?.activity === 'Applied'"
        class="text-proxify-primary text-xs mt-3"
      >
        {{ moment(item?.created_at).format('DD MMMM YYYY, hh:mm') }}
      </div>
      <div
        v-else-if="
          [
            'Rejected',
            'Unrejected',
            'Updated stage',
            'Sourced',
            'Automatic stage change',
            'Automatic rejection',
            'Autorejected idle gray-zone',
            'Autorejected duplication',
            'Grade Recalculated',
            'Email scheduled',
            'Scheduled email was sent',
            'Scheduled email was deleted',
            'Scheduler link sent',
            'Benchmarks Recalculated',
          ].includes(item?.activity)
        "
        class="text-xs mt-3"
      >
        <span class="text-proxify-black">By</span>
        <span class="ml-1">
          {{ item?.user?.name || 'ATS' }}
        </span>
      </div>
      <div
        v-else-if="item?.activity === 'Fields updated'"
        class="mt-3"
      >
        <AppAccordion>
          <template #activator="{ isExpanded }">
            <div class="flex justify-between items-center">
              <div class="text-xs">
                <span class="text-proxify-black">By</span>
                <span class="ml-1">
                  {{ item?.user?.name || 'ATS' }}
                </span>
              </div>
              <div class="flex text-proxify-primary">
                <IconBase
                  width="0.75"
                  class="mr-1 transition duration-300 ease-in-out"
                  :transform="isExpanded ? 'rotate(0)' : 'rotate(-180)'"
                >
                  <IconChevronUp />
                </IconBase>
                View details
              </div>
            </div>
          </template>
          <template #content>
            <div class="p-2">
              <div
                v-for="(value, name, index) in item?.properties?.attributes"
                :key="index"
                class="flex my-1.5 flex-wrap"
              >
                <div class="text-proxify-black first-letter:uppercase mr-1">
                  {{ name.replace('_', ' ') }}:
                </div>
                <div class="text-disabled-normal">
                  {{ formatItemprops(item?.properties?.old[name]) }}
                </div>
                <div class="mx-1 -mt-0.5">&#10230;</div>
                <div class="text-proxify-success">
                  {{ formatItemprops(value) }}
                </div>
              </div>
            </div>
          </template>
        </AppAccordion>
      </div>
    </AppCard>
  </div>
</template>

<script>
import IconBase from '@/components/Icons/IconBase';
import IconCalendar from '@/components/Icons/IconCalendar';
import IconChevronUp from '@/components/Icons/IconChevronUp';
import IconMagic from '@/components/Icons/IconMagic';
import IconSend from '@/components/Icons/IconSend';
import { dateTimeUtils } from '@/utils';
import moment from 'moment';

export default {
  name: 'ApplicantActivityHistoryMessage',
  components: {
    IconBase,
    IconCalendar,
    IconChevronUp,
    IconMagic,
    IconSend,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    maxWidth: {
      type: String,
      default: '100%',
    },
    stage: {
      type: Object,
      required: false,
    },
  },
  computed: {
    isAutomaticStageChange() {
      return this.item?.activity === 'Automatic stage change';
    },
    isAutomaticRejection() {
      return this.item?.activity === 'Automatic rejection';
    },
    isAutomaticEmailSent() {
      return (
        this.item?.activity === 'Email Sent to Candidate' ||
        (this.item?.activity === 'Scheduler link sent' &&
          !this.item?.karat_interview)
      );
    },
    cardTitle() {
      return this.item?.activity === 'Interviewer Changed'
        ? `${this.item?.interview?.name?.split(' ')[0]} ${this.item?.activity}`
        : this.item?.activity;
    },
  },
  created() {
    this.moment = moment;
  },
  methods: {
    formatItemprops(props) {
      if (!props) {
        return '" "';
      } else if (Array.isArray(props)) {
        return props.join(', ');
      }
      return props;
    },
    formattedNotes(notes) {
      return notes.slice(3).slice(0, -4);
    },
    formattedDate(date) {
      if (!date) return;
      return dateTimeUtils.formatDateTimeString(
        date.date,
        date.start_at,
        date.timezone,
        'DD MMM HH:mm'
      );
    },
  },
};
</script>
