<template>
  <div
    id="kanban-wrapper"
    ref="kanbanWrapper"
    class="ats-scrollbar-persistent !overflow-x-scroll"
  >
    <div class="flex flex-row grow w-max">
      <Container
        v-for="(container, index) in activeColumns"
        :key="container.id"
        :ref="`container-${container.id}`"
        :index="index"
        :container="container"
        :on-before-stage-change="onBeforeStageChange"
        :grabbed="isGrabbed"
        :container-height="containerHeight"
        :is-a-p-i-loading="isLoading"
        :data-testid="`container-${container.id}`"
        @paginate="paginate"
        @open-share-link-dialog="openShareLinkDialog"
        @drag="isGrabbed = true"
        @drop="isGrabbed = false"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useChangeCase } from '@vueuse/integrations/useChangeCase';
import { stringUtils } from '@/utils';

import Container from '@/components/Elements/Application/ApplicationStageColumn.vue';

const props = defineProps({
  onBeforeStageChange: {
    type: Function,
    required: true,
  },
  updateStageApplications: {
    type: Function,
    required: true,
  },
  containerHeight: {
    type: Number,
    required: true,
  },
  isLoading: {
    type: Boolean,
    required: true,
  },
});

const store = useStore();

const emit = defineEmits(['open-share-link-dialog']);
const isGrabbed = ref(false);

const containers = computed(() => store.state.application.containers);
const settings = computed(() => store.state.settings);
const stages = computed(() => store.state.applicant.stages);

const activeColumns = computed(() => {
  return (containers.value ?? []).filter((item) => settings.value?.showStages?.items[useChangeCase(item.name, 'camelCase').value].value);
});

const listenStageChannel = () => {
  window.Echo.channel('stages').listen('StageUpdated', (event) => {
    console.log(event);
    updateStageApplications(event);
  });
};

const paginate = (data) => {
  const updatedContainers = containers.value?.map((container) => {
    if (container.id === data.stage) {
      return {
        ...container,
        applications: [...container.applications, ...data.applications],
      };
    } else {
      return { ...container };
    }
  });
  store.commit('application/setContainers', updatedContainers);
};

const openShareLinkDialog = (applicantId) => {
  emit('open-share-link-dialog', applicantId);
};
</script>
