<script setup>
import { computed, ref, toRefs } from 'vue';
import { until } from '@vueuse/core';

import { BaseInputDropdown } from '@/components/Base';
import { useStore } from 'vuex';
import useCities from '@/composables/useCities';

const props = defineProps({
  city: {
    type: String,
    required: false,
  },
  countryCode: {
    type: String,
    required: false,
  },
  inputWidth: {
    type: String,
    default: '400px',
  },
  requiredFields: {
    type: Array,
    required: false,
    default() {
      return ['Country'];
    },
  },
  disable: {
    type: Boolean,
    default: false,
  },
});
const { city, countryCode, requiredFields } = toRefs(props);

const { cityList, fetchCities, clearCityList } = useCities();

const setInitialValues = async () => {
  await until(countries).toMatch((v) => v.length > 0);
  await until(countryCode).toBeTruthy();
  if (requiredFields.value.includes('City')) {
    await until(city).toBeTruthy();
  }
  selectedCity.value = city.value;
  selectedCountry.value = countries.value.find(
    ({ code }) => code === countryCode.value
  );
};

const store = useStore();
const countries = computed(() => store.state.applicant.countries);

const selectedCountry = ref(null);
const selectedCity = ref(null);
const countrySelector = ref(null);
const citySelector = ref(null);

const filteredCountries = ref([]);

const filterCountries = (value, update) => {
  if (value === '') {
    update(() => {
      filteredCountries.value = countries.value;
      clearCityList();
    });
    return;
  }

  const needle = value.toLowerCase();
  update(() => {
    filteredCountries.value = countries.value.filter((country) =>
      country.name.toLowerCase().includes(needle)
    );
  });
};

const filterCities = (value, update) => {
  if (value === '') {
    update(() => {
      clearCityList();
    });
    return;
  }

  update(async () => {
    await fetchCities(selectedCountry.value, value);
  });
};

const emit = defineEmits(['change']);

const handleChange = () => {
  if (selectedCity.value?.code && selectedCountry.value?.code) {
    emit('change', {
      country: selectedCountry.value.code,
      city: selectedCity.value.code,
    });
  }
};
const handleUpdateCountry = (newValue) => {
  if (!newValue) {
    selectedCountry.value = null;
    selectedCity.value = null;
    countrySelector.value.handleFocus();
    return;
  }
  selectedCountry.value = newValue;
  selectedCity.value = null;
  if (!requiredFields.value.includes('City')) {
    emit('change', {
      country: selectedCountry.value.code,
      city: selectedCity.value?.code,
    });
  }
  citySelector.value.handleFocus();
};
const handleUpdateCity = (newValue) => {
  if (!newValue) {
    selectedCity.value = null;
    citySelector.value.handleFocus();
    return;
  }
  selectedCity.value = newValue;
  handleChange();
};
setInitialValues();
</script>
<template>
  <div class="flex gap-2 base-country-selector">
    <div class="grow">
      <div class="text-body-sm font-medium text-proxify-gray-700 mb-2">
        <span>Country</span>
        <span v-if="requiredFields.includes('Country')">*</span>
      </div>
      <BaseInputDropdown
        ref="countrySelector"
        v-model="selectedCountry"
        name="Country"
        type="search"
        :options="filteredCountries"
        option-value="code"
        option-label="name"
        placeholder="Select Country"
        :on-filter="filterCountries"
        :input-width="inputWidth"
        :disable="disable"
        @update:model-value="handleUpdateCountry"
      ></BaseInputDropdown>
    </div>
    <div class="grow">
      <div class="text-body-sm font-medium text-proxify-gray-700 mb-2">
        <span>City</span>
        <span v-if="requiredFields.includes('City')">*</span>
      </div>
      <BaseInputDropdown
        ref="citySelector"
        v-model="selectedCity"
        name="City"
        type="search"
        :options="cityList"
        option-value="code"
        option-label="name"
        placeholder="Type City"
        :on-filter="filterCities"
        :input-width="inputWidth"
        :disable="disable"
        @update:model-value="handleUpdateCity"
      ></BaseInputDropdown>
    </div>
  </div>
</template>
<style scoped>
.base-country-selector {
  max-width: calc(v-bind(inputWidth) + v-bind(inputWidth) + 8px);
}
</style>
