<script setup>
import { computed, toRefs, defineAsyncComponent } from 'vue';
import { useChangeCase } from '@vueuse/integrations/useChangeCase';
import { PIcon } from '@/components/ProxifyUI';

const props = defineProps({
  color: {
    type: String,
    default: '',
  },
  left: {
    type: Boolean,
    default: false,
  },
  right: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String,
    default: '',
  },
  name: {
    type: String,
    default: '',
  },
  customClass: {
    type: String,
  },
});

const { name } = toRefs(props);

const icon = computed(() => {
  const iconName = useChangeCase(name.value, 'pascalCase').value;
  return defineAsyncComponent(
    () => import(`@/components/ProxifyUI/Icons/Icon${iconName}.vue`)
  );
});
</script>

<template>
  <PIcon
    :color="color"
    :left="left"
    :right="right"
    :size="size"
    :custom-class="customClass"
  >
    <component :is="icon" />
  </PIcon>
</template>
