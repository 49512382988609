export default {
  convertPixelsToRem: (px) => {
    const fontSize = parseFloat(
      getComputedStyle(document.documentElement).fontSize.replace('px', '')
    );
    return (px.value ?? px) / fontSize;
  },
  convertRemToPixels: (rem) => {
    const fontSize = parseFloat(
      getComputedStyle(document.documentElement).fontSize.replace('px', '')
    );
    return parseFloat(rem) * fontSize;
  },
};
