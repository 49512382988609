<script setup>
import { computed } from 'vue';
import ApplicantCard from '@/components/Elements/Applicant/ApplicantCard.vue';
import BaseMeterGroup from '@/components/Base/BaseMeterGroup.vue';
import ApplicantMonthlyRateBenchmark from '@/components/Elements/Applicant/ApplicantMonthlyRateBenchmark.vue';
import ApplicantRateBenchmark from '@/components/Elements/Applicant/ApplicantRateBenchmark.vue';
import TextData from '@/components/Elements/Scaffold/TextData.vue';
import BaseBadge from '@/components/Base/BaseBadge.vue';
import { currencyUtils } from '@/utils';

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
  isRateGuidanceEnabled: {
    type: Boolean,
    required: true,
  },
  monthlyRates: {
    type: Array,
    default: () => [],
  },
  hourlyRates: {
    type: Array,
    default: () => [],
  },
});

const showRateCard = computed(() => {
  const rate = props.data?.rate;
  return [
    rate?.monthly_rate,
    rate?.monthly_rate_updated,
    rate?.monthly_rate_confirmed,
    rate?.gig_overtime_hourly_rate,
    rate?.gig_overtime_hourly_rate_updated,
    rate?.gig_overtime_hourly_rate_confirmed,
    rate?.hourly_rate,
    rate?.hourly_rate_updated,
    rate?.hourly_rate_confirmed,
  ].some(Boolean);
});

const hasSignedRates = computed(() => {
  return (
    props.data?.rate?.gig_overtime_hourly_rate_confirmed ||
    props.data?.rate?.monthly_rate_confirmed
  );
});

const expectedMonthlyRate = computed(() => {
  return getRate('monthly_rate');
});

const expectedGigRate = computed(() => {
  return getRate('gig_overtime_hourly_rate');
});

const expectedHourlyRate = computed(() => {
  return getRate('hourly_rate');
});

const commitmentType = computed(() => {
  const type = props.data?.commitment_type;
  try {
    return JSON.parse(type).join(',\n');
  } catch {
    return '';
  }
});

const getCurrencySymbol = currencyUtils.getCurrencySymbol;

const monthlyRatePercentageChange = computed(() => {
  const expected = expectedMonthlyRate.value;
  const confirmed = props.data?.rate?.monthly_rate_confirmed;
  return calculatePercentageChange(expected, confirmed);
});

const gigRatePercentageChange = computed(() => {
  const expected = expectedGigRate.value;
  const confirmed = props.data?.rate?.gig_overtime_hourly_rate_confirmed;
  return calculatePercentageChange(expected, confirmed);
});

const recommendedGigRate = computed(() => {
  if (props.hourlyRates.length >= 3) {
    const currency = getCurrencySymbol(props.data?.rate?.currency);
    return `${currency}${props.hourlyRates[1]} - ${currency}${props.hourlyRates[2]}`;
  }
  return '';
});

const getRate = (type) =>
  props.data?.rate?.[`${type}_updated`] ?? props.data?.rate?.[type];

const calculatePercentageChange = (expectedRate, confirmedRate) => {
  if (!expectedRate || !confirmedRate) {
    return null;
  }

  const change = ((confirmedRate - expectedRate) / expectedRate) * 100;

  return new Intl.NumberFormat('en-US', {
    signDisplay: 'exceptZero',
    maximumFractionDigits: 0,
  }).format(change);
};
</script>

<template>
  <ApplicantCard
    v-if="showRateCard"
    title="Rate"
  >
    <div class="flex flex-col gap-2">
      <div v-if="isRateGuidanceEnabled && data?.stage?.id === 7">
        <div class="flex flex-col gap-2">
          <div
            v-if="hasSignedRates"
            class="flex flex-col gap-2 border-b border-proxify-gray-200 pb-3"
          >
            <div class="font-bold">Signed</div>
            <div class="flex gap-2">
              <TextData
                v-if="data?.rate?.monthly_rate_confirmed"
                title="Monthly Rate"
                :value="
                  getCurrencySymbol(data?.rate?.currency_confirmed) +
                  data?.rate?.monthly_rate_confirmed
                "
              />
              <BaseBadge
                v-if="
                  monthlyRatePercentageChange &&
                  monthlyRatePercentageChange !== '0'
                "
                :label="monthlyRatePercentageChange + '%'"
                class="!px-2"
                size="xs"
              />
            </div>
            <div class="flex gap-2">
              <TextData
                v-if="
                  data?.rate?.gig_overtime_hourly_rate_confirmed &&
                  expectedGigRate
                "
                title="Gig/overtime hourly rate"
                :value="
                  getCurrencySymbol(data?.rate?.currency_confirmed) +
                  data?.rate?.gig_overtime_hourly_rate_confirmed
                "
              />
              <BaseBadge
                v-if="
                  gigRatePercentageChange && gigRatePercentageChange !== '0'
                "
                :label="gigRatePercentageChange + '%'"
                class="!px-2"
                size="xs"
              />
            </div>
          </div>
          <div class="flex flex-col gap-2">
            <div class="pb-3 border-b border-proxify-gray-200">
              <div class="font-bold pb-2">Expected</div>
              <ApplicantMonthlyRateBenchmark
                :monthly-rate="expectedMonthlyRate"
                :currency="getCurrencySymbol(data?.rate?.currency)"
                :rates="monthlyRates"
              />
              <BaseMeterGroup
                :rates="monthlyRates"
                :value="expectedMonthlyRate"
                :unit="getCurrencySymbol(data?.rate?.currency)"
              />
              <TextData
                :value="
                  getCurrencySymbol(data?.rate?.currency) + expectedGigRate
                "
                title="Gig/overtime hourly rate"
                class="pt-2"
              />
            </div>
            <TextData
              v-if="commitmentType"
              title="Commitment"
              :value="commitmentType"
            />
          </div>
        </div>
      </div>

      <div v-else-if="isRateGuidanceEnabled && data?.stage?.id !== 7">
        <div class="flex flex-col gap-2">
          <div
            class="flex flex-col gap-2 border-b border-proxify-gray-200 pb-3"
          >
            <div class="font-bold">Expected by applicant</div>
            <ApplicantMonthlyRateBenchmark
              :monthly-rate="expectedMonthlyRate"
              :currency="getCurrencySymbol(data?.rate?.currency)"
              :rates="monthlyRates"
            />
            <TextData
              :value="getCurrencySymbol(data?.rate?.currency) + expectedGigRate"
              title="Gig/overtime hourly rate"
              class="pt-2"
            />
          </div>
          <div
            v-if="monthlyRates.length"
            class="pb-3 border-b border-proxify-gray-200"
          >
            <div class="text-proxify-gray-900 font-bold pb-2">
              Recommended by Proxify
            </div>
            <div class="text-proxify-black font-medium">Monthly Rate</div>
            <BaseMeterGroup
              :rates="monthlyRates"
              :value="expectedMonthlyRate"
              :unit="getCurrencySymbol(data?.rate?.currency)"
            />
            <TextData
              :value="recommendedGigRate"
              title="Gig/overtime Hourly Rate"
            />
          </div>
          <TextData
            v-if="commitmentType"
            title="Commitment"
            :value="commitmentType"
          />
        </div>
      </div>

      <div v-else>
        <div class="flex flex-col gap-2">
          <TextData
            v-if="data?.rate?.hourly_rate_confirmed"
            title="Confirmed Hourly Rate"
            :value="
              getCurrencySymbol(data?.rate?.currency_confirmed) +
              data?.rate?.hourly_rate_confirmed
            "
            verified
          />
          <TextData
            v-if="data?.rate?.monthly_rate_confirmed"
            title="Confirmed Monthly Rate"
            :value="
              getCurrencySymbol(data?.rate?.currency_confirmed) +
              data?.rate?.monthly_rate_confirmed
            "
            verified
          />
          <TextData
            v-if="expectedMonthlyRate"
            title="Expected Monthly Rate"
            :value="
              getCurrencySymbol(data?.rate?.currency) + expectedMonthlyRate
            "
          />
          <ApplicantRateBenchmark
            v-if="expectedHourlyRate && hourlyRates.length"
            :key="data?.id"
            :hourly-rate="expectedHourlyRate"
            :currency="getCurrencySymbol(data?.rate?.currency)"
            :rates="hourlyRates"
          />
          <TextData
            v-if="commitmentType"
            title="Commitment"
            :value="commitmentType"
          />
        </div>
      </div>
    </div>
  </ApplicantCard>
</template>

<style scoped></style>
