<script>
/**
 * @typedef { import('vue').Ref } Ref
 */

/**
 * Represents the links and attachments of an applicant.
 *
 * @typedef {Object} ApplicationSource
 * @property {?string} referrerEmail - Referrer email of the applicant.
 * @property {?('careers'|'sourced'|'referred')} source - Source of the applicant.
 * @property {?number} sourcerId - Sourcer ID of the applicant.
 * @property {?Object} trafficSource - Traffic source of the applicant.
 */

/**
 * Represents the profile of a user, including personal details and roles within the organization.
 *
 * @typedef {Object} User
 * @property {string} name - Full name of the staff member.
 * @property {string} email - Email address of the staff member.
 * @property {?string} avatar_url - URL of the avatar image. Null if no avatar is set.
 * @property {number} id - Unique identifier for the staff member.
 * @property {boolean} active - Indicates whether the staff member is currently active.
 * @property {string} alias - An alias used for the staff member.
 * @property {Role[]} roles - Array of roles assigned to the staff member.
 *
 * @typedef {Object} Role
 * @property {number} id - Unique identifier for the role.
 * @property {string} name - Technical name of the role.
 * @property {string} display_name - Human-readable name of the role.
 */
export default {
  name: 'ApplicationFormSource',
};
</script>
<script setup>
import {
  BaseInputDropdown,
  BaseInputField,
  BaseRadioGroup,
} from '@/components/Base';
import { PSeparator } from '@/components/ProxifyUI';
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';

const props = defineProps({
  referrerEmail: {
    type: String,
    required: false,
    default() {
      return '';
    },
  },
  source: {
    type: String,
    required: false,
    default() {
      return '';
    },
  },
  sourcerId: {
    type: Number,
    required: false,
    default: undefined,
  },
  trafficSource: {
    type: Object,
    required: false,
    default() {
      return null;
    },
  },
});

const emit = defineEmits([
  'update:referrer-email',
  'update:source',
  'update:sourcer-id',
]);

const store = useStore();
const sourcers = computed(() => store.state.applicant.sourcers);
const sources = computed(() => store.state.application.sources);
/** @type {Ref<ApplicationSource>} */
const form = ref({});
/** @type {Ref<User>} */
const currentUser = computed(() => store.state.auth.user);
const initialSource = computed(() => {
  const isTalentSourcer = (currentUser.value.roles ?? [])
    .map((role) => role.name)
    .includes('talent-sourcer');
  return isTalentSourcer ? currentUser.value.id : null;
});

watch(
  () => ({
    source: props.source,
    sourcerId: props.sourcerId,
    referrerEmail: props.referrerEmail,
  }),
  /** @param {ApplicationSource} newProps */
  (newProps) => {
    form.value = {
      source: newProps.source || (initialSource.value ? 'sourced' : undefined),
      sourcerId:
        !newProps.source || newProps.source === 'sourced'
          ? newProps.sourcerId ?? initialSource.value
          : null,
      referrerEmail:
        newProps.source === 'referred' ? newProps.referrerEmail : null,
    };
  },
  { immediate: true, deep: true }
);

watch(
  () => form.value,
  /** @param {ApplicationSource} newForm */
  (newForm) => {
    emit(
      'update:referrer-email',
      newForm.source === 'referred' ? newForm.referrerEmail : null
    );
    emit('update:source', newForm.source);
    emit(
      'update:sourcer-id',
      newForm.source === 'sourced' ? newForm.sourcerId : null
    );
  },
  { immediate: true, deep: true }
);
</script>
<template>
  <div>
    <div class="flex gap-4">
      <div>
        <div class="text-body-sm font-medium text-proxify-gray-700 mb-2">
          Applicant origin
        </div>
        <BaseRadioGroup
          v-model="form.source"
          name="Source"
          :options="
            sources.filter(({ value }) => trafficSource || value !== 'careers')
          "
          option-value="value"
          option-label="name"
          class="w-fit"
          :orientation="
            trafficSource && form.source !== 'careers'
              ? 'vertical'
              : 'horizontal'
          "
          data-testid="source-radio-group"
        />
      </div>
      <PSeparator
        v-show="!trafficSource && ['sourced', 'referred'].includes(form.source)"
        vertical
        class="h-6 mt-auto mb-2.5"
      />
      <div class="w-full">
        <div
          v-show="['sourced', 'referred'].includes(form.source)"
          class="text-body-sm font-medium text-proxify-gray-700 mb-2"
        >
          {{ form.source === 'sourced' ? 'Sourcer' : 'Referral e-mail' }}
        </div>
        <BaseInputDropdown
          v-show="form.source === 'sourced'"
          v-model="form.sourcerId"
          name="Sourcer"
          :options="sourcers"
          emit-value
        />
        <BaseInputField
          v-show="form.source === 'referred'"
          v-model="form.referrerEmail"
          name="Referral e-mail"
        />
      </div>
    </div>
  </div>
</template>
