import api from '@/api';
import moment from 'moment';
import axios from 'axios';

const state = {
  todos: [],
  applicationTodos: [],
  expeditedTodos: [],
  isTodosMenuActive: false,
  cancelRequest: null,
  isCreateTodoActive: false,
  selectedTodo: null,
};

const actions = {
  async fetchTodos({ commit, state }, parameters) {
    try {
      state.cancelRequest?.cancel();
      const request = axios.CancelToken.source();
      commit('setCancelToken', request);
      const { data: { data } = {} } = await api.todos.getTodos({
        params: parameters,
        cancelToken: state.cancelRequest.token,
      });
      commit('setCancelToken', null);
      commit('setGlobalTodos', data);
    } catch (error) {
      if (!axios.isCancel(error)) {
        console.error(error);
      }
    }
  },
  async fetchByApplication({ commit }, application_id) {
    const { data: { data } = {} } = await api.todos.getByApplication(
      application_id
    );
    commit('setApplicationTodos', data);
  },
  async fetchExpeditedTodos({ commit }, currentUserId) {
    const parameters = {
      is_completed: 0,
    };
    const { data: { data } = {} } = await api.todos.getTodos({ params: parameters });
    const expeditedTodos = data.filter(
      (todo) =>
        todo.assigned_to?.id === currentUserId &&
        todo.due_date &&
        moment(todo.due_date).isBefore(moment().add(1, 'days').startOf('day'))
    );
    commit('setExpeditedTodos', expeditedTodos);
  },
  async createTodo({ commit }, body) {
    const { data: { data } = {} } = await api.todos.post(body);
    commit('addTodo', data);
  },
  async updateTodo({ commit }, { body, todo_id }) {
    const { data: { data } = {} } = await api.todos.put(body, { todo_id });
    commit('updateTodo', data);
  },
  async deleteTodo({ commit }, { todo_id }) {
    await api.todos.delete({ todo_id });
    commit('deleteTodo', todo_id);
  },
};

const getters = {
  globalTodosCount: (state) => state.todos.length,
  expeditedTodosCount: (state) => state.expeditedTodos.length,
  getTodoById: (state) => (id) => state.todos.find((todo) => todo.id === id),
};

const mutations = {
  setTodosMenuStatus(state, options = {}) {
    const { active, isCreateTodoActive = false, todo = null } = options;
    state.isNotificationBarActive = false;
    state.isCreateTodoActive = isCreateTodoActive;
    state.selectedTodo = todo;
    state.isTodosMenuActive = active ?? !state.isTodosMenuActive;
  },
  setCreateTodoStatus(state, value) {
    state.isCreateTodoActive = value ?? !state.isCreateTodoActive;
  },
  setSelectedTodo(state, value) {
    state.selectedTodo = value;
  },
  setGlobalTodos(state, todos) {
    state.todos = todos;
  },
  setExpeditedTodos(state, todos) {
    state.expeditedTodos = todos;
  },
  setApplicationTodos(state, todos) {
    state.applicationTodos = todos;
  },
  addTodo(state, todo) {
    state.todos = [todo, ...state.todos];
  },
  updateTodo(state, updatedTodo) {
    const index = state.todos.findIndex((todo) => todo.id === updatedTodo.id);
    if (index !== -1) {
      state.todos[index] = updatedTodo;
    }
  },
  deleteTodo(state, todoId) {
    state.todos = state.todos.filter((todo) => todo.id !== todoId);
  },
  setCancelToken(state, request) {
    state.cancelRequest = request;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
