<template>
  <Form
    v-slot="{ handleSubmit }"
    ref="sendTemplateCardRef"
    as="app-card"
    class="mx-auto"
    value-class="w-[36rem]"
    width="auto"
  >
    <AppCardHeader class="mb-6">
      <AppCardTitle
        title="Schedule the message"
        value-class="flex justify-between w-full"
      >
        <IconBase
          class="text-proxify-black cursor-pointer mt-2"
          height="0.9"
          width="0.9"
          @click="onClose"
        >
          <IconCross />
        </IconBase>
      </AppCardTitle>
    </AppCardHeader>

    <AppDateTimePicker
      v-model="dateTimePickerValue"
      class="-mt-2"
    />

    <AppFormTextField
      v-model="subject"
      class="mt-4"
      label="Email Subject"
    />

    <div class="field-title">Edit text before sending</div>
    <AppFormTextEditor
      ref="messageEditor"
      v-model="messageText"
      :on-send-message="handleSendEmail"
      :on-attachment-added="onAttachmentAdded"
      :on-attachment-removed="onAttachmentRemoved"
      name="Messages Body"
      placeholder="Type in a message"
      :initial-value="initialValue"
      wrapper-classes="border !border-[#CCD1D7] !rounded-lg"
      required
      :style="{ marginTop }"
      disable-send-message
    />
    <AppCardFooter class="justify-between mt-8">
      <button
        class="font-semibold text-proxify-primary"
        @click="onClose"
      >
        Cancel
      </button>
      <button
        class="font-semibold text-proxify-primary"
        @click="handleSubmit($refs.messageEditor.handleSendMessage)"
      >
        Schedule
      </button>
    </AppCardFooter>
  </Form>
</template>

<script setup>
import IconBase from '@/components/Icons/IconBase.vue';
import IconCross from '@/components/Icons/IconCross.vue';
import { onMounted, ref } from 'vue';
import api from '@/api';

const props = defineProps({
  initialSubject: {
    type: Object,
    required: false,
    default() {
      return {};
    },
  },
  initialValue: {
    type: [String, Object],
    required: false,
  },
  onReceiveMessage: {
    type: Function,
    required: true,
  },
  clearContents: {
    type: Function,
    required: true,
  },
  onClose: {
    type: Function,
    required: true,
  },
  onConfirm: {
    type: Function,
    required: true,
  },
  getEmailSubjects: {
    type: Function,
    required: true,
  },
  subjects: {
    type: Array,
    required: false,
    default() {
      return null;
    },
  },
});

onMounted(() => {
  subject.value = props.initialSubject.name;
});

const dateTimePickerValue = ref('');
const marginTop = ref('unset');
const messageText = ref();
const sendTemplateCardRef = ref();
const subject = ref();
const uploadProgress = ref();

const onAttachmentAdded = () => {
  marginTop.value = '5.5rem';
};
const onAttachmentRemoved = () => {
  marginTop.value = 'unset';
};

const handleSendEmail = async (data, { id }) => {
  const {
    getEmailSubjects,
    onReceiveMessage,
    clearContents,
    onConfirm,
    subjects,
  } = props;
  data.append('subject', subject.value);
  data.append('send_at', dateTimePickerValue.value);
  const { data: response } = await api.applications.emails.post(data, {
    id,
    onUploadProgress,
  });
  if (!subjects.find((item) => item.subject === subject.value)) {
    getEmailSubjects();
  }
  onReceiveMessage(response.email);
  clearContents();
  onConfirm();
};

const onUploadProgress = ({ loaded, total }) => {
  uploadProgress.value = Math.ceil((loaded / total) * 100);
};
</script>

<style scoped>
.field-title {
  @apply text-proxify-black
  mt-4
  mb-2
  font-semibold;
}

.icon-eye {
  @apply mt-1 mx-2 inline-block text-white cursor-pointer;
}

.action-popup {
  @apply bg-white;
}

.action-popup .action-popup-title {
  @apply mb-4
  font-poppins
  text-xl;
}

:deep(.v-select .vs__search) {
  @apply h-9;
}
</style>
