<template>
  <div class="app-card-rating-data">
    <div :class="titleClass" class="font-semibold">{{ title }}</div>
    <div class="flex flex-row">
      <div class="flex flex-row mt-5 mr-5 stars">
        <StarRating :value="value" :spacing="0.25" :height="1" />
      </div>
    </div>
  </div>
</template>

<script>
import StarRating from '@/components/Icons/IconStarRating.vue';

export default {
  name: 'AppCardRatingData',
  components: {
    StarRating,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    titleClass: {
      type: [Object, String],
      required: false,
      default() {
        return { 'text-disabled-dark': true };
      },
    },
    value: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style scoped>
.app-card-rating-data {
  @apply flex flex-row;
}
</style>
