<script setup>
import PCheckbox from '@/components/ProxifyUI/PCheckbox.vue';
import BaseIcon from '@/components/Base/BaseIcon.vue';
import { useVModel } from '@vueuse/core';
import { computed, toRef } from 'vue';
import { useField } from 'vee-validate';

const props = defineProps({
  name: {
    type: String,
    required: false,
  },
  modelValue: {
    type: Boolean,
    required: false,
    default: () => false,
  },
  label: {
    type: String,
    required: false,
  },
  hint: {
    type: String,
    required: false,
  },
  size: {
    type: String,
    required: false,
    default: () => 'md',
  },
  disable: {
    type: Boolean,
    required: false,
    default: () => false,
  },
  required: {
    type: Boolean,
    required: false,
    default: () => false,
  },
});

const emit = defineEmits(['update:modelValue']);
const model = useVModel(props, 'modelValue', emit);

const checkedIconSize = computed(() => {
  if (props.size === 'lg') {
    return '16px';
  }
  return '12px';
});

const toggle = () => {
  if (props.disable) {
    return;
  }
  model.value = !model.value;
};

const { errorMessage, value, meta } = useField(
  toRef(props, 'name'),
  { required: props.required },
  {
    type: 'checkbox',
    uncheckedValue: false,
    valueProp: true,
    initialValue: model,
    label: props.label,
    validateOnValueUpdate: false,
    syncVModel: true,
  }
);

const checked = computed(() => model.value);

defineExpose({ meta });
</script>

<template>
  <div
    class="p-checkbox"
    :class="{
      large: props.size === 'lg',
      disable: props.disable,
      checked: checked,
    }"
    :data-name="name"
    @click.capture.stop="toggle"
  >
    <span class="p-checkbox-icon">
      <BaseIcon
        v-if="checked"
        name="check"
        :size="checkedIconSize"
      ></BaseIcon>
    </span>
    <PCheckbox v-model="value">
      <div class="flex items-center gap-[8px] select-none">
        {{ label }}
        <slot name="prepend"></slot>
      </div>
    </PCheckbox>
    <span
      v-if="errorMessage"
      class="p-checkbox-hint"
    >
      {{ errorMessage }}
    </span>
    <span
      v-else-if="props.hint"
      class="p-checkbox-hint"
    >
      {{ props.hint }}
    </span>
  </div>
</template>

<style lang="scss">
.p-checkbox {
  @apply font-inter;
  padding-left: 24px;
  position: relative;
  cursor: pointer;
  .q-checkbox__inner {
    display: none;
  }

  .q-checkbox__label {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #344054;
  }

  .p-checkbox-hint {
    display: block;
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    color: #475467;
    padding-top: 2px;
    width: 100%;
  }

  .p-checkbox-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    border: 1px solid #d0d5dd;
    border-radius: 4px;
    position: absolute;
    left: 0px;
    top: 2px;
    color: #5258fb;
  }

  &:hover {
    .p-checkbox-icon {
      border-color: #5258fb;
      background-color: #d5d5fe;
    }
  }

  &:active {
    .p-checkbox-icon {
      border-color: #5258fb;
      background-color: #d5d5fe;
      box-shadow: 0px 0px 0px 4px #f4ebff;
    }
  }

  &.checked {
    .p-checkbox-icon {
      border-color: #5258fb;
      background-color: #d5d5fe;
    }
  }

  &.disable {
    cursor: not-allowed;
    .p-checkbox-icon {
      border-color: #e0e0e0;
      background-color: #f5f5f5;
      color: #e0e0e0;
    }

    &:active {
      .p-checkbox-icon {
        border-color: #e0e0e0;
        background-color: #f5f5f5;
        box-shadow: none;
      }
    }
  }

  &.large {
    padding-left: 30px;

    .p-checkbox-icon {
      width: 20px;
      height: 20px;
      top: 1px;
    }

    .q-checkbox__label {
      font-size: 16px;
      line-height: 24px;
    }
  }
}
</style>
