<template>
  <div class="flex">
    <div class="flex flex-col gap-y-2 invisible">
      <div class="text-body-sm font-medium text-proxify-gray-600">
        Priority score
      </div>
      <div class="text-body-lg font-semibold flex items-center">
        <span
          v-if="hiringProbability || hiringProbability === 0"
          :class="hiringProbabilityColor"
        >
          {{ Math.round(hiringProbability) }}%
        </span>
        <AppLoader v-else-if="isLoading" />
        <div v-else>-</div>
        <BaseIcon
          ref="tooltipTargetElement"
          name="info-circle"
          size="12px"
          rounded
          class="cursor-pointer text-proxify-gray-500 ml-1"
        />
        <BaseTooltip
          tooltip-class="shadow-lg gap-[12px] !mt-4"
          max-width="216px"
          :target="tooltipTargetElement"
        >
          <div
            class="score-popup-text"
            :class="{
              'border-b pb-3 border-proxify-gray-200': adjustedScore !== 0,
            }"
          >
            Priority score automatically ranks applicant according to skills,
            experience, and location.
          </div>
          <div
            v-if="adjustedScore !== 0"
            class="flex flex-col gap-y-2"
          >
            <div class="score-popup-text pt-3">
              <span class="font-semibold">
                {{ adjustedScoreContext[adjustedScoreOrder]?.title }}
              </span>
              {{ adjustedScoreContext[adjustedScoreOrder]?.text }}
            </div>
            <div class="flex gap-1 text-body-md">
              <span class="font-normal text-proxify-gray-600">
                {{ profileProbability }}%
              </span>
              <div
                class="font-bold"
                :class="adjustedScoreContext[adjustedScoreOrder]?.textColor"
              >
                <span v-if="adjustedScore > 0">+</span>
                <span>{{ adjustedScore }}%</span>
              </div>
            </div>
          </div>
        </BaseTooltip>
      </div>
    </div>
    <div class="grow"></div>
    <div class="flex gap-x-6">
      <div
        v-for="(item, index) in context"
        :key="index"
        class="flex flex-col gap-y-2"
      >
        <div class="text-body-sm font-medium text-proxify-gray-600">
          {{ item.title }}
        </div>
        <div class="text-body-lg font-semibold text-proxify-gray-900">
          <span v-if="item.available">{{ item.value }}</span>
          <AppLoader v-else-if="isLoading" />
          <div v-else>-</div>
        </div>
        <BaseTooltip
          v-if="!item.available"
          tooltip-class="shadow-lg gap-[12px]"
          max-width="216px"
        >
          <div class="score-popup-title">
            {{ item.infoTitle }}
          </div>
          <div class="score-popup-text pt-4">
            {{ item.infoText }}
          </div>
        </BaseTooltip>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, toRefs, ref } from 'vue';
import { usePrecision } from '@vueuse/math';
import { isDefined } from '@vueuse/core';
import { BaseIcon, BaseTooltip } from '@/components/Base';
import moment from 'moment';

const props = defineProps({
  codilityScore: {
    type: Number,
    required: false,
  },
  englishScore: {
    type: String,
    required: false,
  },
  isLoading: {
    type: Boolean,
    required: false,
  },
  rating: {
    type: Number,
    required: false,
  },
  hiringProbability: {
    type: [String, Number],
    required: false,
  },
  profileProbability: {
    type: [String, Number],
    required: false,
  },
  appliedAt: {
    type: String,
    required: false,
  },
});

const { codilityScore, englishScore, rating } = toRefs(props);

const tooltipTargetElement = ref();

const context = computed(() => [
  {
    available: rating.value,
    title: 'Interviews',
    value: `${usePrecision(rating.value / 20, 2).value}/5`,
    infoTitle: 'Interviews score is unavailable',
    infoText:
      'The applicant has to go through at least one interview and the interviewer needs to fill in the scorecard for the score to be calculated.',
  },
  {
    available: isDefined(codilityScore),
    title: 'Tests',
    value: isDefined(codilityScore) ? `${usePrecision(codilityScore, 0).value}%` : null,
    infoTitle: 'Tests score is unavailable',
    infoText:
      'The applicant has to complete at least one test for the score to be calculated.',
  },
  {
    available: isDefined(englishScore),
    title: 'English',
    value: englishScore.value,
    infoTitle: 'English score is unavailable',
    infoText:
      'The applicant has to complete the video self-assessment or/and the interviewer needs to upload an audio recording to Google Drive for the English score to be calculated.',
  },
]);

const hiringProbabilityColor = computed(() => {
  if (props.hiringProbability >= 75) {
    return 'text-proxify-success-600';
  } else if (props.hiringProbability >= 40 && props.hiringProbability < 75) {
    return 'text-proxify-warning-500';
  } else if (props.hiringProbability < 40) {
    return 'text-proxify-error-500';
  }
  return '';
});

const adjustedScore = computed(() => {
  return props.profileProbability
    ? parseFloat(
        (props.hiringProbability - props.profileProbability).toFixed(2)
      )
    : 0;
});

const adjustedScoreOrder = computed(() => {
  if (adjustedScore.value > 0) {
    return 'adjustedUp';
  } else if (adjustedScore.value < 0) {
    return 'adjustedDown';
  }
  return '';
});

const adjustedScoreContext = computed(() => ({
  adjustedDown: {
    textColor: 'text-proxify-error-600',
    title: 'Score adjusted down.',
    text: `Main skill(s) currently in oversupply as of ${moment(
      props.appliedAt
    ).format('YYYY-MM-DD')}.`,
  },
  adjustedUp: {
    textColor: 'text-proxify-success-600',
    title: 'Score adjusted up.',
    text: `Main skill(s) currently in high demand as of ${moment(
      props.appliedAt
    ).format('YYYY-MM-DD')}.`,
  },
}));
</script>

<style scoped>
.score-popup-title {
  @apply text-body-sm
  font-semibold;
}

.score-popup-text {
  @apply text-body-xs
  font-normal;
}
</style>
