<template>
  <div class="flex flex-row justify-between w-full">
    <div
      class="text-disabled-dark"
      :class="{
        underline,
      }"
    >
      {{ title }}
    </div>
    <div
      class="text-right ml-1"
      :class="{
        underline,
        'text-proxify-success-500': value >= 75,
        'text-proxify-warning-500': value >= 40 && value < 75,
        'text-proxify-error-500': value < 40,
        'text-proxify-gray-400': isValueString,
      }"
    >
      <span>
        {{ value }}
      </span>
      <span v-if="!isValueString">%</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PercentageData',
  props: {
    title: {
      type: String,
      required: true,
      default() {
        return '';
      },
    },
    value: {
      type: [Number, String],
      required: true,
    },
    underline: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    isValueString() {
      return typeof this.value === 'string';
    },
  },
};
</script>
