<script setup>
import { computed, defineAsyncComponent, toRefs } from 'vue';
import { PAvatar, PSeparator } from '@/components/ProxifyUI';
import moment from 'moment';
const BaseDynamicFormQuestion = defineAsyncComponent(() =>
  import('@/components/Base/BaseDynamicFormQuestion.vue')
);
const BaseIcon = defineAsyncComponent(() =>
  import('@/components/Base/BaseIcon.vue')
);
const props = defineProps({
  questions: {
    type: Object,
    required: true,
  },
  answers: {
    type: Object,
    required: true,
  },
  readOnly: {
    type: Boolean,
    default: false,
  },
  currentInterviewer: {
    type: Object,
    required: false,
  },
  publishedAt: {
    type: String,
    required: false,
  },
});

const emit = defineEmits(['update:answers', 'update:field']);

const { answers } = toRefs(props);

const model = computed({
  get() {
    return answers.value;
  },
  set(value) {
    if (value === undefined) return;
    emit('update:answers', value);
  },
});
</script>
<template>
  <div class="font-inter w-full flex flex-col gap-[8px]">
    <PSeparator
      color="#EAECF0"
      class=""
    />
    <div
      v-if="readOnly"
      class="mt-[4px] flex gap-[8px] text-body-sm items-center"
    >
      <PAvatar size="xs">
        <img
          v-if="currentInterviewer.avatar_url"
          :src="currentInterviewer.avatar_url"
          :alt="currentInterviewer.name"
        />
        <BaseIcon
          v-else
          name="user01"
        />
      </PAvatar>
      <div class="font-medium text-proxify-gray-700">
        {{ publishedAt ? 'Published' : 'Draft saved' }} by
        {{ currentInterviewer.name }}
      </div>
      <div
        v-if="publishedAt"
        class="font-normal text-proxify-gray-600"
      >
        — {{ moment(publishedAt).format('dddd D MMM, HH:mm') }}
      </div>
    </div>
    <PSeparator
      v-if="readOnly"
      color="#EAECF0"
      class="mb-[8px] mt-[5px]"
    />
    <BaseDynamicFormQuestion
      v-for="(question, index) in questions"
      :key="`question_${index}`"
      v-model:answers="model"
      :question="question"
      :index="index"
      :has-section-label="question.firstInGroup"
      :has-subsection-label="question.firstInLabel"
      :read-only="readOnly"
      @update:field="(value) => $emit('update:field', value)"
    >
      <template #cross-check>
        <slot name="cross-check" />
      </template>
      <template #final-decision="{ question }">
        <slot
          name="final-decision"
          :question="question"
        />
      </template>
    </BaseDynamicFormQuestion>
  </div>
</template>
