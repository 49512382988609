<template>
  <div class="flex flex-wrap">
    <div
      v-for="(chip, index) in list"
      :key="index"
      class="chip"
      :style="`background-color: ${chip.color}`"
    >
      {{ chip.name }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default() {
        return [];
      },
    },
  },
};
</script>
<style scoped>
.chip {
  @apply m-0.5 px-2 py-1.5 rounded text-base font-semibold text-white;
}
</style>
