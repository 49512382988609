import moment from 'moment-timezone';

export default {
  /**
   * Returns the current timezone by correcting the name of Kyiv
   * @returns {String} The timezone city name
   */
  getTimezone: () => {
    const timezoneCity =
      moment.tz.guess().split('/').pop() === 'Kiev'
        ? 'Kyiv'
        : moment.tz.guess().split('/').pop();
    return timezoneCity;
  },

  /**
   * Returns the time difference between the current time and the input date
   * @param {Date} inputDate - The input date
   * @returns {String} The time difference in days or hours
   */
  getTimeDifference: (inputDate) => {
    const differenceInMinutes = moment().diff(moment(inputDate), 'minutes');
    const days = Math.floor(differenceInMinutes / 1440);
    const hours = Math.floor((differenceInMinutes % 1440) / 60);
    return days >= 1 ? `${days}d` : `${hours}h`;
  },

  /**
   * Calculates the relative class based on the elapsed time and total time
   * @param {Date} startDate - The start date
   * @param {Number} totalDays - The total number of days for the activity
   * @returns {Object} An object with the relative class
   */
  calculateRelativeClass: (startDate, totalDays) => {
    if (!startDate || !totalDays) return {};
    const now = moment();
    const elapsedTime = moment.duration(now.diff(startDate));
    const totalDuration = moment.duration(totalDays, 'days');
    const progress =
      elapsedTime.asMilliseconds() / totalDuration.asMilliseconds();
    const progressPercentage = progress * 100;

    return {
      'text-ats-red': progressPercentage >= 100,
      'text-proxify-warning-500':
        progressPercentage >= 50 && progressPercentage < 100,
      'text-proxify-success': progressPercentage < 50,
    };
  },

  /**
   * Formats the given date and time string using the specified timezone and format.
   *
   * @param {string} date - The date string in 'YYYY-MM-DD' format.
   * @param {string} time - The time string in 'HH:mm' format.
   * @param {string} timezone - The timezone string in IANA format (e.g., 'Europe/Kiev').
   * @param {string, undefined} format - The desired output format for the date and time string (e.g., 'YYYY-MM-DD').
   * @returns {string} The formatted date and time string according to the specified format and timezone.. Optional, defaults to ISO 8601 format.
   */
  formatDateTimeString: (date, time, timezone, format = undefined) => {
    const momentObject = moment.tz(`${date} ${time}`, timezone);
    return momentObject.format(format);
  },
  formatDateWithCalendar: (date, formatOptions = {}) => {
    if (!date) return null;
    const defaultOptions = {
      sameDay: '[Today], HH:mm',
      nextDay: '[Tomorrow], HH:mm',
      lastDay: '[Yesterday], HH:mm',
      lastWeek: 'DD MMM YYYY, HH:mm',
      nextWeek: 'DD MMM YYYY, HH:mm',
      sameElse: 'DD MMM YYYY, HH:mm',
    };
    const options = { ...defaultOptions, ...formatOptions };
    return moment(date).calendar(null, options);
  },

  /**
   * Formats the given total duration in seconds into a string representation of minutes and seconds.
   * The formatted string is in the format "mm.ss" where "mm" represents minutes and "ss" represents seconds.
   *
   * @param {number} totalSeconds - The total duration in seconds.
   * @returns {string} The formatted duration string.
   */
  formatDuration: (totalSeconds) => {
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = (totalSeconds % 60).toFixed();
    return `${minutes.toString().padStart(1, '0')}:${seconds
      .toString()
      .padStart(2, '0')}`;
  },
  convertMonthsToYearsAndMonths: (monthValue) => {
    const years = Math.floor(monthValue / 12);
    const months = Math.floor(monthValue % 12);
    if (years === 0) return `${months}m`;
    if (months === 0) return `${years}y`;

    return `${years}y ${months}m`;
  },
};
