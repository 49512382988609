export default {
  /**
   * Injects an item into an array at the specified position.
   * @param array
   * @param item
   * @param position
   * @return {*[]}
   */
  inject: function (array, item, position = 0) {
    return [...array.slice(0, position), item, ...array.slice(position + 1)];
  },
  /**
   * Inserts an item into an array at the specified position.
   * @param array
   * @param item
   * @param position
   * @return {*[]}
   */
  insert: function (array, item, position = 0) {
    return [...array.slice(0, position), item, ...array.slice(position)];
  },
  /**
   * Removes an item from an array at the specified position.
   * @param array
   * @param position
   * @return {*[]}
   */
  remove: function (array, position) {
    return [...array.slice(0, position), ...array.slice(position + 1)];
  },
  /**
   * Replaces an item in an array at the specified position.
   * @param array
   * @param id
   * @param updatedData
   * @return {*[]}
   */
  update: function (array, id, updatedData) {
    return array.map((item) =>
      item.id === id ? { ...item, ...updatedData } : item
    );
  },
};
