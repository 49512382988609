<script setup>
defineProps({
  indeterminate: {
    type: Boolean,
    default: false,
  },
  rounded: {
    type: Boolean,
    default: false,
  },
  color: {
    type: String,
    default: 'primary',
  },
  size: {
    type: String,
    default: 'md',
  },
  trackColor: {
    type: String,
    default: 'proxify-gray-100',
  },
  thickness: {
    type: Number,
    default: 0.25,
  },
  animationSpeed: {
    type: [Number, String],
    default: 300,
  },
});

const sizeVariants = {
  sm: '32px',
  md: '48px',
  lg: '56px',
  xl: '64px',
};
</script>
<template>
  <q-circular-progress
    :size="sizeVariants[size] ?? size"
    :indeterminate="indeterminate"
    :rounded="rounded"
    :color="color"
    :track-color="trackColor"
    :thickness="thickness"
    :animation-speed="animationSpeed"
  />
</template>
