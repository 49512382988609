import script from "./BaseCheckbox.vue?vue&type=script&setup=true&lang=js"
export * from "./BaseCheckbox.vue?vue&type=script&setup=true&lang=js"

import "./BaseCheckbox.vue?vue&type=style&index=0&id=6edf5dda&lang=scss"

const __exports__ = script;

export default __exports__
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCheckbox});
