import { computed } from 'vue';
import moment from 'moment-timezone';
import { useNow, toValue } from '@vueuse/core';
import { dateTimeUtils } from '@/utils';

export default function useTimeRemaining(calendarEvent) {
  const date = computed(() => toValue(calendarEvent)?.date);
  const startAt = computed(() => toValue(calendarEvent)?.start_at);
  const endAt = computed(() => toValue(calendarEvent)?.end_at);
  const timezone = computed(() => toValue(calendarEvent)?.timezone);
  const { now } = useNow({ interval: 1000, controls: true });
  const { formatDateTimeString } = dateTimeUtils;

  const timeRemaining = computed(() => {
    const start = moment(
      formatDateTimeString(date.value, startAt.value, timezone.value)
    );
    const end = moment(
      formatDateTimeString(date.value, endAt.value, timezone.value)
    );
    const timeUntilStart = start.diff(moment(now.value));
    const timeUntilEnd = end.diff(moment(now.value));

    let duration = '';
    let label = '';
    let status = '';
    if (timeUntilStart > 0) {
      // Event hasn't started yet
      duration = moment.duration(start.diff(moment(now.value)));
      status = 'upcoming';
      label = duration.humanize(true, { s: 0, ss: 0 });
    } else if (timeUntilStart <= 0 && timeUntilEnd > 0) {
      // Event is ongoing
      duration = moment.duration(end.diff(moment(now.value)));
      status = 'occurring';
      label = moment.utc(duration.asMilliseconds()).format('mm:ss');
    }
    return {
      duration,
      status,
      label,
      timeUntilStart,
      timeUntilEnd,
    };
  });

  return {
    timeRemaining,
  };
}
