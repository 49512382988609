<script setup>
import { computed, ref, nextTick } from 'vue';
import { useVModel } from '@vueuse/core';
import { useStore } from 'vuex';
import moment from 'moment-timezone';
import timezones from '@/constants/timezones.json';
import {
  BaseBadge,
  BaseBadgeGroupCollapsing,
  BaseIcon,
  BaseButton,
  BaseButtonDropdown,
} from '@/components/Base';
import { variables, relations } from '@/constants/filters';

const props = defineProps({
  codilityTests: {
    type: Array,
    default: () => [],
  },
  isLoading: {
    type: Boolean,
    required: false,
  },
  modelValue: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits([
  'update:modelValue',
  'update:filter-queries',
  'remove',
]);
const model = useVModel(props, 'modelValue', emit);

const store = useStore();

const variableDropdown = ref(null);
const relationDropdown = ref(null);
const valueDropdown = ref(null);

const timezoneList = timezones
  .map(({ name, tzCode }) => {
    const formattedOffset = moment()
      .tz(tzCode)
      .format('Z')
      .replace(/\+0/, '+')
      .replace(/-0/, '-')
      .replace(/:00/, '');
    return {
      id: tzCode,
      name: `${name} UTC${formattedOffset === '+0' ? '' : formattedOffset}`,
      offset: moment.tz(tzCode).utcOffset(),
    };
  })
  .sort((a, b) => a.offset - b.offset);

const countries = computed(() => store.state.applicant.countries);
const industries = computed(() => store.state.applicant.industries);
const interviewers = computed(() => store.state.application?.interviewers);
const recruiters = computed(() => store.state.applicant.recruiters);
const rejectionReasons = computed(() => store.state.applicant.rejectionReasons);
const skills = computed(() => store.state.applicant.skills);
const sourcers = computed(() => store.state.applicant.sourcers);
const sources = computed(() => store.state.application.sources);
const stages = computed(() => store.state.applicant.stages);
const users = computed(() => store.state.applicant.users);

const relationMenuOptions = computed(() => {
  if (!variable.value) {
    return [];
  }
  return variable.value?.possible_relations.map((item) => {
    const relation = relations.find(({ id }) => id === item);
    if (variable.value?.single_value) {
      return {
        ...relation,
        name: relation.name.replace('{value}', variable.value.single_value),
      };
    }
    return relation;
  });
});

const valueMenuOptions = computed(() => {
  if (
    !variable.value?.value ||
    variable.value?.type === 'date' ||
    variable.value?.type === 'numeric' ||
    variable.value?.type === 'text' ||
    !relation.value?.id
  ) {
    return [];
  }
  const arrayToMenuOptions = (array) => {
    return [
      { value: 'empty', name: 'Empty' },
      ...(array ?? []).map(({ name, id }) => ({ name, value: id })),
    ];
  };
  const answerType = variable.value?.answer_type;
  const optionsMap = {
    applicant_timezone: arrayToMenuOptions(timezoneList),
    recruiter: arrayToMenuOptions(recruiters.value?.recruiters ?? []),
    sourcer: arrayToMenuOptions(sourcers.value),
    codility_test: arrayToMenuOptions(props.codilityTests),
    tech_interviewer: arrayToMenuOptions([
      {
        name: 'Karat',
        id: 'karat',
      },
      ...Object.entries(interviewers.value?.groups || {}).map(([id, name]) => {
        return {
          name,
          id,
        };
      }),
      ...(interviewers.value?.interviewers ?? []),
    ]),

    stages: arrayToMenuOptions(stages.value),
    skills: arrayToMenuOptions(skills.value),
    industries: arrayToMenuOptions(industries.value),
    industry_experience: arrayToMenuOptions(industries.value),
    countries: [
      { value: 'empty', name: 'Empty' },
      ...countries.value.map(({ name, code, risky }) => ({
        name,
        value: code,
        risky,
      })),
    ],
    source: [{ value: 'empty', name: 'Empty' }, ...sources.value],
    users: [
      {
        name: 'ATS (Auto)',
        value: 0,
      },
      ...arrayToMenuOptions(users.value),
    ],
    rejection_reasons: [
      { value: 'empty', name: 'Empty' },
      ...rejectionReasons.value.map(({ name, code }) => ({
        name,
        value: code,
      })),
    ],
  };
  return optionsMap[answerType] ?? answerType;
});

const variableMenuOptions = computed(() => {
  return variables;
});

const variable = computed({
  get: () => {
    return variableMenuOptions.value.find(
      ({ value }) => value === model.value?.variable
    );
  },
  set: (value) => {
    model.value = {
      ...model.value,
      variable: value.value,
      relation: null,
      value: null,
    };
    nextTick(() => {
      model.value = {
        ...model.value,
        relation: relationMenuOptions.value[0]?.value,
      };
      relationDropdown.value.show();
    });
  },
});

const relation = computed({
  get: () => {
    return relationMenuOptions.value.find(
      ({ value, single_value }) =>
        value === model.value?.relation &&
        (variable.value?.single_value &&
        model.value?.value?.[0] === variable.value?.single_value
          ? Boolean(variable.value?.single_value) === single_value
          : true)
    );
  },
  set: (value) => {
    if (variable.value.single_value && value.single_value) {
      model.value = {
        variable: variable.value.value,
        relation: value.value,
        value: [variable.value.single_value],
      };
    } else {
      nextTick(() => {
        model.value = {
          ...model.value,
          relation: value.value,
          value:
            (model.value.value?.length > 1 && !value.allow_multiple) ||
            (model.value.value?.length < 2 && value.value === 'between') ||
            (model.value.value?.length === 1 &&
              model.value.value[0] === 'empty' &&
              !value.single_value)
              ? null
              : model.value.value,
        };
      });
    }
  },
});

const values = computed({
  get: () => {
    if (relation.value?.single_value) {
      return [{ value: variable.value.single_value }];
    }
    if (
      variable.value?.type === 'date' &&
      (model.value?.value ?? []).every(
        (value) =>
          moment.isMoment(value) || (isNaN(value) && moment(value).isValid())
      )
    ) {
      if (!model.value?.value?.length) return undefined;
      return model.value.value.map((value) => ({
        name: moment(value, moment.ISO_8601).format('MMM D, YYYY'),
        value: value,
      }));
    } else if (
      variable.value?.type === 'numeric' ||
      variable.value?.type === 'text'
    ) {
      if (!model.value?.value?.length) return undefined;
      return model.value.value.map((value) => ({
        name: value,
        value: value,
      }));
    }
    return (valueMenuOptions.value ?? []).filter((item) =>
      model.value?.value?.map(String).includes(String(item.value))
    );
  },
  set: (newValue) => {
    if (newValue === null) return;
    if (variable.value?.type === 'date') {
      const selectedDates =
        newValue.from && newValue.to ? Object.values(newValue) : [newValue];
      model.value = {
        ...model.value,
        value: selectedDates.map((date) => moment(date).format('YYYY-MM-DD')),
      };
    } else {
      const value = Array.isArray(newValue)
        ? newValue.map(({ value }) => value)
        : [newValue.value];
      model.value = {
        ...model.value,
        value: value.length ? value : null,
      };
    }
  },
});

defineExpose({
  showVariableDropdown: () => {
    variableDropdown.value.show();
  },
});
</script>
<template>
  <BaseBadge
    outline
    size="lg"
    class="cursor-pointer !bg-white border-proxify-gray-300 rounded-full overflow-hidden hover:bg-proxify-gray-50 !py-1.5"
  >
    <BaseButtonDropdown
      ref="variableDropdown"
      v-model="variable"
      :options="variableMenuOptions"
      map-options
      option-value="value"
      data-testid="variable-dropdown"
    >
      <template #activator>
        <BaseBadge
          color="transparent"
          class="!text-proxify-primary-600 !p-0"
        >
          {{ variable?.name ?? 'Something' }}
        </BaseBadge>
      </template>
    </BaseButtonDropdown>
    <BaseButtonDropdown
      ref="relationDropdown"
      v-model="relation"
      :options="relationMenuOptions"
      map-options
      option-value="value"
      :disable="!variable?.value"
      :search="false"
      menu-width="174px"
      data-testid="relation-dropdown"
      @change="
        (value) =>
          nextTick(() => (!value.single_value ? valueDropdown.show() : null))
      "
    >
      <template #activator>
        <BaseBadge>
          {{ relation?.name ?? 'is' }}
          <template #append>
            <BaseIcon
              name="chevron-down"
              size="12px"
              class="text-[#f0f0f0] rounded-full"
              :class="{
                'bg-proxify-primary-600': variable?.value,
                'bg-proxify-gray-400': !variable?.value,
              }"
            />
          </template>
        </BaseBadge>
      </template>
    </BaseButtonDropdown>
    <BaseButtonDropdown
      v-show="!relation?.single_value"
      ref="valueDropdown"
      v-model="values"
      :options="valueMenuOptions"
      map-options
      option-value="value"
      :disable="!relation?.value"
      :multiple="
        relation?.allow_multiple &&
        (variable?.type === 'list' || relation.value === 'between')
      "
      :type="variable?.type"
      :range="variable?.range"
      data-testid="value-dropdown"
    >
      <template #activator>
        <BaseBadgeGroupCollapsing
          v-if="relation?.allow_multiple && variable?.type === 'list'"
          :items="
            values.length
              ? values.map(({ name }) => name).filter((_, i) => i < 1)
              : ['Something']
          "
          :secondary-items="
            values.map(({ name }) => name).filter((_, i) => i > 0)
          "
          no-padding
          class="flex-wrap !gap-[4px]"
          :badge-class="{
            '!font-normal': true,
            'cursor-pointer': relation?.value,
          }"
          :title="null"
        />
        <BaseBadge
          v-else
          color="transparent"
          class="!p-0"
          :class="{
            '!text-proxify-primary-600': relation?.value,
            '!text-proxify-gray-400': !relation?.value,
            'cursor-pointer': relation?.value,
          }"
        >
          {{
            values && values.length
              ? values.map(({ name }) => name).join(' - ')
              : 'Something'
          }}
        </BaseBadge>
      </template>
    </BaseButtonDropdown>
    <BaseButton
      icon-prepend="x-close"
      round
      class="min-w-0 min-h-0"
      data-testid="remove-filter-button"
      @click="emit('remove')"
    />
  </BaseBadge>
</template>
