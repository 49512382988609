<template>
  <div
    ref="hintContainer"
    class="hint-container"
    :class="!absolute && 'relative'"
  >
    <UseComputedPositions
      v-slot="{ positions }"
      :floating-element="content"
      :target-element="targetElement ?? activator"
      :platform-element="absolute ? platformElement : hintContainer"
      :menu-placement="menuPlacement"
      :menu-offset-x="menuOffsetX"
      :menu-offset-y="menuOffsetY"
      :positioning-strategy="absolute ? 'absolute' : 'relative'"
      :menu-placement-spacing="menuPlacementSpacing"
      :update-target-rect-on-scroll="updateTargetRectOnScroll"
    >
      <div ref="activator">
        <slot name="activator" />
      </div>
      <Transition>
        <div
          v-if="active"
          ref="content"
          class="hint p-4"
          :style="{ ...positions }"
        >
          <slot name="content" />
        </div>
      </Transition>
    </UseComputedPositions>
  </div>
</template>

<script>
import { ref } from 'vue';
import { UseComputedPositions } from '@/composables/useComputedPositions/component';

export default {
  name: 'AppPopup',
  components: {
    UseComputedPositions,
  },
  props: {
    active: {
      type: Boolean,
      required: true,
    },
    updateTargetRectOnScroll: {
      type: Boolean,
      required: false,
    },
    absolute: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    targetElement: {
      required: false,
    },
    platformElement: {
      required: false,
    },
    menuPlacement: {
      type: String,
      required: false,
      default() {
        return 'right-start';
      },
    },
    menuPlacementSpacing: {
      type: Number,
      required: false,
    },
    menuOffsetX: {
      type: Number,
      required: false,
    },
    menuOffsetY: {
      type: Number,
      required: false,
    },
  },
  setup() {
    const content = ref(null);
    const activator = ref(null);
    const hintContainer = ref(null);
    return {
      content,
      activator,
      hintContainer,
    };
  },
};
</script>

<style scoped>
.hint {
  @apply flex
  absolute
  bg-white
  my-auto
  rounded-md
  max-w-64
  z-10
  shadow;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.4s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
