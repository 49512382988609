import axios from 'axios';
import api from '@/api';

const TOKEN_KEY = 'token';
const USER_KEY = 'user';

const authService = {
  getToken() {
    return localStorage.getItem(TOKEN_KEY);
  },
  setToken(token) {
    localStorage.setItem(TOKEN_KEY, token);
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  },
  clearToken() {
    localStorage.removeItem(TOKEN_KEY);
    delete axios.defaults.headers.common['Authorization'];
  },
  getUser() {
    return JSON.parse(localStorage.getItem(USER_KEY));
  },
  setUser(user) {
    localStorage.setItem(USER_KEY, JSON.stringify(user));
  },
  clearUser() {
    localStorage.removeItem(USER_KEY);
  },
};

const state = {
  user: authService.getUser() || {},
  token: authService.getToken() || '',
};

const getters = {
  isLogged: (state) => Boolean(state.token),
  token: (state) => state.token,
};

const actions = {
  async login({ commit, dispatch }, parameters) {
    const response = await api.auth.login(parameters);
    commit('login', response.data);
    authService.setToken(response.data.token);
    authService.setUser(response.data.user);
    dispatch('notifications/fetchNotifications', null, { root: true });
    dispatch('notifications/subscribeNotifications', null, { root: true });
    dispatch('todos/fetchExpeditedTodos', response.data?.user?.id, {
      root: true,
    });

    return response.data;
  },
  logout({ commit, dispatch }) {
    return new Promise((resolve) => {
      dispatch('notifications/unsubscribeNotifications', null, { root: true });
      commit('logout');
      authService.clearToken();
      authService.clearUser();
      resolve();
    });
  },
};

const mutations = {
  login(state, data) {
    authService.setToken(data.token);
    authService.setUser(data.user);
    state.user = data.user;
    state.token = data.token;
  },
  logout(state) {
    state.user = {};
    state.token = '';
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
