<template>
  <div>
    <div
      ref="todosContainer"
      class="ats-scrollbar-persistent scroll-smooth"
    >
      <div class="my-4 mx-3 p-2">
        <div class="pb-6 pt-2">
          <div
            v-for="(todos, status, j) in computedTodos"
            v-show="status === 'Active' || todos.length"
            :key="`${status}-${j}`"
            class="bg-white py-4 pl-1.5 pr-2.5 rounded border-proxify-black/5"
            :class="{ 'pb-8': status === 'Finished' }"
          >
            <div class="px-3 pb-4 font-semibold pointer-events-none">
              {{ status }}
            </div>
            <div
              v-if="status === 'Active' && !selectedTodo"
              class="ml-3 mb-3 text-proxify-primary cursor-pointer"
              @click="handleOpenTodoInput()"
            >
              + New ToDo
            </div>

            <TransitionGroup
              :css="false"
              @before-enter="onBeforeEnter"
              @enter="onEnter"
              @leave="onLeave"
            >
              <div
                v-for="(item, k) in todos"
                v-show="isExpanded || k < 2 || status === 'Active'"
                :id="item.id"
                :key="`${item.id}-${k}`"
                v-element-hover="(state) => onHoverItem(state, item.id)"
                class="flex items-center justify-between"
                :class="{
                  'hover:bg-[#d9d9d9]/10': !selectedTodo,
                  highlighted: isTodoHighlighted(item.id) && !selectedTodo,
                }"
              >
                <ApplicantActivityTodosInput
                  v-if="selectedTodo && selectedTodo.id === item.id"
                  v-model="selectedTodo"
                  :handle-create-or-update-todo="handleCreateOrUpdateTodo"
                  :on-clear-input="onClearInput"
                />
                <AppFormCheckbox
                  v-else
                  v-model="item.is_completed"
                  :name="`${j}_${item.id}`"
                  :label="item.body"
                  disable-label-click
                  :disabled="
                    item.created_by.id !== currentUser.id &&
                    item.assigned_to.id !== currentUser.id
                  "
                  wrapper-classes="!items-start pb-2"
                  :initial-value="{
                    checked: item.is_completed,
                    body: item.body,
                  }"
                  class="text-sm w-full max-w-[70%]"
                  @update:model-value="
                    (is_completed) =>
                      handleCreateOrUpdateTodo({
                        todo_id: item.id,
                        is_completed,
                      })
                  "
                >
                  <template #label>
                    <div class="mt-2 ml-2">
                      <div
                        :class="{
                          'line-through':
                            item.is_completed &&
                            selectedTodo?.id !== item.id &&
                            !isRevealed,
                        }"
                      >
                        {{ item.body }}
                      </div>
                      <div class="flex gap-2">
                        <span
                          v-if="item.created_by.id !== item.assigned_to.id"
                          class="flex items-center text-xs text-disabled-normal font-medium gap-1"
                        >
                          Created by
                          <AppAvatar
                            :name="item.created_by.name"
                            :avatar_url="item.created_by.avatar_url"
                            :alias="item.created_by.alias"
                          ></AppAvatar>
                        </span>
                        <span
                          class="flex items-center text-xs text-disabled-normal font-medium gap-1"
                        >
                          {{
                            item.is_completed ? 'Completed by' : 'Assigned to'
                          }}
                          <AppAvatar
                            :name="
                              item.is_completed && item.completed_by
                                ? item.completed_by?.name
                                : item.assigned_to?.name
                            "
                            :avatar_url="
                              item.is_completed && item.completed_by
                                ? item.completed_by?.avatar_url
                                : item.assigned_to?.avatar_url
                            "
                            :alias="
                              item.is_completed && item.completed_by
                                ? item.completed_by?.alias
                                : item.assigned_to?.alias
                            "
                          ></AppAvatar>
                        </span>
                      </div>
                      <span
                        v-if="item.due_date"
                        class="flex items-center text-xs text-disabled-normal -ml-0.5 mt-2"
                        :class="{
                          '!text-proxify-warning-500': moment(
                            item.due_date
                          ).isSame(moment(), 'days'),
                          '!text-ats-red': moment(item.due_date).isBefore(
                            moment(),
                            'day'
                          ),
                          '!text-proxify-success': moment(item.due_date).isSame(
                            moment().add(1, 'days'),
                            'day'
                          ),
                        }"
                      >
                        <IconBase
                          v-if="
                            moment(item.due_date).isBetween(
                              moment().subtract(1, 'days'),
                              moment().add(1, 'days'),
                              'day',
                              '[]'
                            )
                          "
                          border-radius="0%"
                          width="0.9"
                          height="0.9"
                        >
                          <IconCalendar />
                        </IconBase>
                        {{
                          moment(item.due_date).calendar(null, {
                            sameDay: '[Today]',
                            nextDay: '[Tomorrow]',
                            lastDay: '[Yesterday]',
                            sameElse: 'On DD MMMM YYYY',
                          })
                        }}
                      </span>
                    </div>
                  </template>
                </AppFormCheckbox>
                <BaseButton
                  v-if="
                    item.assigned_to?.id === currentUser.id ||
                    item.created_by?.id === currentUser.id
                  "
                  icon-prepend="dots-vertical"
                  round
                  outline
                  class="text-proxify-gray-600"
                  size="8px"
                  icon-size="20px"
                  @click.stop
                >
                  <BaseMenu
                    :items="[
                      {
                        label: 'Edit',
                        type: 'item',
                        props: {
                          onClick: () => handleOpenTodoInput(item),
                          clickable: true,
                        },
                      },
                      {
                        label: 'Delete',
                        type: 'item',
                        props: {
                          onClick: () => deleteItem(item),
                          clickable: true,
                        },
                      },
                    ]"
                  />
                </BaseButton>
              </div>
            </TransitionGroup>
            <div
              v-if="status === 'Finished' && todos.length > 2"
              class="px-3 text-proxify-primary cursor-pointer"
              @click="isExpanded = !isExpanded"
            >
              Show
              {{ !isExpanded ? `more (+${todos.length - 2})` : 'less' }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <PDialog
      v-model="isRevealed"
      position="standard"
      persistent
    >
      <BaseConfirmationCard
        :on-confirm="confirm"
        :on-close="cancel"
        confirm-button-text="Delete Task"
        cancel-button-text="Go back"
        cancel-button-state="primary"
        confirm-button-state="danger"
      >
        <template #title>Delete task?</template>
        This task will be deleted and cannot be restored
      </BaseConfirmationCard>
    </PDialog>
  </div>
</template>

<script>
import { computed, nextTick, ref, watchEffect } from 'vue';
import { useStore } from 'vuex';
import { useRouteParams } from '@vueuse/router';
import api from '@/api';
import { vElementHover } from '@vueuse/components';
import { useConfirmDialog } from '@vueuse/core';
import ApplicantActivityTodosInput from '@/components/Elements/Applicant/ApplicantActivityTodosInput.vue';
import IconBase from '@/components/Icons/IconBase.vue';
import { BaseButton, BaseMenu, BaseConfirmationCard } from '@/components/Base';
import moment from 'moment';
import IconCalendar from '@/components/Icons/IconCalendar.vue';
import { objectUtils } from '@/utils';
import useScrollToItem from '@/composables/useScrollToItem';
import { useRoute, useRouter } from 'vue-router';
import { gsap } from 'gsap';
import { PDialog } from '@/components/ProxifyUI';

export default {
  name: 'ApplicantActivityTodos',
  components: {
    IconCalendar,
    ApplicantActivityTodosInput,
    IconBase,
    BaseButton,
    BaseMenu,
    BaseConfirmationCard,
    PDialog,
  },
  directives: {
    elementHover: vElementHover,
  },
  setup() {
    const store = useStore();
    const isExpanded = ref(false);
    const isMenuActive = ref(false);
    const isNewInputActive = ref(true);
    const todos = ref([]);
    const application_id = useRouteParams('id');
    const hoveredTodo = ref(null);
    const selectedTodo = ref(null);
    const selectedTodoInitialValue = ref(null);
    const menuTrigger = ref(null);
    const todosContainer = ref(null);
    const currentUser = computed(() => store.state.auth.user);
    const itemId = useRouteParams('itemId');
    const route = useRoute();
    const router = useRouter();
    const itemToDelete = ref();

    const { reveal, isRevealed, cancel, confirm, onConfirm, onCancel } =
      useConfirmDialog();

    onConfirm(async () => {
      const { data } = await api.todos.delete({
        application_id: application_id.value,
        todo_id: itemToDelete.value.id,
      });
      todos.value = [
        ...todos.value.filter(({ id }) => itemToDelete.value.id !== id),
      ];
      store.commit('ui/addSnackbarMessage', {
        title: data.response,
        type: 'success',
      });
    });

    onCancel(() => {
      selectedTodo.value = null;
      selectedTodoInitialValue.value = null;
    });
    watchEffect(async () => {
      const { data: response } = await api.todos.getByApplication(
        application_id.value
      );
      todos.value = response.data;
    });

    const computedTodos = computed(() => {
      return {
        Active: todos.value.filter(({ is_completed }) => !is_completed),
        Finished: todos.value.filter(({ is_completed }) => is_completed),
      };
    });

    const handleOpenTodoInput = (item = {}) => {
      selectedTodo.value = structuredClone(item);
      selectedTodoInitialValue.value = structuredClone(item);
      if (!item.id) {
        todos.value = [selectedTodo.value, ...todos.value];
      }
    };

    const onClearInput = () => {
      selectedTodo.value = null;
      selectedTodoInitialValue.value = null;
      todos.value = todos.value.filter(
        ({ id }) => id !== null && typeof id !== 'undefined'
      );
    };
    const handleCreateOrUpdateTodo = async ({
      todo_id,
      is_completed,
      assigned_to,
      due_date,
    }) => {
      try {
        let newTodoItem;
        let updatedTodoItem;
        if (selectedTodo.value?.id || todo_id) {
          const { filterChanges } = objectUtils;
          const changes = filterChanges(
            selectedTodoInitialValue.value ?? {},
            selectedTodo.value ?? {}
          );
          const { data: response } = await api.todos.put(
            todo_id
              ? { is_completed, assigned_to: assigned_to?.id, due_date }
              : {
                  ...changes,
                  ...(changes?.due_date
                    ? {
                        due_date: moment(changes.due_date).format('YYYY-MM-DD'),
                      }
                    : {}),
                  assigned_to: changes?.assigned_to?.id,
                },
            {
              application_id: application_id.value,
              todo_id: selectedTodo.value?.id ?? todo_id,
            }
          );
          updatedTodoItem = response.data;
        } else {
          const { data: response } = await api.todos.post({
            ...selectedTodo.value,
            assigned_to: selectedTodo.value.assigned_to?.id,
            application_id: application_id.value,
          });
          newTodoItem = response.data;
        }
        const newItem = newTodoItem || updatedTodoItem;

        if (newItem) {
          const updatedTodos = todos.value.map((item) => {
            return item.id === newItem.id ? { ...item, ...newItem } : item;
          });

          todos.value = [
            ...(newTodoItem ? [newTodoItem] : []),
            ...updatedTodos,
          ];
        } else if (selectedTodo.value) {
          todos.value = todos.value.map((item) => {
            return item.id === selectedTodo.value.id
              ? { ...item, ...selectedTodo.value }
              : item;
          });
        }
        isNewInputActive.value = false;
        onClearInput();
      } catch (error) {
        console.log(error);
      }
    };

    const onHoverItem = (state, todoId) => {
      if (isMenuActive.value) return;
      hoveredTodo.value = state
        ? todos.value.find(({ id }) => id === todoId)
        : null;
    };

    const shouldExpandFinishedTodo = async (todoId) => {
      await nextTick();
      const { Finished } = computedTodos.value;
      const targetTodoIndex = Finished.findIndex(
        (todo) => todo.id === Number(todoId)
      );
      return targetTodoIndex > 2;
    };

    const isTodoHighlighted = (todoId) => {
      return itemId.value === String(todoId);
    };

    useScrollToItem(
      todosContainer,
      itemId,
      { behavior: 'auto' },
      (todoId) => {
        if (shouldExpandFinishedTodo(todoId)) {
          isExpanded.value = true;
        }
      },
      () =>
        router.push({
          params: {
            ...route.params,
            itemId: undefined,
          },
        })
    );

    const onBeforeEnter = (element) => {
      element.style.opacity = 0;
    };

    const onEnter = (element, done) => {
      gsap.to(element, {
        opacity: 1,
        delay: element.dataset.index * 0.15,
        onComplete: done,
      });
    };

    const onLeave = (element, done) => {
      gsap.to(element, {
        opacity: 0,
        delay: element.dataset.index * 0.15,
        onComplete: done,
      });
    };

    const deleteItem = (item) => {
      itemToDelete.value = item;
      reveal();
    };

    return {
      menuTrigger,
      todosContainer,
      handleCreateOrUpdateTodo,
      handleOpenTodoInput,
      moment,
      computedTodos,
      currentUser,
      selectedTodo,
      isExpanded,
      isMenuActive,
      isNewInputActive,
      onClearInput,
      onHoverItem,
      hoveredTodo,
      reveal,
      isRevealed,
      confirm,
      cancel,
      isTodoHighlighted,
      onBeforeEnter,
      onEnter,
      onLeave,
      deleteItem,
    };
  },
};
</script>

<style scoped>
@keyframes highlight {
  0% {
    background-color: rgb(88 194 162 / 40%);
  }

  99% {
    background-color: transparent;
  }
}

.highlighted {
  animation: highlight 3s ease-out forwards;
}
</style>
