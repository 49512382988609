<script setup>
defineProps({
  fontSize: {
    type: String,
  },
  color: {
    type: String,
  },
  textColor: {
    type: String,
  },
  icon: {
    type: String,
  },
  square: {
    type: Boolean,
  },
  rounded: {
    type: Boolean,
  },
  size: {
    type: String,
  },
});

const sizeVariants = {
  '2xs': '16px',
  xs: '24px',
  sm: '32px',
  md: '40px',
  lg: '48px',
  xl: '56px',
  '2xl': '64px',
};
</script>
<template>
  <q-avatar
    :size="sizeVariants[size] ?? size"
    :font-size="fontSize"
    :color="color"
    :text-color="textColor"
    :icon="icon"
    :square="square"
    :rounded="rounded"
    class="bg-proxify-gray-100 text-proxify-gray-600"
  >
    <slot />
  </q-avatar>
</template>
