<template>
  <span>{{ time }}</span>
</template>

<script setup>
import moment from 'moment-timezone';
import { onUnmounted, onBeforeMount, ref } from 'vue';

const props = defineProps({
  format: {
    type: String,
    default: 'HH:mm',
  },
  timezone: {
    type: String,
    required: false,
  },
});

const interval = ref();
const time = ref(moment.tz(props.timezone).format(props.format));

onUnmounted(() => {
  clearInterval(interval.value);
});

onBeforeMount(() => {
  interval.value = setInterval(() => {
    time.value = moment.tz(props.timezone).format(props.format);
  }, 1000);
});
</script>
