<template>
  <div class="!pb-0">
    <div class="absolute ml-9">
      <Logo />
    </div>
    <div class="flex flex-col justify-end h-screen ml-9">
      <div class="flex flex-row my-auto">
        <ResetBox />
      </div>
      <div class="flex justify-end">
        <span class="text-proxify-black text-heading-3 px-8 pb-4">
          Made with ❤️ by Proxify
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import Logo from '@/components/Elements/Logo.vue';
import ResetBox from '@/components/Elements/Login/ResetBox.vue';
import titleMixin from '@/mixins/titleMixin';

export default {
  name: 'ResetPassword',
  title: 'Reset Password',
  components: {
    Logo,
    ResetBox,
  },
  mixins: [titleMixin],
};
</script>
