<script setup>
import BaseIcon from '@/components/Base/BaseIcon.vue';
import { useField } from 'vee-validate';
import { useVModel } from '@vueuse/core';
import { computed, onMounted, ref, toRefs } from 'vue';

const props = defineProps({
  modelValue: {
    type: [Number, String],
    required: false,
  },
  name: {
    type: String,
    required: false,
  },
  readOnly: {
    type: Boolean,
    required: false,
    default() {
      return false;
    },
  },
  required: {
    type: Boolean,
    required: false,
    default() {
      return false;
    },
  },
  size: {
    type: String,
    required: false,
    default() {
      return '20px';
    },
  },
});

const hoveredElementIndex = ref();
const isLoaded = ref(true);
const pressedStarIndex = ref(0);
const { name, readOnly } = toRefs(props);

const emit = defineEmits(['update:modelValue']);
const model = useVModel(props, 'modelValue', emit);

const { value, errorMessage, handleChange, meta } = useField(
  name,
  { required: props.required },
  {
    initialValue: model,
    validateOnValueUpdate: false,
    syncVModel: true,
  }
);

onMounted(() => {
  hoveredElementIndex.value = Number((value.value / 20).toFixed());
  isLoaded.value = false;
});

const normalizedRating = computed(() => {
  return Number((value.value / 20).toFixed());
});

const onHoverStar = (n) => {
  hoveredElementIndex.value = n;
};
const onClick = (n) => {
  if (readOnly.value) return;
  handleChange(n * 20);
};
defineExpose({ meta });
</script>

<template>
  <div
    :data-name="name"
    class="w-fit"
  >
    <div class="flex-row inline-flex">
      <BaseIcon
        v-for="n in 5"
        :key="n"
        name="star"
        :size="size"
        :class="{
          'text-transparent': isLoaded,
          'text-proxify-warning-400': n <= hoveredElementIndex,
          'text-proxify-gray-100': n > hoveredElementIndex || !value,
          'cursor-pointer px-0.5': !readOnly,
          'scale-125': !readOnly && pressedStarIndex === n,
        }"
        class="transition duration-300"
        @mouseover="!readOnly && onHoverStar(n)"
        @mouseleave="hoveredElementIndex = normalizedRating"
        @mousedown="pressedStarIndex = n"
        @mouseup="pressedStarIndex = 0"
        @click="onClick(n)"
      />
    </div>
    <div
      v-if="errorMessage && !readOnly"
      class="text-body-sm font-normal text-proxify-error-500"
    >
      {{ errorMessage }}
    </div>
  </div>
</template>
