<template>
  <div>
    <div
      ref="messageContainer"
      class="message-container"
      :class="{
        'items-center': !notes.length,
        'justify-center': !notes.length,
      }"
      :style="{ marginBottom }"
    >
      <BaseMessage
        v-for="message in notes"
        :id="message.id"
        :key="message.id"
        :body="message.body"
        :attachments="message.attachments"
        :trigger-reason="message.trigger_reason"
        :date="message.updated_at"
        :user="message.user"
        :is-automated="message.auto_sent"
        :is-scheduled="message.is_draft"
        :direction="
          message.user.id === currentUser.id ? 'outgoing' : 'incoming'
        "
        type="note"
        @delete="handleDeleteNote"
        @edit="setNoteToEdit"
      ></BaseMessage>
      <PDialog
        v-model="isDeleteDialogActive"
        position="standard"
        persistent
      >
        <BaseConfirmationCard
          cancel-button-text="Cancel"
          confirm-button-text="Delete"
          :on-close="closeDeleteDialog"
          :on-confirm="handleConfirmDelete"
          class="select-none"
        >
          <template #title>Delete scheduled message</template>
          <div class="text-body-sm font-medium text-proxify-gray-600">
            Are you sure you want to delete this scheduled message? This action
            can not be undone.
          </div>
        </BaseConfirmationCard>
      </PDialog>
      <div
        v-if="notes.length === 0"
        class="text-center"
      >
        <div class="font-semibold text-body-md">No notes saved yet</div>
        <div class="font-normal text-body-sm text-proxify-gray-600">
          Type in your first note about this applicant below
        </div>
      </div>
      <div
        v-else
        ref="divider"
        class="mt-4"
      ></div>
    </div>
    <div class="p-4">
      <AppFormTextEditor
        ref="editor"
        v-model="notesEditorOutput"
        name="NotesEditor"
        :on-send-message="handleSendNotes"
        :on-save-message="handleSaveNotes"
        placeholder="Type a new note"
        :options="quillOptions"
        :upload-progress="uploadProgress"
        :content-to-edit="noteToEdit"
        :on-cancel-edit="handleCancelEdit"
        :on-attachment-added="onAttachmentAdded"
        :on-attachment-removed="onAttachmentRemoved"
        :initial-value="notesEditorInitialValue"
        wrapper-classes="border border-proxify-gray-300 rounded-lg shadow-sm"
        submit-button-label="Save"
      />
    </div>
  </div>
</template>
<script>
import { mapState, useStore } from 'vuex';
import { computed, ref, watch, onMounted, nextTick } from 'vue';
import { Delta } from '@vueup/vue-quill';
import api from '@/api';
import snackbarMessagesMixin from '@/mixins/snackbarMessagesMixin';
import { useDebounceFn } from '@vueuse/core';
import { useRouteParams } from '@vueuse/router';
import 'quill-mention';
import useScrollToItem from '@/composables/useScrollToItem';
import { useRoute, useRouter } from 'vue-router';
import { BaseConfirmationCard, BaseMessage } from '@/components/Base';
import { PDialog } from '@/components/ProxifyUI';

export default {
  name: 'ApplicantNotes',
  components: {
    PDialog,
    BaseConfirmationCard,
    BaseMessage,
  },
  mixins: [snackbarMessagesMixin],
  props: {
    notes: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    onReceiveNote: {
      type: Function,
      required: true,
    },
    onDeleteNote: {
      type: Function,
      required: false,
      default: () => {},
    },
    onEditNote: {
      type: Function,
      required: false,
      default: () => {},
    },
    applicant: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },
  setup(props) {
    const messageContainer = ref(null);
    const notesEditorOutput = ref(null);
    const notesEditorInitialValue = ref(null);
    const marginBottom = ref('unset');
    const store = useStore();
    const applicationId = useRouteParams('id');
    const itemId = useRouteParams('itemId');
    const route = useRoute();
    const router = useRouter();

    const onAttachmentAdded = () => {
      marginBottom.value = '4.5rem';
    };
    const onAttachmentRemoved = () => {
      marginBottom.value = 'unset';
    };
    const { scrollToContainerBottom } = useScrollToItem(
      messageContainer,
      itemId,
      {
        scrollToBottom: true,
      },
      null,
      () =>
        router.push({
          params: {
            ...route.params,
            itemId: undefined,
          },
        })
    );
    const storeDraftNote = () => {
      store.commit('drafts/setNoteDraft', {
        id: route.params.id,
        note: notesEditorOutput.value?.ops[0]?.insert,
      });
    };

    const debounceStoreDraftNote = useDebounceFn(() => {
      storeDraftNote();
    }, 200);

    watch(
      () => props.notes,
      () => {
        scrollToContainerBottom?.();
      },
      {
        immediate: true,
        deep: true,
      }
    );
    watch(
      () => notesEditorOutput.value,
      () => {
        if (notesEditorOutput.value) {
          debounceStoreDraftNote();
        }
      }
    );
    watch(
      () => applicationId.value,
      () => {
        setNotesEditorInitialValue();
      }
    );

    const setNotesEditorInitialValue = () => {
      nextTick(() => {
        notesEditorInitialValue.value = new Delta([
          {
            insert:
              store.state.drafts.noteDrafts?.find(
                (item) => item.id === applicationId.value
              )?.note || '',
          },
        ]);
      });
    };

    const quillOptions = computed(() => ({
      modules: {
        toolbar: [],
        mention: {
          allowedChars: /^[A-Za-z\s]*$/,
          mentionDenotationChars: ['@'],
          defaultMenuOrientation: 'bottom',
          offsetLeft: -16,
          onOpen: function () {
            document.querySelector('.activities-container').style.overflow =
              'unset';
          },
          onBeforeClose: function () {
            document.querySelector('.activities-container').style.overflow =
              null;
          },
          source: async function (searchTerm, renderList) {
            let values = [];
            const { users } = store.state.applicant;
            if (users.length) {
              values = users;
            } else {
              const { data: response } = await api.users.getAll();
              store.commit('applicant/setUsers', response.data);
              values = response.data;
            }
            renderList(
              values
                .filter(({ name, active }) => {
                  return (
                    name.toLowerCase().includes(searchTerm.toLowerCase()) &&
                    active
                  );
                })
                .map(({ id, name }) => ({ id, value: name })),
              searchTerm
            );
          },
        },
      },
      theme: 'snow',
    }));

    onMounted(() => {
      setNotesEditorInitialValue();
    });

    return {
      messageContainer,
      marginBottom,
      onAttachmentAdded,
      onAttachmentRemoved,
      quillOptions,
      notesEditorOutput,
      notesEditorInitialValue,
    };
  },
  data() {
    return {
      uploadProgress: 100,
      noteToEdit: null,
    };
  },
  computed: {
    ...mapState({
      users: (state) => state.applicant.users,
      currentUser: (state) => state.auth.user,
    }),
  },
  mounted() {
    this.listenNotesChannel();
  },
  unmounted() {
    this.unsubscribeNotesChannel();
  },
  methods: {
    async handleSendNotes(data, { id }) {
      const { onUploadProgress } = this;
      const { data: note } = await api.applications.notes.post(data, {
        id,
        onUploadProgress,
      });
      this.onReceiveNote(note.response);
    },
    async handleDeleteNote(noteId) {
      const { data } = await api.applications.notes.delete({
        application_id: this.applicant.id,
        note_id: noteId,
      });
      this.onDeleteNote(noteId);
      this.showSuccessMessage(data.response);
    },
    setNoteToEdit(noteId) {
      this.noteToEdit = this.notes.find(({ id }) => id === noteId);
    },
    handleCancelEdit() {
      this.noteToEdit = null;
    },
    async handleSaveNotes(data, { note_id, application_id }) {
      const { onUploadProgress } = this;
      const { data: note } = await api.applications.notes.put(data, {
        note_id,
        application_id,
        onUploadProgress,
      });
      this.onEditNote(note.response);
    },
    onUploadProgress({ loaded, total }) {
      this.uploadProgress = Math.ceil((loaded / total) * 100);
    },
    listenNotesChannel() {
      const { id } = this.$route.params;
      window.Echo.channel(`notes.${id}`).listen(
        'NoteCreatedEvent',
        ({ note }) => {
          if (note.user?.id !== this.currentUser.id) {
            this.onReceiveNote(note);
          }
        }
      );
    },
    unsubscribeNotesChannel() {
      const { id } = this.$route.params;
      window.Echo.leaveChannel(`notes.${id}`);
    },
  },
};
</script>
<style scoped>
.message-container {
  @apply grow overflow-y-auto flex flex-col h-full p-4 gap-4;
}
</style>
