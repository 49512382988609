<script setup>
import BaseIcon from '@/components/Base/BaseIcon.vue';
import PBadge from '@/components/ProxifyUI/PBadge.vue';
import { useChangeCase } from '@vueuse/integrations/useChangeCase';
import { computed } from 'vue';

const props = defineProps({
  badgeClass: {
    type: String,
    required: false,
    default() {
      return '';
    },
  },
  clickable: {
    type: Boolean,
    default: false,
  },
  color: {
    type: String,
    default: 'Gray',
    validator: (value) =>
      [
        'Blue',
        'BlueLight',
        'Error',
        'Gray',
        'GrayBlue',
        'Indigo',
        'Orange',
        'Pink',
        'Primary',
        'Purple',
        'Red',
        'Success',
        'Transparent',
        'Warning',
        'White',
      ].includes(useChangeCase(value, 'pascalCase').value),
  },
  dense: {
    type: Boolean,
    default: false,
  },
  icon: {
    type: String,
    required: false,
    default() {
      return '';
    },
  },
  label: {
    type: [String, Number],
    required: false,
    default: undefined,
  },
  outline: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String,
    default: 'md',
  },
  iconSize: {
    type: String,
    default: '16px',
  },
  iconClass: {
    type: String,
    default: '',
  },
  iconPosition: {
    type: String,
    required: false,
    validate: (value) => ['left', 'right'].includes(value),
    default: 'left',
  },
  square: {
    type: Boolean,
    default: false,
  },
});

const colorClass = computed(
  () =>
    props.color && `p-badge--${useChangeCase(props.color, 'paramCase').value}`
);
</script>
<template>
  <PBadge
    :clickable="clickable"
    :dense="dense"
    :outline="outline"
    :size="size"
    :square="square"
    class="m-0 flex"
    :class="`${colorClass} ${badgeClass}`"
  >
    <template #prepend>
      <slot name="prepend">
        <BaseIcon
          v-if="icon && iconPosition === 'left'"
          :name="icon"
          :size="iconSize"
          :class="iconClass"
          :data-testid="`${icon}-icon`"
        />
      </slot>
    </template>
    <slot>{{ label }}</slot>
    <template #append>
      <slot name="append">
        <BaseIcon
          v-if="icon && iconPosition === 'right'"
          :name="icon"
          :size="iconSize"
          :class="iconClass"
          :data-testid="`${icon}-icon`"
        />
      </slot>
    </template>
  </PBadge>
</template>
<style lang="scss">
.q-chip--clickable {
  &:active {
    box-shadow: 0px 0px 0px 4px #f4ebff !important;
    .p-radio.checked {
      &::before {
        box-shadow: 0px 0px 0px 4px #f4ebff !important;
      }
    }
  }
}
.q-chip__content {
  @apply flex gap-[8px] whitespace-normal;
}
.q-chip--dense {
  .q-chip__content {
    @apply gap-[4px];
  }
}
.p-badge {
  @apply self-start;
  &--blue {
    background: #eff8ff !important;
    color: #175cd3 !important;
  }

  &--blue-light {
    background: #f0f9ff !important;
    color: #026aa2 !important;
  }

  &--error {
    @apply bg-proxify-error-50
    text-proxify-error-700 #{!important};
  }

  &--gray {
    @apply bg-proxify-gray-100
    text-proxify-gray-700 #{!important};
  }

  &--white {
    background: #ffffff !important;
    color: #344054 !important;
  }

  &--gray-blue {
    background: #f8f9fc !important;
    color: #363f72 !important;
  }

  &--indigo {
    background: #eef4ff !important;
    color: #3538cd !important;
  }

  &--orange {
    background: #fff4ed !important;
    color: #b93815 !important;
  }

  &--pink {
    background: #fdf2fa !important;
    color: #c11574 !important;
  }

  &--primary {
    @apply bg-proxify-primary-50
    text-proxify-primary-700 #{!important};
  }

  &--purple {
    background: #faf5ff !important;
    color: #7e22ce !important;
  }

  &--red {
    background: #fff1f3 !important;
    color: #c01048 !important;
  }

  &--success {
    @apply bg-proxify-success-50
    text-proxify-success-700 #{!important};
  }

  &--transparent {
    background: transparent !important;
    color: #475467 !important;
  }

  &--warning {
    background: #fffaeb !important;
    color: #b54708 !important;
  }
}
</style>
