export default {
  /**
   * Open a new window/tab for an external URL
   * @param {string} url - The URL to navigate to
   * @return {void}
   */
  openExternalLink: (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  },

  /**
   * Create and open a new window with certain dimensions and positions
   * @param {object} options - Dimensions and position for the new window
   * @return {Window|null}
   */
  createNewWindow: (options = {}) => {
    // Default values
    const defaults = {
      width: window.screen.availWidth / 2,
      height: window.screen.availHeight,
      left: window.screen.availWidth / 2,
      top: 0,
    };

    // Presets
    const presets = {
      'half-right': { ...defaults },
      'half-left': { ...defaults, left: 0 },
    };

    // Merge defaults and options
    const settings = {
      ...defaults,
      ...options,
      // If options.preset is defined, use the preset
      ...(options.preset && presets[options.preset]
        ? presets[options.preset]
        : {}),
    };

    const windowFeatures = `width=${settings.width},height=${settings.height},left=${settings.left},top=${settings.top}`;

    const url = options.url || '';
    return window.open(url, '', windowFeatures);
  },
};
