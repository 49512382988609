<script setup>
import { useSortable } from '@vueuse/integrations/useSortable';
import { useElementHover, useInfiniteScroll } from '@vueuse/core';
import { useStore } from 'vuex';
import { computed, ref, toRefs, watch } from 'vue';
import api from '@/api';

import { BaseBadge } from '@/components/Base';
import { PCircularProgress, PIntersection } from '@/components/ProxifyUI';
import ApplicationCard from './ApplicationCard.vue';

const props = defineProps({
  container: {
    type: Object,
    default() {
      return {};
    },
  },
  onBeforeStageChange: {
    type: Function,
    required: false,
  },
  containerHeight: {
    type: [String, Number],
    required: false,
  },
  index: {
    type: Number,
    required: false,
  },
  grabbed: {
    type: Boolean,
    required: false,
  },
  isAPILoading: {
    type: Boolean,
    required: false,
  },
});

const emit = defineEmits([
  'open-share-link-dialog',
  'paginate',
  'drag',
  'drop',
]);

const { container, grabbed, isAPILoading } = toRefs(props);
const store = useStore();

const counterNeedUpdate = computed(() => store.state.application?.counters);
const filterQueries = computed(() => store.state.application?.filterQueries);
const applicantCardSettings = computed(
  () => store.getters['settings/applicantCardSettings']
);

const stageColumn = ref(null);
const isCounterLoading = ref(false);
const isLoading = ref(false);
const counters = ref({});
const applicationId = ref(null);

const isHovered = useElementHover(stageColumn);

const cardColorClass = (applicant) => {
  if (
    container.value.name === 'Joined Network' ||
    applicant?.is_rejected ||
    !applicant?.urgency
  )
    return 'opacity-0';
  if (
    container.value.new_applications.includes(applicant?.id) &&
    applicantCardSettings.value?.showOnStages?.items?.new?.value
  ) {
    return 'priority-new';
  }
  return `urgency-${applicant?.urgency}`;
};

const collapsed = computed(() => {
  const { total } = counters.value;
  const visibleApplicationsLength = container.value.applications.length;
  const newApplicationsLength = container.value.new_applications.length;
  return (
    visibleApplicationsLength === 0 &&
    newApplicationsLength === 0 &&
    total === 0 &&
    (stageColumn.value?.childElementCount < 2 ||
      stageColumn.value?.children?.[1]?.classList?.contains?.(
        'skeleton-card'
      )) &&
    !(isHovered.value && grabbed.value)
  );
});
useSortable(stageColumn, container.value.applications, {
  group: 'applications',
  ghostClass: 'opacity-0',
  revertOnSpill: true,
  scroll: true,
  sort: false,
  onSpill: (event_) => {
    event_.item.classList.remove('hidden');
  },
  onMove: (event_) => {
    emit('drag');
    event_.dragged.classList.add('hidden');
    applicationId.value = parseInt(
      event_.dragged?.getAttribute('data-application-id'),
      10
    );
  },
  onEnd: (event_) => {
    emit('drop');
    if (
      event_.from.getAttribute('data-stage') !==
      event_.to.getAttribute('data-stage')
    ) {
      const from = Array.from(event_.from.children).filter(
        (element) =>
          element.getAttribute('data-application-id') ===
          event_.item.getAttribute('data-application-id')
      );
      const to = Array.from(event_.to.children).filter(
        (element) =>
          element.getAttribute('data-application-id') ===
          event_.item.getAttribute('data-application-id')
      );
      if ((from.length && to.length) || to.length > 1) {
        event_.item.classList.remove('hidden');
      } else {
        event_.item.classList.add('hidden');
      }
      const previousStageId = parseInt(
        event_.from.getAttribute('data-stage'),
        10
      );
      const nextStageId = parseInt(event_.to.getAttribute('data-stage'), 10);
      props.onBeforeStageChange({
        applicationId: applicationId.value,
        previousStageId,
        nextStageId,
        fallbackFunction: () => {
          event_.from.insertBefore(event_.item, event_.from.firstElementChild);
        },
      });
    }
  },
});

const metaData = ref({
  current_page: 1,
  per_page: 10,
});

const getNextPage = async (currentPage) => {
  if (isLoading.value) return;
  try {
    isLoading.value = true;
    const { data: { data: response } = {} } =
      await api.applications.getApplications({
        params: {
          ...filterQueries.value,
          page: currentPage,
          stage: container.value.id,
        },
      });

    metaData.value = {
      current_page: response.current_page,
      per_page: response.per_page,
    };

    if (response.data.length) {
      emit('paginate', {
        stage: container.value.id,
        applications: response.data,
      });
    }
  } catch (error) {
    console.log(error);
  } finally {
    isLoading.value = false;
  }
};

const isLastPage = computed(() => {
  if (counters.value.total < 10 || container.value.applications.length < 10)
    return true;
  return (
    Math.max(1, Math.ceil(counters.value.total / metaData.value.per_page)) ===
    metaData.value.current_page
  );
});

useInfiniteScroll(
  stageColumn,
  () => {
    if (!isLastPage.value && !isLoading.value) {
      getNextPage(metaData.value.current_page + 1);
    }
  },
  { distance: metaData.value.per_page }
);

watch(
  () => counterNeedUpdate.value,
  (newValue, oldValue) => {
    const { id } = container.value;
    if (newValue && (!oldValue || newValue[id] !== oldValue[id])) {
      isCounterLoading.value = true;
      api.stages
        .counters({ id, ...filterQueries.value })
        .then(({ data: response }) => {
          counters.value = response.counters;
        })
        .finally(() => {
          isCounterLoading.value = false;
        });
    }
  },
  { immediate: true }
);
watch(
  () => filterQueries.value,
  (newValue, oldValue) => {
    if (
      newValue &&
      (!oldValue || JSON.stringify(newValue) !== JSON.stringify(oldValue))
    ) {
      metaData.value = {
        current_page: 1,
        per_page: 10,
      };
    }
  },
  { deep: true }
);
</script>

<template>
  <div
    class="py-4 pl-4 pr-2 border-r border-t border-proxify-gray-200"
    :class="{
      'bg-white': index % 2 === 0,
      'bg-proxify-gray-50': index % 2 !== 0,
      'w-[265px]': !collapsed,
      'w-[128px]': collapsed,
    }"
  >
    <div class="flex flex-col gap-[6px] header pb-4">
      <div class="text-body-md font-semibold text-proxify-gray-900">
        {{ container.name }}
      </div>
      <div
        v-if="
          applicantCardSettings?.showOnStages.items.applicantsCounter.value &&
          !isAPILoading &&
          !isCounterLoading
        "
        class="text-body-sm font-normal text-proxify-gray-600 flex gap-2"
      >
        <span>
          {{ counters.total ?? 0 }} applicant{{
            counters.total !== 1 ? 's' : ''
          }}
        </span>
        <BaseBadge
          v-if="counters.critical"
          color="error"
          icon="bell-ringing04"
          size="xs"
          icon-size="12px"
        >
          {{ counters.critical }}
        </BaseBadge>
        <BaseBadge
          v-if="counters.high"
          color="warning"
          icon="bell01"
          size="xs"
          icon-size="12px"
        >
          {{ counters.high }}
        </BaseBadge>
      </div>
      <div v-if="isAPILoading || isCounterLoading">
        <div class="skeleton-info flex gap-2 mt-2">
          <div
            class="skeleton-counter w-2/4 h-3 bg-proxify-gray-300 rounded"
          ></div>
          <div
            class="skeleton-badge w-1/6 h-3 bg-proxify-gray-300 rounded"
          ></div>
          <div
            class="skeleton-badge w-1/6 h-3 bg-proxify-gray-300 rounded"
          ></div>
        </div>
      </div>
    </div>
    <div
      ref="stageColumn"
      class="flex flex-col gap-4 select-none ats-scrollbar !overflow-y-scroll transition-width"
      :style="{ height: containerHeight + 'px' }"
      :data-stage="container.id"
    >
      <PIntersection
        v-for="application in container.applications"
        v-show="!isAPILoading"
        :key="application.id"
        :data-application-id="application.id"
        :root="stageColumn"
        once
      >
        <RouterLink :to="`/applications/${application?.id}`">
          <ApplicationCard
            v-if="application"
            :application="application"
            :current-stage-name="container.name"
            :card-color="cardColorClass(application)"
            @open-share-link-dialog="
              $emit('open-share-link-dialog', application.id)
            "
          />
        </RouterLink>
        <template #hidden>
          <div class="skeleton-card">
            <div
              class="skeleton-title w-full h-4 bg-proxify-gray-300 rounded"
            ></div>
            <div
              class="skeleton-rating w-1/4 h-3 mt-2 bg-proxify-gray-300 rounded"
            ></div>
            <div
              class="skeleton-badge w-1/5 h-3 mt-2 bg-proxify-gray-300 rounded"
            ></div>
            <div class="skeleton-skills flex mt-2 gap-2">
              <div class="w-1/5 h-3 bg-proxify-gray-300 rounded"></div>
              <div class="w-1/5 h-3 bg-proxify-gray-300 rounded"></div>
              <div class="w-1/5 h-3 bg-proxify-gray-300 rounded"></div>
            </div>
            <div class="skeleton-action flex mt-2 gap-2">
              <div class="w-1/6 h-3 bg-proxify-gray-300 rounded"></div>
              <div class="w-1/6 h-3 bg-proxify-gray-300 rounded"></div>
            </div>
          </div>
        </template>
      </PIntersection>
      <PCircularProgress
        v-if="isLoading"
        rounded
        indeterminate
        color="primary"
        class="w-full align-center"
      ></PCircularProgress>
      <template v-if="isAPILoading && !collapsed">
        <div
          v-for="i in 5"
          :key="i"
          class="skeleton-card"
        >
          <div
            class="skeleton-title w-full h-4 bg-proxify-gray-300 rounded"
          ></div>
          <div
            class="skeleton-rating w-1/4 h-3 mt-2 bg-proxify-gray-300 rounded"
          ></div>
          <div
            class="skeleton-badge w-1/5 h-3 mt-2 bg-proxify-gray-300 rounded"
          ></div>
          <div class="skeleton-skills flex mt-2 gap-2">
            <div class="w-1/5 h-3 bg-proxify-gray-300 rounded"></div>
            <div class="w-1/5 h-3 bg-proxify-gray-300 rounded"></div>
            <div class="w-1/5 h-3 bg-proxify-gray-300 rounded"></div>
          </div>
          <div class="skeleton-action flex mt-2 gap-2">
            <div class="w-1/6 h-3 bg-proxify-gray-300 rounded"></div>
            <div class="w-1/6 h-3 bg-proxify-gray-300 rounded"></div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<style lang="scss">
.priority-new {
  @apply border-2 border-proxify-green-400;
}

.urgency-critical {
  @apply border-2 border-proxify-error-500;
}

.urgency-high {
  @apply border-2 border-proxify-warning-400;
}

.skeleton-card {
  @apply hover:shadow-md
  cursor-pointer
  flex
  px-4
  py-4
  flex-col
  gap-3
  rounded-xl
  border
  border-proxify-gray-200
  bg-white
  shadow-sm;
}

// Skeleton styles
.skeleton-title,
.skeleton-rating,
.skeleton-badge,
.skeleton-header,
.skeleton-info > div,
.skeleton-skills > div,
.skeleton-action > div {
  animation: loading 1.5s infinite alternate;
}

@keyframes loading {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0.5;
  }
}

.transition-width {
  transition: width 0.3s ease;
}
</style>
