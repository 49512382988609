<template>
  <div data-preview class="email-preview-wrapper h-screen">
    <div class="email-preview-title">
      <BaseButton
        icon-prepend="chevron-left"
        class="icon-link-gray p-0"
        @click="onClose"
      >
        Go back
      </BaseButton>
    </div>
    <iframe
      :srcdoc="template"
      class="email-preview-body"
      title="Email Preview"
    ></iframe>
  </div>
</template>

<script>
import { BaseButton } from '@/components/Base';

export default {
  name: 'EmailPreviewCard',
  components: {
    BaseButton,
  },
  props: {
    template: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: false,
      default() {
        return 'Template';
      },
    },
    onClose: {
      type: Function,
      required: true,
    },
  },
};
</script>

<style scoped>
.email-preview-wrapper {
  @apply shadow-xl
  rounded-[12px]
  p-4
  bg-white
  w-[36rem]
  max-h-[44rem];
}

.email-preview-title {
  @apply flex
  items-center
  mb-4
  font-poppins
  text-heading-3;
}

.email-preview-body {
  @apply w-[34rem] overflow-x-hidden h-[calc(100%-3rem)];
}
</style>
