<script setup>
import { ratingUtils } from '@/utils';
import BaseBadge from '@/components/Base/BaseBadge.vue';
import { computed } from 'vue';
import { BaseIcon, BaseTooltip } from '@/components/Base';

const props = defineProps({
  monthlyRate: {
    type: Number,
    required: true,
  },
  rates: {
    type: Array,
    required: true,
  },
  currency: {
    type: String,
    default: '€',
  },
});

const verdict = computed(() => {
  return ratingUtils.calculateVerdict(props.monthlyRate, props.rates);
});

const color = computed(() => {
  const colors = {
    low: 'gray',
    typical: 'success',
    high: 'error',
  };

  return colors[verdict.value];
});
</script>

<template>
  <div class="flex items-center flex-wrap justify-between">
    <span class="text-proxify-gray-900">Monthly rate</span>
    <span class="text-right flex-grow py-[2px]">
      <BaseBadge
        size="sm"
        :color="color"
        class="bg-transparent !text-body-sm !font-medium"
      >
        {{ currency + monthlyRate }}
      </BaseBadge>
      <BaseBadge
        size="sm"
        :color="color"
        class="capitalize !text-body-sm !font-medium cursor-pointer"
      >
        {{ verdict }}
        <BaseIcon
          name="info-circle"
          size="12px"
        />
        <BaseTooltip
          :offset="[0, 10]"
          class="verdict-tooltip bg-proxify-gray-900 !text-white w-[235px] text-left"
        >
          <div class="flex gap-1 text-white !text-left">
            <span class="capitalize">
              {{ verdict }}
            </span>
            <span>
              monthly rate, calculated based on skills, and years of experience
            </span>
          </div>
        </BaseTooltip>
      </BaseBadge>
    </span>
  </div>
</template>
<style lang="scss">
.verdict-tooltip {
  &.q-tooltip--style.arrow-top::before {
    border-bottom-color: #15172f !important;
  }
}
</style>
