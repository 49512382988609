<template>
  <Transition name="app-dialog">
    <div
      v-if="show"
      class="app-dialog flex fixed inset-0 backdrop-blur-sm h-full w-full"
      @click="clickOutside && close()"
    >
      <div
        ref="popupWrapper"
        class="rounded"
        :class="appDialogContentClass"
        :style="`width: ${width}`"
        @click.stop
      >
        <slot></slot>
      </div>
    </div>
  </Transition>
</template>

<script>
export default {
  name: 'AppDialog',
  props: {
    appDialogContentClass: {
      type: [Object, String],
      required: false,
    },
    clickOutside: {
      type: Boolean,
      default: true,
    },
    show: {
      type: Boolean,
      default: true,
    },
    width: {
      type: String,
      default: '37.5rem',
    },
    fixedTop: {
      type: Boolean,
      required: false,
    },
    close: {
      type: Function,
      required: false,
    },
  },
  watch: {
    show(newValue) {
      if (newValue) {
        document.documentElement.style.overflow = 'hidden';
        [...document.querySelectorAll('.container-draggable')].forEach(
          (element) => (element.style.overflow = 'hidden')
        );
        // Handle Esc key
        document.addEventListener('keydown', this.onEscape);
      } else {
        document.documentElement.style.overflow = 'auto';
        [...document.querySelectorAll('.container-draggable')].forEach(
          (element) => (element.style.overflow = null)
        );
        document.removeEventListener('keydown', this.onEscape);
      }
    },
  },
  methods: {
    onEscape(event) {
      if (event.keyCode === 27) {
        this.close?.();
      }
    },
  },
};
</script>
<style>
.app-dialog {
  z-index: 9999;
}
</style>
