<script setup>
import { computed, nextTick, ref, watch } from 'vue';
import { useVModel } from '@vueuse/core';
import { BaseInputDropdown, BaseInputField } from '@/components/Base';
import { answers } from '@/constants/filters';

// Define props
const props = defineProps({
  modelValue: {
    type: Object,
    required: false,
    default: () => ({ skill: null, years: null, proficiency_level: null }),
  },
  skills: {
    type: Array,
    required: false,
    default: () => [],
  },
  title: {
    type: String,
    required: false,
    default: '',
  },
  disable: {
    type: Boolean,
    required: false,
    default: () => false,
  },
  dense: {
    type: Boolean,
    required: false,
    default: () => true,
  },
  required: {
    type: Boolean,
    required: false,
    default: () => false,
  },
  allowNameOnly: {
    type: Boolean,
    required: false,
    default: () => false,
  },
  resetModelValue: {
    type: Boolean,
    required: false,
    default: () => false,
  },
});

// Define emits
const emit = defineEmits(['update:modelValue']);

// Use v-model and create refs
const model = useVModel(props, 'modelValue', emit);
const skillName = ref(null);
const skillExperience = ref(null);
const skillProficiency = ref(null);
const nameFocused = ref(false);
const experienceFocused = ref(false);
const proficiencyFocused = ref(false);
const skill = ref(model.value.skill);
const years = ref(model.value.years);
const proficiency = ref(model.value.proficiency_level);

// Watch for changes
watch(
  () => model.value,
  (value) => {
    skill.value = value.skill;
    years.value = value.years ? parseInt(value.years, 10) : undefined;
    proficiency.value = value.proficiency_level;
  },
  { immediate: true, deep: true }
);

// Computed props
const allFieldsFilled = computed(() => {
  return (
    Boolean(skill.value) &&
    Boolean(years.value) &&
    Boolean(proficiency.value) &&
    Boolean(proficiency.value?.value)
  );
});

/**
 * A variable that determines if all fields are blurred.
 *
 * @type {ComputedRef<boolean>}
 */
const allBlurred = computed(() => {
  return (
    !nameFocused.value && !experienceFocused.value && !proficiencyFocused.value
  );
});

// Map proficiency level options
const proficiencyLevelOptions = answers.proficiency_level.map(
  (item, index) => ({
    value: index + 1,
    label: item,
  })
);
watch(
  () => allBlurred.value,
  (newValue) => {
    if (newValue && props.resetModelValue) {
      resetSkillData();
    }
  }
);
/**
 * Resets the skill data to their initial values.
 * @function
 * @name resetSkillData
 */
const resetSkillData = () => {
  model.value = { skill: null, years: null, proficiency_level: null };
  skill.value = null;
  years.value = null;
  proficiency.value = null;
};

// Update model value function
const updateModelValue = () => {
  if (allFieldsFilled.value || props.allowNameOnly) {
    emit('update:modelValue', {
      ...model.value,
      skill: skill.value,
      years: years.value,
      proficiency_level: proficiency.value,
      main_skill: true,
    });
    if (!props.allowNameOnly && props.resetModelValue) {
      resetSkillData();
    }
  }
};
</script>

<template>
  <div
    class="flex gap-4"
    :class="{ 'gap-2': dense }"
  >
    <div>
      <div class="text-body-sm font-medium text-proxify-gray-700 mb-2">
        <span>
          {{ title }}
        </span>
        <span v-if="required">*</span>
      </div>
      <BaseInputDropdown
        ref="skillName"
        v-model="skill"
        :name="title"
        map-options
        :options="skills"
        option-label="name"
        option-value="id"
        :input-width="dense ? '216px' : '220px'"
        type="search"
        :disable="disable"
        @focus="() => (nameFocused = true)"
        @blur="
          () => {
            nextTick(() => {
              skillExperience.handleFocus();
              nameFocused = false;
            });
          }
        "
        @update:model-value="updateModelValue"
      />
    </div>
    <div>
      <div class="text-body-sm font-medium text-proxify-gray-700 mb-2">
        <span>Experience</span>
        <span v-if="required">*</span>
      </div>
      <BaseInputField
        ref="skillExperience"
        v-model="years"
        :min="0"
        :name="`${title} Experience`"
        trailing-text="years"
        input-width="120px"
        type="number"
        paddingless-side="right"
        :disable="disable"
        @change="updateModelValue"
        @focus="() => (experienceFocused = true)"
        @blur="() => (experienceFocused = false)"
      />
    </div>
    <div>
      <div class="text-body-sm font-medium text-proxify-gray-700 mb-2">
        <span>Level of expertise</span>
        <span v-if="required">*</span>
      </div>
      <BaseInputDropdown
        ref="skillProficiency"
        v-model="proficiency"
        :name="`${title} Level of expertise`"
        map-options
        :options="proficiencyLevelOptions"
        option-label="label"
        option-value="value"
        :input-width="dense ? '216px' : '220px'"
        :disable="disable"
        @update:model-value="updateModelValue"
        @focus="() => (proficiencyFocused = true)"
        @blur="() => (proficiencyFocused = false)"
      />
    </div>
  </div>
</template>
