<template>
  <div>
    <AppSkeletonLoader
      v-if="isLoading"
      full-height
      data-testid="messages-skeleton-loader"
    />
    <div
      v-show="!isLoading"
      id="messageContainer"
      ref="messageContainer"
      class="message-container"
      :class="{
        'items-center justify-center': !messages.length,
      }"
      :style="{ marginBottom }"
    >
      <div
        v-if="!messages.length"
        class="text-center"
      >
        <div class="font-semibold text-body-md">No emails sent yet</div>
        <div class="font-normal text-body-sm text-proxify-gray-600">
          Start your conversation by sending the first email
        </div>
      </div>
      <div
        v-if="selectedSubject"
        class="subject"
      >
        {{ selectedSubject.name }}
      </div>

      <BaseMessage
        v-for="(message, index) in sentMessages"
        :id="message.draft_id"
        :key="message.id + index"
        :body="message.body"
        :attachments="message.attachments"
        :trigger-reason="message.trigger_reason"
        :date="message.scheduled_for ?? message.date"
        :user="getMessageUser(message)"
        :is-automated="message.auto_sent"
        :is-scheduled="message.is_draft"
        :direction="message.direction"
        :show-read-icon="Boolean(message.read_at)"
        show-sent-icon
        @delete="openDeleteDialog"
      ></BaseMessage>
      <PDialog
        v-model="isDeleteDialogActive"
        position="standard"
        persistent
      >
        <BaseConfirmationCard
          cancel-button-text="Cancel"
          confirm-button-text="Delete"
          :on-close="closeDeleteDialog"
          :on-confirm="handleConfirmDelete"
          class="select-none"
        >
          <template #title>Delete scheduled message</template>
          <div class="text-body-sm font-medium text-proxify-gray-600">
            Are you sure you want to delete this scheduled message? This action
            can not be undone.
          </div>
        </BaseConfirmationCard>
      </PDialog>
      <div
        ref="divider"
        class="mt-4"
      ></div>
    </div>
    <div class="p-4">
      <AppFormTextEditor
        ref="messagesEditor"
        v-model="messagesEditorOutput"
        name="MessagesEditor"
        has-input-header
        :on-send-message="handleSendEmail"
        :on-attachment-added="onAttachmentAdded"
        :on-attachment-removed="onAttachmentRemoved"
        placeholder="Type a new message"
        :upload-progress="uploadProgress"
        :open-dialog="revealSendMessageDialog"
        active-sand-clock-icon
        :initial-value="messagesEditorInitialValue"
        wrapper-classes="border border-proxify-gray-300 rounded-lg shadow-sm"
      >
        <template #input-header>
          <div class="activity-input-subject mt-4">
            <AppPopup
              v-if="
                subjectOptions.length === 0 &&
                (!draftSubject || draftSubject === 'New Subject')
              "
              :active="isPopupActive"
              :platform-element="messagesEditor"
              :menu-offset-x="-1"
              :menu-placement-spacing="0"
              menu-placement="right-end"
            >
              <template #activator>
                <BaseButton
                  class="icon-secondary-gray text-body-sm"
                  outlined
                  rounded
                  icon-prepend="plus"
                  icon-size="20px"
                  @click="handleOpenSubjectPopup"
                >
                  New subject
                </BaseButton>
              </template>
              <template #content>
                <div class="block w-64">
                  <AppFormTextField
                    v-model="subjectModel"
                    ref-name="subject"
                    label="New Subject"
                    required
                    :focus="true"
                    value-class="text-proxify-primary"
                    placeholder="Subject"
                    @keydown.enter="handleAddNewSubject"
                  />
                  <div class="popup-actions">
                    <div
                      class="cursor-pointer text-proxify-gray-700"
                      @click="handleCloseSubjectPopup"
                    >
                      Cancel
                    </div>
                    <div
                      class="cursor-pointer text-proxify-primary"
                      @click="handleAddNewSubject"
                    >
                      Create
                    </div>
                  </div>
                </div>
              </template>
            </AppPopup>
            <div
              v-else
              class="subject-selector"
            >
              <ValueSelector
                v-if="selectedSubject?.name"
                ref="valueSelector"
                name="Subject"
                trigger-type="contained"
                menu-placement="right-end"
                positioning-strategy="relative"
                :options="subjectOptions"
                :value="selectedSubject.name"
                :platform-element="$refs.messagesEditor?.$el"
                :menu-offset-x="0"
                hide-input-icon
                show-create-button
                placeholder="Value"
                :on-change="handleSelectSubject"
                :container-height="selectedSubjectSelectorHeight"
              ></ValueSelector>
            </div>
          </div>
        </template>
        <template #input-footer>
          <BaseButton
            class="icon-secondary-gray py-[9px] text-body-sm"
            outlined
            rounded
            icon-prepend="edit02"
            icon-size="20px"
            @click="sendTemplateDialog"
          >
            Send a template
          </BaseButton>
        </template>
      </AppFormTextEditor>
    </div>

    <Teleport to="body">
      <SendTemplateDialog
        ref="sendTemplateDialog"
        :on-send="handleSendTemplate"
        type="template"
      />

      <AppDialog
        v-if="isSendMessageDialogRevealed"
        ref="sendTemplateDialog"
        width="36rem"
        :click-outside="false"
        app-dialog-content-class="m-auto bg-white"
      >
        <SendMessageDialog
          :initial-subject="selectedSubject"
          :initial-value="JSON.parse(JSON.stringify(messagesEditorOutput))"
          :get-email-subjects="getEmailSubjects"
          :subjects="subjects"
          :on-receive-message="onReceiveMessage"
          :clear-contents="clearContents"
          :on-close="cancelSendMessageDialog"
          :on-confirm="confirmSendMessageDialog"
        />
      </AppDialog>
    </Teleport>
  </div>
</template>
<script>
import { mapState, useStore } from 'vuex';
import SendMessageDialog from '@/components/Elements/Applicant/ApplicantActivityEmailSendMessageDialog';
import SendTemplateDialog from '@/components/Elements/Applicant/ApplicantActivityEmailSendTemplateDialog';
import ValueSelector from '@/components/Elements/Scaffold/ValueSelector';
import { Delta } from '@vueup/vue-quill';
import api from '@/api';
import snackbarMessagesMixin from '@/mixins/snackbarMessagesMixin';
import { nextTick, ref, watch } from 'vue';
import { useScroll, useConfirmDialog, useDebounceFn } from '@vueuse/core';
import { useRouteParams } from '@vueuse/router';
import {
  BaseButton,
  BaseConfirmationCard,
  BaseMessage,
} from '@/components/Base';
import { PDialog } from '@/components/ProxifyUI';

export default {
  name: 'ApplicantMessages',
  components: {
    PDialog,
    BaseConfirmationCard,
    ValueSelector,
    SendMessageDialog,
    SendTemplateDialog,
    BaseButton,
    BaseMessage,
  },
  mixins: [snackbarMessagesMixin],
  props: {
    messages: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    subjects: {
      type: Array,
      required: false,
      default() {
        return null;
      },
    },
    onSelectSubject: {
      type: Function,
      required: true,
    },
    selectedSubject: {
      type: Object,
      required: false,
      default() {
        return null;
      },
    },
    onReceiveMessage: {
      type: Function,
      required: true,
    },
    onDeleteMessage: {
      type: Function,
      required: true,
    },
    getEmailSubjects: {
      type: Function,
      required: true,
    },
    applicant: {
      type: Object,
      required: false,
      default() {
        return null;
      },
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const isScrolledToMessage = ref(false);
    const messageContainer = ref(null);
    const messagesEditor = ref(null);
    const marginBottom = ref('unset');
    const wrapperElement = ref(null);
    const messagesEditorOutput = ref(null);
    const store = useStore();
    const applicationId = useRouteParams('id');
    const itemId = useRouteParams('itemId');

    const { y } = useScroll(messageContainer, { behavior: 'smooth' });
    const scrollToBottom = () => {
      nextTick(() => {
        y.value = messageContainer.value?.scrollHeight;
      });
    };
    const scrollToElement = (id) => {
      nextTick(() => {
        const element = document.getElementById(id);
        if (!element) return;

        element.scrollIntoView({ behavior: 'smooth' });

        if (isScrolledToMessage.value) return;
        // Fade out a background effect
        element.style.background = 'rgba(88,194,162,.4)';
        let timer = 1;
        for (let index = 40; index >= 0; index--) {
          (function () {
            setTimeout(function () {
              let opacity = index > 9 ? `0.${index}` : `0.0${index}`;
              if (index) {
                element.style.background = `rgba(88,194,162,${opacity})`;
              } else {
                element.style.background = '';
              }
            }, timer * 80);
          })();
          timer++;
        }
        isScrolledToMessage.value = true;
      });
    };
    const onAttachmentAdded = () => {
      marginBottom.value = '4.5rem';
    };
    const onAttachmentRemoved = () => {
      marginBottom.value = 'unset';
    };

    const storeDraftMessage = () => {
      store.commit('drafts/setMessageDraft', {
        id: applicationId.value,
        message: messagesEditorOutput.value?.ops?.[0]?.insert,
        subject: props.selectedSubject?.name ?? 'New Subject',
      });
    };

    const debounceStoreDraftMessage = useDebounceFn(() => {
      storeDraftMessage();
    }, 200);

    const {
      isRevealed: isSendMessageDialogRevealed,
      cancel: cancelSendMessageDialog,
      confirm: confirmSendMessageDialog,
      reveal: revealSendMessageDialog,
    } = useConfirmDialog();

    watch(
      () => props.messages,
      () => {
        if (props.messages.length) {
          const messageScrollTo = props.messages.find(
            (item) => item.id === parseInt(itemId.value)
          );
          if (messageScrollTo) {
            scrollToElement(itemId.value);
          } else {
            scrollToBottom();
          }
        }
      }
    );
    watch(
      () => marginBottom.value,
      () => {
        scrollToBottom();
      }
    );
    watch(
      () => messagesEditorOutput.value,
      () => {
        if (messagesEditorOutput.value) {
          debounceStoreDraftMessage();
        }
      },
      { deep: true }
    );

    return {
      messageContainer,
      messagesEditor,
      marginBottom,
      onAttachmentAdded,
      onAttachmentRemoved,
      isSendMessageDialogRevealed,
      revealSendMessageDialog,
      cancelSendMessageDialog,
      confirmSendMessageDialog,
      wrapperElement,
      scrollToBottom,
      scrollToElement,
      messagesEditorOutput,
    };
  },
  data() {
    return {
      subjectModel: '',
      isPopupActive: false,
      subjectOptions: [],
      uploadProgress: 100,
      messagesEditorInitialValue: null,
      isDeleteDialogActive: false,
      selectedMessage: null,
    };
  },
  computed: {
    ...mapState({
      users: (state) => state.applicant.users,
      currentUser: (state) => state.auth.user,
      messageDrafts: (state) => state.drafts.messageDrafts,
    }),
    selectedSubjectSelectorHeight() {
      return `${
        this.convertPixelsToRem(document.documentElement.clientHeight) - 20.75
      }rem`;
    },
    sentMessages() {
      return [
        ...this.messages.filter((item) => !item.is_draft),
        ...this.messages
          .filter((item) => item.is_draft)
          .sort((a, b) => a.scheduled_for - b.scheduled_for),
      ];
    },
    draftSubject() {
      return this.messageDrafts?.[this.$route.params?.id]?.[0]?.subject;
    },
  },
  mounted() {
    this.listenMessagesChannel();
    this.$watch(
      'subjects',
      (newValue) => {
        if (newValue) {
          this.subjectOptions = newValue.map(({ subject, total }) => ({
            name: subject,
            value: subject,
            trailingText: total,
          }));

          if (this.$route.params?.subject) {
            const subjectParameter = decodeURI(this.$route.params?.subject);
            const subject = this.subjectOptions.find(
              (item) => item.name === subjectParameter
            );
            this.onSelectSubject(subject);
            this.setMessagesEditorInitialValue(subject);
          } else {
            this.onSelectSubject(this.subjectOptions[0]);
            this.setMessagesEditorInitialValue(this.subjectOptions[0]);
          }
        }
      },
      { deep: true, immediate: true }
    );
  },
  unmounted() {
    this.unsubscribeMessagesChannel();
  },
  methods: {
    openDeleteDialog(id) {
      this.selectedMessage = id;
      this.isDeleteDialogActive = true;
    },
    getMessageUser(message) {
      const { users, applicant } = this;
      const atsUser = users.find(({ id }) => message.user_id === id);
      if (atsUser) return atsUser;

      return message.auto_sent
        ? {
            name: 'Proxify Team',
          }
        : {
            name: applicant.full_name,
            roles: [
              {
                display_name: applicant.competency,
              },
            ],
            ...(applicant.applicant ?? {}),
          };
    },
    closeDeleteDialog() {
      this.selectedMessage = null;
      this.isDeleteDialogActive = false;
    },
    async handleConfirmDelete() {
      await this.onDeleteMessage(this.selectedMessage);
      this.closeDeleteDialog();
    },
    async handleSendEmail(data, { id }) {
      const { onUploadProgress } = this;
      const { name: subject } = this.selectedSubject;
      data.append('subject', subject);
      const { data: response } = await api.applications.emails.post(data, {
        id,
        onUploadProgress,
      });
      if (response.email) {
        this.onReceiveMessage(response.email);
      } else {
        this.showErrorMessage(
          "Failed to send email. Please ensure the applicant's email address is valid and try again."
        );
      }
    },
    async handleSendTemplate(data, id) {
      const { onUploadProgress } = this;
      this.handleSelectSubject(data.get('subject'));
      const { data: response } = await api.applications.emails.post(data, {
        id,
        onUploadProgress,
      });
      this.showSuccessMessage('Email template was sent successfully');
      this.onReceiveMessage(response.email[0]);
    },
    onUploadProgress({ loaded, total }) {
      this.uploadProgress = Math.ceil((loaded / total) * 100);
    },
    handleOpenSubjectPopup() {
      this.isPopupActive = true;
    },
    handleCloseSubjectPopup() {
      this.isPopupActive = false;
    },
    async handleSelectSubject(input) {
      this.handleCloseSubjectPopup();
      const selectedSubject = this.subjectOptions.find(
        ({ name }) => name === input
      );
      if (selectedSubject) {
        await this.onSelectSubject(selectedSubject);
        const messageScrollTo = this.messages.find(
          (item) => item.id === parseInt(this.$route.params?.itemId)
        );
        if (messageScrollTo) {
          this.scrollToElement(messageScrollTo.email?.id);
        }
        this.setMessagesEditorInitialValue(selectedSubject);
      } else {
        this.subjectModel = input;
        this.handleAddNewSubject();
        this.setMessagesEditorInitialValue(input);
      }
    },
    handleAddNewSubject() {
      if (this.subjectModel) {
        this.subjectOptions = [
          {
            value: this.subjectModel,
            name: this.subjectModel,
            trailingText: 0,
          },
          ...this.subjectOptions,
        ];
        this.onSelectSubject(this.subjectOptions[0]);
        this.isPopupActive = false;
        this.$store.commit('drafts/setMessageDraftSubject', {
          id: this.$route.params.id,
          subject: this.subjectModel,
        });
        this.subjectModel = '';
      }
    },
    listenMessagesChannel() {
      const { id } = this.$route.params;
      window.Echo.channel(`emails.${id}`).listen(
        'EmailReceivedEvent',
        ({ email }) => {
          if (email.user_id !== this.currentUser.id) {
            this.onReceiveMessage(email);
          }
        }
      );
    },
    sendTemplateDialog() {
      this.$refs.sendTemplateDialog.open();
    },
    unsubscribeMessagesChannel() {
      const { id } = this.$route.params;
      window.Echo.leaveChannel(`emails.${id}`);
    },
    clearContents() {
      this.$refs.messagesEditor.clearContents();
    },
    setMessagesEditorInitialValue(subject) {
      if (
        !subject &&
        this.draftSubject &&
        this.draftSubject !== 'New Subject'
      ) {
        this.onSelectSubject({
          value: this.draftSubject,
          name: this.draftSubject,
          trailingText: 0,
        });
      }

      this.$nextTick(() => {
        const message = this.messageDrafts?.[this.$route.params?.id]?.find?.(
          (item) =>
            item.subject ===
            (subject?.name ?? this.draftSubject ?? 'New Subject')
        )?.message;
        this.messagesEditorInitialValue = new Delta([
          {
            insert: message || '',
          },
        ]);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.message-container {
  @apply grow overflow-y-auto flex flex-col h-full p-4 gap-4;
}

.initial-message {
  @apply font-normal mt-4 text-center text-body-sm text-proxify-gray-500;
}

.subject {
  @apply text-center font-medium text-body-sm text-proxify-gray-600;
}

.activity-input-subject {
  @apply mx-4 flex;
}

.popup-title {
  @apply font-bold mb-4 font-poppins text-lg;
}

.popup-actions {
  @apply flex justify-between mt-8 text-proxify-primary;
}

.popup-actions .action-button {
  @apply cursor-pointer;
}

.button-subject-new {
  @apply inline-flex p-2;

  background-color: #f9f9f9;
}

.button-send-template {
  @apply mt-1
  mx-4
  px-2
  max-h-[2.1rem]
  bg-ats-snow
  inline-flex
  items-center
  rounded
  cursor-pointer;
}

.button-send-template:hover,
.button-subject-new:hover {
  background: #f0f0f0;
}

.button-send-template:active,
.button-subject-new:active {
  background: #ddd;
}
</style>
