import axios from 'axios';

export default {
  login(parameters) {
    return axios.post('/login', parameters);
  },
  reset(parameters) {
    return axios.post('/reset', parameters);
  },
};
