<script setup>
import { BaseBadge, BaseIcon, BaseTooltip } from '@/components/Base';
import PButton from '@/components/ProxifyUI/PButton.vue';

const props = defineProps({
  type: {
    type: String,
    required: false,
    default: () => 'button',
  },
  to: {
    type: [String, Object],
    required: false,
  },
  replace: {
    type: Boolean,
    required: false,
    default: () => false,
  },
  href: {
    type: String,
    required: false,
  },
  target: {
    type: String,
    required: false,
  },
  buttonLabel: {
    type: [String, Number],
    required: false,
  },
  icon: {
    type: String,
    required: false,
  },
  iconAppend: {
    type: String,
    required: false,
  },
  iconPrepend: {
    type: String,
    required: false,
  },
  iconRight: {
    type: String,
    required: false,
  },
  iconSize: {
    type: String,
    required: false,
  },
  outline: {
    type: Boolean,
    required: false,
    default: () => false,
  },
  flat: {
    type: Boolean,
    required: false,
    default: () => false,
  },
  unelevated: {
    type: Boolean,
    required: false,
    default: () => false,
  },
  rounded: {
    type: Boolean,
    required: false,
    default: () => false,
  },
  round: {
    type: Boolean,
    required: false,
    default: () => false,
  },
  push: {
    type: Boolean,
    required: false,
    default: () => false,
  },
  square: {
    type: Boolean,
    required: false,
    default: () => false,
  },
  glossy: {
    type: Boolean,
    required: false,
    default: () => false,
  },
  fab: {
    type: Boolean,
    required: false,
    default: () => false,
  },
  fabMini: {
    type: Boolean,
    required: false,
    default: () => false,
  },
  padding: {
    type: String,
    required: false,
  },
  color: {
    type: String,
    required: false,
  },
  textColor: {
    type: String,
    required: false,
  },
  noCaps: {
    type: Boolean,
    required: false,
    default: () => false,
  },
  noWrap: {
    type: Boolean,
    required: false,
    default: () => false,
  },
  dense: {
    type: Boolean,
    required: false,
    default: () => false,
  },
  ripple: {
    type: [Boolean, Object],
    required: false,
  },
  tabindex: {
    type: [Number, String],
    required: false,
    default: () => 0,
  },
  align: {
    type: String,
    required: false,
    default: () => 'center',
  },
  stack: {
    type: Boolean,
    required: false,
    default: () => false,
  },
  stretch: {
    type: Boolean,
    required: false,
    default: () => false,
  },
  loading: {
    type: Boolean,
    required: false,
    default: () => false,
  },
  disable: {
    type: Boolean,
    required: false,
    default: () => false,
  },
  capitalize: {
    type: Boolean,
    required: false,
    default: () => false,
  },
  tooltipText: {
    type: String,
    required: false,
  },
  tooltipSelf: {
    type: String,
    required: false,
  },
  tooltipOffset: {
    type: Array,
    required: false,
    default: () => [0, 6],
  },
  tooltipAnchor: {
    type: String,
    required: false,
  },
  tooltipArrow: {
    type: String,
    required: false,
  },
  badgeLabel: {
    type: [String, Number],
    required: false,
  },
  size: {
    type: String,
    required: false,
  },
});
</script>
<template>
  <PButton v-bind="props">
    <template #prepend>
      <slot name="prepend">
        <BaseIcon v-if="iconPrepend" :name="iconPrepend" :size="iconSize" />
      </slot>
    </template>
    <slot>
      {{ buttonLabel }}
      <BaseTooltip
        v-if="tooltipText"
        :offset="tooltipOffset"
        :self="tooltipSelf"
        :anchor="tooltipAnchor"
        :arrow-position="tooltipArrow"
      >
        <span v-safe-html="tooltipText"></span>
      </BaseTooltip>
      <div v-if="badgeLabel" class="absolute bottom-[26px] left-[26px]">
        <BaseBadge
          :label="badgeLabel"
          color="error"
          size="xs"
          class="border border-proxify-error-100"
        />
      </div>
    </slot>
    <template #append>
      <slot name="append">
        <BaseIcon v-if="iconAppend" :name="iconAppend" :size="iconSize" />
      </slot>
    </template>
  </PButton>
</template>
<style lang="scss">
.q-btn__content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap !important;
  white-space: nowrap !important;
  gap: 8px;
}
</style>
