import { computed } from 'vue';
import { useWindowSize } from '@vueuse/core';

export default function useNormalizedWindowSize() {
  const { width, height } = useWindowSize();

  const scale = computed(() => {
    if (width.value < 1280) {
      return 0.9;
    } else if (width.value <= 1320) {
      return 0.925;
    } else if (width.value <= 1360) {
      return 0.95;
    } else if (width.value <= 1400) {
      return 0.975;
    } else if (width.value <= 1440) {
      return 0.99;
    } else {
      return 1;
    }
  });

  const normalizedWidth = computed(() => width.value / scale.value);
  const normalizedHeight = computed(() => height.value / scale.value);

  return {
    width: normalizedWidth,
    height: normalizedHeight,
  };
}
