<script setup>
import { computed, ref } from 'vue';
import { BaseButton, BaseIcon } from '@/components/Base';
import { PAvatar, PPopupProxy, PSeparator } from '@/components/ProxifyUI';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

const route = useRoute();
const store = useStore();

const currentUser = computed(() => store.state.auth.user);
const forcePlaceholder = ref(false);

const menuItems = computed(() => [
  {
    name: 'Home',
    icon: 'home-line',
    path: '/',
    active: route.name === 'Home',
  },
  {
    name: 'Applications',
    icon: 'file-search02',
    path: '/applications',
    active: route.name === 'Applications',
  },
]);
</script>
<template>
  <div
    class="flex flex-col py-8 items-center border-r border-proxify-gray-200 gap-6 h-screen"
  >
    <BaseButton
      icon-size="32px"
      icon-prepend="proxify-logo"
      class="p-0"
      to="/"
    />
    <div class="flex flex-col justify-center gap-2 pt-[19px] pb-8">
      <BaseButton
        v-for="(item, index) in menuItems"
        :key="index"
        :icon-prepend="item.icon"
        class="icon-navitem"
        :class="{ current: item.active }"
        :tooltip-text="item.name"
        tooltip-self="center left"
        tooltip-anchor="center right"
        tooltip-arrow="left"
        :tooltip-offset="[7, 0]"
        :to="item.path"
      />
    </div>
    <div class="grow"></div>
    <PAvatar class="cursor-pointer">
      <img
        v-if="currentUser.avatar_url && !forcePlaceholder"
        :src="currentUser.avatar_url"
        :alt="currentUser.name"
        @error="forcePlaceholder = true"
      />
      <BaseIcon v-else name="user01" />
      <PPopupProxy
        class="p-popup"
        self="center left"
        anchor="center right"
        arrow-position="left"
        :offset="[27, 0]"
      >
        <div class="py-2 px-3">
          <div class="p-6 flex gap-3">
            <PAvatar>
              <img
                v-if="currentUser.avatar_url && !forcePlaceholder"
                :src="currentUser.avatar_url"
                :alt="currentUser.name"
                @error="forcePlaceholder = true"
              />
              <BaseIcon v-else name="user01" />
            </PAvatar>
            <div>
              <div>
                {{ currentUser.name }}
              </div>
              <div
                v-for="role in currentUser.roles"
                :key="role.name"
                class="font-normal"
              >
                {{ role.display_name }}
              </div>
            </div>
          </div>
          <PSeparator />
          <div class="px-6 py-3">
            <BaseButton href="/logout" class="py-[10px]">
              Log out
              <template #append
                ><BaseIcon name="log-out01" size="20px"
              /></template>
            </BaseButton>
          </div>
        </div>
      </PPopupProxy>
    </PAvatar>
  </div>
</template>

<style lang="scss">
.p-popup {
  box-shadow: 0 4px 6px -2px rgb(16 24 40 / 3%),
    0 12px 16px -4px rgb(16 24 40 / 8%);
  overflow: visible;

  &::before {
    content: " ";
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid transparent;
    position: absolute;
    border-right-color: $tooltip-background;
    top: 50%;
    left: -10px;
    margin-top: -5px;
  }
}
</style>
