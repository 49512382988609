<template>
  <div :data-name="name">
    <div
      v-if="parse(modelValue ?? format([])).length || !readOnly"
      class="text-proxify-black mb-2"
    >
      {{ name }}
      <span
        v-if="required && !readOnly"
        class="text-disabled-normal font-normal text-xs"
      >
        *required
      </span>
    </div>
    <div
      v-for="(item, index) in readOnly
        ? parse(modelValue ?? format([]))
        : options"
      :key="`${name}[${index}]`"
    >
      <component
        :is="`AppForm${capitalizeFirstLetter(type)}`"
        v-model="items"
        :name="name"
        :label="item"
        :truthy-value="item"
        :initial-value="
          type === 'radio' ? modelValue : parse(modelValue ?? format([]))
        "
        :value="item"
        :required="required"
        :disabled="disabled"
        :read-only="readOnly"
        @update:model-value="onUpdate"
        @update:input-value="onUpdateInput"
      />
    </div>
    <ErrorMessage
      v-slot="{ message }"
      :name="name"
      as="div"
    >
      <span class="text-ats-red text-sm">{{ message }}</span>
    </ErrorMessage>
  </div>
</template>

<script>
export default {
  name: 'AppFormItemGroup',
  props: {
    type: {
      type: String,
      required: false,
      default() {
        return 'checkbox';
      },
    },
    modelValue: {
      type: [Array, String],
      required: false,
    },
    options: {
      type: Array,
      required: true,
    },
    name: {
      type: String,
      required: false,
    },
    required: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    disabled: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    readOnly: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    parse: {
      type: Function,
      required: false,
      default(item) {
        return item;
      },
    },
    format: {
      type: Function,
      required: false,
      default(item) {
        return item;
      },
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      inputValue: '',
      items: undefined,
    };
  },
  methods: {
    onUpdate(value) {
      if (this.type === 'radio') {
        this.$emit('update:modelValue', value);
      } else {
        this.$emit(
          'update:modelValue',
          this.format(
            this.options
              .map((item) => {
                if (value.includes(item)) {
                  return item === 'Other:' ? this.inputValue : item;
                }
              })
              .filter(Boolean)
          )
        );
      }
    },
    onUpdateInput(value) {
      this.inputValue = value;
      this.onUpdate(this.items ?? []);
    },
    capitalizeFirstLetter([first = '', ...rest]) {
      return [first.toUpperCase(), ...rest].join('');
    },
  },
};
</script>
