<template>
  <div class="sticky top-[140px]">
    <div class="applicant-switcher">
      <router-link
        v-for="(tab, index) in tabs"
        :key="index"
        v-slot="{ navigate }"
        :to="{ name: 'Applicant', params: { tab: tab.path } }"
        custom
      >
        <button
          role="link"
          :class="{ active: tab.path === currentTab }"
          @click="navigate"
        >
          {{ tab?.name }}
          <div v-if="showDraftTooltip(tab)">
            <BaseIcon
              name="edit03"
              size="14px"
              class="ml-1"
            />
            <BaseTooltip :offset="[0, 6]">
              <div class="flex whitespace-nowrap">
                <span>Draft</span>
                <span v-if="draftMessage && tab.name === 'Emails'">
                  s ({{ draftsMessageCount }})
                </span>
              </div>
              <div class="font-normal truncate max-w-48">
                {{ getDraftText(tab) }}
              </div>
            </BaseTooltip>
          </div>
        </button>
      </router-link>
    </div>
  </div>
</template>

<script setup>
import { BaseIcon, BaseTooltip } from '@/components/Base';
import { computed } from 'vue';
import { useRouteParams } from '@vueuse/router';
import { useStore } from 'vuex';

const props = defineProps({
  currentTab: {
    type: String,
    required: true,
  },
});

const store = useStore();
const applicationId = useRouteParams('id');

const tabs = [
  {
    name: 'Emails',
    path: 'emails',
  },
  {
    name: 'Interviews',
    path: 'interviews',
  },
  {
    name: 'Notes',
    path: 'notes',
  },
  {
    name: 'Tests',
    path: 'tests',
  },
  {
    name: 'History',
    path: 'history',
  },
  {
    name: 'Todos',
    path: 'todos',
  },
];

const draftMessage = computed(() => {
  return (
    store.state.drafts.messageDrafts?.[applicationId.value] &&
    store.state.drafts.messageDrafts?.[applicationId.value][0].message
  );
});

const draftsMessageCount = computed(() => {
  return store.state.drafts.messageDrafts?.[applicationId.value].length;
});

const draftNote = computed(() => {
  return store.state.drafts.noteDrafts.find(
    (item) => item.id === applicationId.value
  )?.note;
});

const getDraftText = (tab) => {
  if (tab.name === 'Emails') {
    return draftMessage.value;
  } else if (tab.name === 'Notes') {
    return draftNote.value;
  }
};

const showDraftTooltip = (tab) => {
  return (
    tab.path !== props.currentTab &&
    ((tab.name === 'Emails' && draftMessage.value) ||
      (tab.name === 'Notes' && draftNote.value))
  );
};
</script>

<style scoped>
.applicant-switcher {
  @apply p-1
  mx-8
  flex
  flex-row
  gap-x-2
  bg-proxify-gray-50
  rounded-lg
  border
  border-proxify-gray-100
  text-proxify-gray;
}

.applicant-switcher button {
  @apply py-2
  px-3
  flex
  justify-center
  grow
  text-proxify-gray-500
  text-body-sm
  font-medium
  rounded-md;
}

.applicant-switcher > .active {
  @apply text-proxify-gray-500
  bg-white
  shadow-sm;
}
</style>
