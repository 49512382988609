<script setup>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { useElementBounding } from '@vueuse/core';
import useNormalizedWindowSize from '@/composables/useNormalizedWindowSize';
import api from '@/api';

import HomeInterviews from '@/components/Elements/Home/HomeInterviews.vue';
import KPICard from '@/components/Elements/Home/KpiCard.vue';

const store = useStore();
const router = useRouter();
const currentUser = computed(() => store.state.auth.user);
const isLogged = computed(() => store.getters['auth/isLogged']);

const countries = computed(() => store.state.applicant.countries);
const skills = computed(() => store.state.applicant.skills);
const sources = computed(() => store.state.application.sources);
const sourcers = computed(() => store.state.applicant.sourcers);
const stages = computed(() => store.state.applicant.stages);
const users = computed(() => store.state.applicant.users);

const kpiWrapper = ref(null);
const { bottom } = useElementBounding(kpiWrapper);
const { height } = useNormalizedWindowSize();
const containerHeight = computed(() => {
  return `${height.value - bottom.value - 82}px`;
});

document.title = 'Proxify ATS | Home';
if (!isLogged.value) {
  router.push('/login');
}

const getSources = async () => {
  if (sources.value.length) return;
  const { data: { data } = {} } = await api.data.sources();
  store.commit('application/setSources', data);
};
const getCountries = async () => {
  if (countries.value.length) return;
  const { data: { countries: countriesData } = {} } =
    await api.data.countries();
  store.commit('applicant/setCountries', countriesData);
};
const getSkills = async () => {
  if (skills.value.length) return;
  const { data: { data } = {} } = await api.data.skills();
  store.commit('applicant/setSkills', data);
};
const getSourcers = async () => {
  if (sourcers.value.length) return;
  const { data: { data } = {} } = await api.users.sourcers();
  store.commit('applicant/setSourcers', data);
};
const getStages = async () => {
  if (stages.value.length) return;
  const { data: { data } = {} } = await api.stages.getStages();
  store.commit('applicant/setStages', data);
};
const getUsers = async () => {
  if (users.value.length) return;
  const { data: { data } = {} } = await api.users.getAll();
  store.commit('applicant/setUsers', data);
};

if (isLogged.value) {
  getSources();
  getSourcers();
  getCountries();
  getSkills();
  getStages();
  getUsers();
}

store.commit('appTraffic/setCurrentRoute', [
  {
    name: `Welcome to ATS${
      currentUser.value?.name ? `, ${currentUser.value.name.split(' ')[0]}` : ''
    }!`,
    path: '/',
    icon: 'home-line',
  },
]);
</script>
<template>
  <div class="home">
    <div class="home-container">
      <div
        ref="kpiWrapper"
        class="grid gap-8 grid-cols-1 md:grid-cols-2"
      >
        <KPICard type="applicant" />
        <KPICard type="interview" />
      </div>
      <HomeInterviews :container-height="containerHeight" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.home {
  @apply flex justify-center;

  &-container {
    @apply flex flex-col h-full w-full grow gap-8 p-8 max-w-[1359px];
  }
}
</style>
