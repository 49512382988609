<template>
  <router-link to="/" class="contents">
    <img class="logo" src="../../assets/logo-black.svg" alt="Proxify Logo" />
  </router-link>
</template>

<script>
export default {
  name: 'ProxifyLogo',
};
</script>

<style scoped>
.logo {
  @apply w-1/2;

  transition: 0.3s;
}

.logo:hover {
  filter: drop-shadow(3px 3px 5px rgb(21 23 47 / 20%));
}
</style>
