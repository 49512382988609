<template>
  <div class="app-switcher">
    <button
      v-for="(item, index) in labels"
      :key="index"
      :class="buttonClass(index)"
      @click.prevent="onChange(index)"
    >
      {{ item }}
    </button>
  </div>
</template>

<script>
export default {
  props: {
    currentTab: {
      type: Number,
      required: true,
    },
    labels: {
      type: Array,
      required: true,
      validator(value) {
        return value.length <= 6;
      },
    },
    onChange: {
      type: Function,
      required: true,
    },
  },
  methods: {
    buttonClass(index) {
      const active = index === this.currentTab ? 'active' : null;
      return `w-1/${this.labels.length} ${active}`;
    },
  },
};
</script>

<style scoped>
.app-switcher {
  @apply h-10
  flex
  inline-flex
  text-base
  text-disabled-normal
  bg-[#f0f0f0]
  rounded-lg
  p-1
  shadow-inner;
}

.app-switcher button {
  @apply whitespace-nowrap font-semibold text-base px-3;
}

.app-switcher > .active {
  @apply bg-proxify-primary rounded-lg text-white;
}
</style>
