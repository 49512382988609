<template>
  <div class="app-card-header" :class="valueClass">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'AppCardHeader',
  props: {
    valueClass: {
      type: [Object, String],
      required: false,
    },
  },
};
</script>

<style scoped>
.app-card-header {
  @apply flex flex-row;
}
</style>
