<script setup>
import { BaseBadge, BaseIcon, BaseTooltip } from '@/components/Base';
import { stringUtils } from '@/utils';

const { capitalizeFirstLetter } = stringUtils;

defineProps({
  recording: {
    type: Object,
    required: true,
  },
});
</script>
<template>
  <BaseBadge
    v-if="recording?.status === 'failed'"
    size="sm"
    color="error"
  >
    Failed
    <template #append>
      <BaseIcon
        name="info-circle"
        color="proxify-error-500"
      />
      <BaseTooltip
        max-width="216px"
        :offset="[0, 10]"
      >
        <div class="flex flex-col gap-y-2">
          <div class="text-body-sm font-semibold">Recording failed</div>
          <div class="text-body-xs font-normal text-proxify-gray-700">
            No speech is detected.
          </div>
          <div class="text-body-xs font-normal text-proxify-gray-400">
            Response from SpeechAce
          </div>
        </div>
      </BaseTooltip>
    </template>
  </BaseBadge>
  <BaseBadge
    v-else-if="recording?.status === 'ready'"
    size="sm"
    color="success"
  >
    Processed
    <template #append>
      <BaseIcon
        name="info-circle"
        color="proxify-success-500"
      />
      <BaseTooltip
        max-width="216px"
        :offset="[0, 10]"
      >
        <div
          class="flex flex-col gap-y-2"
          data-testid="speechace-result"
        >
          <div class="text-body-sm font-semibold">Recording processed</div>
          <div
            v-for="(score, name, index) in recording?.speechace_result?.scores
              ?.cefr_score"
            :key="index"
            class="flex gap-x-2 text-body-xs font-normal text-proxify-gray-700"
          >
            {{ capitalizeFirstLetter(name) }}:
            <span class="font-semibold">
              {{ score }}
            </span>
          </div>
          <div class="text-body-xs font-normal text-proxify-gray-400">
            Response from SpeechAce
          </div>
        </div>
      </BaseTooltip>
    </template>
  </BaseBadge>
  <BaseBadge
    v-else-if="recording?.status === 'pending'"
    size="sm"
  >
    Pending
  </BaseBadge>
</template>
