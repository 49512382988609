<script setup>
import BaseChartBar from '@/components/Base/BaseChartBar.vue';
import { ratingUtils } from '@/utils';
import BaseBadge from '@/components/Base/BaseBadge.vue';
import { computed } from 'vue';

const props = defineProps({
  hourlyRate: {
    type: Number,
    required: true,
  },
  rates: {
    type: Array,
    required: true,
  },
  currency: {
    type: String,
    default: '€',
  },
});

const verdict = computed(() => {
  return ratingUtils.calculateVerdict(props.hourlyRate, props.rates);
});

const color = computed(() => {
  const colors = {
    low: 'gray',
    typical: 'success',
    high: 'error',
  };

  return colors[verdict.value];
});
</script>

<template>
  <div class="pb-[12px] border-b-[1px] border-proxify-gray-200">
    <div
      :key="hourlyRate"
      class="flex items-center flex-wrap justify-between mb-2"
    >
      <span class="text-proxify-gray-900">Expected Hourly Rate</span>
      <span class="text-right flex-grow py-[2px]">
        <BaseBadge
          size="sm"
          :color="color"
          class="bg-transparent !text-body-sm !font-medium"
        >
          {{ currency + hourlyRate }}
        </BaseBadge>
        <BaseBadge
          size="sm"
          :color="color"
          class="capitalize !text-body-sm !font-medium"
        >
          {{ verdict }}
        </BaseBadge>
      </span>
    </div>
    <BaseChartBar
      :rates="rates"
      :value="hourlyRate"
      :unit="currency"
    ></BaseChartBar>
  </div>
</template>
