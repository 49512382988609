<template>
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    stroke="currentColor"
  >
    <path
      d="M10.666 14v-1.333A2.667 2.667 0 0 0 7.999 10H3.333a2.667 2.667 0 0 0-2.667 2.667V14M5.667 7.333a2.667 2.667 0 1 0 0-5.333 2.667 2.667 0 0 0 0 5.333ZM13.334 5.333v4M15.334 7.333h-4"
      stroke-width="1.333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconUserPlus',
};
</script>

<style scoped></style>
