<template>
  <div class="app-card-text-data">
    <div :class="titleClass" class="font-semibold">{{ title }}</div>
    <div :class="valueClass" class="pl-1">{{ value }}</div>
  </div>
</template>

<script>
export default {
  name: 'AppCardTextData',
  props: {
    title: {
      type: String,
      required: true,
    },
    titleClass: {
      type: [Object, String],
      required: false,
      default() {
        return { 'text-disabled-dark': true };
      },
    },
    value: {
      type: String,
      required: true,
    },
    valueClass: {
      type: [Object, String],
      required: false,
      default() {
        return { 'text-proxify-black': true };
      },
    },
  },
};
</script>

<style scoped>
.app-card-text-data {
  @apply flex flex-row;
}
</style>
