<script>
/**
 * @typedef { import('vue').Ref } Ref
 */

/**
 * Represents the personal information of an applicant.
 *
 * @typedef {Object} ApplicantPersonalInformation
 * @property {?string} firstName - Applicant's first name.
 * @property {?string} lastName - Applicant's last name.
 * @property {?string} email - Email address of the applicant.
 * @property {?string} phone - Phone number of the applicant.
 * @property {?string} city - City of residence.
 * @property {?boolean} relocate - Indicates if the applicant is willing to relocate.
 * @property {?string} country - Country of residence.
 */
/**
 * Represents a country.
 *
 * @typedef {Object} Country
 * @property {number} id - Unique identifier for the country.
 * @property {string} name - Name of the country.
 * @property {string} code - ISO 3166-1 alpha-2 code of the country.
 * @property {?string} continent - Continent of the country.
 * @property {?string} currency - Currency of the country.
 * @property {?string} original_currency - Original currency of the country.
 * @property {?boolean} eu - Indicates if the country is part of the European Union.
 * @property {?boolean} risky - Indicates if the country is considered risky.
 * @property {?number} rate_index - Rate index of the country.
 * @property {?string} current_exchange_rage - Current exchange rate of the country.
 * @property {?string} created_at - Date and time when the country was created.
 * @property {?string} updated_at - Date and time when the country was last updated.
 */
export default {
  name: 'ApplicationFormPersonalInformation',
};
</script>
<script setup>
import {
  BaseCheckbox,
  BaseCountrySelector,
  BaseInputField,
} from '@/components/Base';
import { useStore } from 'vuex';
import { computed, ref, toRefs, watch } from 'vue';

const props = defineProps({
  city: {
    type: String,
    required: false,
    default() {
      return '';
    },
  },
  country: {
    type: String,
    required: false,
    default() {
      return '';
    },
  },
  email: {
    type: String,
    required: false,
    default() {
      return '';
    },
  },
  firstName: {
    type: String,
    required: false,
    default() {
      return '';
    },
  },
  lastName: {
    type: String,
    required: false,
    default() {
      return '';
    },
  },
  phone: {
    type: String,
    required: false,
    default() {
      return '';
    },
  },
  relocate: {
    type: Boolean,
    required: false,
    default() {
      return false;
    },
  },
  requiredFields: {
    type: Array,
    required: false,
    default() {
      return [];
    },
  },
});

const emit = defineEmits([
  'update:city',
  'update:country',
  'update:email',
  'update:first-name',
  'update:last-name',
  'update:phone',
  'update:relocate',
]);

const store = useStore();
const { country } = toRefs(props);

/** @type {Ref<Country[]>} */
const countries = computed(() => store.state.applicant.countries);
/** @type {Ref<string>} */
const countryCode = computed(() => {
  const countryData = getCountry(country.value);
  return countryData?.code || '';
});

/**
 * @param {string} item
 * @returns {Country}
 */
const getCountry = (item) => {
  return countries.value.find(
    ({ code, name }) => code === item || name === item
  );
};
/** @type {Ref<ApplicantPersonalInformation>} */
const form = ref({});

watch(
  () => ({
    city: props.city,
    country: props.country,
    email: props.email,
    firstName: props.firstName,
    lastName: props.lastName,
    phone: props.phone,
    relocate: props.relocate,
  }),
  (newProps) => {
    form.value = { ...newProps };
  },
  { immediate: true }
);

watch(
  () => form.value,
  /** @param {ApplicantPersonalInformation} newForm */
  (newForm) => {
    emit('update:city', newForm.city);
    emit('update:country', getCountry(newForm.country)?.code);
    emit('update:email', newForm.email);
    emit('update:first-name', newForm.firstName);
    emit('update:last-name', newForm.lastName);
    emit('update:phone', newForm.phone);
    emit('update:relocate', newForm.relocate);
  },
  { deep: true, immediate: true }
);

const handleChangeCountry = (value) => {
  form.value = {
    ...form.value,
    country: value.country,
    city: value.city,
  };
};
</script>
<template>
  <div class="flex flex-col gap-4">
    <div class="text-proxify-gray-900 text-body-md font-semibold">
      Personal information
    </div>
    <div class="grid gap-4 grid-cols-2 w-full">
      <div class="flex flex-col gap-[6px]">
        <div class="text-body-sm font-medium text-proxify-gray-700">
          <span>First name</span>
          <span v-if="requiredFields.includes('First name')">*</span>
        </div>
        <BaseInputField
          v-model="form.firstName"
          name="First name"
        />
      </div>
      <div class="flex flex-col gap-[6px]">
        <div class="text-body-sm font-medium text-proxify-gray-700">
          <span>Last name</span>
          <span v-if="requiredFields.includes('Last name')">*</span>
        </div>
        <BaseInputField
          v-model="form.lastName"
          name="Last name"
        />
      </div>
    </div>
    <div class="grid gap-4 grid-cols-2">
      <div class="flex flex-col gap-[6px]">
        <div
          class="text-body-sm font-medium text-proxify-gray-700"
          type="email"
        >
          <span>E-mail</span>
          <span v-if="requiredFields.includes('E-mail')">*</span>
        </div>
        <BaseInputField
          v-model="form.email"
          name="E-mail"
        />
      </div>
      <div class="flex flex-col gap-[6px]">
        <div
          class="text-body-sm font-medium text-proxify-gray-700"
          type="phone"
        >
          <span>Phone</span>
          <span v-if="requiredFields.includes('Phone')">*</span>
        </div>
        <BaseInputField
          v-model="form.phone"
          name="Phone"
        />
      </div>
    </div>
    <div class="flex flex-col gap-3">
      <BaseCountrySelector
        class="gap-4"
        input-width="288px"
        :city="city"
        :required-fields="
          requiredFields.filter((field) => ['Country', 'City'].includes(field))
        "
        :country-code="countryCode"
        @change="handleChangeCountry"
      />
      <BaseCheckbox
        v-model="form.relocate"
        label="Plans to relocate in <6 months"
        name="Plans to relocate in <6 months"
      />
    </div>
  </div>
</template>
