<script setup>
defineProps({
  background: {
    type: String,
    default: '#f4f5f7',
  },
  clickable: {
    type: Boolean,
    default: false,
  },
  color: {
    type: String,
    default: '#344054',
  },
  dense: {
    type: Boolean,
    default: false,
  },
  outline: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String,
    default: 'sm',
  },
  square: {
    type: Boolean,
    default: false,
  },
  ripple: {
    type: Boolean,
    default: false,
  },
});

const sizes = {
  xs: 'p-badge-xs',
  sm: 'p-badge-sm',
  md: 'p-badge-md',
  lg: 'p-badge-lg',
  xl: 'p-badge-xl',
};
</script>

<template>
  <q-chip
    :clickable="clickable"
    :dense="dense"
    :outline="outline"
    :square="square"
    class="p-badge"
    :class="sizes[size]"
    :ripple="ripple"
  >
    <slot name="prepend"></slot>
    <slot></slot>
    <slot name="append"></slot>
  </q-chip>
</template>

<style lang="scss">
.p-badge {
  @apply font-inter font-medium m-0 #{!important};
  background: v-bind(background);
  color: v-bind(color);
}

.q-chip__content {
  @apply flex;
}

.p-badge-xs {
  @apply h-[22px]
  text-body-xs
  rounded-[16px]
  py-0.5
  px-1
  #{!important};
  .q-chip__content {
    @apply h-[18px]
    p-0
    #{!important};
  }
}

.p-badge-sm {
  @apply h-[24px]
  text-body-xs
  rounded-[16px]
  py-[4px]
  px-[8px]
  gap-1
  #{!important};
  .q-chip__content {
    @apply h-[24px]
    py-[4px]
    px-0
    #{!important};
  }
}

.p-badge-md {
  @apply text-body-sm
  rounded-[16px]
  py-[4px]
  px-[12px]
  gap-1
  #{!important};
  .q-chip__content {
    @apply px-0
    #{!important};
  }
}

.p-badge-lg {
  @apply h-[unset]
  text-body-sm
  rounded-[100px]
  py-[10px]
  px-[16px]
  gap-[6px]
  #{!important};
  .q-chip__content {
    @apply px-0
    #{!important};
  }
}

.p-badge-xl {
  @apply h-[40px]
  text-body-md
  rounded-[100px]
  py-[8px]
  px-[20px]
  gap-[6px]
  #{!important};
  .q-chip__content {
    @apply h-[40px]
    py-[7px]
    px-0
    #{!important};
  }
}
</style>
