import axios from 'axios';
import auth from './auth';
import stages from './stages';
import applications from './applications';
import scheduler from './scheduler';
import notifications from './notifications';
import rejectionReasons from './rejection-reasons';
import data from './data';
import todos from './todos';
import metrics from './metrics';
import users from './users';

axios.defaults.baseURL = process.env.VUE_APP_API;

const api = {
  auth,
  stages,
  applications,
  scheduler,
  notifications,
  rejectionReasons,
  data,
  todos,
  metrics,
  users,
};

export default api;
