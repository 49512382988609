import axios from 'axios';

export default {
  sources() {
    return axios.get('/data/sources');
  },
  skills() {
    return axios.get('/data/skills');
  },
  countries() {
    return axios.get('/data/countries');
  },
  industries() {
    return axios.get('/data/industries');
  },
};
