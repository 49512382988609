<template>
  <PDialog
    v-model="isSendTemplateDialogOpen"
    persistent
    position="standard"
  >
    <BaseConfirmationCard
      :on-confirm="handleSubmit(handleSendTemplate, handleInvalidSubmit)"
      :on-close="close"
      :is-loading="isLoading"
      paddingless
    >
      <template #title>Select a template and send</template>
      <div class="flex flex-col gap-4 px-6">
        <div
          class="font-medium text-body-sm font-proxify-gray-700 flex flex-col gap-1.5"
        >
          <span>E-mail template</span>
          <BaseInputDropdown
            v-model="templateToSend"
            name="Email template"
            :options="templates"
            map-options
            type="search"
            @update:model-value="handleChangeTemplates"
          >
            <template #after>
              <BaseButton
                class="icon-tertiary-gray border-none p-2.5 trailing-button"
                icon-prepend="eye"
                :disable="!templateToSend"
                @click="onClickLookup"
              />
            </template>
          </BaseInputDropdown>
        </div>
        <template v-if="templateToSend">
          <BaseLabelScheduler
            v-model="sendAction"
            class="gap-1"
          />
          <div>
            <div class="field-title">Edit text before sending</div>
            <TemplateEditor
              ref="templateEditor"
              v-model="templateToSend"
              :on-update-h-t-m-l="onUpdateHTMLOutput"
              :set-edited="setTemplateEdited"
            />
          </div>
          <div
            v-if="linkField"
            class="font-medium text-body-sm font-proxify-gray-700 flex flex-col gap-1.5"
          >
            <span>Link</span>
            <BaseInputField
              v-model="link"
              name="Link"
              class="action-link"
            />
          </div>
          <div v-if="attachmentField">
            <div
              class="font-medium text-body-sm font-proxify-gray-700 flex flex-col gap-1.5"
            >
              File
            </div>
            <BaseInputFile
              title="Attach file"
              name="Attachment"
              @handle-files-changed="(event) => (file = event)"
            />
          </div>
        </template>
      </div>
    </BaseConfirmationCard>
  </PDialog>
  <PDialog
    :model-value="isRevealed"
    position="standard"
    persistent
  >
    <BaseConfirmationCard
      v-if="isRevealed"
      cancel-button-text="Cancel"
      confirm-button-text="Change Template"
      :on-close="cancel"
      :on-confirm="confirm"
    >
      <template #title>Unsaved Changes</template>
      <div>
        We noticed you are changing the template after making some edits, any
        edits will be lost
      </div>
    </BaseConfirmationCard>
  </PDialog>
  <!--Template-->
  <PDialog
    v-model="showPreview"
    position="standard"
  >
    <EmailPreviewCard
      v-if="templateToSend"
      :title="templateToSend.name"
      :template="templateToSend.template"
      :on-close="() => (showPreview = false)"
    />
  </PDialog>
</template>

<script setup>
import api from '@/api';
import TemplateEditor from '@/components/Elements/Scaffold/TemplateEditor.vue';
import { stringUtils } from '@/utils';
import { computed, reactive, toRefs, watch } from 'vue';
import EmailPreviewCard from '@/components/Elements/Scaffold/EmailPreviewCard.vue';
import { useForm } from 'vee-validate';
import { useConfirmDialog } from '@vueuse/core';
import { useRouteParams } from '@vueuse/router';
import { PDialog } from '@/components/ProxifyUI';
import {
  BaseConfirmationCard,
  BaseButton,
  BaseInputField,
  BaseInputDropdown,
  BaseInputFile,
  BaseLabelScheduler,
} from '@/components/Base';

const props = defineProps({
  onSend: {
    type: Function,
    required: true,
  },
  type: {
    type: String,
    required: false,
    default() {
      return 'email';
    },
  },
});

const applicationId = useRouteParams('id');
const state = reactive({
  file: null,
  link: '',
  showPreview: false,
  emailPreviewTop: null,
  templates: [],
  templateToSend: null,
  htmlBody: '',
  isTemplateEdited: false,
  isSendTemplateDialogOpen: false,
  isLoading: false,
  isSending: false,
  sendAction: undefined,
});

const {
  file,
  link,
  showPreview,
  templates,
  templateToSend,
  htmlBody,
  isTemplateEdited,
  isSendTemplateDialogOpen,
  isLoading,
  isSending,
  sendAction,
} = toRefs(state);

const { reveal, isRevealed, cancel, confirm, onConfirm } = useConfirmDialog();

const attachmentField = computed(() => {
  return (
    templateToSend.value?.fields?.length &&
    templateToSend.value?.fields.find((item) => item.type === 'file')
  );
});

const linkField = computed(() => {
  return (
    templateToSend.value?.fields?.length &&
    templateToSend.value?.fields.find((item) => item.type === 'string')
  );
});

const EMAIL_TEMPLATE = 'Email template';
const LINK = 'Link';
const ATTACHMENT = 'Attachment';

const isAttachmentRequired = () =>
  (templateToSend.value?.validation?.attachment ?? []).includes('required');

const validationSchema = computed(() => {
  return {
    [EMAIL_TEMPLATE]: { required: true },
    [LINK]: { required: Boolean(linkField.value) },
    [ATTACHMENT]: {
      required: isAttachmentRequired(),
    },
  };
});
const { handleSubmit, setFieldValue, handleReset } = useForm({
  validationSchema,
  initialValues: {
    'Email template': null,
    Link: '',
    Attachment: null,
  },
});

const handleInvalidSubmit = ({ errors }) => {
  const invalidQuestions = Object.keys(errors);
  const firstInvalidQuestionElement = document.querySelector(
    `[data-name='${invalidQuestions[0]}']`
  );
  if (firstInvalidQuestionElement) {
    firstInvalidQuestionElement.scrollIntoView();
  }
};
const onUpdateHTMLOutput = (value) => {
  htmlBody.value = value;
};

const setTemplateEdited = (value) => {
  isTemplateEdited.value = value;
};

const close = () => {
  showPreview.value = false;
  sendAction.value = undefined;
  templateToSend.value = null;
  htmlBody.value = '';
  link.value = '';
  file.value = null;
  handleReset();
  isSendTemplateDialogOpen.value = false;
};

const getTemplates = async () => {
  isLoading.value = true;
  const { data } = await api.applications.emails.getTemplates({
    id: applicationId.value,
  });
  isLoading.value = false;
  templates.value = data.templates.map((item) => ({
    ...item,
    markdown: stringUtils.convertToDelta(item.markdown),
  }));
};

const handleChangeTemplates = async (template) => {
  onConfirm(() => {
    if (!template) {
      setFieldValue('Email template', null);
      return;
    }
    const { id } = template;
    templateToSend.value = JSON.parse(
      JSON.stringify(templates.value.find((item) => item.id === id))
    );
  });

  if (isTemplateEdited.value) {
    await reveal();
  } else {
    confirm();
  }
};

const handleSendTemplate = async () => {
  if (isSending.value) return;
  try {
    isSending.value = true;
    const { file, link } = state;
    let data = new FormData();
    if (isTemplateEdited.value) {
      data.append('markdown', '1');
      data.append('body', htmlBody.value);
    }
    data.append('subject', templateToSend.value?.subject);
    data.append('email_template_id', templateToSend.value?.id);
    if (file) {
      data.append(`template_data[${attachmentField.value?.name}]`, file);
    }
    if (link) {
      data.append(`template_data[${linkField.value?.name}]`, link);
    }
    if (!file && !link) {
      data.append('template_data[]', '');
    }
    if (sendAction.value?.value) {
      data.append('send_at', sendAction.value?.value);
    }
    await props.onSend(data, applicationId.value);
    close();
    isSending.value = false;
  } catch (error) {
    console.log(error);
  } finally {
    isSending.value = false;
    handleReset();
  }
};

const onClickLookup = () => {
  showPreview.value = !showPreview.value;
};

const open = () => {
  sendAction.value = undefined;
  templateToSend.value = null;
  htmlBody.value = '';
  link.value = '';
  file.value = null;
  handleReset();
  isSendTemplateDialogOpen.value = true;
};
watch(
  () => applicationId.value,
  (newValue) => {
    if (newValue) {
      getTemplates();
    }
  },
  { immediate: true }
);

defineExpose({
  open,
});
</script>

<style scoped>
.field-title {
  @apply text-proxify-gray-700
  mb-1.5
  text-body-sm
  font-medium;
}

.icon-eye {
  @apply mt-1 mx-2 inline-block text-white cursor-pointer;
}

.action-popup {
  @apply bg-white;
}

.action-popup .action-popup-title {
  @apply mb-4
  font-poppins
  text-xl;
}

:deep(.v-select .vs__search) {
  @apply h-9;
}
</style>
