<script setup>
const props = defineProps({
  type: {
    type: String,
    required: false,
    default: () => 'button',
  },
  replace: {
    type: Boolean,
    required: false,
    default: () => false,
  },
  outline: {
    type: Boolean,
    required: false,
    default: () => false,
  },
  flat: {
    type: Boolean,
    required: false,
    default: () => false,
  },
  rounded: {
    type: Boolean,
    required: false,
    default: () => false,
  },
  round: {
    type: Boolean,
    required: false,
    default: () => false,
  },
  push: {
    type: Boolean,
    required: false,
    default: () => false,
  },
  square: {
    type: Boolean,
    required: false,
    default: () => false,
  },
  glossy: {
    type: Boolean,
    required: false,
    default: () => false,
  },
  fab: {
    type: Boolean,
    required: false,
    default: () => false,
  },
  fabMini: {
    type: Boolean,
    required: false,
    default: () => false,
  },
  noCaps: {
    type: Boolean,
    required: false,
    default: () => false,
  },
  noWrap: {
    type: Boolean,
    required: false,
    default: () => false,
  },
  dense: {
    type: Boolean,
    required: false,
    default: () => false,
  },
  tabindex: {
    type: [Number, String],
    required: false,
    default: () => 0,
  },
  align: {
    type: String,
    required: false,
    default: () => 'center',
  },
  stack: {
    type: Boolean,
    required: false,
    default: () => false,
  },
  stretch: {
    type: Boolean,
    required: false,
    default: () => false,
  },
  loading: {
    type: Boolean,
    required: false,
    default: () => false,
  },
  disable: {
    type: Boolean,
    required: false,
    default: () => false,
  },
  capitalize: {
    type: Boolean,
    required: false,
    default: () => false,
  },
});
</script>

<template>
  <q-btn
    v-bind="props"
    :no-caps="!capitalize"
  >
    <slot name="prepend"></slot>
    <slot></slot>
    <slot name="append"></slot>
  </q-btn>
</template>

<style lang="scss" scoped>
.icon-navitem {
  @apply bg-white rounded-[6px] text-proxify-gray-700 p-3;
  &:hover {
    @apply bg-proxify-gray-50;
  }
  &:active {
    box-shadow: 0 0 0 4px #f2f4f7;
  }
  &.current {
    @apply bg-proxify-gray-50;
    &:hover {
      @apply bg-proxify-gray-100;
    }
    &:active {
      @apply bg-proxify-gray-50;
      box-shadow: 0 0 0 4px #f2f4f7;
    }
  }
}
.icon-primary {
  @apply bg-proxify-primary
  inner-border
  inner-border-proxify-primary
  text-[#ffffff]
  transition
  duration-300
  cursor-pointer;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  &:hover {
    @apply bg-[#3c3ed4]
    inner-border
    inner-border-[#3c3ed4];
  }
  &:active {
    @apply bg-proxify-primary
    inner-border
    inner-border-proxify-primary;
    box-shadow:
      0 1px 2px rgba(16, 24, 40, 0.05),
      0 0 0 4px #f4ebff;
  }
  &:disabled {
    @apply bg-[#b1b8ff]
    inner-border
    inner-border-[#b1b8ff];
  }
  &.button-destructive {
    @apply bg-proxify-error-600 inner-border-proxify-error-600 text-white;
    &:hover {
      @apply bg-proxify-error-700 inner-border-proxify-error-700;
    }
    &:active {
      box-shadow: 0 0 0 4px #fee4e2;
    }
    &:disabled {
      @apply bg-proxify-error-200 inner-border-proxify-error-200 cursor-not-allowed #{!important};
    }
  }
}

.icon-secondary-gray {
  @apply bg-white
  inner-border
  inner-border-[#D0D5DD]
  text-[#344054]
  transition
  duration-300;
  &:hover {
    @apply bg-[#f9fafb]
    inner-border
    inner-border-[#D0D5DD];
  }
  &:active {
    @apply text-[#344054];
    box-shadow:
      0 1px 2px rgba(16, 24, 40, 0.05),
      0 0 0 4px #f2f4f7;
  }
  &:disabled {
    @apply text-[#d0d5dd]
    inner-border
    inner-border-[#eaecf0];
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  }
}

.icon-secondary {
  @apply bg-proxify-primary-50
  inner-border
  inner-border-proxify-primary-50
  text-proxify-primary-700
  transition
  duration-300;
  &:hover {
    @apply bg-[#d5d5fe]
    inner-border
    inner-border-[#d5d5fe]
    text-[#272594];
  }
  &:active {
    box-shadow:
      0 1px 2px rgba(16, 24, 40, 0.05),
      0 0 0 4px #f4ebff;
  }
  &:disabled {
    @apply bg-[#f5f5ff]
    inner-border
    inner-border-[#f5f5ff]
    text-[#999afd];
  }
}

.icon-tertiary-gray {
  @apply text-[#475467]
  transition
  duration-300;
  &:hover {
    @apply bg-[#f9fafb]
    text-[#344054];
  }
  &:active {
    @apply text-[#475467];
  }
  &:disabled {
    @apply text-[#d0d5dd]
    inner-border-proxify-gray-200;
  }
}

.icon-tertiary {
  @apply text-[#3c3ed4]
  transition
  duration-300;
  &:hover {
    @apply bg-proxify-primary-50
    text-[#272594];
  }
  &:active {
    @apply text-[#3c3ed4];
  }
  &:disabled {
    @apply text-[#d0d5dd];
  }
}

.icon-link-gray {
  @apply text-[#475467]
  transition
  duration-300;
  &:hover {
    @apply text-[#344054];
  }
  &:active {
    @apply text-[#475467];
  }
  &:disabled {
    @apply text-[#d0d5dd];
  }
}

.icon-link-color {
  @apply text-[#3c3ed4]
  transition
  duration-300;
  &:hover {
    @apply text-[#272594];
  }
  &:active {
    @apply text-[#3c3ed4];
  }
  &:disabled {
    @apply text-[#d0d5dd];
  }
}
</style>
