<script setup>
import ApplicantCard from '@/components/Elements/Applicant/ApplicantCard.vue';
import BaseIcon from '@/components/Base/BaseIcon.vue';
import { computed, ref } from 'vue';
import ApplicantSkillBadge from '@/components/Elements/Applicant/ApplicantSkillBadge.vue';
import { applicationUtils } from '@/utils';

const props = defineProps({
  skills: {
    type: Array,
    required: false,
  },
  keySkillRates: {
    type: Array,
    required: false,
  },
});

const separatedSkills = computed(() => {
  return applicationUtils.separateSkills(props.skills);
});

const filteredSecondarySkills = computed(() => {
  return applicationUtils
    .separateSkills(props.skills)
    .secondarySkills.filter(
      (skill) =>
        skill.years >= 4 && skill.supply_demand_ratio.label === 'High demand'
    );
});

const filteredOtherSecondarySkills = computed(() => {
  return applicationUtils
    .separateSkills(props.skills)
    .secondarySkills.filter(
      (skill) =>
        skill.years < 4 || skill.supply_demand_ratio.label !== 'High demand'
    );
});

const expanded = ref(false);
</script>

<template>
  <ApplicantCard
    title="Skills"
    max-height="none"
    class="break-after-avoid"
  >
    <div class="flex flex-col gap-3">
      <div class="flex flex-col gap-2">
        <div class="text-body-sm text-proxify-gray font-medium">
          <span>Primary skill</span>
          <span v-if="separatedSkills.mainSkills.length > 1">s</span>
        </div>
        <div class="flex flex-col gap-2">
          <ApplicantSkillBadge
            v-for="(item, index) in separatedSkills.mainSkills"
            :key="index"
            :skill="item"
            :rates="index === 0 ? keySkillRates : []"
          />
        </div>
      </div>
      <div
        v-if="separatedSkills.secondarySkills.length"
        class="flex flex-col gap-2"
      >
        <div
          class="flex justify-between text-body-sm text-proxify-gray font-medium"
          :class="{ 'flex-col gap-2': filteredSecondarySkills.length }"
        >
          <div>
            <span>Secondary skill</span>
            <span v-if="separatedSkills.secondarySkills.length > 1">s</span>
            ({{ separatedSkills.secondarySkills.length }})
          </div>
          <div
            v-if="filteredSecondarySkills.length"
            class="flex flex-col gap-2"
          >
            <ApplicantSkillBadge
              v-for="(item, index) in filteredSecondarySkills"
              :key="index"
              :skill="item"
            />
          </div>
          <div
            v-if="filteredOtherSecondarySkills?.length"
            class="cursor-pointer text-primary text-right"
            @click="expanded = !expanded"
          >
            Show all
            <BaseIcon
              name="chevron-down"
              class="transition duration-300"
              :class="[expanded ? 'rotate-180' : '', 'ml-2']"
              size="20px"
            />
          </div>
        </div>
        <Transition>
          <div
            v-show="expanded"
            class="overflow-hidden"
          >
            <div class="flex flex-col gap-2">
              <ApplicantSkillBadge
                v-for="(item, index) in filteredOtherSecondarySkills"
                :key="index"
                :skill="item"
              />
            </div>
          </div>
        </Transition>
      </div>
    </div>
  </ApplicantCard>
</template>

<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.3s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
