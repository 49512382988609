<template>
  <div class="notification-header">
    <div class="flex gap-2 items-center">
      <div class="text-body-sm font-medium">{{ title }}</div>
    </div>
    <div
      v-element-hover="onHoverMark"
      :class="{
        'mark-as-read':
          (!isRead && !isMarkAsReadHovered) ||
          (isRead && isMarkAsReadHovered && isCardHovered),
        'mark-as-unread':
          (isRead && isCardHovered && !isMarkAsReadHovered) ||
          (!isRead && isMarkAsReadHovered),
      }"
      @click.stop="markAsRead"
    >
      <BaseIcon
        v-if="
          (!isRead && isMarkAsReadHovered) ||
          (isRead && isCardHovered && !isMarkAsReadHovered)
        "
        name="check"
        size="14px"
        color="white"
      />
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { vElementHover } from '@vueuse/components';
import { BaseIcon } from '@/components/Base';

export default {
  name: 'NotificationHeader',
  components: {
    BaseIcon,
  },
  directives: {
    elementHover: vElementHover,
  },
  props: {
    markAsRead: {
      type: Function,
      required: true,
    },
    isRead: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },

    isCardHovered: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const isMarkAsReadHovered = ref(false);

    const onHoverMark = (state) => {
      isMarkAsReadHovered.value = state;
    };

    return {
      onHoverMark,
      isMarkAsReadHovered,
    };
  },
};
</script>

<style scoped>
.notification-header {
  @apply flex justify-between;
}

.notification-header .mark-as-read {
  @apply w-5 h-5 max-w-5 max-h-5 rounded-full border-proxify-gray-300 border cursor-pointer;
}

.notification-header .mark-as-unread {
  @apply w-5 h-5 max-w-5 max-h-5 rounded-full cursor-pointer flex items-center justify-center bg-proxify-primary-600;
}
</style>
