<script setup>
import { computed } from 'vue';
import { ratingUtils } from '@/utils';

const COLORS = ['gray', 'green', 'red'];
const MIN_BAR_WIDTH_PERCENT = 10;

/**
 * Props definition.
 */
const props = defineProps({
  value: {
    type: Number,
    required: true,
  },
  rates: {
    type: Array,
    required: true,
  },
  unit: {
    type: String,
    default: '€',
  },
  description: {
    type: String,
    default: '',
  },
  labelOverlapThreshold: {
    type: Number,
    required: false,
    default: 15,
  },
});

/**
 * Compute the minimum and maximum rates.
 */
const minRate = computed(() => Math.min(...props.rates));
const maxRate = computed(() => Math.max(...props.rates));
const totalRange = computed(() => maxRate.value - minRate.value);

const computeInitialWidths = (rates, totalRange) => {
  return rates
    .slice(0, -1)
    .map((rate, index) => ((rates[index + 1] - rate) / totalRange) * 100);
};

const adjustWidthsToMinimum = (widths, minWidth) => {
  return widths.map((width) => (width < minWidth ? minWidth : width));
};

const adjustWidthsToFit = (widths, totalWidth, minWidth) => {
  const totalAdjustedWidth = widths.reduce((sum, width) => sum + width, 0);

  if (totalAdjustedWidth <= totalWidth) return widths;

  const excessWidth = totalAdjustedWidth - totalWidth;

  const totalAdjustableWidth = widths.reduce(
    (sum, width) => (width > minWidth ? sum + (width - minWidth) : sum),
    0
  );

  return widths.map((width) =>
    width > minWidth
      ? width - ((width - minWidth) / totalAdjustableWidth) * excessWidth
      : width
  );
};

const computeBarData = (rates, adjustedWidths, colors, unit) => {
  const barData = [];
  let cumulativePosition = 0;

  adjustedWidths.forEach((width, index) => {
    const start = rates[index];
    const end = rates[index + 1];

    barData.push({
      startLabel: `${unit}${start}`,
      endLabel: `${unit}${end}`,
      width,
      color: colors[index],
      startPosition: cumulativePosition,
      endPosition: cumulativePosition + width,
      style: {
        width:
          index < adjustedWidths.length - 1
            ? `calc(${width}% - 2px)`
            : `${width}%`,
        marginRight: index < adjustedWidths.length - 1 ? '2px' : '0',
      },
    });

    cumulativePosition += width;
  });

  return barData;
};

const bars = computed(() => {
  const initialWidths = computeInitialWidths(props.rates, totalRange.value);
  const widthsWithMinimums = adjustWidthsToMinimum(
    initialWidths,
    MIN_BAR_WIDTH_PERCENT
  );
  const adjustedWidths = adjustWidthsToFit(
    widthsWithMinimums,
    100,
    MIN_BAR_WIDTH_PERCENT
  );

  return computeBarData(props.rates, adjustedWidths, COLORS, props.unit);
});

const findBarForValue = (value, rates, barsData) => {
  for (let index = 0; index < barsData.length; index++) {
    if (value >= rates[index] && value <= rates[index + 1]) {
      return {
        bar: barsData[index],
        start: rates[index],
        end: rates[index + 1],
      };
    }
  }
  return null;
};

/**
 * Compute the left position of the indicator (tooltip).
 */
const indicatorLeft = computed(() => {
  const { value } = props;
  const barsData = bars.value;

  if (value <= minRate.value) return 0;
  if (value >= maxRate.value) return 100;

  const barInfo = findBarForValue(value, props.rates, barsData);
  if (!barInfo) return 0; // Fallback if bar not found (shouldn't happen if rates are valid)

  const { bar, start, end } = barInfo;
  const valueRatio = (value - start) / (end - start);

  return bar.startPosition + valueRatio * (bar.endPosition - bar.startPosition);
});

const createLabel = (label, position, isMin, isMax, isQuantile) => ({
  label,
  position,
  isMin,
  isMax,
  isQuantile,
  extendLine: false,
});

const addQuantileLabels = (labelsArray, rates, barsData, unit) => {
  for (let index = 1; index < rates.length - 1; index++) {
    const position = barsData[index - 1].endPosition;
    labelsArray.push(
      createLabel(`${unit}${rates[index]}`, position, false, false, true)
    );
  }
};

const detectOverlappingLabels = (labelsArray, threshold) => {
  for (let index = 0; index < labelsArray.length; index++) {
    const currentLabel = labelsArray[index];

    const previousLabel = labelsArray[index - 1];
    const nextLabel = labelsArray[index + 1];
    if (index === 1) {
      if (
        currentLabel.isQuantile &&
        (currentLabel.position - previousLabel.position < threshold ||
          nextLabel.position - currentLabel.position < threshold)
      ) {
        currentLabel.extendLine = true;
      }
    }

    if (index === 2) {
      if (
        currentLabel.isQuantile &&
        nextLabel.position - currentLabel.position < threshold
      ) {
        currentLabel.extendLine = true;
      }
    }
  }
};

const labels = computed(() => {
  const rates = props.rates;
  const barsData = bars.value;
  const unit = props.unit;

  const labelsArray = [
    createLabel(
      `${unit}${rates[0]}`,
      barsData[0].startPosition,
      true,
      false,
      false
    ), // Min label
  ];

  addQuantileLabels(labelsArray, rates, barsData, unit);

  labelsArray.push(
    createLabel(`${unit}${rates[rates.length - 1]}`, 100, false, true, false)
  ); // Max label

  detectOverlappingLabels(labelsArray, props.labelOverlapThreshold);

  return labelsArray;
});

/**
 * Determine the color of the indicator based on the value.
 */
const indicatorColor = computed(() => {
  const { value, rates } = props;
  if (value < rates[1]) return 'gray'; // Low
  if (value < rates[2]) return 'green'; // Typical
  return 'red'; // High
});

/**
 * Format the indicator text.
 */
const indicatorText = computed(() => {
  return `${props.unit}${props.value}`;
});

const verdict = computed(() => {
  return ratingUtils.calculateVerdict(props.value, props.rates);
});
</script>

<template>
  <p
    v-if="description"
    class="chart-description"
  >
    {{ description.replace('{verdict}', verdict) }}
  </p>
  <!-- Bars and Indicator -->
  <div class="bars-wrapper">
    <div class="bars">
      <!-- Bars -->
      <div
        v-for="(bar, index) in bars"
        :key="index"
        class="bar"
        :class="bar.color"
        :style="bar.style"
      ></div>

      <!-- Indicator Tooltip -->
      <div
        class="indicator"
        :class="indicatorColor"
        :style="{ left: indicatorLeft + '%' }"
      >
        {{ indicatorText }}
      </div>
    </div>

    <!-- Labels -->
    <div class="labels">
      <div
        v-for="(label, index) in labels"
        :key="index"
        class="label"
        :style="{ left: label.position + '%' }"
      >
        <span
          :class="{
            'label-min': label.isMin,
            'label-max': label.isMax,
            'label-quantile': label.isQuantile,
            'extend-line': label.extendLine,
          }"
        >
          {{ label.label }}
        </span>
        <!-- Line Extension for Overlapping Labels -->
        <div
          v-if="label.extendLine"
          class="line-extension"
        ></div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.chart-description {
  @apply mb-3 text-body-xs font-semibold;
  &:first-letter {
    @apply capitalize;
  }
}

.bar-chart {
  position: relative;
  padding: 40px 16px 0;
}

.bars-wrapper {
  position: relative;
  margin-top: 57px;
  margin-bottom: 30px;
  padding: 0 22px;
  &:has(.extend-line) {
    margin-bottom: 52px;
  }
}

.bars {
  display: flex;
  height: 8px;
  position: relative;
}

.indicator {
  position: absolute;
  top: -42px;
  transform: translateX(-50%);
  padding: 8px 4px;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  white-space: nowrap;

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -6px;
    border-width: 6px;
    border-style: solid;
    border-color: transparent transparent transparent transparent;
  }
}

.indicator.gray {
  @apply bg-proxify-gray-100 text-proxify-gray-700;
  &::after {
    @apply border-t-proxify-gray-100;
  }
}

.indicator.green {
  @apply bg-proxify-success-100 text-proxify-success-700;
  &::after {
    @apply border-t-proxify-success-100;
  }
}

.indicator.red {
  @apply bg-proxify-error-100 text-proxify-error-700;
  &::after {
    @apply border-t-proxify-error-100;
  }
}

.bars {
  display: flex;
  height: 8px;
  margin-top: 8px;
}

.bar {
  height: 8px;
  border-radius: 8px;
  position: relative;
}

.bar.gray {
  @apply bg-proxify-gray-300;
}

.bar.green {
  @apply bg-proxify-success-500;
}

.bar.red {
  @apply bg-proxify-error-500;
}

.labels {
  position: relative;
  margin-top: 4px;
}

.label {
  position: absolute;
  transform: translateX(-50%);
  text-align: center;
}

.label span {
  @apply text-body-xs text-proxify-gray-400;
}

.label-min,
.label-max {
  transform: none;
}

.label-min {
  left: 0;
  text-align: left;
}

.label-max {
  left: 100%;
  text-align: right;
}

.label-quantile {
  @apply text-proxify-gray-700 #{!important};
}

.extend-line {
  display: block;
  position: relative;
  margin-bottom: 8px;
  top: 24px;
}

.line-extension {
  @apply bg-proxify-gray-300;
  position: absolute;
  top: -4px;
  left: calc(50% - 2px);
  width: 1px;
  height: 24px;
}
</style>
