<template>
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-icon="open"
    stroke="currentColor"
  >
    <circle cx="12" cy="12" r="10" stroke="transparent" fill="none" />
    <path
      d="M17 12L7 12"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12 17L7 12L12 7"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
