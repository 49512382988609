import { useStorage } from '@vueuse/core';
import { objectUtils } from '@/utils';

const state = {
  messageDrafts: useStorage('messageDrafts', {}),
  noteDrafts: useStorage('noteDrafts', []),
};

const getters = {};

const actions = {};

const mutations = {
  setMessageDraft(state, values) {
    if (values.message === '\n') {
      this.commit('drafts/deleteDraftMessage', values);
      return;
    }

    const draftToEdit = state.messageDrafts[values.id]?.find(
      (item) => item.subject === values.subject
    );

    if (draftToEdit) {
      draftToEdit.message = values.message;
    } else {
      state.messageDrafts[values.id] = [
        ...[
          {
            subject: values.subject,
            message: values.message,
          },
        ],
        ...(state.messageDrafts[values.id]
          ? state.messageDrafts[values.id]
          : []),
      ];
    }
  },
  setMessageDraftSubject(state, values) {
    const draftToSetSubject =
      (state.messageDrafts[values.id] ?? []).find(
        (item) => item.subject === 'New Subject'
      ) ?? {};
    draftToSetSubject.subject = values.subject;
  },
  deleteDraftMessage(state, values) {
    if (!state.messageDrafts?.[values.id]) return;
    state.messageDrafts[values.id] = state.messageDrafts[values.id].filter(
      (item) => item.subject !== values.subject
    );
    if (Object.keys(state.messageDrafts[values.id]).length === 0) {
      state.messageDrafts = objectUtils.removeKeyFromObject(
        state.messageDrafts,
        values.id
      );
    }
  },
  setNoteDraft(state, values) {
    if (values.note === '\n') {
      this.commit('drafts/deleteDraftNote', values);
      return;
    }

    const draftToEdit = state.noteDrafts.find((item) => item.id === values.id);

    if (draftToEdit) {
      draftToEdit.note = values.note;
    } else {
      state.noteDrafts = [
        ...[
          {
            id: values.id,
            note: values.note,
          },
        ],
        ...state.noteDrafts,
      ];
    }
  },
  deleteDraftNote(state, values) {
    state.noteDrafts = state.noteDrafts.filter((item) => item.id !== values.id);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
