import axios from 'axios';

export default {
  getTodos({ params, cancelToken }) {
    return axios.get('/todos', { params, cancelToken });
  },
  getByApplication(application_id) {
    return axios.get(`/todos?application_id=${application_id}`);
  },
  post(body) {
    return axios.post('/todos', body);
  },
  put(body, { todo_id }) {
    return axios.put(`/todos/${todo_id}`, body);
  },
  delete({ todo_id }) {
    return axios.delete(`/todos/${todo_id}`);
  },
};
