<script setup>
import { toRef, computed, ref } from 'vue';
import { PTextInput } from '@/components/ProxifyUI';
import { BaseIcon, BaseInputDropdown } from '@/components/Base';
import { useVModel } from '@vueuse/core';
import { useField } from 'vee-validate';

const props = defineProps({
  name: {
    type: String,
    required: false,
  },
  trailingName: {
    type: String,
  },
  label: {
    type: String,
    required: false,
  },
  placeholder: {
    type: String,
    required: false,
  },
  hint: {
    type: String,
    required: false,
  },
  disable: {
    type: Boolean,
    required: false,
    default: () => false,
  },
  readOnly: {
    type: Boolean,
    required: false,
    default: () => false,
  },
  error: {
    type: Boolean,
    required: false,
    default: () => false,
  },
  errorMessage: {
    type: String,
    required: false,
  },
  showErrorMessage: {
    type: Boolean,
    default: true,
  },
  type: {
    type: String,
    required: false,
    default: () => 'text',
    validator: (value) => {
      return [
        'text',
        'password',
        'textarea',
        'email',
        'search',
        'tel',
        'file',
        'number',
        'url',
      ].includes(value);
    },
  },
  leadingText: {
    type: String,
    required: false,
  },
  leadingIcon: {
    type: String,
    required: false,
  },
  leadingIconClass: {
    type: String,
    required: false,
  },
  trailingDropdown: {
    type: Boolean,
    required: false,
  },
  trailingDropdownOptions: {
    type: Array,
    required: false,
    default: () => ['USD', 'EUR'],
  },
  trailingMenuWidth: {
    type: Number,
    required: false,
  },
  trailingMenuOffset: {
    type: Array,
    required: false,
  },
  trailingModelValue: {
    type: String,
    required: false,
  },
  trailingText: {
    type: String,
    required: false,
  },
  trailingBorder: {
    type: Boolean,
    default: true,
  },
  inputWidth: {
    type: String,
  },
  modelValue: {
    type: [String, Number],
    required: false,
  },
  required: {
    type: Boolean,
    required: false,
    default: () => false,
  },
  fieldLabel: {
    type: String,
    required: false,
  },
  hideBottomSpace: {
    type: Boolean,
    required: false,
    default: () => false,
  },
  options: {
    type: Array,
    required: false,
    default: () => [],
  },
  min: {
    type: [Number, String],
    required: false,
  },
  paddinglessSide: {
    type: String,
    required: false,
  },
  debounce: {
    type: [Number, String],
    required: false,
  },
  autofocus: {
    type: Boolean,
    required: false,
    default: () => false,
  },
  rows: {
    type: String,
    default: '3',
  },
  maxLength: {
    type: [Number, String],
    required: false,
  },
  inputClass: {
    type: String,
    required: false,
  },
  showErrorIcon: {
    type: Boolean,
    required: false,
  },
  normalize: {
    type: Boolean,
    default: false,
  },
  validateOnValueUpdate: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const paddinglessMap = {
  left: '!pl-0',
  right: '!pr-0',
  vertical: '!py-0',
  horizontal: '!px-0',
  all: '!p-0',
};

const nativePadding = computed(() => {
  if (props.paddinglessSide) {
    return paddinglessMap[props.paddinglessSide];
  }
  return '';
});
const pTextInput = ref(null);
const handleFocus = () => {
  pTextInput.value.focus();
};

const emit = defineEmits([
  'update:trailingModelValue',
  'update:modelValue',
  'focus',
  'blur',
]);
const trailingModel = useVModel(props, 'trailingModelValue', emit);

const border = props.trailingBorder ? '1px solid #d0d5dd' : 'none';
const trailingPadding = props.trailingBorder ? '0 14px' : '0 0 0 14px';

const { errorMessage, value, meta } = useField(
  toRef(props, 'name'),
  { required: props.required },
  {
    initialValue: toRef(props, 'modelValue'),
    label: toRef(props, 'fieldLabel'),
    validateOnValueUpdate: props.validateOnValueUpdate,
    syncVModel: true,
  }
);

defineExpose({ meta, handleFocus });
</script>

<template>
  <div
    :data-name="name"
    class="relative"
  >
    <PTextInput
      ref="pTextInput"
      v-model="value"
      v-bind="props"
      :class="{ 'p-text-input': !normalize }"
      :type="type"
      hide-bottom-space
      :error="Boolean(errorMessage)"
      :input-class="[nativePadding, inputClass].filter(Boolean).join(' ')"
      autocomplete="off"
      @focus="emit('focus')"
      @blur="emit('blur')"
    >
      <template
        v-if="leadingIcon"
        #prepend
      >
        <BaseIcon
          :name="leadingIcon"
          size="20px"
          :class="leadingIconClass"
        />
      </template>
      <template
        v-if="leadingText"
        #before
      >
        <div class="py-[8px] px-[2px]">
          {{ leadingText }}
        </div>
      </template>
      <template
        v-if="showErrorIcon && Boolean(errorMessage)"
        #append
      >
        <BaseIcon
          name="alert-circle"
          size="16px"
          class="pr-2 text-proxify-error-500"
        />
      </template>
      <template
        v-if="trailingDropdown || trailingText || $slots.after"
        #after
      >
        <slot name="after">
          <BaseInputDropdown
            v-if="trailingDropdown"
            v-model="trailingModel"
            :name="trailingName"
            borderless
            paddingless
            map-options
            trailing-menu
            :menu-width="trailingMenuWidth"
            :options="trailingDropdownOptions"
            :offset="trailingMenuOffset"
          />
          <div
            v-else
            class="trailing-text"
          >
            {{ trailingText }}
          </div>
        </slot>
      </template>
    </PTextInput>
    <div v-if="showErrorMessage && errorMessage">
      <div class="text-proxify-error-500 text-body-sm font-normal mt-[6px]">
        {{ errorMessage }}
      </div>
    </div>
    <div
      v-if="maxLength"
      class="absolute right-2 bottom-2 text-proxify-gray-500 text-body-xs font-normal"
    >
      {{ value?.length ?? 0 }}/{{ maxLength }}
    </div>
  </div>
</template>

<style lang="scss">
.p-text-input {
  @apply font-inter
  w-[v-bind(inputWidth)];

  &:has(.q-field__before) {
    &:not(:has(.q-field__after)) {
      .q-field__inner {
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
      }
    }
  }
  &.q-textarea {
    .q-field__native {
      @apply py-2.5 #{!important};
    }
  }
  .trailing-text {
    @apply py-[8px]
    px-[2px]
    text-body-md
    font-normal
    text-proxify-gray-600;
  }
  &.q-field--with-bottom {
    @apply pb-0;
  }

  &:has(.q-field__after) {
    &:has(.base-input-dropdown) {
      &:has(.q-field--highlighted) {
        border-radius: 8px;
        box-shadow:
          0 1px 2px rgba(16, 24, 40, 0.05),
          0 0 0 4px #f4ebff;
      }
    }
  }

  &.q-field--highlighted {
    &:not(.q-field--error) {
      &:has(.q-field__after),
      &:has(.q-field__before) {
        &:not(:has(.trailing-button)) {
          border-radius: 8px;
          box-shadow:
            0 1px 2px rgba(16, 24, 40, 0.05),
            0 0 0 4px #f4ebff;
        }
        &:has(.trailing-button) {
          .q-field__control {
            border-radius: 8px;
            box-shadow:
              0 1px 2px rgba(16, 24, 40, 0.05),
              0 0 0 4px #f4ebff;
          }
        }
      }

      &:not(:has(.q-field__after)) {
        &:not(:has(.q-field__before)) {
          .q-field__control {
            border-radius: 8px;
            box-shadow:
              0 1px 2px rgba(16, 24, 40, 0.05),
              0 0 0 4px #f4ebff;
          }
        }
      }
      &:has(textarea) {
        .q-field__control {
          border-radius: 8px;
          box-shadow:
            0 1px 2px rgba(16, 24, 40, 0.05),
            0 0 0 4px #f4ebff !important;
        }
      }
    }
  }

  .q-field {
    &__native {
      @apply proxify-scrollbar text-body-md #{!important};
      min-height: 40px;
      font-weight: 400 !important;
      padding: 6px 12px;
      color: #15172f;
      font-size: 16px;
      line-height: 1.5;
      resize: none !important;

      &::placeholder {
        color: #667085;
      }
    }
    &__control {
      height: unset;
      border-radius: 8px;
      &:before,
      &:after {
        content: none;
      }
    }
    &__inner {
      background-color: #fff;
      &:has(+ .q-field__after) {
        border: 1px solid #d0d5dd;
        border-left: v-bind(border);
        box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 8px;
        &:not(:has(+ .q-field__after > .trailing-button)) {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
      &:not(:has(+ .q-field__after)) {
        &:not(:has(input[role='combobox'])) {
          &:not(:has(textarea)) {
            border: 1px solid #d0d5dd;
            box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
            border-radius: 8px;
          }
        }
        &:has(textarea) {
          .q-field__control {
            border: 1px solid #d0d5dd;
            box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
            border-radius: 8px;
          }
        }
      }
    }
    &__prepend {
      padding-left: 12px;
      padding-right: 0;
      margin-right: -2px;
    }
    &__before {
      @apply text-body-md font-normal text-proxify-gray-600 #{!important};
      border: 1px solid #d0d5dd;
      border-right: none;
      box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 8px 0 0 8px;
      padding: v-bind(trailingPadding);
      + .q-field__inner {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
    &__after {
      &:has(.base-input-dropdown),
      &:has(.trailing-text) {
        overflow: hidden;
        border: 1px solid #d0d5dd;
        padding: 0 14px;
        border-left: none;
        border-radius: 0 8px 8px 0;
        .q-field__control {
          box-shadow: none;
        }
      }
    }
    &__bottom {
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      color: #475467;
      padding-top: 6px;
    }
    &__marginal {
      height: unset;
    }
    &__label {
      font-size: 14px;
      transform: none;
      font-weight: 500;
      color: #344054;
      top: 0;
    }
  }
  &.q-field--disabled {
    .q-field__native {
      background-color: #f9fafb;
    }
  }

  &.q-field--error {
    .q-field__inner {
      border: 1.5px solid #fda29b !important;
    }
    &:has(.q-field__after),
    &:has(.q-field__before) {
      border-color: #fda29b !important;
      box-shadow:
        0 1px 2px rgba(16, 24, 40, 0.05),
        0 0 0 4px #fee4e2 !important;
      border-radius: 8px;
      .q-field__inner {
        box-shadow: none;
      }
    }
    &:not(:has(.q-field__after)) {
      &:not(:has(.q-field__before)) {
        // &:has(textarea) {
        .q-field__control {
          border-color: #fda29b !important;
          border-radius: 8px;
          &:focus-within {
            box-shadow:
              0 1px 2px rgba(16, 24, 40, 0.05),
              0 0 0 4px #fee4e2 !important;
          }
        }
        // }
      }
    }
    .q-field__native {
      &:focus {
        box-shadow: none !important;
      }
    }

    .q-field__bottom {
      color: #f04438;
    }
  }
}

// Remove arrows/spinners from input type number
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
