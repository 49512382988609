<script setup>
import {
  BaseLabel,
  BaseLabelInput,
  BaseButtonDropdown,
  BaseButton,
} from '@/components/Base';
import { useChangeCase } from '@vueuse/integrations/useChangeCase';
import { useVModel } from '@vueuse/core';
import { computed, ref, nextTick, toRef } from 'vue';
import { useField } from 'vee-validate';

const emit = defineEmits(['update:modelValue', 'updateItems']);

const props = defineProps({
  name: {
    type: String,
    required: false,
  },
  color: {
    type: String,
    default: 'PrimaryLight',
    validator: (value) =>
      [
        'PrimaryDark',
        'PrimaryLight',
        'PrimaryMedium',
        'SecondaryLight',
      ].includes(useChangeCase(value, 'pascalCase').value),
  },
  options: {
    type: Array,
    default: () => [],
  },
  modelValue: {
    type: Array,
  },
  optionLabel: {
    type: String,
    required: false,
    default: () => 'label',
  },
  optionValue: {
    type: String,
    required: false,
    default: () => 'value',
  },
  outline: {
    type: Boolean,
    required: false,
  },
  size: {
    type: String,
    default: 'md',
  },
  buttonSize: {
    type: String,
    default: 'md',
  },
  required: {
    type: Boolean,
    required: false,
    default: () => false,
  },
  fieldLabel: {
    type: String,
    required: false,
  },
  otherValueType: {
    type: String,
    required: false,
    default: () => 'text',
  },
  labelClass: {
    type: String,
    required: false,
  },
  mapOptions: {
    type: Boolean,
    required: false,
    default: () => false,
  },
});

// Use a VueUse utility to simplify v-model handling
const model = useVModel(props, 'modelValue', emit);

const {
  errorMessage,
  value: fieldValue,
  handleChange,
  meta,
} = useField(
  toRef(props, 'name'),
  { required: props.required },
  {
    initialValue: model,
    label: toRef(props, 'fieldLabel'),
    validateOnValueUpdate: false,
    syncVModel: true,
  }
);

defineExpose({ meta });

// State to hold the value of the 'Other' input
const otherValue = ref(null);

// Helper to check if an item is selected
const isItemSelected = (item) =>
  (fieldValue.value || []).includes(item?.[props.optionValue] ?? item);

// Toggle selection of an item
const toggleItemSelection = (item) => {
  const value = item?.[props.optionValue] ?? item;
  if (isItemSelected(item)) {
    handleChange((fieldValue.value || []).filter((v) => v !== value));
  } else if (value !== otherValue.value) {
    handleChange([...(fieldValue.value || []), value]);
  }
};

// Update the value of 'other' input
const updateOtherValue = async (value) => {
  const cleanedValue = value.replace(/[^a-zA-Z0-9 ]/g, '');

  if (otherValue.value) {
    handleChange(
      (fieldValue.value || []).filter((item) => item !== otherValue.value)
    );
    await nextTick();
    if (otherValue.value === cleanedValue) return;
  }
  if (cleanedValue) {
    handleChange([...(fieldValue.value || []), cleanedValue]);
    otherValue.value = cleanedValue;
  }
};

// Helper to get the label of an item
const getLabel = (item) => item?.[props.optionLabel] ?? item;

// Computed property to get all items except 'Other'
const itemsWithoutOther = computed(() =>
  props.options.filter(
    (item) => item?.[props.optionLabel] !== 'Other' && item !== 'Other'
  )
);

// Initialize 'Other' input's value if there's any 'other' item in the model value
(fieldValue.value || []).forEach((item) => {
  if (!props.options.some((option) => option[props.optionLabel] === item)) {
    otherValue.value = item;
  }
});
</script>

<template>
  <div
    class="flex flex-col gap-[6px]"
    :data-name="name"
  >
    <div class="flex gap-x-[8px] gap-y-[12px] flex-wrap">
      <BaseLabel
        v-for="(item, index) in itemsWithoutOther"
        :key="index"
        :outline="outline"
        :size="size"
        :color="color"
        :label="getLabel(item)"
        :selected="isItemSelected(item)"
        :class="labelClass"
        @click.capture="toggleItemSelection(item)"
      />
      <BaseLabelInput
        v-if="otherValueType === 'text'"
        v-model="otherValue"
        :size="size"
        @update:input-value="updateOtherValue"
      />
      <BaseButtonDropdown
        v-if="otherValueType === 'date'"
        type="date"
      >
        <template #activator>
          <BaseButton
            class="icon-secondary-gray py-2.5 text-body-sm"
            icon-prepend="calendar"
            icon-size="20px"
            :size="buttonSize"
            rounded
          >
            Select Date
          </BaseButton>
        </template>
      </BaseButtonDropdown>
    </div>
    <span
      v-if="errorMessage"
      class="text-body-sm font-normal text-proxify-error-500"
    >
      {{ errorMessage }}
    </span>
  </div>
</template>
