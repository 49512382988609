import script from "./BaseInputField.vue?vue&type=script&setup=true&lang=js"
export * from "./BaseInputField.vue?vue&type=script&setup=true&lang=js"

import "./BaseInputField.vue?vue&type=style&index=0&id=f2a84714&lang=scss"

const __exports__ = script;

export default __exports__
import QField from 'quasar/src/components/field/QField.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QField});
