import { ref, computed, watch } from 'vue';
import { useRouteParams } from '@vueuse/router';
import api from '@/api';

export default function useCodility() {
  const applicationId = useRouteParams('id');
  const codilityTestLinks = ref([]);
  const codilityTests = ref([]);
  const codilityTestsAreLoading = ref(false);

  const getApplicantTests = async () => {
    if (applicationId.value) {
      const response = await api.applications.codility.getTestLinks(
        applicationId.value
      );
      codilityTestLinks.value = response.data.codility_test_links;
    }
  };

  const codilityScore = computed(() => {
    if (!codilityTestLinks.value?.length) return null;
    const filledTests = codilityTestLinks.value.filter(
      ({ result }) => result?.result || result?.result === 0
    );
    if (!filledTests.length) return null;
    const sumPercentage = filledTests.reduce((accumulator, current) => {
      const { max_result: maximumResult, result } = current.result;
      const percentage = (result / maximumResult) * 100;
      return accumulator + percentage;
    }, 0);
    return sumPercentage / filledTests.length;
  });

  const getTests = async (options = {}) => {
    const { withArchived } = options;
    if (codilityTests.value.length || codilityTestsAreLoading.value) {
      return;
    }
    codilityTestsAreLoading.value = true;

    const filterBlocks = ['[LIVE-CODING-ONLY]', '[TEST-DAY-ONLY]'];
    const { data: { tests } = {} } = await api.applications.codility.getTests({
      with_archived: withArchived,
    });
    codilityTests.value = tests.filter(
      ({ is_archived: isArchived, name }) =>
        !filterBlocks.some((item) => name.includes(item)) &&
        (withArchived || !isArchived)
    );

    codilityTestsAreLoading.value = false;
  };

  const handleDownloadResult = async (assessment) => {
    const { id, test } = assessment;
    const { data } = await api.applications.codility.downloadResult({
      codility_test_link_id: id,
    });
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `Codility Test Result - ${test.name}.pdf`);
    document.body.appendChild(link);
    link.click();
  };

  const downloadAllResults = async () => {
    await getApplicantTests();
    await Promise.all(
      codilityTestLinks.value.map((assessment) =>
        handleDownloadResult(assessment)
      )
    );
  };

  // This function will trigger the getApplicantTests function whenever id changes
  watch(() => applicationId.value, getApplicantTests);

  return {
    codilityTestLinks,
    codilityScore,
    codilityTests,
    codilityTestsAreLoading,
    getApplicantTests,
    getTests,
    downloadAllResults,
  };
}
