<template>
  <div class="divider">
    <div class="divider-line"></div>
    <div class="divider-text px-4 text-disabled-normal flex gap-2">
      <slot />
    </div>
    <div class="divider-line"></div>
  </div>
</template>

<script>
export default {
  name: 'AppDivider',
};
</script>

<style scoped>
.divider {
  @apply flex items-center;
}

.divider-line {
  @apply bg-proxify-black/5 h-px w-full flex-1;
}

.divider-text {
  @apply px-4 text-disabled-normal flex gap-2 cursor-default;
}
</style>
