<script setup>
import { computed, ref, toRefs } from 'vue';
import {
  BaseBadge,
  BaseButton,
  BaseCheckbox,
  BaseIcon,
  BaseTooltip,
  BaseMenu,
} from '@/components/Base';
import { useElementHover } from '@vueuse/core';
import { PAvatar } from '@/components/ProxifyUI';
import moment from 'moment';
const props = defineProps({
  todo: {
    type: Object,
    required: true,
  },
  showAssignee: {
    type: Boolean,
    required: false,
  },
  showAssigner: {
    type: Boolean,
    required: false,
  },
  showBorder: {
    type: Boolean,
    required: false,
  },
});
const emit = defineEmits([
  'open-edit-dialog',
  'open-delete-dialog',
  'toggle-todo',
]);
const { todo } = toRefs(props);

const todosCard = ref(null);
const isMenuActive = ref(false);

const isHovered = useElementHover(todosCard);

const formattedDueDate = computed(() => {
  return moment(todo.value.due_date).calendar(null, {
    sameDay: '[today]',
    nextDay: 'MMMM D',
    lastDay: '[yesterday]',
    lastWeek: 'MMMM D',
    nextWeek: 'MMMM D',
    sameElse: 'MMMM D',
  });
});
const isExpedited = computed(() => {
  return moment(todo.value.due_date).isSameOrBefore(
    moment().add(1, 'days').startOf('day')
  );
});

const menuOptions = [
  {
    label: 'Edit',
    props: {
      onClick: () => emit('open-edit-dialog', todo.value),
      clickable: true,
    },
    type: 'item',
    leftSection: { icon: { name: 'pencil02' } },
  },
  {
    label: 'Delete',
    props: {
      onClick: () => emit('open-delete-dialog', todo.value),
      clickable: true,
    },
    type: 'item',
    leftSection: { icon: { name: 'trash03' } },
  },
  {
    type: 'separator',
  },
];
const formatName = (name) => {
  if (!name) {
    return '';
  }
  const nameParts = name.split(' ');
  return nameParts
    .map((namePart, index) => {
      if (index === 0) {
        return namePart;
      }
      return `${namePart.charAt(0).toUpperCase()}.`;
    })
    .join(' ');
};
</script>
<template>
  <div
    ref="todosCard"
    class="todos-card__container"
    :class="{ 'bg-proxify-gray-25': isHovered }"
  >
    <div
      class="todos-card__body"
      :class="{ 'border-b': showBorder }"
    >
      <div class="todos-card__content">
        <div class="todos-card__text">
          <BaseCheckbox
            size="lg"
            :name="`todo_${todo.id}`"
            :model-value="todo?.is_completed"
            class="max-w-[288px]"
            @update:model-value="
              (is_completed) =>
                emit('toggle-todo', { is_completed, todo_id: todo.id })
            "
          >
            <template #prepend>
              <div class="todos-card__label">
                <div
                  v-if="showAssigner"
                  class="h-[20px] text-body-sm flex items-center"
                >
                  <PAvatar size="2xs">
                    <img
                      v-if="todo.assigned_by?.avatar_url"
                      :src="todo.assigned_by?.avatar_url"
                      :alt="todo.assigned_by?.name"
                    />
                    <BaseIcon
                      v-else
                      name="user01"
                    />
                  </PAvatar>
                  <BaseTooltip>
                    <div class="todos-card__assigner-popup">
                      <div>Assigned to you by</div>
                      <div class="todos-card__avatar">
                        <PAvatar size="2xs">
                          <img
                            v-if="todo.assigned_by.avatar_url"
                            :src="todo.assigned_by.avatar_url"
                            :alt="todo.assigned_by.name"
                          />
                          <BaseIcon
                            v-else
                            name="user01"
                          />
                        </PAvatar>
                        <span class="text-proxify-gray-700 font-semibold">
                          {{ todo.assigned_by?.name }}
                        </span>
                      </div>
                    </div>
                  </BaseTooltip>
                </div>
                <div
                  class="text-body-sm font-medium select-none text-proxify-gray-700"
                  :class="{
                    'line-through': todo?.is_completed,
                  }"
                >
                  {{ todo.body }}
                </div>
              </div>
            </template>
          </BaseCheckbox>
          <div
            v-if="todo.due_date || todo.application"
            class="todos-card__text-bottom"
          >
            <BaseBadge
              v-if="todo.due_date"
              size="xs"
              dense
              :color="isExpedited ? 'error' : 'gray'"
              :icon="isExpedited ? 'alarm-clock' : 'clock'"
              icon-size="12px"
              :icon-class="
                isExpedited ? 'text-proxify-error-500' : 'text-proxify-gray-500'
              "
              class="!pl-1.5 !pr-2 select-none"
            >
              due
              {{ formattedDueDate }}
            </BaseBadge>
            <BaseButton
              v-if="todo.application"
              :to="`/applications/${todo.application.id}/todos`"
              target="_blank"
              class="icon-link-color p-0 text-body-sm font-semibold min-h-0 overflow-hidden"
              dense
            >
              <div class="truncate">
                {{ todo.application.full_name }}
              </div>
            </BaseButton>
          </div>
        </div>
        <div
          v-if="(todo.assigned_to && showAssignee) || todo.completed_at"
          class="todos-card__supporting-text"
        >
          <div
            v-if="todo.assigned_to && showAssignee"
            class="todos-card__assignee"
          >
            <span>Assignee</span>
            <div class="todos-card__avatar">
              <PAvatar size="2xs">
                <img
                  v-if="todo.assigned_to.avatar_url"
                  :src="todo.assigned_to.avatar_url"
                  :alt="todo.assigned_to.name"
                />
                <BaseIcon
                  v-else
                  name="user01"
                />
              </PAvatar>
              <span>{{ formatName(todo.assigned_to?.name) }}</span>
            </div>
          </div>
          <div
            v-if="todo.completed_at"
            class="todos-card__completed-at"
          >
            <span v-if="todo.assigned_to && showAssignee">, completed on</span>
            <span v-else>Completed on</span>
            <span>{{ moment(todo.completed_at).format('MMMM D') }}</span>
          </div>
        </div>
      </div>
      <div class="todos-card__menu">
        <BaseButton
          v-if="isHovered || isMenuActive"
          icon-prepend="dots-vertical"
          round
          class="icon-tertiary-gray hover:!bg-proxify-gray-100 p-2.5"
          icon-size="20px"
          size="8px"
        ></BaseButton>
        <BaseMenu
          :items="menuOptions"
          dense
          @show="isMenuActive = true"
          @hide="isMenuActive = false"
        >
          <template #footer>
            <div
              class="text-body-xs font-normal px-2.5 pb-1 pt-0.5 text-proxify-gray-500 select-none"
            >
              Created on
              <span class="text-proxify-gray-700">
                {{ moment(todo.created_at).format('MMMM D') }}
              </span>
            </div>
          </template>
        </BaseMenu>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.todos-card {
  &__container {
    @apply px-6;
  }

  &__body {
    @apply flex
    items-center
    justify-between
    py-4
    min-h-20
    border-proxify-gray-200;
  }

  &__content {
    @apply flex
    flex-col
    gap-1;
  }

  &__text {
    @apply flex
    flex-col
    gap-2;

    &-bottom {
      @apply flex
      gap-2
      items-center
      max-w-[292px]
      pl-[30px];
    }
  }

  &__label {
    @apply flex gap-2.5 items-start;
  }

  &__assigner-popup {
    @apply flex
    flex-col
    gap-2
    text-body-xs
    text-proxify-gray-400
    font-normal;
  }

  &__supporting-text {
    @apply flex
    pl-[30px]
    select-none;
  }

  &__assignee {
    @apply flex
    gap-2
    items-center
    whitespace-nowrap
    text-body-xs
    font-normal
    text-proxify-gray-600
    select-none;
  }

  &__completed-at {
    @apply text-body-xs
    font-normal
    whitespace-nowrap
    text-proxify-gray-700;
  }

  &__avatar {
    @apply flex
    gap-1
    items-center
    text-proxify-gray-900
    select-none;
  }
}
</style>
