<script setup>
import { onKeyStroke, useConfirmDialog, useVModel } from '@vueuse/core';
import { reactive, ref, toRefs, watch } from 'vue';
import { useForm } from 'vee-validate';

const { isRevealed, reveal, confirm } = useConfirmDialog();
const { handleSubmit } = useForm();
const handleConfirm = handleSubmit(({ url, title }) => {
  confirm({ url, title });
});

const props = defineProps({
  legends: {
    type: Array,
    required: false,
    default() {
      return [
        {
          value: '#link',
          label: 'link',
          fields: ['url', 'title'],
        },
        {
          value: '#button',
          label: 'button',
          fields: ['url', 'title'],
        },
        {
          value: '#candidate',
          label: 'candidate',
          fields: [],
        },
      ];
    },
  },
  modelValue: {
    type: Object,
    required: false,
    default() {
      return {};
    },
  },
  markdown: {
    type: String,
    required: false,
    default: '',
  },
  dataAttributes: {
    type: Array,
    required: false,
    default() {
      return ['id', 'value', 'denotationChar', 'link', 'target', 'fields'];
    },
  },
  onUpdateHTML: {
    type: Function,
    required: false,
    default() {
      return () => {};
    },
  },
  setEdited: {
    type: Function,
    required: false,
    default() {
      return () => {};
    },
  },
});

const state = reactive({
  selectedLegend: '',
  initialMarkdown: props.modelValue?.markdown ?? '',
  initialSubject: '',
});
const emit = defineEmits(['update:modelValue']);

const { selectedLegend, initialMarkdown, initialSubject } = toRefs(state);

const template = useVModel(props, 'modelValue', emit);

const mentionList = (searchTerm, renderList) => {
  renderList(
    props.legends
      .filter(({ value }) => {
        return value.toLowerCase().includes(searchTerm.toLowerCase());
      })
      .map(({ label: value, value: id, fields }) => ({
        id,
        value,
        fields,
      })),
    searchTerm
  );
};

const onSelectMention = async (item, selectItem) => {
  if (item.fields.length) {
    state.selectedLegend = item.value;
    const { data } = await reveal();
    const { title, url } = data ?? {};
    if (title && url) {
      selectItem({
        ...item,
        id: `${item.id}[${title}](${url})`,
        value: `${item.value}<[>${title}<]><(>${url}<)>`
          .replaceAll('<', '<span class="text-proxify-black mx-px">')
          .replaceAll('>', '</span>'),
      });
    }
    state.title = '';
    state.url = '';
  } else {
    selectItem(item);
  }
};

const quillOptions = {
  modules: {
    toolbar: [],
    mention: {
      allowedChars: /^[A-Za-z\s]*$/,
      mentionDenotationChars: ['#'],
      defaultMenuOrientation: 'top',
      fixMentionsToQuill: true,
      source: mentionList,
      dataAttributes: props.dataAttributes,
      onSelect: onSelectMention,
    },
  },
};

watch(
  template,
  (newValue, oldValue) => {
    if (newValue?.id && newValue?.id !== oldValue?.id) {
      initialMarkdown.value = newValue.markdown;
      initialSubject.value = newValue.subject;
    }
    if (newValue?.subject !== initialSubject.value) {
      props.setEdited(true);
    }
  },
  { immediate: true, deep: true }
);

const legendInfo = ref(null);

onKeyStroke(
  'Enter',
  (event) => {
    event.preventDefault();
    handleConfirm();
  },
  { target: legendInfo }
);
onKeyStroke(
  'Escape',
  (event) => {
    event.preventDefault();
    confirm(null);
  },
  { target: legendInfo }
);
const outputOptions = {
  removeSpans: true,
  removeDatasets: true,
  removeBrs: false,
};
</script>
<template>
  <div>
    <AppFormTextEditor
      ref="templateEditor"
      v-model="template.markdown"
      :on-update-h-t-m-l="onUpdateHTML"
      :output-options="outputOptions"
      :set-edited="setEdited"
      name="Email Body"
      :initial-value="initialMarkdown"
      wrapper-classes="border !border-[#CCD1D7] !rounded-lg"
      :options="quillOptions"
      placeholder="Type in here"
      disable-actions
      required
      italic
      class="font-normal"
    >
      <template #input-header>
        <div class="border-b border-[#000000]/20 mx-4">
          <AppFormTextField
            v-model="template.subject"
            label="Email Subject"
            hide-label
            dense
            borderless
            paddingless
          />
        </div>
      </template>
    </AppFormTextEditor>
    <AppPopup
      :active="isRevealed"
      absolute
    >
      <template #content>
        <div
          ref="legendInfo"
          class="block w-64"
        >
          <div class="popup-title">#{{ selectedLegend }}</div>
          <AppFormTextField
            ref-name="title"
            class="mb-4"
            :focus="true"
            value-class="text-proxify-primary"
            placeholder="Title"
            label="title"
            hide-label
            required
          />
          <AppFormTextField
            ref-name="url"
            type="url"
            value-class="text-proxify-primary"
            placeholder="URL"
            label="url"
            hide-label
            required
          />
          <div class="popup-actions">
            <div
              class="action-button"
              @click="confirm(null)"
            >
              Cancel
            </div>
            <div
              class="action-button"
              @click="handleConfirm"
            >
              Add
            </div>
          </div>
        </div>
      </template>
    </AppPopup>
  </div>
</template>

<style scoped>
.popup-title {
  @apply font-bold mb-4 font-poppins text-lg;
}

.popup-actions {
  @apply flex justify-between mt-8 text-proxify-primary;
}

.popup-actions .action-button {
  @apply cursor-pointer;
}

:deep(.mention) {
  @apply text-proxify-primary;
}
</style>
