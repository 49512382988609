<script setup>
import { BaseButton, BaseIcon, BaseTooltip } from '@/components/Base';
import { PSeparator } from '@/components/ProxifyUI';
import { computed, toRefs } from 'vue';

const props = defineProps({
  modelValue: {
    type: String,
    default: 'desc',
  },
});

const { modelValue } = toRefs(props);

const emit = defineEmits(['update:modelValue']);

const tooltipData = computed(() => {
  return {
    title: modelValue.value === 'asc' ? 'Sorted by oldest' : 'Sorted by newest',
    message:
      modelValue.value === 'asc'
        ? 'Click to sort by newest'
        : 'Click to sort by oldest',
    sortedByAsc: modelValue.value === 'asc',
  };
});

const setSortOrder = () => {
  emit('update:modelValue', modelValue.value === 'asc' ? 'desc' : 'asc');
};
</script>
<template>
  <div class="flex items-center">
    <BaseButton
      class="icon-link-gray p-0"
      :class="{ '-scale-y-100': tooltipData.sortedByAsc }"
      icon-prepend="switch_vertical02"
      @click.stop="setSortOrder"
    />
    <BaseTooltip
      tooltip-class="shadow-lg gap-[12px] w-[168px]"
      max-width="216px"
    >
      <div class="flex flex-col gap-y-2 text-proxify-gray-700">
        <div class="font-semibold text-body-sm flex gap-x-2 items-center">
          <BaseIcon
            class="transition duration-300"
            name="switch_vertical02"
            :class="{ '-scale-y-100': tooltipData.sortedByAsc }"
          />
          {{ tooltipData.title }}
        </div>
        <PSeparator color="#eaecf0" />
        <div class="font-normal text-body-xs">{{ tooltipData.message }}</div>
      </div>
    </BaseTooltip>
  </div>
</template>
