import script from "./BaseRadio.vue?vue&type=script&setup=true&lang=js"
export * from "./BaseRadio.vue?vue&type=script&setup=true&lang=js"

import "./BaseRadio.vue?vue&type=style&index=0&id=3e0516ce&lang=scss"

const __exports__ = script;

export default __exports__
import QRadio from 'quasar/src/components/radio/QRadio.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QRadio});
