<script setup>
import { ref } from 'vue';
import moment from 'moment';

const props = defineProps({
  disablePastDates: {
    type: Boolean,
    default: false,
  },
});

const element = ref(null);
defineExpose({
  offsetCalendar: (type, descending) =>
    element.value?.offsetCalendar?.(type, descending),
});

const optionsFunction = (date) => {
  return !props.disablePastDates || date >= moment().format('YYYY/MM/DD');
};
</script>
<template>
  <q-date
    ref="element"
    class="p-date"
    no-unset
    color="proxify-gray-900"
    :options="optionsFunction"
  >
    <slot />
  </q-date>
</template>
<style lang="scss">
.p-date {
  .q-date__calendar-item--out,
  .q-date__calendar-item--fill {
    @apply opacity-[0.18] select-none;
  }
  .q-btn-item:hover,
  .q-date__today:hover {
    @apply bg-proxify-purple-200
    text-proxify-gray-900
    transition
    duration-300 #{!important};
  }
  .q-date__edit-range-from .q-date__today {
    @apply bg-proxify-primary-600 #{!important};
  }
  .q-date__range-from:before,
  .q-date__range-to:before {
    @apply bg-proxify-gray-50 opacity-100;
  }
  .q-date__edit-range {
    @apply bg-proxify-gray-50;
  }
  .q-date__calendar-item:after {
    @apply content-none #{!important};
  }
  .q-date__range {
    .q-btn {
      @apply text-inherit #{!important};
    }
  }
  .q-date__range:before {
    @apply bg-proxify-gray-50
    opacity-100;
  }
  .q-date__edit-range-from {
    @apply bg-proxify-gray-50
    rounded-l-[50%];
    .q-btn {
      @apply bg-proxify-primary-600
      rounded-full
      text-white #{!important};
    }
  }
  .q-date__edit-range-to {
    @apply bg-proxify-gray-50
    rounded-r-[50%];
    .q-btn {
      @apply bg-proxify-primary-600
      rounded-full
      text-white #{!important};
    }
  }
}
</style>
