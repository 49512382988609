import script from "./PDate.vue?vue&type=script&setup=true&lang=js"
export * from "./PDate.vue?vue&type=script&setup=true&lang=js"

import "./PDate.vue?vue&type=style&index=0&id=2d4f8cb0&lang=scss"

const __exports__ = script;

export default __exports__
import QDate from 'quasar/src/components/date/QDate.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QDate,QBtn});
