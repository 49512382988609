<template>
  <div class="relative">
    <slot name="toggler" :toggle="toggle" />
    <slot name="content" :toggle="toggle" :active="active" />
  </div>
</template>

<script>
export default {
  name: 'AppDropdown',
  data() {
    return {
      active: false,
    };
  },
  methods: {
    toggle() {
      this.active = !this.active;
    },
  },
};
</script>
