import api from '@/api';
import axios from 'axios';
import moment from 'moment';
import { browserUtils } from '@/utils';

const CONTAINERS_INITIAL_STATE = [
  {
    id: 8,
    name: 'Sourcing Inbox',
    days_due: null,
    recruiter_required: 0,
    interview_required: 0,
    interviewer_required: 0,
    is_sourcing: 1,
    counters: {
      total: 0,
    },
    new_applications: [],
    applications: [],
  },
  {
    id: 9,
    name: 'Sourcing Review',
    days_due: null,
    recruiter_required: 0,
    interview_required: 0,
    interviewer_required: 0,
    is_sourcing: 1,
    counters: {
      total: 0,
    },
    new_applications: [],
    applications: [],
  },
  {
    id: 1,
    name: 'Inbox',
    days_due: 5,
    recruiter_required: 0,
    interview_required: 0,
    interviewer_required: 0,
    is_sourcing: 0,
    counters: {
      total: 0,
    },
    new_applications: [],
    applications: [],
  },
  {
    id: 2,
    name: 'Reviewing',
    days_due: 1,
    recruiter_required: 1,
    interview_required: 0,
    interviewer_required: 0,
    is_sourcing: 0,
    counters: {
      total: 0,
    },
    new_applications: [],
    applications: [],
  },
  {
    id: 3,
    name: 'Screening',
    days_due: 5,
    recruiter_required: 1,
    interview_required: 1,
    interviewer_required: 0,
    is_sourcing: 0,
    counters: {
      total: 0,
    },
    new_applications: [],
    applications: [],
  },
  {
    id: 4,
    name: 'Technical Assessment',
    days_due: 5,
    recruiter_required: 1,
    interview_required: 0,
    interviewer_required: 0,
    is_sourcing: 0,
    counters: {
      total: 0,
    },
    new_applications: [],
    applications: [],
  },
  {
    id: 5,
    name: 'Technical Interview',
    days_due: 5,
    recruiter_required: 1,
    interview_required: 1,
    interviewer_required: 1,
    is_sourcing: 0,
    counters: {
      total: 0,
    },
    new_applications: [],
    applications: [],
  },
  {
    id: 6,
    name: 'Membership Invite',
    days_due: 5,
    recruiter_required: 1,
    interview_required: 0,
    interviewer_required: 0,
    is_sourcing: 0,
    counters: {
      total: 0,
    },
    new_applications: [],
    applications: [],
  },
  {
    id: 7,
    name: 'Joined network',
    days_due: null,
    recruiter_required: 1,
    interview_required: 0,
    interviewer_required: 0,
    is_sourcing: 0,
    counters: {
      total: 0,
    },
    new_applications: [],
    applications: [],
  },
];

const state = {
  containers: CONTAINERS_INITIAL_STATE,
  total: null,
  counters: {},
  filterQueries: {},
  interviewers: null,
  cancelRequest: null,
  containerHeight: 0,
  sources: [],
};

const getters = {};

const actions = {
  getContainers({ commit, state }, { params }) {
    return new Promise((resolve, reject) => {
      state.cancelRequest?.cancel();
      const request = axios.CancelToken.source();
      commit('setCancelToken', request);
      commit('setContainers', CONTAINERS_INITIAL_STATE);
      commit('setTotalApplications', null);
      return api.applications
        .getApplications({
          params: { ...state.filterQueries, ...params },
          cancelToken: state.cancelRequest.token,
        })
        .then(
          (response) => {
            if (response && response.data && response.data.data) {
              response.data.data.map(({ id }) => {
                commit('setCounterNeedUpdate', id);
              });
              commit('setCancelToken', null);
              commit('setContainers', response.data.data);
              commit('setTotalApplications', response.data.meta.total);
              resolve(response);
            }
          },
          (error) => {
            if (!axios.isCancel(error)) {
              reject(error);
            }
          }
        );
    });
  },
  async getInterviewers({ commit, state }) {
    if (state.interviewers) {
      return;
    }
    const { data: interviewers } = await api.users.techInterviewers();
    commit('setInterviewers', interviewers.data);
  },

  downloadAttachment({ commit }, downloadUrl) {
    const { openExternalLink } = browserUtils;

    api.applications.downloadAttachment(downloadUrl)
      .then((response) => {
        openExternalLink(response.data.url);
      });
  }
};

/**
 * Determines if an application should be added to a given container.
 * @param {Object} container - The container to potentially add the application to.
 * @param {string} nextStage - The id of the next stage.
 * @returns {boolean} - Whether the application should be added to the container.
 */
const shouldAddToContainer = (container, nextStage) => {
  // Check if sorting is ascending
  const isSortAsc = state.filterQueries?.sort === 'asc';
  // Check if container is the correct stage
  const isCorrectContainer = container.id === nextStage;

  // Check if container is not full
  const isContainerNotFull =
    container.counters.total <= container.applications.length;

  // Check if application count is correct
  const isCorrectApplicationCount =
    !container.applications.length || container.applications.length % 10 !== 0;

  // Determine if application should be added based on container state and filter queries
  return (
    isCorrectContainer &&
    (!isSortAsc || isContainerNotFull) &&
    (!isSortAsc || isCorrectApplicationCount)
  );
};

/**
 * Updates the applications list by adding a new application and ensuring order.
 * @param {Array} applications - The current array of applications in the container.
 * @param {Object} applicationToAdd - The application to add to the container.
 * @returns {Array} - The updated array of applications.
 */
const updateApplications = (applications, applicationToAdd) => {
  // Check if sorting is ascending
  const isSortAsc = state.filterQueries?.sort === 'asc';
  // Return updated applications array based on the sorting order
  if (isSortAsc) {
    return [
      ...applications.filter(({ id }) => id !== applicationToAdd.id),
      applicationToAdd,
    ];
  }
  return [
    applicationToAdd,
    ...applications.filter(({ id }) => id !== applicationToAdd.id),
  ];
};

const mutations = {
  setCancelToken(state, request) {
    state.cancelRequest = request;
  },
  setContainers(state, containers) {
    state.containers = containers;
  },
  setTotalApplications(state, total) {
    state.total = total;
  },
  setCounterNeedUpdate(state, counterId) {
    state.counters = { ...state.counters, [counterId]: Date.now() };
  },
  setFilterQueries(state, filterQueries) {
    state.filterQueries = filterQueries;
    localStorage.setItem('filterQueries', JSON.stringify(filterQueries));
  },
  setInterviewers(state, interviewers) {
    state.interviewers = interviewers;
  },
  setSources(state, sources) {
    state.sources = Object.entries(sources).map(([value, name]) => ({
      name,
      value,
    }));
  },
  /**
   * Moves an application to a specified stage within the state.
   * @param {Object} state - The current state of the application.
   * @param {Object} params - Object containing stageTo and application.
   * @param {string} params.stageTo - The destination stage id for the application.
   * @param {Object} params.application - The application object to be moved.
   */
  moveApplication(state, { stageTo, application }) {
    // Current timestamp formatted using moment.js
    const now = moment(new Date()).format();

    // Update each container's applications
    state.containers = state.containers.map((container) => {
      if (shouldAddToContainer(container, stageTo)) {
        // Create an updated application object with the new last_activity time
        const updatedApplication = {
          ...application,
          last_activity: now,
        };

        // Update the applications in the container
        container.applications = updateApplications(
          container.applications,
          updatedApplication
        );
      }

      return container;
    });
  },
  addToNewApplications(state, { stageId, application }) {
    state.containers = [
      ...state.containers.map(({ ...container }) => {
        return {
          ...container,
          new_applications: [
            ...container.new_applications,
            ...(container.id === stageId ? [application.id] : []),
          ],
        };
      }),
    ];
  },
  removeFromNewApplications(state, { stageId, application }) {
    state.containers = [
      ...state.containers.map(({ ...container }) => {
        return {
          ...container,
          new_applications: [
            ...(container.id === stageId
              ? container.new_applications.filter((id) => id !== application.id)
              : container.new_applications),
          ],
        };
      }),
    ];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
