<script setup>
import { computed } from 'vue';
import { BaseIcon, BaseButton } from '@/components/Base';
const props = defineProps({
  color: {
    type: String,
    default: 'gray',
    validator: (value) => {
      return ['gray', 'primary', 'success', 'warning', 'error'].includes(value);
    },
  },
  hideCloseButton: {
    type: Boolean,
    default: false,
  },
  breakpoint: {
    type: String,
    default: 'default',
  },
  title: {
    type: String,
    default: '',
  },
  vertical: {
    required: false,
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['close']);

const icon = computed(() => {
  return {
    gray: 'info-circle',
    primary: 'info-circle',
    success: 'check-circle',
    warning: 'alert-triangle',
    error: 'alert-circle',
  }[props.color];
});

const colorClass = computed(() => {
  return `p-alert_${props.color}`;
});
</script>
<template>
  <div
    class="w-full shadow-sm font-inter p-[16px] flex flex-col gap-[12px] rounded-[12px] border relative"
    :class="colorClass"
  >
    <div
      class="flex"
      :class="{ 'flex-col': !vertical }"
    >
      <div class="flex gap-[12px] items-center flex-wrap">
        <BaseIcon
          :name="icon"
          size="20px"
          :class="colorClass"
        />
        <div
          v-if="breakpoint === 'mobile'"
          class="grow"
        ></div>
        <slot name="title">
          <div
            class="text-body-lg font-semibold grow"
            :class="{ 'basis-full order-last': breakpoint === 'mobile' }"
          >
            {{ props.title }}
          </div>
        </slot>
        <BaseButton
          v-if="!props.hideCloseButton"
          icon-prepend="x-close"
          round
          class="icon-tertiary text-body-xs absolute top-[8px] right-[8px]"
          :class="colorClass"
          @click="emit('close')"
        />
      </div>
      <div
        v-if="$slots.default"
        :class="{ 'mb-[12px]': $slots.actions, 'mt-1': !vertical }"
      >
        <slot />
      </div>
      <slot name="actions"></slot>
    </div>
  </div>
</template>
<style lang="scss">
.p-alert {
  &_gray {
    @apply bg-proxify-gray-25 border-proxify-gray-300 text-proxify-gray-700 #{!important};
  }
  &_primary {
    @apply bg-proxify-primary-25 border-proxify-primary-300 text-proxify-primary-700 #{!important};
  }
  &_success {
    @apply bg-proxify-success-25 border-proxify-success-300 text-proxify-success-700 #{!important};
  }
  &_warning {
    @apply bg-proxify-warning-25 border-proxify-warning-300 text-proxify-warning-700 #{!important};
  }
  &_error {
    @apply bg-proxify-error-25 border-proxify-error-300 text-proxify-error-700 #{!important};
  }
}
</style>
