import script from "./AuthenticatedLayoutHeader.vue?vue&type=script&setup=true&lang=js"
export * from "./AuthenticatedLayoutHeader.vue?vue&type=script&setup=true&lang=js"

import "./AuthenticatedLayoutHeader.vue?vue&type=style&index=0&id=34ccc6e2&lang=css"

const __exports__ = script;

export default __exports__
import QBreadcrumbs from 'quasar/src/components/breadcrumbs/QBreadcrumbs.js';
import QBreadcrumbsEl from 'quasar/src/components/breadcrumbs/QBreadcrumbsEl.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBreadcrumbs,QBreadcrumbsEl});
