<template>
  <div
    class="loader"
    data-testid="app-loader"
  >
    <div class="bounce1"></div>
    <div class="bounce2"></div>
    <div class="bounce3"></div>
  </div>
</template>

<script>
export default {
  name: 'AppLoader',
  props: {
    backgroundColor: {
      type: String,
      default() {
        return '#434343';
      },
    },
    size: {
      type: String,
      default() {
        return '0.25rem';
      },
    },
  },
};
</script>

<style scoped>
.loader {
  text-align: center;
  z-index: 1;
  display: inline-block;
}

.loader > div {
  @apply w-[v-bind(size)] h-[v-bind(size)] my-0 mx-px rounded-full inline-block;

  background-color: v-bind(backgroundColor);
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.loader .bounce1 {
  animation-delay: -0.32s;
}

.loader .bounce2 {
  animation-delay: -0.16s;
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    transform: scale(0);
  }

  40% {
    transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    transform: scale(0);
  }

  40% {
    transform: scale(1);
  }
}
</style>
